import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RadiologyService } from '../../../services/radiology.service';
import { Router } from '@angular/router';
import { TechAcknowledgementService } from '../../../services/tech-acknowledgement.service';
import { HospitaldoctorService } from '../../../services/hospitaldoctor.service';
import { environment } from '../../../../environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../../services/user.service';
import { ConfigurationService } from '../../../services/configuration.service';
import { ShareDataService } from '../../../services/share-data.service';
import * as moment from 'moment';
import { formatDate } from '@angular/common';
import { LabTestService } from '../../../services/lab-test.service';
import { RouteShareService } from '../../../services/route-share.service';
import { SystemControlsService } from '../../../services/system-controls.service';

declare var $: any;
declare var toastr: any;
declare var tinymce: any;
@Component({
  selector: 'app-report-writing-new-v2',
  templateUrl: './report-writing-new-v2.component.html',
  styleUrls: ['./report-writing-new-v2.component.css'],
})
export class ReportWritingNewV2Component implements OnInit {
  @ViewChild('conformCancelModal') conformCancelModal: ElementRef;
  @ViewChild('changeDrModal') changeDrModal: ElementRef;
  pageDataList: any;
  listData: any;
  manufactureSearchValue = '';
  searchType = 'default';
  listIds = [];
  doctors = [];
  templateNames = [];
  reportsData = [];
  docId = '';
  history = '';
  impression = '';
  type = '';
  report = '';
  reportName = '';
  service_id: any;
  activeIndex: any;
  currentPatient: any;
  patientData: any;
  current_environment: string;
  showReports = false;
  showReport = false;
  previous_report = '';
  rejectedSampleReason = '';
  rejectedSampleId: any;
  classic = true;
  currentHospital = environment.hospital;
  subDepartments = [];
  departmentId = '';
  showFinalSignButton = true;
  screenControls = [];
  radiologyPacsUrls: any;
  currentPacUrl: any;
  changDrType: any;
  changDrId: any;
  changObjId: any;
  currentControls: any;
  fromDate: any;
  toDate: any;
  today = formatDate(new Date(), 'dd-MM-yyyy', 'en');
  showHistory = false;
  patientType = 'self';
  urgentAction = false;
  hospitalName = environment.hospital;
  mrn: any;
  screenName = '';
  constructor(
    private ngxLoader: NgxUiLoaderService,
    private radiologyService: RadiologyService,
    private router: Router,
    private ref: ChangeDetectorRef,
    private techAcknowledgementService: TechAcknowledgementService,
    private hospitaldoctorService: HospitaldoctorService,
    private modalService: NgbModal,
    private userService: UserService,
    private configService: ConfigurationService,
    private shareDataService: ShareDataService,
    private labTestService: LabTestService,
    private routeSevice: RouteShareService,
    private systemControlService: SystemControlsService,
  ) {
    this.screenName = this.routeSevice.getValue();
    this.current_environment = environment.rorUrl;
  }
  openResultModal(): any {
    $('#ResultModal').modal('show');
  }
  closeResultModal(): any {
    $('#ResultModal').modal('hide');
  }
  ngOnInit(): void {
    tinymce.remove('#reports');
    // $('#reportMain').empty();
    setTimeout(() => {
      tinymce.init({
        selector: '#reports',
        menubar: false,
        height: 400,
        toolbar: [
          'undo redo | cut copy paste | bold italic | link image | alignleft aligncenter alignright alignjustify | blockquote advlist | autolink lists charmap code | styleselect fontselect fontsizeselect | bullist numlist | outdent indent | table | lineheightselect',
        ],
        plugins:
          'advlist autolink link image lists charmap code table lineheight',
        content_style: 'body { font-size: 16pt; font-family: Arial; }',
      });
    }, 100);
    // this.shareDataService.controlCheck.subscribe(data => {
    this.systemControlService.getUpdatedChecks().subscribe((data) => {
      this.currentControls = data.report_controls;
    });
    this.fetchTemplates();
    this.getScreenControls();
    this.fetchDoctors();
    this.getRadiologySubDepartments();
    this.getRadiologyPACSUrls();
    $('.selectpicker').selectpicker();
    $('#fromDate').datepicker({
      format: 'dd-mm-yyyy',
      orientation: 'bottom left',
    });
    $('#toDate').datepicker({
      format: 'dd-mm-yyyy',
      orientation: 'bottom right',
    });
    const date = new Date();
    date.setDate(date.getDate() - 1);
    const yesterday = moment(date).format('DD-MM-yyyy');
    setTimeout(() => {
      $('#fromDate').datepicker('setDate', yesterday);
      $('#toDate').datepicker('setDate', this.today);
      this.searchFilter();
    }, 1000);
  }
  searchFilterValue(): any {
    this.searchFilter();
  }
  searchFilter(): any {
    this.ngxLoader.start();
    this.listData = [];
    this.pageDataList = [];
    this.fromDate = $('#fromDate').val();
    this.toDate = $('#toDate').val();
    this.currentPatient = '';

    this.techAcknowledgementService
      .searchRadiologyWritingServicesV2(
        '',
        '',
        this.manufactureSearchValue,
        true,
        this.departmentId,
        this.searchType,
        this.fromDate,
        this.toDate,
        '/radiology/radiology-writing-v2',
        this.patientType,
      )
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          this.pageDataList = data.patient_services;
          this.listData = data.patient_services;
          this.ref.markForCheck();
          if (this.pageDataList.length > 0) {
            if (!this.currentPatient) {
              setTimeout(() => {
                this.fetchReports(this.pageDataList[0], 0);
              }, 500);
            }
          }
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  fetchDoctors(): any {
    this.ngxLoader.start();
    this.hospitaldoctorService.fetchRefDoctorsDoctorV2().subscribe(
      (data) => {
        this.doctors = data.users;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }
  getRadiologyPACSUrls(): any {
    this.configService.getPACSUrlV2().subscribe(
      (data) => {
        if (data.radiology_configurator) {
          this.radiologyPacsUrls = data.radiology_configurator;
          this.updatePACSURL(true);
        }
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.error);
      },
    );
  }
  updateFilters(type): any {
    this.pageDataList = [];
    if (type === '') {
      this.pageDataList = this.listData;
    }
    if (type === 'urgent') {
      for (let i = 0; i < this.listData.length; i++) {
        if (this.listData[i].urgent === true) {
          this.pageDataList.push(this.listData[i]);
        }
      }
    }
    if (type === 'returned') {
      for (let i = 0; i < this.listData.length; i++) {
        if (this.listData[i].is_rejected === true) {
          this.pageDataList.push(this.listData[i]);
        }
      }
    }
    if (type === 'opd') {
      for (let i = 0; i < this.listData.length; i++) {
        if (this.listData[i].ipd === false) {
          this.pageDataList.push(this.listData[i]);
        }
      }
    }
    if (type === 'ipd') {
      for (let i = 0; i < this.listData.length; i++) {
        if (this.listData[i].ipd === true) {
          this.pageDataList.push(this.listData[i]);
        }
      }
    }
  }
  fetchTemplates(): any {
    this.ngxLoader.start();
    this.templateNames = [];
    setTimeout(() => {
      $('#templateList').selectpicker('refresh');
    }, 500);
    this.radiologyService.searchTemplateV2('', true, '').subscribe(
      (data) => {
        this.templateNames = data.templates;
        setTimeout(() => {
          $('#templateList').selectpicker('refresh');
        }, 500);
        // setTimeout(() => {
        //   $('#templateList').selectpicker();
        // }, 1000);
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }
  setReportTemplete(id): any {
    const reports = this.templateNames.filter((t) => t.id === Number(id))[0];
    if (reports) {
      tinymce.remove('#impressions');
      // $('#reportMain').empty();
      this.classic = reports.classic;
      tinymce.get('reports').setContent(reports.report);
      if (this.classic === false) {
        setTimeout(() => {
          tinymce.init({
            selector: '#impressions',
            menubar: false,
            height: 400,
            toolbar: [
              'undo redo | cut copy paste | bold italic | link image | alignleft aligncenter alignright alignjustify | blockquote advlist | autolink lists charmap code | styleselect fontselect fontsizeselect | bullist numlist | outdent indent | table | lineheightselect',
            ],
            plugins:
              'advlist autolink link image lists charmap code table lineheight',
            content_style: 'body { font-size: 16pt; font-family: Arial; }',
          });
        }, 200);
        setTimeout(() => {
          // $('#impressions').summernote('code', reports.impression);
          tinymce.get('impressions').setContent(reports.impression);
          // $('#impressions').summernote('code', reports.impression);
        }, 500);
      }
    }
  }
  resetSummerNotes(): any {
    tinymce.get('reports').setContent('');
    if (this.classic === false) {
      tinymce.get('impressions').setContent('');
    }
    this.reportName = '';
  }
  savePatientReport(value, isPrint): any {
    this.type = value;
    this.report = tinymce.get('reports').getContent();
    this.impression = '';
    if (this.type === 'report' || this.type === 'sign') {
      if (
        this.service_id === null ||
        this.service_id === undefined ||
        this.service_id === ''
      ) {
        toastr.error('Select the investigation to submit report.');
        return;
      }
    }
    // if (this.type !== 'report' && ( $('#reports').summernote('isEmpty') === true )) {
    //   toastr.error('Report cannot be empty.');
    //   return;
    // }
    const reportsMsgTxt = $.trim($(this.report).text());
    if (this.type !== 'report' && reportsMsgTxt.trim() === '') {
      toastr.error('Report cannot be empty.');
      return;
    }
    // if ( this.classic === false ) {
    //   this.impression = $('#impressions').summernote('code');
    //   if (this.type !== 'report' && ( $('#impressions').summernote('isEmpty') === true )) {
    //     toastr.error('Impression cannot be empty.');
    //     return;
    //   }
    // }
    if (this.classic === false) {
      this.impression = tinymce.get('impressions').getContent();
      const impressionsMsgTxt = $.trim($(this.impression).text());
      if (this.type !== 'report' && impressionsMsgTxt.trim() === '') {
        toastr.error('Impression cannot be empty.');
        return;
      }
    }
    if (this.type === 'template' && this.reportName === '') {
      toastr.error('Template Name cannot be empty.');
      return;
    }
    this.ngxLoader.start();
    this.radiologyService
      .saveReportWritingV2(
        this.reportName,
        this.report,
        this.impression,
        this.type,
        this.service_id,
        this.classic || false,
        this.history,
        this.urgentAction,
      )
      .subscribe(
        (data) => {
          if (data.success) {
            toastr.success(data.message);
          } else {
            toastr.error(data.message);
          }
          this.ngxLoader.stop();
          if (data.report && this.type === 'sign') {
            this.resetSummerNotes();
            this.service_id = '';
            this.printReport(data, this.currentPatient);
            if (this.hospitalName === 'farooqHospital') {
              this.verifyapproval(this.currentPatient.patient.mrn);
            }
          }
          if (data.report && isPrint === true) {
            this.printReport(data, this.currentPatient);
          } else {
            // this.searchFilter();
          }
          if (this.type === 'template') {
            // this._flashMessagesService.show('Template ' + obj.name + ' save successfully', { cssClass: 'alert-success', timeout: 5000 });
            this.fetchTemplates();
          }
          if (this.type === 'sign') {
            this.currentPatient = null;
            this.searchFilter();
          } else if (this.type === 'report') {
            this.pageDataList[this.activeIndex] = data.investigation;
          } else {
          }
          // if (data['patient_services']) {
          //   this.reportsData = data;
          //   this.history = '';
          //   this.previous_report = '';
          //   this.showReport = false;
          // }
        },
        (err) => {
          toastr.error(err.error.message);
          this.ngxLoader.stop();
        },
      );
  }
  verifyapproval(mrn): any {
    this.labTestService.approvedUnVerifiedLab(mrn).subscribe(
      (data) => {},
      (err) => {
        toastr.error(err.error.message);
      },
    );
  }
  printReport(data, currentPatient): any {
    let patientData = currentPatient;
    if (this.currentControls !== '') {
      if (
        this.currentControls.radiology_services.is_radiology_invoice === false
      ) {
        window.open(
          '/radiology/pdf-report-v2?id=' +
            data.report.id +
            '&visit_id=' +
            patientData.patient.visit_id +
            '&mrn=' +
            patientData.patient.mrn,
        );
      } else {
        window.open(
          this.current_environment +
            '/v4/reports/print_report.pdf?auth_token=' +
            localStorage.getItem('auth_token') +
            '&medical_unit_id=' +
            localStorage.getItem('current_medical_unit_id') +
            '&id=' +
            data.report.id,
        );
      }
    } else {
      window.open(
        this.current_environment +
          '/v4/reports/print_report.pdf?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          data.report.id,
      );
    }
  }
  fetchReports(row, i): any {
    this.closePatientList();
    $('.radiologyReport').removeClass('btn-light');
    $('#radiologyReport' + i).addClass('btn-light');
    this.resetSummerNotes();
    $('#templateList').val('');
    setTimeout(() => {
      $('#templateList').selectpicker('refresh');
    }, 500);
    this.classic = true;
    this.showReports = true;
    this.showReport = false;
    this.showHistory = false;
    this.ngxLoader.start();
    this.activeIndex = i;
    this.currentPatient = row;
    console.log(
      'currentPatient?.any_contraindications_to_receive_contrast_textarea',
      this.currentPatient?.any_contraindications_to_receive_contrast_textarea,
    );
    this.service_id = row.id;
    console.log('this.history', this.history);
    this.history = row.history;
    setTimeout(() => {
      $('#templateList').selectpicker('refresh');
    }, 500);
    tinymce.remove('#impressions');
    if (row.report) {
      this.classic = row.report.classic;
      tinymce.remove('#reports');
      setTimeout(() => {
        tinymce.init({
          selector: '#reports',
          menubar: false,
          height: 400,
          toolbar: [
            'undo redo | cut copy paste | bold italic | link image | alignleft aligncenter alignright alignjustify | blockquote advlist | autolink lists charmap code | styleselect fontselect fontsizeselect | bullist numlist | outdent indent | table | lineheightselect',
          ],
          plugins:
            'advlist autolink link image lists charmap code table lineheight',
          content_style: 'body { font-size: 16pt; font-family: Arial; }',
        });
        tinymce.get('reports').setContent(row.report.report);
      }, 500);
      if (this.classic === false) {
        setTimeout(() => {
          tinymce.init({
            selector: '#impressions',
            menubar: false,
            height: 400,
            toolbar: [
              'undo redo | cut copy paste | bold italic | link image | alignleft aligncenter alignright alignjustify | blockquote advlist | autolink lists charmap code | styleselect fontselect fontsizeselect | bullist numlist | outdent indent | table | lineheightselect',
            ],
            plugins:
              'advlist autolink link image lists charmap code table lineheight',
            content_style: 'body { font-size: 16pt; font-family: Arial; }',
          });
          tinymce.get('impressions').setContent(row.report.impression);
        }, 500);
      }
    }
    this.radiologyService.fetchReportsByPatientIdV2(row.patient.id).subscribe(
      (investigations) => {
        this.reportsData = investigations.patient_services;
        // this.updateFilters('all-btn');
        this.ngxLoader.stop();
      },
      (err) => {
        toastr.error(err.error.message);
        this.ngxLoader.stop();
      },
    );
  }
  displayReport(row, id): any {
    $('.radiologyReportPrevious').removeClass('btn-light');
    $('#radiologyReportPrevious' + id).addClass('btn-light');

    // this.service_id = row.id;
    // this.history = row.history;
    if (row.report) {
      // this.previous_report = 'Result: ' + row.report.report;
      // this.previous_report += '\nImpression: ' + row.report.impression;
      setTimeout(() => {
        document.getElementById('previousReport').innerHTML =
          'Result: ' + row.report.report;
        if (row.report.impression) {
          document.getElementById('previousReportImpression').innerHTML =
            '\nImpression: ' + row.report.impression;
        } else {
          document.getElementById('previousReportImpression').innerHTML = '';
        }
      }, 200);
    }
    this.showReport = true;
  }
  rejectLabTest(element): any {
    this.rejectedSampleId = element.id;
    this.rejectedSampleReason = 'Patient Not Arrived';
    this.modalService.open(this.conformCancelModal);
  }
  rejectLabTestFun(): any {
    this.ngxLoader.start();
    this.radiologyService
      .rejectLabTestV2(this.rejectedSampleId, this.rejectedSampleReason)
      .subscribe(
        (data) => {
          this.searchFilter();
          this.modalService.dismissAll(this.conformCancelModal);
          $('#rejectLabTestModal').modal('hide');
          toastr.success('Order Rejected');
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  updateClassicType(): any {
    setTimeout(() => {
      tinymce.remove('#reports');
      tinymce.init({
        selector: '#reports',
        menubar: false,
        height: 400,
        toolbar: [
          'undo redo | cut copy paste | bold italic | link image | alignleft aligncenter alignright alignjustify | blockquote advlist | autolink lists charmap code | styleselect fontselect fontsizeselect | bullist numlist | outdent indent | table | lineheightselect',
        ],
        plugins:
          'advlist autolink link image lists charmap code table lineheight',
        content_style: 'body { font-size: 16pt; font-family: Arial; }',
      });
      if (this.classic === false) {
        // $('#impressions').summernote({
        //   height: 230
        // });
        // $('#impressionsMain').empty();
        // $('#impressionsMain').append('<div class="summernote" id="impressions"></div>');
        tinymce.remove('#impressions');
        // tinymce.get('impressions').setContent('');
        tinymce.init({
          selector: '#impressions',
          menubar: false,
          height: 400,
          toolbar: [
            'undo redo | cut copy paste | bold italic | link image | alignleft aligncenter alignright alignjustify | blockquote advlist | autolink lists charmap code | styleselect fontselect fontsizeselect | bullist numlist | outdent indent | table | lineheightselect',
          ],
          plugins:
            'advlist autolink link image lists charmap code table lineheight',
          content_style: 'body { font-size: 16pt; font-family: Arial; }',
        });
      }
    }, 500);
  }
  openDiscomImage(id): any {
    // window.open('RaidCustomProtocol:http://demo.aizahwelfaresociety.com/1.2.392.200036.9116.2.6.1.48.1211419013.1560188486.914355');
    // window.open(environment.pacsUrl + '/weasis-pacs-connector/viewer?accessionNumber=' + id);
    const newUrl = this.currentPacUrl.replace('{:id}', id);
    console.log('newUrl', newUrl);
    window.open(newUrl);
    // if ( this.currentHospital === 'uolHospital' ) {
    //   // window.open('radiant://?n=paet&v=HPACS&n=pstv&v=' + id + '&v=ACCESSION-NUMBER');
    //   window.open('radiant://?n=paet&v=HPACS&n=pstv&v=00080050&v=%22' + id + '%22');
    // } else {
    //   window.open(environment.pacsUrl + '/weasis-pacs-connector/viewer?accessionNumber=' + id);
    // }
  }
  updatePACSURL(value: boolean): any {
    if (value === true) {
      this.currentPacUrl = this.radiologyPacsUrls.web_url;
    } else {
      this.currentPacUrl = this.radiologyPacsUrls.radiant_url;
    }
  }
  getRadiologySubDepartments(): any {
    this.subDepartments = [];
    this.radiologyService.radiologySubDeptByUserV2().subscribe(
      (data) => {
        this.subDepartments = data.departments;
        setTimeout(() => {
          $('#department').selectpicker('refresh');
        }, 200);
      },
      (err) => {},
    );
  }
  getScreenControls(): any {
    this.userService.UserControlByScreenV2().subscribe(
      (data) => {
        this.screenControls = data.roles;
        if (
          this.screenControls.filter((m) => m.code === 'showfinalsignbutton')
            .length > 0
        ) {
          if (
            this.screenControls.filter(
              (m) => m.code === 'showfinalsignbutton',
            )[0].is_show === true
          ) {
            this.showFinalSignButton = true;
          } else {
            this.showFinalSignButton = false;
          }
        }
      },
      (err) => {},
    );
  }
  changeDoctor(type, id, drId): any {
    this.changDrId = drId;
    this.changObjId = id;
    this.changDrType = type;
    this.modalService.open(this.changeDrModal);
    setTimeout((e) => {
      $('#doctorsList').selectpicker('refresh');
    }, 500);
    setTimeout((e) => {
      $('#doctorsList').selectpicker();
    }, 800);
  }
  changeDoctorFun(): any {
    if (this.changDrId === '') {
      toastr.error('Select Doctor');
      return;
    }
    this.ngxLoader.start();
    let obj;
    if (this.changDrType === 'referring') {
      obj = {
        performing_doctor_id: '',
        referring_doctor_id: this.changDrId,
        ids: [],
      };
      obj.ids.push(this.changObjId);
    } else {
      obj = {
        performing_doctor_id: this.changDrId,
        referring_doctor_id: '',
        ids: [],
      };
      obj.ids.push(this.changObjId);
    }
    this.radiologyService.changeDoctor(obj).subscribe(
      (data) => {
        this.searchFilterValue();
        this.currentPatient = null;
        this.modalService.dismissAll(this.changeDrModal);
        toastr.success('Change Doctor');
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  openPatientList(): any {
    $('#patient-list').toggleClass('offcanvas-on');
  }
  closePatientList(): any {
    $('#patient-list').removeClass('offcanvas-on');
  }
}
