import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  fetchAllUsers(name): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/users/get_usernames?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&name=' +
          name,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchAllUsersv2(name): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/get_usernames?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&name=' +
          name,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchCurrentUser(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/users/specific_user?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getInvoiceControls(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/system_billing_control/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getGeneralControls(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/system_general_control/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&fields=id',
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchUserControlByScreen(): Observable<any> {
    const url = window.location.pathname;
    const userRole = JSON.parse(localStorage.getItem('currentUser'));
    const roleId = userRole.role.id;
    const results = this.http
      .get(
        environment.rorUrl +
          '/v4/roles/role_screen_controls?screen_url=' +
          url +
          '&role_id=' +
          roleId +
          '&auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  UserControlByScreenV2(): Observable<any> {
    const url = window.location.pathname;
    const userRole = JSON.parse(localStorage.getItem('currentUser'));
    const roleId = userRole.role.id;
    const results = this.http
      .get(
        environment.pythonUrl +
          '/api/role_screen_controls?screen_url=' +
          url +
          '&role_id=' +
          roleId +
          '&auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateUserEmergencyType(user_id, emergency): Observable<any> {
    const results = this.http
      .put(
        `${environment.rorUrl}/v4/users/update_emergency_user`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          user_id,
          emergency,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getUserEmergencyType(user_id): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/users/emergency_user?user_id=` +
          user_id +
          '&auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getUserEmergencyTypev2(user_id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/emergency_user?user_id=` +
          user_id +
          '&auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getUserPrescriptionTemplete(user_id): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/user_prescription_templates?user_id=` +
          user_id +
          '&auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getUserPrescriptionTempletev2(user_id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/user_prescription_templates?user_id=` +
          user_id +
          '&auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
