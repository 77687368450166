import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class OtService {
  constructor(private http: HttpClient) {}
  fetchOperationRequests(values): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/operation_requests/all_requests?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&department_id=' +
          values.department_id +
          '&ward_id=' +
          values.ward_id +
          '&urgency_id=' +
          values.urgency_id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchOperationRequestsV2(values): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/ipd/all_requests/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&department_id=' +
          values.department_id +
          '&ward_id=' +
          values.ward_id +
          '&urgency_id=' +
          values.urgency_id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  searchOperationRequests(value): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/operation_requests/all_requests?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          value.type +
          '&query=' +
          value.search_query +
          '&operation_room_id=' +
          value.operation_room_id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchApprovedOperationRequests(values): Observable<any> {
    return this.http
      .get(
        `${environment.rorUrl}/v4/operation_requests/approved_requests?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          values.type +
          '&query=' +
          values.search_query,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  fetchSpecificApprovedOperationRequest(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/operation_requests/` +
          id +
          '?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getAllOtServices(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/ot_services?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getAllOtServicesV2(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/ipd/ot_services/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addOperationRequest(value, patient): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/patients/` + patient + '/operation_requests',
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          operation_request: value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addOperationRequestV2(value, patient): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/ipd/operation_requests/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          operation_request: value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addDocumentInOperationRequests(file, id): Observable<any> {
    const body = new FormData();
    if (file) {
      file.forEach((item: string | Blob) => body.append('file', item));
    }
    const results = this.http.post(
      `${environment.pythonUrl}/ipd/operation_requests/upload_procedure_document/` +
        `?auth_token=` +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id') +
        '&id=' +
        id,
      body,
      {
        headers: new HttpHeaders({
          AuthenticationToken: localStorage.getItem('auth_token'),
        }),
      },
    );
    return results;
  }
  getOperationRooms(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/operation_rooms?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getOperationRoomsV2(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/ipd/operation_rooms?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getParamedicalUsers(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/users/paramedic_users?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  createORRequest(value, id, reqID): Observable<any> {
    const results = this.http
      .put(
        `${environment.rorUrl}/v4/patients/` +
          id +
          `/operation_requests/` +
          reqID,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
          operation_request: value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  createPatientBeforeAnesthesia(value, requestId): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/patient_anesthesias`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          operation_request_id: requestId,
          patient_anesthesia: value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getPatientBeforeAnesthesia(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patient_anesthesias?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&operation_request_id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updatePatientBeforeAnesthesia(value, patientAnesthesiaID): Observable<any> {
    const results = this.http
      .put(
        `${environment.rorUrl}/v4/patient_anesthesias/` + patientAnesthesiaID,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          patient_anesthesia: value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  createPatientBeforeLeavingClinic(value, requestId): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/or_leaving_clinics`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          operation_request_id: requestId,
          or_leaving_clinic: value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getPatientBeforeLeavingClinic(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/or_leaving_clinics?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&operation_request_id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updatePatientBeforeLeavingClinic(value, patientLeavingID): Observable<any> {
    const results = this.http
      .put(
        `${environment.rorUrl}/v4/or_leaving_clinics/` + patientLeavingID,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          or_leaving_clinic: value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  createPatientBeforeGoingOutOR(value, requestId): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/before_leaving_operation_rooms`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          operation_request_id: requestId,
          before_leaving_operation_room: value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getPatientBeforeGoingOutOR(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/before_leaving_operation_rooms?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&operation_request_id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updatePatientBeforeGoingOutOR(value, patientORID): Observable<any> {
    const results = this.http
      .put(
        `${environment.rorUrl}/v4/before_leaving_operation_rooms/` +
          patientORID,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          before_leaving_operation_room: value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  createPatientBeforeSurgicalIncision(value, requestId): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/before_surgical_incisions`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          operation_request_id: requestId,
          before_surgical_incision: value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getPatientBeforeSurgicalIncision(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/before_surgical_incisions?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&operation_request_id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updatePatientBeforeSurgicalIncision(
    value,
    patientSurgicalID,
  ): Observable<any> {
    const results = this.http
      .put(
        `${environment.rorUrl}/v4/before_surgical_incisions/` +
          patientSurgicalID,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          before_surgical_incision: value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateOperationRequestFun(value, data): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/patients/` +
          data.patient.id +
          '/operation_requests/update_status',
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          operation_status: value,
          id: data.id,
          patient_id: data.patient.id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  allOperationRequests(values): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/operation_requests/ot_report_filter?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&department_id=' +
          values.department_id +
          '&ward_id=' +
          values.ward_id +
          '&urgency_id=' +
          values.urgency_id +
          '&from_date=' +
          values.from_date +
          '&to_date=' +
          values.to_date +
          '&operation_room_id=' +
          values.operation_room_id +
          '&type=' +
          values.type,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getProcedureWiseReport(current_page, per_page, values): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/ipd/procedure_wise_report/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          current_page +
          '&per_page=' +
          per_page +
          '&department_id=' +
          values.department_id +
          '&surgeon_id=' +
          values.surgeon_id +
          '&procedure_id=' +
          values.procedure_id +
          '&from_date=' +
          values.from_date +
          '&to_date=' +
          values.to_date +
          '&ward_id=' +
          values.ward_id +
          '&urgency_id=' +
          values.urgency_id +
          '&operation_room_id=' +
          values.operation_room_id +
          '&type=' +
          values.type,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getDocumentsList(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/ipd/operation_requests/send_procedure_document/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  operationRequestNotesFun(patient, request): Observable<any> {
    const results = this.http
      .put(
        `${environment.rorUrl}/v4/patients/` +
          patient.id +
          '/operation_requests/add_notes?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          request,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addOperationNotes(values, reqId): Observable<any> {
    return this.http
      .post(
        `${environment.rorUrl}/v4/operation_requests/` +
          reqId +
          '/operation_notes',
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          operation_note: values,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  updateOperationNotes(values, reqId, notesId): Observable<any> {
    return this.http
      .put(
        `${environment.rorUrl}/v4/operation_requests/` +
          reqId +
          '/operation_notes/' +
          notesId,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          operation_note: values,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  getOperationNotes(reqId): Observable<any> {
    return this.http
      .get(
        `${environment.rorUrl}/v4/operation_requests/` +
          reqId +
          `/operation_notes?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  getPreOrders(requestID): Observable<any> {
    return this.http
      .get(
        `${environment.rorUrl}/v4/pre_operative_orders?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&operation_request_id=' +
          requestID,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  submitPreOrders(value, requestId): Observable<any> {
    return this.http
      .post(
        `${environment.rorUrl}/v4/pre_operative_orders/`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          operation_request_id: requestId,
          pre_operative_order: value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  getPostOrders(requestID): Observable<any> {
    return this.http
      .get(
        `${environment.rorUrl}/v4/post_operative_orders/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&operation_request_id=' +
          requestID,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  submitPostOrders(value, requestId): Observable<any> {
    return this.http
      .post(
        `${environment.rorUrl}/v4/post_operative_orders/`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          operation_request_id: requestId,
          post_operative_order: value,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  submitChecklistFunctionReq(value, requestId, templateId): Observable<any> {
    return this.http
      .post(
        `${environment.rorUrl}/v4/operation_requests/` +
          'heading_data_against_ot_request?operation_request_id=' +
          requestId,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          check_list_headings: value,
          //      signature_url: signatureUrl,
          check_list_template_id: templateId,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  checkListingGetAgainstOt(requestID): Observable<any> {
    return this.http
      .get(
        `${environment.rorUrl}/v4/operation_requests/` +
          requestID +
          '/get_heading_data_against_ot_request' +
          '?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  checkListingGetAgainstOtDetails(requestID, templateId): Observable<any> {
    return this.http
      .get(
        `${environment.rorUrl}/v4/operation_requests/` +
          requestID +
          '/get_heading_data_against_ot_request' +
          '?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&check_list_template_id=' +
          templateId,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
}
