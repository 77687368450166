import { Component, Input, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DepartmentsbyhospitalService } from '../../../services/departmentsbyhospital.service';
import { HospitaldoctorService } from '../../../services/hospitaldoctor.service';
import * as moment from 'moment';
import { ReservationManagmentServiceService } from '../../../services/reservation-managment-service.service';
import { LookupService } from '../../../services/lookup.service';
import { ServiceService } from '../../../services/service.service';
import { PricingService } from '../../../services/pricing.service';
import { environment } from '../../../../environments/environment';
import { UserService } from '../../../services/user.service';
import { SystemControlsService } from '../../../services/system-controls.service';
import { LoanService } from '../../../services/loan.service';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-booking-slot-appointments',
  templateUrl: './booking-slot-appointments.component.html',
  styleUrls: ['./booking-slot-appointments.component.css'],
})
export class BookingSlotAppointmentsComponent implements OnInit {
  @Input() patient: any;

  departments = [];
  doctors_by_dept: any;
  Doctor_Id = '';
  department_id: any;
  currentDate: any;
  currentMonth: any;
  current_Date = new Date();
  doctorSlotsData = [];
  startDate = new Date();
  endDate = new Date();
  SevenDaysLater: any;
  doctorID: any;
  department_Id: any;
  departmentId: any;
  visit_Type: any;
  paymentMode = 'Cash';
  visit_Type_billing_code: any;
  visitFee: any;
  slotID: any;
  serviceId: any;
  pricingId: any;
  visitType = [];
  subDepartments = [];
  diableSubmitButton = false;
  isSubmitVisitType = false;
  toType = '';
  previousNonEmptyDate: any;
  patientID: any;
  invoiceControl: any;
  currentControls: any;
  paymentAdvance = {
    mode_of_payment: 'Cash',
    payment_details: '',
    bank_id: '',
    receipt_number: '',
  };

  bankList: any;

  constructor(
    private ngxLoader: NgxUiLoaderService,
    private departmentService: DepartmentsbyhospitalService,
    private hospitalDoctorsService: HospitaldoctorService,
    private reservationService: ReservationManagmentServiceService,
    private lookupService: LookupService,
    private serviceService: ServiceService,
    private pricingService: PricingService,
    private userService: UserService,
    private systemControlService: SystemControlsService,
    private loan: LoanService,
  ) {}

  ngOnInit(): void {
    $('.month')
      .datepicker({
        format: 'mm-yyyy',
        viewMode: 'months',
        minViewMode: 'months',
        orientation: 'bottom left',
      })
      .on('changeDate', (e) => {
        const selectedDate = moment($('.month').val(), 'MM-YYYY');
        this.startDate = selectedDate.startOf('month').toDate();
        this.endDate = selectedDate.date(7).toDate();
        this.getListingData();
      });
    this.currentMonth = $('.month').val();
    this.currentMonth = moment(this.current_Date).format('MM-YYYY');
    this.fetchDoctors();
    this.fetchDepartments();
    this.updateDateRange();
    this.getInvoiceControls();
    this.systemControlService.getUpdatedChecks().subscribe((data) => {
      this.currentControls = data.report_controls;
    });
  }

  fetchDepartments(): any {
    this.ngxLoader.start();
    this.departmentService.getOpdDepartmentsListV2().subscribe(
      (data) => {
        this.departments = data.departments;
        setTimeout(() => {
          $('.departmentSelectPicker').selectpicker();
          $('.departmentSelectPicker').selectpicker('refresh');
        }, 500);
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  fetchDoctors(): any {
    this.ngxLoader.start();
    this.hospitalDoctorsService.fetchOpdConsultationDoctorV2().subscribe(
      (data) => {
        this.doctors_by_dept = data.users;
        setTimeout(() => {
          $('.doctorSelectPicker').selectpicker();
          $('.doctorSelectPicker').selectpicker('refresh');
        }, 500);
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  updateDateRange(): any {
    this.startDate = new Date();
    this.startDate.setDate(this.startDate.getDate());
  }

  previousWeek(value): any {
    this.toType = value;
    if (this.doctorSlotsData.length < 1) {
      this.startDate = this.previousNonEmptyDate;
    } else {
      const firstIndexDate = moment(this.doctorSlotsData[0]?.date, [
        'yyyy-MM-DD',
      ]);
      this.startDate = firstIndexDate.subtract(1, 'days').toDate();
    }
    this.getListingData();
  }

  nextWeek(value): any {
    this.toType = value;
    if (this.doctorSlotsData.length < 1) {
      this.startDate = this.previousNonEmptyDate;
    } else {
      const lastIndex = this.doctorSlotsData.length - 1;
      const lastIndexDate = moment(this.doctorSlotsData[lastIndex]?.date, [
        'yyyy-MM-DD',
      ]);
      this.startDate = lastIndexDate.add(1, 'days').toDate();
    }
    this.getListingData();
  }

  getDoctors(departmentId): any {
    this.departmentId = departmentId;
    this.fetchDeptDoctors(this.departmentId);
  }
  getDepartments(doctorId): any {
    this.doctorID = doctorId;
    this.toType = 'forward';
    this.startDate = new Date();
    const dep_id = this.doctors_by_dept.filter(
      (t) => t.id === Number(doctorId),
    )[0].doctor_department_id;
    this.department_Id = dep_id;
    setTimeout(() => {
      $('#departmentSelectPicker').selectpicker('refresh');
      $('#departmentSelectPicker').selectpicker('');
    }, 500);
    this.getListingData();
  }

  fetchDeptDoctors(id): any {
    this.ngxLoader.start();
    this.hospitalDoctorsService.fetchDepartmentDoctorListV2(id).subscribe(
      (data) => {
        this.Doctor_Id = '';
        this.doctors_by_dept = data.users;
        setTimeout(() => {
          $('.doctorSelectPicker').selectpicker();
          $('.doctorSelectPicker').selectpicker('refresh');
        }, 500);
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  getListingData(): any {
    this.doctorSlotsData = [];
    const CurrentDate = moment(this.startDate, ['yyyy-MM-DD']).format(
      'yyyy-MM-DD',
    );
    this.currentDate = CurrentDate;
    this.ngxLoader.start();
    this.reservationService
      .getSlotsDataList(this.doctorID, this.currentDate, this.toType)
      .subscribe(
        (data) => {
          this.doctorSlotsData = data.time_ranges;
          this.previousNonEmptyDate = this.startDate;
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  openBookedModal(slotId): any {
    this.slotID = slotId;
    $('#proceedToPayModal').modal('show');
    this.fetchLookup();
    this.getBankDetail();
  }
  closeProcessToPayModal(): any {
    this.visitFee = '';
    this.paymentMode = 'Cash';
    this.visit_Type = '';
    $('#proceedToPayModal').modal('hide');
  }

  fetchLookup(): any {
    this.ngxLoader.start();
    if (this.department_Id) {
      this.departmentId = this.department_Id;
    } else {
      this.departmentId = this.department_id;
    }
    this.lookupService
      .indexVisitTypeV2(this.doctorID, this.departmentId, true, '', '')
      .subscribe(
        (data) => {
          this.visitType = data.lookups;
          this.visit_Type =
            this.visitType.filter(
              (t) => t.key === 'Normal' || t.key === 'General',
            )[0]?.id || '';
          if (this.visit_Type) {
            this.fetchVisitService(this.visit_Type);
          }
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
        },
      );
  }
  fetchVisitService(id): any {
    if (id === '' || id === null || id === undefined) {
      return;
    }
    this.ngxLoader.start();
    this.serviceService
      .getVisitServiceV3(
        this.patient?.id,
        id,
        this.doctorID,
        this.departmentId,
        'true',
        'false',
        'false',
      )
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          if (data?.service?.id) {
            this.visit_Type_billing_code = data.service.billing_code;
            this.fetchConsultationPrice();
          }
        },
        (err) => {
          this.ngxLoader.stop();
        },
      );
  }

  fetchConsultationPrice(): any {
    const obj = {
      patient_type_id: this.patient.patient_type.id,
      current_billing_code: this.visit_Type_billing_code,
    };
    this.visitFee = '';
    this.ngxLoader.start();
    this.pricingService.searchPriceV3(obj).subscribe(
      (data) => {
        if (this.patient.admitted) {
          this.visitFee = data.pricings[0].ipd_price;
        } else {
          this.visitFee = data.pricings[0].opd_price;
        }
        this.serviceId = data.pricings[0].service;
        this.pricingId = data.pricings[0].id;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  reserveSlot(slotId, status): any {
    this.ngxLoader.start();
    if (status === 'hold') {
      this.patientID = '';
    } else {
      this.patientID = this.patient.id;
    }
    this.reservationService
      .updateReserveSlot(slotId, this.patientID, status)
      .subscribe(
        (data) => {
          this.getListingData();
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  cancelReservation(slotId): any {
    this.ngxLoader.start();
    this.reservationService
      .cancelReserveSlot(slotId, this.patient.id)
      .subscribe(
        (data) => {
          this.getListingData();
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  submitSlotbooking(): any {
    if (this.visit_Type === '') {
      this.isSubmitVisitType = true;
      return;
    }
    this.diableSubmitButton = true;
    this.ngxLoader.start();
    this.reservationService
      .submitSlotbooking(
        this.slotID,
        this.patient.id,
        this.visit_Type,
        this.visitFee,
        this.serviceId,
        this.pricingId,
        this.doctorID,
        this.departmentId,
        this.paymentAdvance,
      )
      .subscribe(
        (data) => {
          this.diableSubmitButton = false;
          this.getListingData();
          if (
            this.invoiceControl.invoice_controls.show_service_invoice === true
          ) {
            window.open(
              'pdf-reports/patient-services-invoices-v2?invoice_id=' +
                data.invoice_id +
                '&visit_id=' +
                data.visit_id +
                '&mrn=' +
                data.mrn +
                '&patient_id=' +
                data.patient_id,
            );
          } else {
            if (
              this.currentControls.patient_services.pos_invoice_python_short
            ) {
              window.open(
                environment.pythonUrl +
                  '/report/generate_service_invoice/' +
                  data.invoice_id +
                  '/?auth_token=' +
                  localStorage.getItem('auth_token') +
                  '&medical_unit_id=' +
                  localStorage.getItem('current_medical_unit_id'),
              );
            } else {
              window.open(
                'pdf-reports/services-invoices-short?id=' +
                  data.invoice_id +
                  '&visit_id=' +
                  data.visit_id,
              );
            }
          }
          if (
            this.currentControls.patient_services.is_print_prescription === true
          ) {
            if (
              this.currentControls.patient_services.is_prescription === false
            ) {
              window.open(
                'pdf-reports/prescription-v2?id=' +
                  data.patient_id +
                  '&mrn=' +
                  data.mrn +
                  '&visit_id=' +
                  data.visit_id,
              );
            } else {
              window.open(
                environment.rorUrl +
                  '/v4/patients/' +
                  data.patient_id +
                  '/prescription_slip.pdf?auth_token=' +
                  localStorage.getItem('auth_token') +
                  '&medical_unit_id=' +
                  localStorage.getItem('current_medical_unit_id') +
                  '&visit_id=' +
                  data.visit_id,
              );
            }
          }
          this.closeProcessToPayModal();
          this.ngxLoader.stop();
        },
        (err) => {
          this.diableSubmitButton = false;
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  isPastDate(date: any, startTime: any): any {
    const currentDate = new Date();
    const formattedCurrentDateTime = moment(
      currentDate,
      'YYYY-MM-DD h:mm a',
    ).toDate();
    const slotDateTime = new Date(`${date} ${startTime}`);
    return slotDateTime < formattedCurrentDateTime;
  }

  getInvoiceControls(): any {
    this.userService.getInvoiceControls().subscribe(
      (data) => {
        this.invoiceControl = data.system_billing_control;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  getBankDetail(): any {
    this.loan.getbankALlList().subscribe(
      (data) => {
        this.bankList = data;
        if (this.bankList.length) {
          setTimeout((e) => {
            $('.selectPickerBankDetail').selectpicker('refresh');
            $('.selectPickerBankDetail').selectpicker();
          }, 500);
        }
      },
      (err) => {
        toastr.error(err.error.error);
      },
    );
  }

  payemtnChange(): any {
    this.paymentAdvance.receipt_number = '';
    this.paymentAdvance.payment_details = '';
    this.paymentAdvance.bank_id = '';
  }
}
