import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class OpdService {
  constructor(private http: HttpClient) {}

  getLabsTest(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v3/lab_tests?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  fetchPatientsStatisticsFun(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/visits/doctor_wise_opd_count?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchPatientsStatisticsFunV2(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/opd/doctor_wise_opd_count/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getVitalsFun(patient, history): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          `/vitals.json?auth_token=` +
          localStorage.getItem('auth_token') +
          '&history=' +
          history +
          '&visit_id=' +
          patient.visit_id +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getVitalsFunV2(patient, history, visit_type): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/opd/vitals/` +
          patient.id +
          `?auth_token=` +
          localStorage.getItem('auth_token') +
          '&history=' +
          history +
          '&visit_id=' +
          patient.visit_id +
          '&patient_id=' +
          patient.id +
          '&visit_type=' +
          visit_type +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  addVitalsFun(vital, patient): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          `/vitals.json?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          vital,
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          visit_id: patient.visit_id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addVitalsFunV2(vital, patient): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/opd/vitals/` +
          patient.id +
          `/?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          vital,
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          visit_id: patient.visit_id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  labOrderFun(order, patient): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/patients/` + patient.mrn + `/orders`,
        {
          order,
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  labTestOrderHistoryFun(patient): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          `/orders.json?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&status=all&order_type=Lab' +
          '&visit_id=' +
          patient.visit_id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getDiagnosisListFun(search_string): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/icd_codes/search_icd_codes/`,
        {
          query: search_string,
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getDiagnosisListFunHistory(search_string): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/ipd/search_icd_codes/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          query: search_string,
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getDiagnosisListFunv2(search_string): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/opd/search_icd_codes/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          query: search_string,
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  requestForBilling(dental_request_services): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/dental_service_request/invoicing_against_request_service/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&current_page=opd-v',
        {
          dental_request_service: dental_request_services,
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getAllDiagnosisListFun(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/icd_codes?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getAllDefaultDiagnosisListFun(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/icd_codes/get_default_with_department?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getAllDefaultDiagnosisListFunV2(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/ipd/get_default_with_department/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getDentalServices(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/dental_service_request/services_with_code_dental/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  deleteOpdSign(id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/api/system_opd_control/` +
          id +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          dental_sign_status: false,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getDentalServicesPrice(billingCode, patient): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/pricings/pricing_against_multiple_billing_codes/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&billing_code=' +
          billingCode +
          '&patient_type_id=' +
          patient.patient_type_id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getDentalServicesListing(page, perPage, patient): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/dental_service_request/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          perPage +
          '&patient_id=' +
          patient.id +
          '&visit_id=' +
          patient.visit_id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  submitDentalChart(dentalForm): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/dental_service_request/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          dental_request_service: dentalForm,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateDentalListing(dentalForm, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/api/dental_service_request/` +
          id +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          dental_request_service: dentalForm,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  addDiagnosisFun(
    selected_dignosis,
    patient,
    type,
    addtional_dignosis,
  ): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/patient_diagnoses`,
        {
          type,
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          patient_id: patient.mrn,
          visit_id: patient.visit_id,
          selected_dignosis,
          addtional_dignosis,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addDiagnosisFunv2(
    selected_dignosis,
    patient,
    type,
    addtional_dignosis,
  ): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/opd/patient_diagnoses/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          type,
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          patient_id: patient.id,
          visit_id: patient.visit_id,
          selected_dignosis,
          addtional_dignosis,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  addDiagnosisFunMain(diagnosis, patient): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/patient_diagnoses/add_ipd_diagnoses`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          patient_id: patient.mrn,
          diagnosis,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addDiagnosisFunMainV2(diagnosis, patient): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/ipd/add_ipd_diagnoses/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          patient_id: patient.id,
          diagnosis,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getPaitentDiagnosisFun(patient, history): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patient_diagnoses?auth_token=` +
          localStorage.getItem('auth_token') +
          '&visit_id=' +
          patient.visit_id +
          '&patient_id=' +
          patient.mrn +
          '&history=' +
          history +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getPaitentDiagnosisFunv2(patient, history, visitType): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/opd/patient_diagnoses/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&visit_id=' +
          patient.visit_id +
          '&patient_id=' +
          patient.id +
          '&history=' +
          history +
          '&visit_type=' +
          visitType +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  submitPatientHistoryFun(patient_complaint, patient): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          `/patient_complaints.json`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          patient_complaint,
          visit_id: patient.visit_id,
          admission_id: patient.admissions[0].id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  submitPatientHistoryFunV2(patient_complaint, patient): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/ipd/patient_complaints/` +
          patient.mrn +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          patient_complaint,
          visit_id: patient.visit_id,
          admission_id: patient.admissions[0].id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getPatientHistoryFun(patient): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          '/patient_complaints?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getPatientHistoryFunV2(patient): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/ipd/patient_complaints/` +
          patient.mrn +
          '/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getPatientHistoryFunFromOpd(patient): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          '/patient_complaints/patient_complaints_by_visit?auth_token=' +
          localStorage.getItem('auth_token') +
          '&visit_id=' +
          patient.visit_id +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getAllergiesListFun(search_string): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/allergy_types?auth_token=` +
          localStorage.getItem('auth_token') +
          '&allergy_name=' +
          search_string +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getAllergiesListFunV2(search_string): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/ipd/allergy_types?auth_token=` +
          localStorage.getItem('auth_token') +
          '&allergy_name=' +
          search_string +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  treatmentComplete(patient): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          `/treatment_completed`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          visit_id: patient.visit_id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  treatmentCompleteV2(patient): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/ipd/discharge_patients/treatment_completed/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          visit_id: patient.visit_id,
          patient_id: patient.id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  assignMedicineToPatient(medicine, patient): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          `/patient_medications.json`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          visit_id: patient.visit_id,
          admission_id:
            patient.admissions.length > 1 ? patient.admissions[0].id : '',
          ward_id:
            patient.admissions.length > 1 ? patient.admissions[0].ward_id : '',
          patient_medication: medicine,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  assignMedicineToPatientv2(
    medicine,
    patient,
    advisedBy,
    employeeType,
    consumable,
  ): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/opd/patient_medications/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_mrn=' +
          patient.id,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          visit_id: patient.visit_id,
          admission_id:
            patient.admissions.length > 0 ? patient.admissions[0].id : '',
          ward_id:
            patient.admissions.length > 0 ? patient.admissions[0].ward_id : '',
          patient_medication: medicine,
          advised_by: advisedBy,
          employee_type: employeeType,
          is_consumable_item: consumable,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getMedicineHistoryFun(patient, history, frequency): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          `/patient_medications.json?auth_token=` +
          localStorage.getItem('auth_token') +
          '&visit_id=' +
          patient.visit_id +
          '&history=' +
          history +
          '&frequency=' +
          frequency +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getMedicineHistoryFunv2(
    patient,
    history,
    frequency,
    visitType,
    consumable,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/opd/patient_medications/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&visit_id=' +
          patient.visit_id +
          '&history=' +
          history +
          '&frequency=' +
          frequency +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_mrn=' +
          patient.id +
          '&visit_type=' +
          visitType +
          '&is_consumable_item=' +
          consumable,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getMedicineHistoryFunForPatientHistory(
    patient,
    history,
    frequency,
    visitId,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          `/patient_medications.json?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&visit_id=' +
          visitId +
          '&history=' +
          history +
          '&frequency=' +
          frequency +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getProgressNotesFun(patient, status): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v3/investigation_notes?patient_id=` +
          patient.mrn +
          `&auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&investigation_type=' +
          status +
          '&visit_id=' +
          patient.visit_id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getProgressNotesFunV2(patient, status, visitType): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/ipd/investigation_notes/?patient_id=` +
          patient.id +
          `&auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&investigation_type=' +
          status +
          '&visit_id=' +
          patient.visit_id +
          '&visit_type=' +
          visitType +
          '&notes_id=' +
          patient.notes_id || '',
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  createNurseProgressNotesFun(notes, patient): Observable<any> {
    let admId = '';
    if (patient.admissions.length > 0) {
      admId = patient.admissions[patient.admissions.length - 1].id;
    }
    const results = this.http
      .post(
        `${environment.rorUrl}/v3/investigation_notes`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          patient_id: patient.mrn,
          admission_id: admId,
          investigation_note: notes,
          visit_id: patient.visit_id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  createNurseProgressNotesFunV2(
    notes,
    patient,
    emp,
    userPassword,
    status,
  ): Observable<any> {
    let admId = '';
    if (patient.admissions.length > 0) {
      admId = patient.admissions[patient.admissions.length - 1].id;
    }
    const results = this.http
      .post(
        `${environment.pythonUrl}/ipd/investigation_notes/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          patient_id: patient.id,
          admission_id: admId,
          investigation_note: notes,
          visit_id: patient.visit_id,
          user_id: emp,
          user_password: userPassword,
          status: status,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  submitPharmacist(notes, patient): Observable<any> {
    let admId = '';
    if (patient.admissions.length > 0) {
      admId = patient.admissions[patient.admissions.length - 1].id;
    }
    const results = this.http
      .post(
        `${environment.rorUrl}/v3/investigation_notes`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          patient_id: patient.mrn,
          admission_id: admId,
          investigation_note: notes,
          visit_id: patient.visit_id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  submitPharmacistV2(notes, patient): Observable<any> {
    let admId = '';
    if (patient.admissions.length > 0) {
      admId = patient.admissions[patient.admissions.length - 1].id;
    }
    const results = this.http
      .post(
        `${environment.pythonUrl}/ipd/investigation_notes/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          patient_id: patient.id,
          admission_id: admId,
          investigation_note: notes,
          visit_id: patient.visit_id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateNurseProgressNotesFun(notes, patient, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.rorUrl}/v3/investigation_notes/` +
          id +
          ` ?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          patient_id: patient.mrn,
          investigation_note: notes,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateNurseProgressNotesFunV2(
    notes,
    patient,
    id,
    emp,
    userPassword,
    status,
  ): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/ipd/investigation_notes/` +
          id +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          patient_id: patient.id,
          investigation_note: notes,
          user_id: emp,
          user_password: userPassword,
          status: status,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  changeDoctorForDental(obj, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/api/dental_service_request/` +
          id +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          dental_request_service: obj,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  changeDentalStatus(obj, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/api/dental_service_request/` +
          id +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          dental_request_service: obj,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  addDoctorProgressNotesFun(notes, doctorId, shift, patient): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v3/investigation_notes`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          patient_id: patient.mrn,
          admission_id: patient.admissions[patient.admissions.length - 1].id,
          note: notes,
          shift,
          doctor_id: doctorId,
          investigation_type: 'doctor',
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addDoctorProgressNotesFunV2(
    obj,
    patient,
    userId,
    userPassword,
  ): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/ipd/investigation_notes/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          patient_id: patient.id,
          user_id: userId,
          user_password: userPassword,
          admission_id: patient.admissions[patient.admissions.length - 1].id,
          investigation_note: obj,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateDoctorProgressNotesFun(
    notes,
    doctorId,
    shift,
    patient,
    id,
  ): Observable<any> {
    const results = this.http
      .put(
        `${environment.rorUrl}/v3/investigation_notes/` +
          id +
          ` ?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          patient_id: patient.mrn,
          note: notes,
          shift,
          doctor_id: doctorId,
          investigation_type: 'doctor',
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateDoctorProgressNotesFunV2(
    obj,
    patient,
    id,
    userId,
    userPassword,
  ): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/ipd/investigation_notes/` +
          id +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          patient_id: patient.id,
          user_id: userId,
          user_password: userPassword,
          investigation_note: obj,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  saveGeneralHistory(patient_general_history, patient): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          `/patient_general_histories.json`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          patient_general_history,
          visit_id: patient.visit_id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  saveGeneralHistoryV2(patient_general_history, patient): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/opd/general_history/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          patient_general_history,
          visit_id: patient.visit_id,
          patient_id: patient.id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  createReviewSystemHistory(patient_review_system, patient): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          `/patient_review_systems.json`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          patient_review_system,
          visit_id: patient.visit_id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  createReviewSystemHistoryV2(patient_review_system, patient): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/opd/patient_review_systems/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          patient_review_system,
          visit_id: patient.visit_id,
          patient_id: patient.id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  createPhysicalSystemicExaminationHistory(
    physicalSystemicExamination,
    patient,
  ): Observable<any> {
    return this.http
      .post(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          `/physical_systemic_examinations.json`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          physical_systemic_examination: physicalSystemicExamination,
          visit_id: patient.visit_id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  createPhysicalSystemicExaminationHistoryV2(
    physicalSystemicExamination,
    patient,
  ): Observable<any> {
    return this.http
      .post(
        `${environment.pythonUrl}/opd/physical_systemic_examinations/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          physical_systemic_examination: physicalSystemicExamination,
          visit_id: patient.visit_id,
          patient_id: patient.id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  updatePhysicalSystemicExaminationHistory(
    physicalSystemicExamination,
    patient,
  ): Observable<any> {
    return this.http
      .put(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          `/physical_systemic_examinations/` +
          physicalSystemicExamination.id,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          physical_systemic_examination: physicalSystemicExamination,
          visit_id: patient.visit_id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  updatePhysicalSystemicExaminationHistoryV2(
    physicalSystemicExamination,
    patient,
  ): Observable<any> {
    return this.http
      .put(
        `${environment.pythonUrl}/opd/physical_systemic_examinations/` +
          physicalSystemicExamination.id +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          physical_systemic_examination: physicalSystemicExamination,
          visit_id: patient.visit_id,
          patient_id: patient.id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }

  createPatientCentralNervousSystem(
    central_nervous_system,
    patient,
  ): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          `/central_nervous_systems.json`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          central_nervous_system,
          visit_id: patient.visit_id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  createPatientCentralNervousSystemV2(
    central_nervous_system,
    patient,
  ): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/opd/central_nervous_systems/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          central_nervous_system,
          visit_id: patient.visit_id,
          patient_id: patient.id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updatePatientCentralNervousSystemV2(
    central_nervous_system,
    patient,
    id,
  ): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/opd/central_nervous_systems/` +
          id +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          central_nervous_system,
          visit_id: patient.visit_id,
          patient_id: patient.id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updatePatientCentralNervousSystem(
    centralNervousSystem,
    patient,
  ): Observable<any> {
    return this.http
      .put(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          `/central_nervous_systems/` +
          centralNervousSystem.id,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          central_nervous_system: centralNervousSystem,
          visit_id: patient.visit_id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }

  createPatientMedications(values, patient, discharge, type): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/patients/save_template`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          patient_id: patient.mrn,
          visit_id: patient.visit_id,
          discharge_type: discharge,
          values,
          type,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  createPatientMedicationsV2(
    values,
    patient,
    discharge,
    type,
  ): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/opd/save_template/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          visit_id: patient.visit_id,
          patient_id: patient.id,
          discharge_type: discharge,
          values,
          type,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  changeStatusOnWebsite(visit_id): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/camden_website/patient_treatment_complete/?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          visit: visit_id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  createPatientMedicationsAsTemplate(
    values,
    type,
    name,
    status,
  ): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/templates`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          status,
          name,
          type,
          values,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  createPatientMedicationsAsTemplateV2(
    values,
    type,
    name,
    status,
  ): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/opd/templates/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          status,
          name,
          type,
          values,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getAllTemplates(status): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/templates?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&status=' +
          status,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getAllTemplatesV2(status): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/opd/templates/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&status=' +
          status,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getPatientTemplate(patient, id): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patients/get_template?auth_token=` +
          localStorage.getItem('auth_token') +
          '&patient_id=' +
          patient.mrn +
          '&visit_id=' +
          id +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getPatientTemplateV2(patient, id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/opd/get_template/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&patient_id=' +
          patient.id +
          '&visit_id=' +
          id +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getPatientVisits(patient): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patients/get_patient_visits?auth_token=` +
          localStorage.getItem('auth_token') +
          '&patient_id=' +
          patient.mrn +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getPatientVisitsV2(patient): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/opd/get_patient_visits/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&patient_id=' +
          patient.id +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getPatientAdmission(patient): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/admissions/patient_admissions?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patient.id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getPatientAdmissionV2(patient): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/patient_admissions/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patient.id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getPatientAdmissionHistoryV2(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/patient_admissions/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updateTemplates(values, type, name, status, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.rorUrl}/v4/templates/` +
          id +
          ` ?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          status,
          name,
          type,
          values,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  saveOpdControls(file, signLabel): Observable<any> {
    const body = new FormData();
    body.append('dental_label', signLabel);
    body.append('dental_sign_image', file);
    const results = this.http.post(
      `${environment.pythonUrl}/api/system_opd_control/?auth_token=` +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id'),
      body,
      {
        headers: new HttpHeaders({
          AuthenticationToken: localStorage.getItem('auth_token'),
        }),
      },
    );
    return results;
  }
  getOpdControlsData(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/system_opd_control/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addConsulting(value, isOpd, patient): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/ipd_doctors`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          ipd_doctor: value,
          opd: isOpd,
          visit_id: patient.visit_id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  addConsultingV2(value, isOpd, patient): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/opd/ipd_doctors/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          ipd_doctor: value,
          opd: isOpd,
          visit_id: patient.visit_id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getConsultations(patient, status, history): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/ipd_doctors?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patient.id +
          '&status=' +
          status +
          '&history=' +
          history,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getConsultationsV2(patient, status, history, visitType): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/opd/ipd_doctors/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patient.id +
          '&visit_id=' +
          patient.visit_id +
          '&status=' +
          status +
          '&history=' +
          history +
          '&visit_type=' +
          visitType,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  addOperationRequest(value, patient): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/patients/` +
          patient.id +
          '/operation_requests',
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          operation_request: value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  addOperationRequestV2(value, patient): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/ipd/operation_requests/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          operation_request: value,
          patient_id: patient.id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getOperationRequests(patient): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patients/` +
          patient.id +
          `/operation_requests?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patient.id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getOperationRequestsV2(patient, visitType): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/ipd/operation_requests?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patient.id +
          '&request_id=' +
          patient.request_id +
          '&visit_type=' +
          visitType,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getOperationRqst(patient, visitType, visitId): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/patients/` +
          patient.id +
          `/operation_requests?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          patient.id +
          '&visit_type=' +
          visitType +
          '&visit_id=' +
          visitId,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  cancelPrintOperationRequest(patient, id): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/patients/` +
          patient.id +
          '/operation_requests/cancel_request?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  cancelPrintOperationRequestV2(patient, id): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/ipd/cancel_request/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          patient_id: patient.id,
          id: id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateGeneralHistory(
    patient_general_history,
    patient,
    patientGeneralHistoryId,
  ): Observable<any> {
    let results = this.http
      .put(
        `${environment.rorUrl}/v4/patients/` +
          patient.mrn +
          `/patient_general_histories/` +
          patientGeneralHistoryId,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          patient_general_history,
          visit_id: patient.visit_id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateGeneralHistoryV2(
    patient_general_history,
    patient,
    patientGeneralHistoryId,
  ): Observable<any> {
    let results = this.http
      .put(
        `${environment.pythonUrl}/opd/general_history/` +
          patientGeneralHistoryId +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          patient_general_history,
          visit_id: patient.visit_id,
          patient_id: patient.id,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  // doc tab code
  getPatientDocHistory(id, visitId): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/attachments/patient_wise_attachments?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          id +
          '&visit_id=' +
          visitId,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getPatientDocuments(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/attachments/patient_wise_attachments?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  uploadPatientDoc(id, visitId, patientId, file, length): Observable<any> {
    const body = new FormData();
    body.append('id', id);
    body.append('visit_id', visitId);
    body.append('patient_id', patientId);
    body.append('document1', file);
    body.append('length', length);
    const results = this.http.put(
      `${environment.rorUrl}/v4/document_types/add_attachment/?auth_token=` +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id'),
      body,
      {
        headers: new HttpHeaders({
          AuthenticationToken: localStorage.getItem('auth_token'),
        }),
      },
    );
    return results;
  }
  getPatientDocs(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/attachments/patient_wise_attachments?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&employee_id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getPatientDocumentsWhileRegistration(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/upload_document/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getPatientDocums(requestId, id): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/attachments/patient_wise_attachments?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&operation_request_id=' +
          requestId +
          '&patient_id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getPatientDoms(visitId, id): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/attachments/patient_wise_attachments?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          visitId +
          '&visit_id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getPatientDcmnts(id, visitId): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/attachments/patient_wise_attachments?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          id +
          '&visit_id=' +
          visitId,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getPatientDcmntsV2(id, visitId, visitType): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/ipd/patient_wise_attachments/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&patient_id=' +
          id +
          '&visit_id=' +
          visitId +
          '&visit_type=' +
          visitType,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  uploadPatientDocuments(
    fileData,
    employee_id,
    visit_type,
    requestID,
    currentVisitID,
    visitId,
    patientId,
  ): Observable<any> {
    const body = new FormData();
    body.append('id', fileData.docType);
    // if (fileData) {
    //   fileData.files.forEach((files: string | Blob) => body.append('document', files));
    // }
    for (let i = 0; i < fileData.files.length; i++) {
      let ind = i + 1;
      body.append('document' + ind, fileData.files[i]);
    }
    body.append('length', fileData.files.length);
    body.append('description', fileData.text_areas);
    body.append('name', fileData.document_name);
    body.append('employee_id', employee_id);
    body.append('visit_type', visit_type);
    body.append('operation_request_id', requestID);
    body.append('visit_id', currentVisitID);
    body.append('currentVisitID', visitId);
    body.append('patient_id', patientId);
    const results = this.http.put(
      `${environment.rorUrl}/v4/document_types/add_attachment/?auth_token=` +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id'),
      body,
      {
        headers: new HttpHeaders({
          AuthenticationToken: localStorage.getItem('auth_token'),
        }),
      },
    );
    return results;
  }
  uploadPatientDocumentsV2(
    fileData,
    employee_id,
    visit_type,
    requestID,
    currentVisitID,
    visitId,
    patientId,
  ): Observable<any> {
    const body = new FormData();
    body.append('id', fileData.docType);
    // if (fileData) {
    //   fileData.files.forEach((files: string | Blob) => body.append('document', files));
    // }
    for (let i = 0; i < fileData.files.length; i++) {
      let ind = i + 1;
      body.append('document' + ind, fileData.files[i]);
    }
    body.append('length', fileData.files.length);
    body.append('description', fileData.text_areas);
    body.append('name', fileData.document_name);
    body.append('employee_id', employee_id);
    body.append('visit_type', visit_type);
    body.append('operation_request_id', requestID);
    body.append('visit_id', currentVisitID);
    body.append('currentVisitID', visitId);
    body.append('patient_id', patientId);
    const results = this.http.post(
      `${environment.pythonUrl}/ipd/add_attachment/?auth_token=` +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id'),
      body,
      {
        headers: new HttpHeaders({
          AuthenticationToken: localStorage.getItem('auth_token'),
        }),
      },
    );
    return results;
  }
  uploadPatientDocumentsWhileRegistration(
    fileData,
    patientId,
  ): Observable<any> {
    const body = new FormData();
    body.append('document_type', fileData.docType);
    // if (fileData) {
    //   fileData.files.forEach((files: string | Blob) => body.append('document', files));
    // }
    for (let i = 0; i < fileData.files.length; i++) {
      let ind = i + 1;
      body.append('document' + ind, fileData.files[i]);
    }
    body.append('length', fileData.files.length);
    body.append('description', fileData.text_areas);
    body.append('document_name', fileData.document_name);
    body.append('patient_id', patientId);
    const results = this.http.post(
      `${environment.pythonUrl}/api/upload_document/?auth_token=` +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id'),
      body,
      {
        headers: new HttpHeaders({
          AuthenticationToken: localStorage.getItem('auth_token'),
        }),
      },
    );
    return results;
  }

  opdVisitHistory(currentPage: any, perPage: any, opdForm): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/opd/doctor_wise_patient_summary/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&date=' +
          opdForm.date +
          '&doctor_id=' +
          opdForm.doctor_id +
          '&page=' +
          currentPage +
          '&per_page=' +
          perPage,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  tokenNumberCall(visitId, status): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/send_token_numbers/update_token_number/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          visit_id: visitId,
          status: status,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getTokenNumberLists(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/send_token_numbers/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
