<!--begin::Entry-->
<div class="container-fluid">
  <div class="card pdf-card">
    <div class="card-body" style="padding: 10px 20px">
      <div
        *ngIf="extractedPath === 'lab-report-pdf'"
        style="padding-bottom: 5px; padding-left: 13px"
        class="d-print-none"
      >
        <button class="btn btn-light-primary btn-sm" (click)="printReport()">
          Print
        </button>
      </div>
      <div
        *ngIf="extractedPath !== 'lab-report-pdf'"
        style="padding-bottom: 5px; padding-left: 13px"
        class="d-print-none text-right"
      >
        <button class="btn btn-light-primary btn-sm" (click)="printReport()">
          Print
        </button>
      </div>
      <table class="w-100">
        <thead class="text-center">
          <tr>
            <td style="width: 100%">
              <ng-container *ngIf="this.headerType === 2">
                <table style="width: 100%">
                  <tr class="borders">
                    <td style="width: 15%; padding: 0">
                      <img
                        [src]="settingData?.logo?.url"
                        style="width: auto; height: 106px"
                      />
                    </td>
                    <td style="width: 62%">
                      <div class="text-left">
                        <h3 class="hosp-name">{{ settingData?.title }}</h3>
                      </div>
                      <div class="font-size-h6 text-left">
                        <h5 class="hosp-address">{{ settingData?.address }}</h5>
                      </div>
                      <div class="font-size-h5 text-left">
                        <h5 class="hosp-address">
                          {{ settingData?.phone_number }}
                        </h5>
                      </div>
                      <div class="d-flex font-size-h5 text-left">
                        <h5 class="hosp-address mr-1">
                          {{ settingData?.website_url }},
                        </h5>
                        <h5 class="hosp-address">{{ settingData?.email }}</h5>
                      </div>
                    </td>
                  </tr>
                </table>
              </ng-container>
              <ng-container *ngIf="this.headerType === 0">
                <img
                  style="width: 100%; padding-bottom: 15px; margin-top: 15px"
                  [src]="headerPageData?.image"
                  *ngIf="headerImageType === '1'"
                />
                <img
                  style="width: 100%; padding-bottom: 15px; margin-top: 15px"
                  [src]="headerPageData?.header_file2"
                  *ngIf="headerImageType === '2'"
                />
              </ng-container>
              <ng-container *ngIf="this.headerType === 1">
                <div style="height: 120px"></div>
              </ng-container>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <app-patient-info
                (patient)="getPatient($event)"
                (summaryControl)="getSummaryControl($event)"
              ></app-patient-info>
              <hr class="my-6 line" />
              <div *ngFor="let reportData of pageDataList; let i = index">
                <!--              <div class="d-flex">-->
                <!--                <div class="w-50">-->
                <!--                  <h2 class="mb-0 StartData">-->
                <!--                    <strong>{{ reportData.name }}</strong>-->
                <!--                  </h2>-->
                <!--                </div>-->
                <!--              </div>-->
                <!--              <hr class="my-6 line" />-->
                <div *ngFor="let data of reportData.value; let j = index">
                  <div class="pb-5" [ngClass]="{ pageBreakInside: i !== 0 }">
                    <app-culture-report
                      [pageDataList]="data"
                      *ngIf="
                        data?.report_type === 'Culture-1' ||
                        data?.report_type === 'Culture'
                      "
                    ></app-culture-report>
                    <div *ngIf="data?.report_type === 'PCR'">
                      <app-pcr-report
                        [pageDataList]="data"
                        [reportName]="data?.test_name"
                      ></app-pcr-report>
                    </div>
                    <div
                      *ngIf="
                        data?.report_type != 'Culture-1' &&
                        data?.report_type != 'Culture' &&
                        data?.report_type !== 'PCR'
                      "
                    >
                      <app-report-customize-one
                        [pageDataList]="data"
                        [groupIndex]="i + '' + j"
                      ></app-report-customize-one>
                      <div
                        class="mt-8"
                        [innerHTML]="data?.parameter_comments"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot class="footer-space">
          <tr>
            <td>
              <div class="foot" style="width: 100%">
                <div class="d-flex footerNote" style="padding-top: 60px">
                  <p style="width: 70%" class="pl-0">
                    <u>{{ footerPageData?.note }}</u>
                  </p>
                  <div style="width: 30%">
                    <p class="mb-1">{{ footerPageData?.verified_by }}</p>
                    <p>{{ footerPageData?.designation_name }}</p>
                  </div>
                </div>
                <div class="text-center">
                  <p class="footerNote" *ngIf="pageDataList.length > 0">
                    <strong
                      *ngIf="pageDataList[0].report_status !== 'Unverified'"
                      >Electronically verified, No signature(s) required</strong
                    >
                    <strong
                      *ngIf="pageDataList[0].report_status === 'Unverified'"
                      >Electronically Not Verified</strong
                    >
                  </p>
                </div>
                <div *ngIf="this.footerType === 2">
                  <div class="flex-parent">
                    <ng-container
                      *ngFor="
                        let data of footerPageData?.signature_array;
                        let z = index
                      "
                    >
                      <div class="flex-item flex-grow">
                        <div>
                          <span class="font-weight-bolder">{{
                            data?.short_name
                          }}</span>
                        </div>
                        <div>
                          <span>{{ data?.qualification }}</span>
                        </div>
                        <div>
                          <span>{{ data?.certification }}</span>
                        </div>
                        <div>
                          <span>{{ data?.designation }}</span>
                        </div>
                      </div>
                      <!--            <div class="flex-item flex-grow">{{data.qualification}}</div>-->
                      <!--            <div class="flex-item flex-grow">{{data.certification}}</div>-->
                      <!--            <div class="flex-item flex-grow">{{data.designation}}</div>-->
                    </ng-container>
                  </div>
                </div>
                <div *ngIf="this.footerType === 0">
                  <div>
                    <img
                      style="width: 100%; height: 100px"
                      [src]="footerPageData?.image"
                      *ngIf="footerImageType === '1'"
                    />
                    <img
                      style="width: 100%; height: 100px"
                      [src]="footerPageData?.footer_file2"
                      *ngIf="footerImageType === '2'"
                    />
                  </div>
                </div>
                <div *ngIf="this.footerType === 1">
                  <div style="height: 120px"></div>
                </div>
                <!--          <div class="flex-parent">-->
                <!--            <div class="flex-item flex-grow">Iram</div>-->
                <!--            <div class="flex-item flex-grow">Eitsam</div>-->
                <!--            <div class="flex-item flex-grow">Iram</div>-->
                <!--          </div>-->
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
      <!--      <table>-->
      <!--      <table class="w-100 foot">-->
      <!--      </table>-->
    </div>
  </div>
</div>
