import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class DoctorConfigurationService {
  constructor(private http: HttpClient) {}

  addDoctorPackage(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/doctor_packages/`,
        {
          auth_token: localStorage.getItem('auth_token'),
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          doctor_package: value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getDoctorPackages(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/doctor_packages?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getDoctorPackagesv2(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/doctor_packages?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getDoctorPackagesId(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/users/get_doctor_package_id?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getDoctorPackagesIdv2(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/get_doctor_package_id?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getDoctorCategoriesId(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/doctor_fee/user_doctor_category/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&emp_id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateDoctorBillingPackages(id, billingId): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/users/update_doctor_package_id?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          id,
          doctor_package_id: billingId,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateDoctorBillingPackagesv2(id, billingId): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/update_doctor_package_id/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          id,
          doctor_package_id: billingId,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateDoctorBillingCategoriesv2(id, categoryId): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/doctor_fee/user_doctor_category/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          emp_id: id,
          doctor_category_id: categoryId,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getComplaintTask(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/user_general_controls/` +
          id +
          `?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateComplaintsTasks(id, showTask): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/api/user_general_controls/` +
          id +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          general_controls: showTask,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getDoctorPackageByID(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/doctor_packages/` +
          id +
          `?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&role=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  updateDoctorPackage(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.rorUrl}/v4/doctor_packages/` +
          id +
          `?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          // auth_token: localStorage.getItem('auth_token'),
          // medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          doctor_package: value,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getUserWorkList(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/worklists?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&performing_doctor_id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getUserWorkListv2(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/worklists?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&performing_doctor_id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getServiceType(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/add_service_type?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&user_id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateUserWorkList(id, doctor_ids): Observable<any> {
    const results = this.http
      .put(
        `${environment.rorUrl}/v4/worklists/update_worklists?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          performing_doctor_id: id,
          doctor_ids,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateUserWorkListv2(id, doctor_ids): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/api/update_worklists/1/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          performing_doctor_id: id,
          doctor_ids,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateRoomNumber(id, room_number): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/api/assign_room_to_doc/1/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          user_id: id,
          room_number,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getRoomNumber(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/assign_room_to_doc/send_doc_room_number/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&user_id=' +
          id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
