import { ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from '../../services/auth.service';
import { ShareDataService } from '../../services/share-data.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OtService } from '../../services/ot.service';
import * as moment from 'moment';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-ot-scheduling',
  templateUrl: './ot-scheduling.component.html',
  styleUrls: ['./ot-scheduling.component.css'],
})
export class OtSchedulingComponent implements OnInit, OnDestroy {
  otSchedulingForm: FormGroup;
  today = formatDate(new Date(), 'dd-MM-yyyy', 'en');
  allOperationRequests = [];
  hospitalName: any;
  timeInterval: any;
  constructor(
    private router: Router,
    private otService: OtService,
    private fb: FormBuilder,
    private ngxLoader: NgxUiLoaderService,
    private authService: AuthService,
    private shareDataService: ShareDataService,
  ) {
    this.hospitalName = JSON.parse(localStorage.getItem('currentUser'));
    this.hospitalName = this.hospitalName.medical_units[0].title;
  }
  // get OT Schedule List
  searchRequests(): any {
    this.otSchedulingForm
      .get('from_date')
      .setValue(moment(new Date()).startOf('day').format('DD-MM-YYYY h:mm a'));
    this.otSchedulingForm
      .get('to_date')
      .setValue(moment(new Date()).format('DD-MM-YYYY h:mm a'));
    this.otSchedulingForm.get('department_id').setValue('');
    this.otSchedulingForm.get('ward_id').setValue('');
    this.otSchedulingForm.get('urgency_id').setValue('');
    this.otSchedulingForm.get('operation_room_id').setValue('');
    this.otSchedulingForm.get('operation_room_id').setValue('');
    this.allOperationRequests = [];
    this.otService.allOperationRequests(this.otSchedulingForm.value).subscribe(
      (data) => {
        this.allOperationRequests = data.operation_requests;
        if (this.allOperationRequests.length === 0) {
          toastr.error('No Data Found');
        }
      },
      (err) => {},
    );
  }
  // LogOut
  logout(): any {
    this.ngxLoader.start();
    this.authService
      .logOutv2(localStorage.getItem('auth_token'))
      .subscribe((data) => {
        localStorage.clear();
        this.shareDataService.changeMessage([]);
        this.router.navigate(['login-v2']);
        this.ngxLoader.stop();
        localStorage.setItem('is_session_expired', 'true');
      });
  }
  ngOnInit(): void {
    this.otSchedulingForm = this.fb.group({
      from_date: ['', Validators.required],
      to_date: ['', Validators.required],
      department_id: [''],
      ward_id: [''],
      urgency_id: [''],
      operation_room_id: [''],
      type: [''],
    });
    this.searchRequests();
    this.timeInterval = setInterval(() => {
      this.searchRequests();
    }, 300000);
  }
  ngOnDestroy(): void {
    clearInterval(this.timeInterval);
  }
}
