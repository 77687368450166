import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { SystemControlsService } from '../system-controls.service';
import { UserService } from '../user.service';
@Injectable({
  providedIn: 'root',
})
export class PrescriptionDataResolver implements Resolve<any> {
  constructor(private systemControlsService: SystemControlsService) {}
  resolve(): Observable<any> {
    return this.systemControlsService.getPrescriptionControlV2();
  }
}
export class SystemGeneralControls implements Resolve<any> {
  constructor(private userService: UserService) {}
  resolve(): Observable<any> {
    return this.userService.getGeneralControls();
  }
}
