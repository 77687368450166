import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { WardService } from '../../../services/ward.service';
import { DepartmentsbyhospitalService } from '../../../services/departmentsbyhospital.service';
import { LookupService } from '../../../services/lookup.service';
import { HospitaldoctorService } from '../../../services/hospitaldoctor.service';
import { CompanyService } from '../../../services/company.service';
import { PatientService } from '../../../services/patient.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from '../../../../environments/environment';
import { PatientAdmissionService } from '../../../services/patient-admission.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import SignaturePad from 'signature_pad';
import { RouteShareService } from '../../../services/route-share.service';
import { ShareDataService } from '../../../services/share-data.service';
import { SystemControlsService } from '../../../services/system-controls.service';
import { inactiveDeceasedPatient } from 'src/app/system-messages/system-messages';

declare var $: any;
declare var toastr: any;
@Component({
  selector: 'app-admission-v2',
  templateUrl: './admission-v2.component.html',
  styleUrls: ['./admission-v2.component.css'],
})
export class AdmissionV2Component implements OnInit {
  @ViewChild('conformPatientDischargeModal')
  conformPatientDischargeModal: ElementRef;
  @ViewChild('conformFinalSettlementModal')
  conformFinalSettlementModal: ElementRef;
  @ViewChild('canvas', { static: true }) canvas: ElementRef;
  sig: SignaturePad;

  PatientAdmissionForm: FormGroup;
  PatientSearchForm: FormGroup;
  PatientAdmitionInfoForm: FormGroup;
  submitted = false;
  family_mrn: any;
  patient: any;
  patient_first_name: '';
  isAdmReq = false;
  doctors = [];
  doctors_by_dept: any;
  wards = [];
  beds = [];
  wardBeds = [];
  departments = [];
  departmentsIpd = [];
  lookups = [];
  patient_admission: any;
  patient_admissions = [];
  default_patient_mrn: any;
  showBackButton = false;
  current_environment: string;
  isUpdate = false;
  updatePatientAdmittedId: any;
  currentHospital = environment.hospital;
  admittedPatients = false;
  admissionRequestId = '';
  subDepartments = [];
  DocId: any;
  signature_url: any;
  urlImage = '';
  isSignature = true;
  urlImageUpdate = false;
  urlButton = false;
  screenName = '';
  currentControls: any;
  patient_visit_id: any;
  isDisable = false;
  disableUpdate = false;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private ngxLoader: NgxUiLoaderService,
    private patientService: PatientService,
    private companyService: CompanyService,
    private hospitalDoctorsService: HospitaldoctorService,
    private wardService: WardService,
    private departmentService: DepartmentsbyhospitalService,
    private routeSevice: RouteShareService,
    private lookupService: LookupService,
    private patientAdmissionService: PatientAdmissionService,
    private modalService: NgbModal,
    private departmentsByHospitalService: DepartmentsbyhospitalService,
    private shareDataService: ShareDataService,
    private systemControlService: SystemControlsService,
  ) {
    this.screenName = this.routeSevice.getValue();
    // this.shareDataService.controlCheck.subscribe(data => {
    this.systemControlService.getUpdatedChecks().subscribe((data) => {
      this.currentControls = data.report_controls;
    });
  }

  ngOnInit(): any {
    this.PatientAdmissionForm = this.formBuilder.group({
      visit_id: [''],
      patient_id: [''],
      referred_from: ['', Validators.required],
      referred_to: ['', Validators.required],
      // department_id: ['', Validators.required],
      ward_id: ['', Validators.required],
      bed_id: [''],
      sub_department_id: [''],
      doctor_id: [''],
      user_id: [''],
      attendent_name: ['', Validators.required],
      attendent_mobile: ['', Validators.required],
      attendent_address: [''],
      attendent_nic: [''],
      attendent_relationship: [''],
      admission_number: [''],
      status: [''],
      condition: ['normal'],
      comments: [''],
      medical_officer_id: [''],
      signature_url: [''],
    });

    this.current_environment = environment.rorUrl;

    this.PatientSearchForm = this.formBuilder.group({
      search_query: [''],
      type: ['default'],
    });
    this.PatientAdmitionInfoForm = this.formBuilder.group({
      search_query: [''],
      type: ['default'],
    });

    // this.route.data.subscribe(({ patient_admissions }) => {
    //   this.patient_admissions = patient_admissions["admissions"];
    //   if (this.patient_admissions) {
    //     this.isAdmReq =  true;
    //   }
    //   this.ngxLoader.stop();
    // });

    this.default_patient_mrn = '';

    const patientAdmission = JSON.parse(
      localStorage.getItem('patientAdmission'),
    );
    const patientSearch = JSON.parse(localStorage.getItem('patientSearch'));
    if (patientAdmission || patientSearch) {
      this.PatientAdmissionForm.setValue(patientAdmission);
      this.PatientAdmitionInfoForm.setValue(patientSearch);
    }

    $('.selectpicker').selectpicker();

    this.fetchDoctors();
    // this.fetchWards();
    this.getWardListing();
    this.fetchDepartments();
    this.fetchIPDDepartments();
    this.getAdmittedPatient();
    this.fetchPatientInfo();
    this.searchPatient();
    this.fetchLookupsRelationship();
  }
  searchPatient(): any {
    if (this.PatientSearchForm.value.search_query.length === 11) {
      this.PatientSearchForm.value.type = 'phone1';
    } else if (this.PatientSearchForm.value.search_query.length === 8) {
      this.PatientSearchForm.value.type = 'mrn';
    } else if (this.PatientSearchForm.value.search_query.length === 13) {
      this.PatientSearchForm.value.type = 'patient_nic';
    } else {
      this.PatientSearchForm.value.type = 'default';
    }
    this.fetchPatients();
  }
  fetchPatients(): any {
    this.ngxLoader.start();
    var referal = false;
    this.patientService
      .searchAdmittedPatientV2(this.PatientSearchForm.value)
      .subscribe(
        (data) => {
          this.patient_admissions = data.patients;
          this.ngxLoader.stop();
        },
        (err) => {
          this.patient_admissions = [];
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  fetchPatientInfo(): any {
    $('.signature').css('display', 'none');
    this.urlImage = '';
    this.urlButton = true;
    if (this.route.snapshot.queryParams.patient_id) {
      this.PatientAdmitionInfoForm.value.search_query =
        this.route.snapshot.queryParams.patient_id;
      this.default_patient_mrn = this.route.snapshot.queryParams.patient_id;
      this.showBackButton = true;
    }
    if (this.PatientAdmitionInfoForm.value.search_query.length === 8) {
      this.PatientAdmitionInfoForm.value.type = 'mrn';
      this.ngxLoader.start();
      this.patientService
        .searchPatientByTypeAdmissionV2(this.PatientAdmitionInfoForm.value)
        .subscribe(
          (data) => {
            if (data) {
              this.patient = data.patients[0];
              this.urlImage =
                data.patients[0]?.admissions[0]?.signature_image?.url || '';
              if (this.urlImage === '') {
                this.urlButton = false;
                $('.signature').css('display', 'block');
              }

              // if (this.patient.admissions.length > 0){
              //   var len = this.patient.admissions.length;
              //   if (this.patient.admissions[len-1].status === 'Admitted'){
              //     this.ngxLoader.stop();
              //     this.router.navigateByUrl('
              //     patient-registration');
              //     this._flashMessagesService.show('You cannot admit a patient who is already admitted.', { cssClass: 'alert-danger', timeout: 5000 });
              //   }
              // }
              this.patient_first_name = data.patients[0].first_name;
              this.default_patient_mrn = data.patients[0].mrn;
              this.selectPatient(this.patient);
            }
            this.ngxLoader.stop();
          },
          (err) => {
            this.ngxLoader.stop();
            toastr.error(err.error.message);
          },
        );
    }
  }

  fetchDoctors(): any {
    this.sig = new SignaturePad(this.canvas.nativeElement);
    this.ngxLoader.start();
    this.hospitalDoctorsService.fetchIpdConsultationDoctorV2().subscribe(
      (data) => {
        this.doctors = data.users;
        this.doctors_by_dept = data.users;

        setTimeout(() => {
          $('#moDoctors').selectpicker();
          $('#doctorId').selectpicker();
        }, 500);
        setTimeout(() => {
          $('#moDoctors').selectpicker('refresh');
          $('#doctorId').selectpicker('refresh');
        }, 500);
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        setTimeout(() => {
          $('#moDoctors').selectpicker();
          $('#doctorId').selectpicker();
        }, 500);
        setTimeout(() => {
          $('#moDoctors').selectpicker('refresh');
          $('#doctorId').selectpicker('refresh');
        }, 500);
        toastr.error(err.error.message);
      },
    );
  }

  fetchDepByDoctor(id): any {
    if (
      this.doctors_by_dept.filter((t) => t.id === Number(id))[0]
        .doctor_department_id
    ) {
      let departmentId = this.doctors_by_dept.filter(
        (t) => t.id === Number(id),
      )[0].doctor_department_id;
      this.PatientAdmissionForm.patchValue({
        referred_to: departmentId,
        signature_url: '',
      });
      this.getSubDepartment(departmentId);
      setTimeout(() => {
        $('#referred_to').selectpicker('refresh');
      }, 100);
    }
  }
  fetchDoctorsByDep(index): any {
    this.doctors_by_dept = [];
    this.ngxLoader.start();
    this.hospitalDoctorsService.fetchDepartmentDoctorListV2(index).subscribe(
      (data) => {
        this.doctors_by_dept = data.users;
        // this.getSubDepartment(this.DocId);
        setTimeout(() => {
          $('#doctorId').selectpicker();
        }, 500);
        setTimeout(() => {
          $('#doctorId').selectpicker('refresh');
        }, 500);
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        setTimeout(() => {
          $('#doctorId').selectpicker();
        }, 500);
        setTimeout(() => {
          $('#doctorId').selectpicker('refresh');
        }, 500);
        toastr.error(err.error.message);
      },
    );
  }

  getWardListing(): any {
    this.wards = [];
    this.wardService.activeWardNamesV2().subscribe(
      (data) => {
        this.wards = data.wards;
        setTimeout(() => {
          $('#wardId').selectpicker('refresh');
        }, 500);
      },
      (error) => {
        toastr.error(error.error.message);
        this.ngxLoader.stop();
      },
    );
  }

  fetchDepartments(): any {
    this.departments = [];
    this.ngxLoader.start();
    this.departmentService.getOpdDepartmentsListV2().subscribe(
      (data) => {
        this.departments = data.departments;
        $('#referredFrom').selectpicker('refresh');
        setTimeout(() => {
          $('#referredFrom').selectpicker('refresh');
          $('#referredFrom').selectpicker();
        }, 500);
        // $('#referredFrom').selectpicker('refresh');
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  getSubDepartment(value): any {
    this.subDepartments = [];
    this.PatientAdmissionForm.get('sub_department_id').setValue('');
    setTimeout(() => {
      $('#subDepartment').selectpicker('refresh');
    }, 200);
    this.ngxLoader.start();
    this.departmentsByHospitalService.parent_deptV2(value).subscribe(
      (data) => {
        this.subDepartments = data.departments;
        setTimeout(() => {
          $('#subDepartment').selectpicker('refresh');
        }, 500);
        setTimeout(() => {
          $('#subDepartment').selectpicker();
        }, 500);
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  fetchIPDDepartments(): any {
    this.ngxLoader.start();
    this.departmentService.getIPDDepartmentsListV2().subscribe(
      (data) => {
        this.departmentsIpd = data.departments;
        $('#referred_to').selectpicker('refresh');
        setTimeout(() => {
          $('#referred_to').selectpicker('refresh');
          $('#referred_to').selectpicker();
        }, 500);
        // $('#referredFrom').selectpicker('refresh');
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  fetchLookupsRelationship(): any {
    this.ngxLoader.start();
    this.lookupService.indexV2('').subscribe(
      (data) => {
        this.lookups = data.lookups;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  _keyPressOnlyChar(event: any): any {
    const pattern = /^[a-zA-Z\s]*$/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  _onlyNumeric(event: any): any {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  patientRegistration(patient): any {
    this.router.navigate(['/patient/registration-v2/'], {
      queryParams: { patient_mrn: patient.mrn },
    });
  }

  createAdmission(): any {
    if (this.isDisable) {
      return;
    }
    this.isDisable = true;
    if (!this.patient) {
      toastr.error('Select patient');
      this.isDisable = false;
      return;
    }
    if (this.patient.admitted === true) {
      toastr.error('You cannot admit a patient who is already admitted.');
      this.isDisable = false;
      return;
    }
    this.submitted = true;
    if (
      this.PatientAdmissionForm.invalid ||
      this.PatientAdmitionInfoForm.invalid ||
      !this.patient.id
    ) {
      this.isDisable = false;
      return;
    }
    if (this.signature_url === '') {
      this.clearSignature();
    }
    this.getImage();
    if (!this.isSignature) {
      this.isDisable = false;
      return;
    }
    this.ngxLoader.start();
    this.PatientAdmissionForm.get('signature_url').setValue(this.signature_url);
    this.PatientAdmissionForm.get('patient_id').setValue(this.patient.id);
    this.PatientAdmissionForm.get('status').setValue('Admitted');
    this.patientAdmissionService
      .createV2(this.PatientAdmissionForm.value, this.admissionRequestId)
      .subscribe(
        (data) => {
          this.isDisable = false;
          this.patient_visit_id = data.admission.visit_id;
          localStorage.removeItem('patientAdmission');
          localStorage.removeItem('patientSearch');
          this.ngxLoader.stop();
          toastr.success('Successfully Admitted');
          if (this.currentControls !== '') {
            if (
              this.currentControls.admission_controls.admission_form_web ===
              false
            ) {
              window.open(
                'pdf-reports/patient-admission-v2?id=' +
                  this.patient.id +
                  '&visit_id=' +
                  this.patient_visit_id +
                  '&mrn=' +
                  this.patient.mrn +
                  '&invoice_id=' +
                  data.admission.invoice_id,
              );
            } else {
              window.open(
                this.current_environment +
                  '/v3/patients/' +
                  this.patient.id +
                  '/admission_slip.pdf?auth_token=' +
                  localStorage.getItem('auth_token') +
                  '&medical_unit_id=' +
                  localStorage.getItem('current_medical_unit_id'),
              );
            }
          } else {
            window.open(
              this.current_environment +
                '/v3/patients/' +
                this.patient.id +
                '/admission_slip.pdf?auth_token=' +
                localStorage.getItem('auth_token') +
                '&medical_unit_id=' +
                localStorage.getItem('current_medical_unit_id'),
            );
          }
          this.patientRegistration(this.patient);
          // this.fetchWards();
          this.getWardListing();
        },
        (err) => {
          this.isDisable = false;
          this.ngxLoader.stop();
          if (
            err.error.final_settlement === false &&
            this.currentHospital === 'nationalHospital'
          ) {
            this.modalService.open(this.conformFinalSettlementModal);
          } else {
            toastr.error(err.error.message);
          }
        },
      );
  }

  draftAdmission(): any {
    this.PatientAdmissionForm.value.patient_id = this.patient.id;
    this.PatientAdmissionForm.value.status = 'Draft';
    localStorage.setItem(
      'patientAdmission',
      JSON.stringify(this.PatientAdmissionForm.value),
    );
    localStorage.setItem(
      'patientSearch',
      JSON.stringify(this.PatientAdmitionInfoForm.value),
    );
    toastr.success('Successfully Saved');
    // if (this.PatientAdmissionForm.invalid) {
    //     return;
    // }
    // this.ngxLoader.start();
    // this.patientAdmissionService.create(this.PatientAdmissionForm.value).subscribe(
    //   data => {
    //     this.ngxLoader.stop();
    //     this._flashMessagesService.show('Successfully Saved', { cssClass: 'alert-success', timeout: 5000 });
    //   },
    //   err => {
    //     this.ngxLoader.stop();
    //     this._flashMessagesService.show(err['error']['message'], { cssClass: 'alert-danger', timeout: 5000 });
    //   }
    // );
  }

  resetPatientAdmissionForm(): any {
    this.patient = null;
    this.default_patient_mrn = '';
    this.isUpdate = false;
    this.PatientAdmissionForm.reset({
      referred_from: '',
      referred_to: '',
      ward_id: '',
      bed_id: '',
      doctor_id: '',
      medical_officer_id: '',
      condition: 'normal',
      attendent_relationship: '',
    });
    this.fetchPatientInfo();
  }

  selectPatient(data): any {
    if (data.is_deceased) {
      toastr.error(inactiveDeceasedPatient);
      return;
    }
    this.clearSignature();
    this.urlButton = true;
    this.urlImage = data.admissions[0]?.signature_image?.url || '';
    $('.signature').css('display', 'none');
    if (this.urlImage === '') {
      this.urlButton = false;
      $('.signature').css('display', 'block');
    }
    this.isUpdate = true;

    if (this.admittedPatients === false) {
      if (data.admissions.length > 0) {
        if (data.admissions[0].referred_to) {
          this.fetchDoctorsByDep(data.admissions[0].referred_to.id);
          this.getSubDepartment(data.admissions[0].referred_to.id);
        }
        this.updatePatientAdmittedId = data.admissions[0].id;
        // this.updateWard(data.bed_id, data.admissions[0].ward.id);
        this.getWardBeds(data.bed_id, data.admissions[0].ward.id);
      }
      if (data.admissions.length > 0) {
        this.admissionRequestId = '';
        let referId = '';
        if (data.admissions[0].referred_to) {
          referId = data.admissions[0].referred_to.id;
        }
        this.patient_visit_id = data.admissions[0].visit_id;
        this.PatientAdmissionForm.patchValue({
          patient_id: data.id,
          referred_from: data.admissions[0].referred_from.id,
          referred_to: referId,
          sub_department_id: data.admissions[0].sub_department_id || '',
          medical_officer_id: data.admissions[0].medical_officer_id || '',
          doctor_id: data.admissions[0].doctor_id,
          ward_id: data.admissions[0].ward.id,
          bed_id: data.bed_id,
          condition: data.admissions[0].condition,
          attendent_relationship: data.admissions[0].attendent_relationship,
          attendent_name: data.admissions[0].attendent_name,
          attendent_mobile: data.admissions[0].attendent_mobile,
          attendent_address: data.admissions[0].attendent_address,
          attendent_nic: data.admissions[0].attendent_nic,
          admission_number: data.admissions[0].admission_number,
          status: data.admissions[0].status,
          comments: data.admissions[0].comments,
        });
      }
    } else if (this.admittedPatients === true) {
      if (data.reservations) {
        let referId = '';
        if (data.reservations.referred_to) {
          referId = data.reservations.referred_to.id;
        }
        let referFromId = '';
        if (data.reservations.referred_from) {
          referFromId = data.reservations.referred_from.id;
        }
        this.admissionRequestId = data.reservations.id;
        this.PatientAdmissionForm.patchValue({
          patient_id: data.id,
          referred_from: referFromId,
          referred_to: referId,
          sub_department_id: data.admissions[0]?.sub_department_id || '',
          medical_officer_id: data.reservations.medical_officer_id || '',
          doctor_id: data.reservations.doctor_id || '',
          ward_id: data.reservations.ward_id || '',
          bed_id: data.reservations.bed_id,
          condition: data.reservations.condition,
          attendent_relationship: data.reservations.attendent_relationship,
          attendent_name: data.reservations.attendent_name,
          attendent_mobile: data.reservations.attendent_mobile,
          attendent_address: data.reservations.attendent_address,
          attendent_nic: data.reservations.attendent_nic,
          admission_number: data.reservations.admission_number,
          status: data.reservations.status,
          comments: data.reservations.comments,
        });
        $('#doctorId').selectpicker('refresh');
        // this.fetchDoctorsByDep(referId);
        this.fetchDepByDoctor(data.reservations.doctor_id);
        this.updatePatientAdmittedId = data.reservations.id;
        // this.updateWard(data.reservations.bed_id, data.reservations.ward_id);
        this.getWardBeds(
          data.reservations.bed_id || '',
          data.reservations.ward_id || '',
        );
      }
      // if (data.reservations) {
      //   this.fetchDoctorsByDep(data.reservations.referred_to.id);
      //   this.updatePatientAdmittedId = data.reservations.id;
      //   // this.updateWard(data.reservations.bed_id, data.reservations.ward_id);
      //   this.getWardBeds(data.reservations.bed_id, data.reservations.ward_id);
      // }
    }

    setTimeout(() => {
      $('#referredFrom').selectpicker('refresh');
      $('#referred_to').selectpicker('refresh');
      $('#wardId').selectpicker('refresh');
      $('#moDoctors').selectpicker('refresh');
      // $('#bedId').selectpicker('refresh');
    }, 500);

    this.patient = data;
    this.patient_first_name = data.first_name;
    this.default_patient_mrn = data.mrn;

    this.closePatientList();
  }

  updateAdmittedPatient(): any {
    this.disableUpdate = true;
    this.submitted = true;
    if (this.PatientAdmissionForm.invalid) {
      this.disableUpdate = false;
      return;
    }
    if (this.urlButton === false) {
      this.getImage();
    }
    this.PatientAdmissionForm.get('signature_url').setValue(this.signature_url);
    this.ngxLoader.start();
    this.patientAdmissionService
      .updateV2(this.PatientAdmissionForm.value, this.updatePatientAdmittedId)
      .subscribe(
        (data) => {
          this.disableUpdate = false;
          this.submitted = false;
          // this.patient_visit_id=data.admissions.visit_id;
          this.fetchPatients();
          // this.fetchWards();
          this.getWardListing();
          toastr.success('Successfully Updated');
          if (this.currentControls !== '') {
            if (
              this.currentControls.admission_controls.admission_form_web ===
              false
            ) {
              window.open(
                'pdf-reports/patient-admission-v2?id=' +
                  this.patient.id +
                  '&visit_id=' +
                  this.patient_visit_id +
                  '&mrn=' +
                  this.patient.mrn +
                  '&invoice_id=' +
                  data.admission.invoice_id,
              );
            } else {
              window.open(
                this.current_environment +
                  '/v3/patients/' +
                  this.patient.id +
                  '/admission_slip.pdf?auth_token=' +
                  localStorage.getItem('auth_token') +
                  '&medical_unit_id=' +
                  localStorage.getItem('current_medical_unit_id'),
              );
            }
          } else {
            window.open(
              this.current_environment +
                '/v3/patients/' +
                this.patient.id +
                '/admission_slip.pdf?auth_token=' +
                localStorage.getItem('auth_token') +
                '&medical_unit_id=' +
                localStorage.getItem('current_medical_unit_id'),
            );
          }
          this.resetPatientAdmissionForm();
        },
        (err) => {
          this.disableUpdate = false;
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  getAdmittedPatient(): any {
    this.patient_admissions = [];
    this.ngxLoader.start();
    this.patientAdmissionService.indexV2().subscribe(
      (data) => {
        this.patient_admissions = data.admissions;
        if (this.patient_admissions) {
          this.isAdmReq = true;
        }
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  dischargeAdmittedPatientConform(): any {
    this.modalService.open(this.conformPatientDischargeModal);
  }
  dischargeAdmittedPatient(): any {
    this.modalService.dismissAll(this.conformPatientDischargeModal);
    this.ngxLoader.start();
    this.patientAdmissionService
      .discharge(this.default_patient_mrn, this.updatePatientAdmittedId)
      .subscribe(
        (data) => {
          this.resetPatientAdmissionForm();
          this.fetchPatients();
          toastr.success('Patient discharge successfully');
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  printAdmitionSlip(): any {
    if (this.currentControls !== '') {
      if (
        this.currentControls.admission_controls.admission_form_web === false
      ) {
        window.open(
          'pdf-reports/patient-admission-v2?id=' +
            this.patient.id +
            '&visit_id=' +
            this.patient_visit_id +
            '&mrn=' +
            this.patient.mrn,
        );
      } else {
        window.open(
          this.current_environment +
            '/v3/patients/' +
            this.patient.id +
            '/admission_slip.pdf?auth_token=' +
            localStorage.getItem('auth_token') +
            '&medical_unit_id=' +
            localStorage.getItem('current_medical_unit_id'),
        );
      }
    } else {
      window.open(
        this.current_environment +
          '/v3/patients/ ' +
          this.patient.id +
          '/admission_slip.pdf?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
      );
    }
  }
  patientTagPrint(): any {
    window.print();
  }
  gotoOpdPage(): any {
    this.router.navigateByUrl('opd');
  }
  getWardBeds(bedId, wardId: any): any {
    this.PatientAdmissionForm.get('bed_id').setValue('');
    if (!wardId) {
      this.wardBeds = [];
      setTimeout(() => {
        $('#bedId').selectpicker('refresh');
      }, 500);
      return;
    }
    this.ngxLoader.start();
    this.wardService.getNewBedV2(wardId).subscribe(
      (data) => {
        this.wardBeds = data.beds;

        this.ngxLoader.stop();
        setTimeout(() => {
          $('#bedId').selectpicker('refresh');
        }, 500);
        if (bedId) {
          this.PatientAdmissionForm.get('bed_id').patchValue(bedId);
        }
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  updateWard(bedId, id): any {
    this.PatientAdmissionForm.controls.bed_id.setValue('');
    const obj = this.wards.find((item) => item.id === Number(id));
    if (obj) {
      this.beds = obj.beds;
    } else {
      this.beds = [];
    }
    setTimeout(() => {
      $('#bedId').selectpicker();
    }, 500);
    setTimeout(() => {
      $('#bedId').selectpicker('refresh');
    }, 500);
    if (bedId) {
      this.PatientAdmissionForm.get('bed_id').patchValue(bedId);
    }
  }

  finalSettlementPatient(): any {
    this.ngxLoader.start();
    const finalSettlement = {
      mode_of_payment: 'Cash',
      payment_details: '',
    };
    this.patientService
      .finalSettlementPatientV2(this.patient, finalSettlement, '')
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          this.modalService.dismissAll(this.conformFinalSettlementModal);
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  openPatientList(): any {
    $('#patient-list').toggleClass('offcanvas-on');
  }

  closePatientList(): any {
    $('#patient-list').removeClass('offcanvas-on');
  }
  get f(): any {
    return this.PatientAdmissionForm.controls;
  }
  get k(): any {
    return this.PatientSearchForm.controls;
  }
  get j(): any {
    return this.PatientAdmitionInfoForm.controls;
  }

  Requested(): any {
    this.ngxLoader.start();
    this.patientService
      .searchRequestedPatientV2(this.PatientSearchForm.value)
      .subscribe(
        (data) => {
          this.patient_admissions = data.reservations;
          this.ngxLoader.stop();
        },
        (err) => {
          this.patient_admissions = [];
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  changePatients(event: any): any {
    this.patient = null;
    this.PatientAdmissionForm.reset({
      patient_id: '',
      referred_from: '',
      referred_to: '',
      medical_officer_id: '',
      doctor_id: '',
      ward_id: '',
      bed_id: '',
      condition: 'normal',
      attendent_relationship: '',
      attendent_name: '',
      attendent_mobile: '',
      attendent_address: '',
      attendent_nic: '',
      admission_number: '',
      status: '',
      comments: '',
    });
    this.submitted = false;
    setTimeout(() => {
      $('#referredFrom').selectpicker();
      $('#referred_to').selectpicker();
      $('#doctorId').selectpicker();
      $('#wardId').selectpicker();
      $('#moDoctors').selectpicker();
      $('#bedId').selectpicker();
    }, 500);
    setTimeout(() => {
      $('#referredFrom').selectpicker('refresh');
      $('#referred_to').selectpicker('refresh');
      $('#doctorId').selectpicker('refresh');
      $('#wardId').selectpicker('refresh');
      $('#moDoctors').selectpicker('refresh');
      $('#bedId').selectpicker('refresh');
    }, 500);

    // this.PatientSearchForm.value.type = 'default';
    this.PatientSearchForm.get('type').patchValue('default');
    this.PatientSearchForm.get('search_query').patchValue('');

    if (event.target.checked === true) {
      this.Requested();
    } else if (event.target.checked === false) {
      this.fetchPatients();
    }
  }

  searchPatients(value): any {
    if (value === true) {
      this.Requested();
    } else if (value === false) {
      this.fetchPatients();
    }
  }

  clearSignature(): any {
    this.sig.clear();
  }

  getImage(): any {
    // if(this.sig.isEmpty()) {
    //   toastr.error('Signature Is Required');
    //   this.isSignature=false;
    //   return
    // }
    // else {
    //   this.isSignature=true;
    //   this.signature_url = this.sig.toDataURL();
    // }
    this.isSignature = true;
    this.signature_url = this.sig.toDataURL();
  }
  updateSignature(): any {
    this.urlImage = '';

    this.urlButton = false;
    $('.signature').css('display', 'block');
  }
}
