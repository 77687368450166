import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
  OnChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { OpdService } from '../../../../services/opd.service';
import * as moment from 'moment/moment';
import { environment } from '../../../../../environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VitalsValidationService } from '../../../../services/vitals-validation.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SystemControlsService } from '../../../../services/system-controls.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PatientService } from '../../../../services/patient.service';
import { HospitaldoctorService } from '../../../../services/hospitaldoctor.service';
import { LookupService } from '../../../../services/lookup.service';
import { EmployeeRegistrationService } from '../../../../services/employee-registration.service';
import { ServiceService } from '../../../../services/service.service';
import { PricingService } from '../../../../services/pricing.service';
import { LabTestService } from '../../../../services/lab-test.service';

declare var $: any;
declare var toastr: any;
declare var Chart: any;

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css'],
})
export class MainComponent implements OnInit {
  @ViewChild('alertBox') alertBox: ElementRef;

  @Input() patient: any;
  @Input() doctor: any;
  @Input() resetMainData: any;
  @Input() doctors: any;
  @Input() refdoctor: any;
  @Input() isopd: boolean;
  @Input() isReq: boolean;
  @Input() isMainVitalsUpdate: number;
  @Input() isNotesUpdate: number;
  @Input() isMainDiagnosisUpdate: number;
  @Input() isConsultationUpdate: number;
  @Input() isMainServicesUpdate: number;
  @Output() isMainVitalsRecieved = new EventEmitter<string>();
  @Output() isMainServicesRecieved = new EventEmitter<string>();
  @Output() isNotesRecieved = new EventEmitter<string>();
  @Output() isMainDiagnosisRecieved = new EventEmitter<string>();
  @Output() isConsultationRecieved = new EventEmitter<string>();

  consultationForm: FormGroup;
  progressNotesForm: FormGroup;
  pharmacistForm: FormGroup;

  vitalsListData = [];
  vitalsData: any = {
    weight: '',
    height: '',
    temperature: '',
    bp_systolic: '',
    bp_diastolic: '',
    pulse: '',
    resp_rate: '',
    o2_saturation: '',
    bmi: '',
    bsr: '',
  };
  vitalsListDataAppend = [];
  patientAllHistory = false;
  alert_message = '';
  currentControls: any;
  allowServiceZeroPrice = false;
  serviceFormConsultantVisit = {
    patient_services: [
      {
        service_id: '',
        pricing_id: '',
        doctor_id: '',
        service_type: '',
        comments: '',
        department_id: '',
        billing_code: '',
        performing_doctor_id: '',
        referring_doctor_id: '',
        mode_of_payment: '',
        price: 0,
        discount: 0,
        service_price: 0,
        actual_price: 0,
        quantity: 0,
        urgent: false,
        quantity_allowed: false,
        editable: false,
        patient_service_id: '',
        flight_date_time: '',
        reporting_time_required: false,
      },
    ],
    discount: 0,
    cash: 0,
    ledger: '',
    mode_of_payment: 'Credit',
    payment_details: '',
    receivable: 0,
    patient_id: '',
  };

  isSubmit = false;
  lookups = [];
  attendVisit = [];
  doctor_ids: any;
  department_ids: any;
  serviceData = {
    patient_id: '',
    mode_of_payment: 'Credit',
    discount: 0,
    receivable: 0,
    cash: 0,
    ledger: '',
    eprescription: true,
    patient_services: [
      {
        service_id: '',
        pricing_id: '',
        doctor_id: '',
        service_type: '',
        comments: '',
        department_id: '',
        billing_code: '',
        performing_doctor_id: '',
        referring_doctor_id: '',
        price: 0,
        service_dept: '',
        discount: 0,
        service_price: 0,
        quantity: 1,
        urgent: false,
      },
    ],
  };
  services = [];
  servicesTotalBill = 0;
  uniq_services = [];
  checkDuplicateServices = true;
  patientServiceHistory = [];
  servicesPrintRecent = true;
  isChecked = false;
  current_environment: string;
  mainDiagnosisListAdd = [];
  allDiagnosisDataMain = [];
  diagnosisSearchResultMain = false;
  progressNotes = '';
  progressNotesShift = 'Morning';
  progressNotesDoctor = '';
  diagnosisListData = [];
  diagnosisSearchResult = false;
  diagnosisSearch = '';
  paitestDiagnosisHistoryData: any;
  paitestDiagnosisHistory = [];
  differentialSearch = '';
  finalSearch = '';
  submitted = false;
  notesPrintList: any = [];
  notesList: any = [];
  notesListNurse: any = [];
  notesListDoc: any = [];
  notesListPharmacist: any = [];
  allAllergiesListData = [];
  userPassword = '';
  empId = '';
  isConfimedNursing = false;
  doctor_department_id: any;
  doctorEmpId = '';
  doctorUserPassword = '';
  isConfirmedDoctor = false;
  settingData: any;

  constructor(
    private opdService: OpdService,
    private chRef: ChangeDetectorRef,
    private modalService: NgbModal,
    private vitalsValidation: VitalsValidationService,
    private formBuilder: FormBuilder,
    private systemControlService: SystemControlsService,
    private ngxLoader: NgxUiLoaderService,
    private patientService: PatientService,
    private hospitalDoctorsService: HospitaldoctorService,
    private lookupService: LookupService,
    private employeeService: EmployeeRegistrationService,
    private serviceService: ServiceService,
    private systemControlsService: SystemControlsService,
    private pricingService: PricingService,
    private labTestService: LabTestService,
  ) {}

  ngOnInit(): void {
    this.consultationForm = this.formBuilder.group({
      doctor_id: ['', Validators.required],
      department_id: [''],
      visit_type_id: ['', Validators.required],
      doctor_type_id: ['', Validators.required],
      patient_id: ['', Validators.required],
      bill: [false],
      detail: [''],
      status: [''],
    });
    this.progressNotesForm = this.formBuilder.group({
      note: '',
      event: '',
      investigation_type: 'nursing',
      shift: 'Morning',
      handling_over_staff: '',
      receiving_staff: '',
      written_by: '',
      doctor_id: '',
      vital_attributes: this.formBuilder.group({
        weight: '',
        height: '',
        temperature: '',
        bp_systolic: '',
        bp_diastolic: '',
        pulse: '',
        resp_rate: '',
        o2_saturation: '',
        head_circ: '',
      }),
    });
    this.pharmacistForm = this.formBuilder.group({
      doctor_id: [''],
      investigation_type: 'pharmacist',
      note: ['', Validators.required],
      appropriate_dose: [false],
      appropriate_route: [false],
      appropriate_time: [false],
      fda_approved_drug: [false],
      any_reaction: [false],
    });
    this.systemControlService.getUpdatedChecks().subscribe((data) => {
      this.currentControls = data.report_controls;
      if (
        this.currentControls.patient_services.allow_zero_service_price === true
      ) {
        this.allowServiceZeroPrice = true;
      }
    });

    this.addMoreDianosisMain();
    this.getVitals('');
    this.fetchAttendingVisiting();
    this.fetchServicesTypes();
    this.fetchServicesHistory('');
    this.getProgressNotes('nursing', '');
    this.resetServices();
    this.getWebControls();
  }

  ngOnChanges(changes: SimpleChanges): any {
    console.log('changes11', changes);
    if (changes.resetMainData) {
      this.resetMainTabAfterDischarge();
    }
    if (changes.isMainVitalsUpdate) {
      this.getVitals('');
    }
    if (changes.isMainDiagnosisUpdate) {
      this.getPaitentDiagnosis('');
    }
    if (changes.isMainServicesUpdate) {
      this.fetchServicesHistory('');
    }
    this.getProgressNotes('nursing', changes.patient.currentValue);
    this.getVitals(changes.patient.currentValue);
    this.getPaitentDiagnosis(changes.patient.currentValue);
    this.fetchServicesHistory(changes.patient.currentValue);
    this.getPatientAllergies(changes.patient.currentValue);
    this.getAllAllergiesList(changes.patient.currentValue);
    setTimeout(() => {
      this.resetMainTabData();
    }, 4000);
    setTimeout(() => {
      $('#ipdConsultingdoctor').selectpicker('refresh');
    }, 500);
  }
  getWebControls(): any {
    this.labTestService.fetchLabsControlsv2().subscribe(
      (data) => {
        this.settingData = data;
      },
      (err) => {},
    );
  }

  getVitals(val): any {
    if (!this.patient) {
      return;
    }
    this.vitalsData = {
      weight: '',
      height: '',
      temperature: '',
      bp_systolic: '',
      bp_diastolic: '',
      pulse: '',
      resp_rate: '',
      o2_saturation: '',
      bmi: '',
      bsr: '',
    };
    this.vitalsListData = [];
    this.vitalsListDataAppend = [];
    this.opdService
      .getVitalsFunV2(this.patient, this.patientAllHistory, '')
      .subscribe(
        (data) => {
          this.vitalsListDataAppend = data.vitals;
          for (let i = 0; i < this.vitalsListDataAppend.length; i++) {
            const bmi = this.calculateBMI(
              this.vitalsListDataAppend[i].weight,
              this.vitalsListDataAppend[i].height,
            );
            this.vitalsListDataAppend[i].bmi = bmi;
          }
          this.vitalsListData = this.vitalsListDataAppend;
          //
          this.chRef.markForCheck();
          // this.ngAfterViewInit();
        },
        (err) => {},
      );
  }
  calculateBMI(weight, height): any {
    if (height && weight) {
      // var finalHeight = height * .025;
      const finalHeight = height * 0.01;
      const BMI = weight / Math.pow(finalHeight, 2);
      return BMI.toFixed(0);
    } else {
      return '-';
    }
  }

  getLastTwentyVitals(vitals): any {
    if (vitals.length > 20) {
      vitals = vitals.slice(Math.max(vitals.length - 20, 1));
    }
    return vitals;
  }

  formatGraphDate(dates): any {
    const date = moment(dates).format('DD-MM-yyyy');
    return date;
  }

  isNumberKey(e): any {
    const charCode = e.which ? e.which : e.keyCode;
    if (e.target.value.includes('.')) {
      if (e.key === '.') {
        return false;
      }
    }
    if (
      charCode !== 190 &&
      charCode !== 96 &&
      charCode !== 97 &&
      charCode !== 98 &&
      charCode !== 99 &&
      charCode !== 100 &&
      charCode !== 101 &&
      charCode !== 102 &&
      charCode !== 103 &&
      charCode !== 104 &&
      charCode !== 105 &&
      charCode !== 46 &&
      charCode !== 110 &&
      charCode > 31 &&
      (charCode < 48 || charCode > 57)
    ) {
      return false;
    }
    return true;
  }

  _onlyNumeric(event: any): any {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  bmiCalculate(): any {
    if (this.vitalsData.weight === '' || this.vitalsData.height === '') {
      this.vitalsData.bmi = '';
    }
    if (this.vitalsData.weight && this.vitalsData.height) {
      this.vitalsData.bmi = '';
      // var finalHeight = this.vitalsData.height * .025;
      const finalHeight = this.vitalsData.height * 0.01;
      const BMI = this.vitalsData.weight / Math.pow(finalHeight, 2);
      this.vitalsData.bmi = BMI.toFixed(0);
      // const convertMeter = this.vitalsData.height / 100;
      // const meterSqr = convertMeter * convertMeter;
      // const abc = this.vitalsData.weight / meterSqr;
      // this.vitalsData.bmi = abc;
    }
  }

  printVitals(value): any {
    window.open(
      'pdf-reports/vitals-patient-history?id=' +
        this.patient.id +
        '&visit_id=' +
        this.patient.visit_id +
        '&mrn=' +
        this.patient.mrn +
        '&history=' +
        value +
        '&invoice_id=',
    );
    // window.open(environment.rorUrl + '/v4/patients/patient_vitals.pdf?auth_token=' + localStorage.getItem('auth_token') + '&medical_unit_id=' + localStorage.getItem('current_medical_unit_id') + '&patient_mrn=' + this.patient.mrn + '&history=' + value);
  }

  addVitals(value): any {
    if (!this.patient) {
      this.modalService.open(this.alertBox);
      this.alert_message = 'Select patient';
      return;
    }
    if (
      this.vitalsData.temperature === '' &&
      this.vitalsData.bp_systolic === '' &&
      this.vitalsData.bp_diastolic === '' &&
      this.vitalsData.pulse === '' &&
      this.vitalsData.resp_rate === '' &&
      this.vitalsData.o2_saturation === '' &&
      this.vitalsData.height === '' &&
      this.vitalsData.weight === '' &&
      this.vitalsData.bsr === ''
    ) {
      toastr.error('Add at least 1 vital');
      return;
    }
    if (this.vitalsValidation.actionOnVitals(this.vitalsData) === false) {
      return;
    }

    this.ngxLoader.start();
    this.opdService.addVitalsFunV2(this.vitalsData, this.patient).subscribe(
      (data) => {
        this.vitalsData = {
          weight: '',
          height: '',
          temperature: '',
          bp_systolic: '',
          bp_diastolic: '',
          pulse: '',
          resp_rate: '',
          o2_saturation: '',
          bmi: '',
          bsr: '',
        };
        this.isMainVitalsUpdate = Math.random();
        this.isMainVitalsRecieved.emit(this.isMainVitalsUpdate.toString());
        if (value === 'submit') {
          this.getVitals('');
        }
        if (value === 'next') {
          $('.navi .navi-item .navi-link').removeClass('active');
          $('.navi .navi-item .navi-link').removeClass('active');
          const element: HTMLElement = document.getElementById(
            'HistoryTabs',
          ) as HTMLElement;
          element.click();
        }
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        this.modalService.open(this.alertBox);
        this.alert_message = err.error.message[0];
      },
    );
  }
  getPatientAllergies(val): any {
    this.ngxLoader.start();
    this.opdService.getPatientHistoryFunV2(this.patient).subscribe(
      (data) => {
        if (data.allergies) {
          const string = data.allergies.split(',');
          setTimeout(() => {
            $('#ipdPatientAllergiesMain').val(string);
            $('#ipdPatientAllergiesMain').select2().trigger('change');
          }, 1000);
        }
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }
  submitPatientAllergies(): any {
    const patientHistoryComplaint = { allergies: '' };
    patientHistoryComplaint.allergies = $('#ipdPatientAllergiesMain')
      .val()
      .toString();
    if (patientHistoryComplaint.allergies === '') {
      toastr.error('Add at allergies');
      return;
    }
    this.ngxLoader.start();
    this.opdService
      .submitPatientHistoryFunV2(patientHistoryComplaint, this.patient)
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
        },
      );
  }

  onSubmitConsultations(value): any {
    if (
      this.serviceFormConsultantVisit.patient_services[0].service_price === 0 &&
      this.consultationForm.get('bill').value === true
    ) {
      toastr.error(
        'This service is not configured. Please contact IT Administrator',
      );
      return;
    }

    if (this.consultationForm.get('bill').value === true) {
      this.consultationForm.get('patient_id').patchValue(this.patient.id);
      this.consultationForm.get('status').patchValue('ipd');
      if (value !== 'main') {
        this.consultationForm
          .get('detail')
          .setValue($('#consultingDetails').summernote('code'));
      }
      this.isSubmit = true;
      if (this.consultationForm.invalid) {
        return;
      }
      this.createVisit();
    }

    this.consultationForm.get('patient_id').patchValue(this.patient.id);
    this.consultationForm.get('status').patchValue('ipd');
    if (value !== 'main') {
      this.consultationForm
        .get('detail')
        .setValue($('#consultingDetails').summernote('code'));
    }

    this.isSubmit = true;
    if (this.consultationForm.invalid) {
      return;
    }
    this.ngxLoader.start();
    this.opdService
      .addConsultingV2(this.consultationForm.value, false, this.patient)
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          this.isConsultationUpdate = Math.random();
          this.isConsultationRecieved.emit(
            this.isConsultationUpdate.toString(),
          );
          if (value !== 'main') {
            // $('#consultingDetails').summernote('code', '');
            // $('#consultingDetail').summernote('code', '');
            // this.fetchLookup();
          }
          toastr.success('Added Consultant');
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  createVisit(): any {
    const paymentStatus = 'unpaid';
    this.ngxLoader.start();
    this.serviceFormConsultantVisit.patient_id = this.patient.id;
    this.patientService
      .createPatientServiceV2(
        this.serviceFormConsultantVisit,
        this.patient,
        paymentStatus,
        '',
        '',
        '',
        '',
        'ipd-v2',
      )
      .subscribe((data) => {
        this.ngxLoader.stop();
      });
  }

  get f(): any {
    return this.consultationForm.controls;
  }

  fetchDoctorDepartments(id): any {
    const dep_id = this.doctors.filter((t) => t.id === Number(id))[0]
      .doctor_department_id;
    this.fetchLookup(id, dep_id);
    this.doctor_department_id = dep_id;
    this.serviceFormConsultantVisit.patient_services[0].department_id = dep_id;
    this.serviceFormConsultantVisit.patient_services[0].doctor_id = id;
    this.consultationForm
      .get('department_id')
      .setValue(this.doctor_department_id);
  }

  fetchLookup(id, dep_id): any {
    this.ngxLoader.start();
    this.doctor_ids = id;
    this.department_ids = dep_id;
    this.lookupService
      .indexVisitTypeV2(
        this.doctor_ids,
        this.department_ids,
        this.isopd,
        true,
        this.patient.id,
      )
      .subscribe(
        (data) => {
          this.lookups = data.lookups;
          this.consultationForm
            .get('visit_type_id')
            .setValue(
              this.lookups.filter(
                (t) =>
                  t.category === 'Visit Type' &&
                  (t.key === 'Normal' || t.key === 'General'),
              )[0]?.id || '',
            );
          if (
            this.lookups.filter(
              (t) =>
                t.category === 'Visit Type' &&
                (t.key === 'Normal' || t.key === 'General'),
            )[0]?.id
          ) {
            this.fetchVisitService('');
          }
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
        },
      );
  }

  fetchVisitService(val): any {
    this.ngxLoader.start();
    this.serviceService
      .getVisitServiceV3(
        this.patient.id,
        this.consultationForm.get('visit_type_id').value,
        this.consultationForm.get('doctor_id').value,
        this.consultationForm.get('department_id').value,
        this.isopd,
        true,
        false,
      )
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          this.serviceFormConsultantVisit.patient_services[0].service_id =
            data.service.id;
          this.serviceFormConsultantVisit.patient_services[0].billing_code =
            data.service.billing_code;
          this.fetchConsultationPrice();
        },
        (err) => {
          this.ngxLoader.stop();
          // this.updatePrice();
          toastr.error(err.error.message);
        },
      );
  }

  fetchConsultationPrice(): any {
    const obj = {
      patient_type_id: this.patient.patient_type.id,
      current_billing_code:
        this.serviceFormConsultantVisit.patient_services[0].billing_code,
      // current_billing_code: ServiceForm.this.patient_ser[0].billing_code,
    };
    this.ngxLoader.start();
    this.pricingService.searchPriceV3(obj).subscribe(
      (data) => {
        let discount;
        if (
          this.patient.admissions.length > 0 &&
          this.patient.admitted === true
        ) {
          this.serviceFormConsultantVisit.patient_services[0].price =
            data.pricings[0].ipd_price;
          this.serviceFormConsultantVisit.patient_services[0].service_price =
            data.pricings[0].ipd_price;
          this.serviceFormConsultantVisit.patient_services[0].actual_price =
            data.pricings[0].ipd_price;
          discount =
            (data.pricings[0].ipd_price / 100) *
            data.pricings[0].system_discount_percentage;
        } else {
          this.serviceFormConsultantVisit.patient_services[0].price =
            data.pricings[0].opd_price;
          this.serviceFormConsultantVisit.patient_services[0].service_price =
            data.pricings[0].opd_price;
          this.serviceFormConsultantVisit.patient_services[0].actual_price =
            data.pricings[0].opd_price;
          discount =
            (data.pricings[0].opd_price / 100) *
            data.pricings[0].system_discount_percentage;
        }
        this.serviceFormConsultantVisit.patient_services[0].discount =
          Math.round(discount);
        this.serviceFormConsultantVisit.patient_services[0].pricing_id =
          data.pricings[0].id;
        this.serviceFormConsultantVisit.patient_services[0].quantity = 1;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  fetchAttendingVisiting(): any {
    this.employeeService.fetchConfigurationV2('doctor_type').subscribe(
      (data) => {
        this.attendVisit = data.configurator_types;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  fetchSelectedServices(ind, type, serviceType): any {
    this.serviceData.patient_services[ind].service_dept = '';
    this.serviceData.patient_services[ind].department_id = '';
    this.serviceData.patient_services[ind].billing_code = '';
    this.serviceData.patient_services[ind].service_id = '';
    this.serviceData.patient_services[ind].service_price = 0;
    this.serviceData.patient_services[ind].discount = 0;
    this.serviceData.patient_services[ind].pricing_id = '';
    this.serviceData.patient_services[ind].price = 0;
    this.services[ind] = [];
    this.ngxLoader.start();
    this.serviceService.getServiceByTypesV2(serviceType).subscribe(
      (data) => {
        this.services[ind] = data.services;
        if (type === 'main') {
          $('#serviceNameMain' + ind).val('-1');
          $('#referringDoctorMian' + ind).val('');
          setTimeout(() => {
            $('#serviceNameMain' + ind).selectpicker();
            $('#referringDoctorMian' + ind).selectpicker();
          }, 500);
          setTimeout(() => {
            $('#serviceNameMain' + ind).selectpicker('refresh');
            $('#referringDoctorMian' + ind).selectpicker('refresh');
          }, 500);
        } else {
          $('#serviceName' + ind).val('-1');
          setTimeout(() => {
            $('#serviceName' + ind).selectpicker();
          }, 500);
          setTimeout(() => {
            $('#serviceName' + ind).selectpicker('refresh');
          }, 500);
        }
        this.servicesTotalBill = 0;
        this.chRef.detectChanges();
        // $('#serviceNameMain').val('-1');
        // setTimeout(() => {
        //   $('#serviceNameMain').selectpicker('refresh');
        // }, 500);
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  fetchServicesTypes(): any {
    this.ngxLoader.start();
    this.uniq_services = [];
    this.systemControlsService.getServicesTypeConfigV2().subscribe(
      (data) => {
        this.uniq_services = data.service_type_configurators;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  updateServicesDept(ind, data, event): any {
    const serviceObj = this.services[ind].find((m) => m.id === Number(event));
    // for (let i = 0; i < this.services.length; i++) {
    //   if (event === this.services[i].id.toString()) {
    if (serviceObj.department) {
      this.serviceData.patient_services[ind].service_dept =
        serviceObj.department.title;
      this.serviceData.patient_services[ind].department_id =
        serviceObj.department_id;
    }
    this.serviceData.patient_services[ind].billing_code =
      serviceObj.billing_code;
    this.serviceData.patient_services[ind].service_id = serviceObj.id;
    this.fetchServicePrice(serviceObj.billing_code, ind);
    // }
    // }
  }

  fetchServicePrice(code, ind): any {
    this.ngxLoader.start();
    this.pricingService.searchPriceAgainstV2(this.patient, code).subscribe(
      (data) => {
        let discount;
        if (
          this.patient.admissions.length > 0 &&
          this.patient.admitted === true
        ) {
          this.serviceData.patient_services[ind].service_price =
            data.pricings[0].ipd_price;
          discount =
            (data.pricings[0].ipd_price / 100) *
            data.pricings[0].system_discount_percentage;
        } else {
          this.serviceData.patient_services[ind].service_price =
            data.pricings[0].opd_price;
          discount =
            (data.pricings[0].opd_price / 100) *
            data.pricings[0].system_discount_percentage;
        }
        this.serviceData.patient_services[ind].discount = discount;
        this.serviceData.patient_services[ind].pricing_id = data.pricings[0].id;
        this.serviceData.patient_services[ind].price =
          this.serviceData.patient_services[ind].service_price;
        this.serviceData.patient_services[ind].quantity = 1;
        this.ngxLoader.stop();
        this.servicesTotalBill = 0;

        for (let i = 0; i < this.serviceData.patient_services.length; i++) {
          this.servicesTotalBill =
            this.servicesTotalBill + this.serviceData.patient_services[i].price;
        }
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  removeServices(i, price): any {
    if (this.serviceData.patient_services.length > 1) {
      this.servicesTotalBill = this.servicesTotalBill - price;
      this.serviceData.patient_services.splice(i, 1);
      this.services.splice(i, 1);
    } else if (this.serviceData.patient_services.length === 1) {
      this.servicesTotalBill = this.servicesTotalBill - price;
      this.serviceData.patient_services.splice(i, 1);
      this.services.splice(i, 1);
      this.addMoreServices();
    }
    setTimeout(() => {
      $('.serviceName').selectpicker('refresh');
    }, 200);
  }

  addMoreServices(): any {
    setTimeout(() => {
      $('.serviceSelectpickerMain').selectpicker();
      $('.serviceSelectpicker').selectpicker();
    }, 10);
    const addMoreData = {
      service_id: '',
      pricing_id: '',
      doctor_id: '',
      service_type: '',
      comments: '',
      department_id: '',
      billing_code: '',
      performing_doctor_id: '',
      referring_doctor_id: '',
      price: 0,
      service_dept: '',
      discount: 0,
      service_price: 0,
      quantity: 1,
      urgent: false,
    };
    this.serviceData.patient_services.push(addMoreData);
    this.services[this.serviceData.patient_services.length - 1] = [];
    if (this.serviceData.patient_services.length == 1) {
      this.services[0] = [];
    }
  }

  fetchServicesHistory(val): any {
    $('#ipdServicedoctor').selectpicker();
    this.servicesPrintRecent = true;
    this.ngxLoader.start();
    this.patientServiceHistory = [];
    this.patientService
      .patientServices(this.patient, this.patientAllHistory, '')
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          this.patientServiceHistory = data.patient_services;
        },
        (err) => {
          this.ngxLoader.stop();
        },
      );
  }

  hasDuplicateIds(patient_services): any {
    return (
      patient_services.filter(
        (service, index, arr) =>
          arr.findIndex((s) => s.service_id === service.service_id) !== index,
      ).length > 0
    );
  }

  addServices(type): any {
    $('#duplicateServicesAllertModal').modal('hide');
    let counter = 0;
    $('.addServiceForm select').css('border', '1px solid #E4E6EF');
    this.serviceData.discount = 0;
    if (type === '') {
      for (let i = 0; i < this.serviceData.patient_services.length; i++) {
        this.serviceData.discount =
          this.serviceData.discount +
          this.serviceData.patient_services[i].discount;
        const serviceType = $('#serviceType' + i).val();
        const serviceName = $('#serviceName' + i).val();
        const referringDoctor = $('#referringDoctor' + i).val();
        const performingDoctor = $('#performingDoctor' + i).val();

        if (serviceType === 'Select type' || serviceType === null) {
          $('#serviceType' + i).css('border', '1px solid #dc3545');
          counter++;
        }

        // let typeObj = this.uniq_services.find(m => m.name === this.serviceData.patient_services[i].service_type);
        // if (typeObj.ref_doctor === true && this.serviceData.patient_services[i].doctor_name === '') {
        //   counter++;
        //   $('#referringDoctor' + i).css('border', '1px solid #dc3545');
        // }
        if (
          serviceName === 'Select service' ||
          serviceName === '-1' ||
          serviceName === null
        ) {
          $('#serviceName' + i).css('border', '1px solid #dc3545');
          counter++;
        }
        this.serviceData.patient_services[i].referring_doctor_id = $(
          '#referringDoctor' + i,
        ).val();
        this.serviceData.patient_services[i].performing_doctor_id = $(
          '#performingDoctor' + i,
        ).val();
        if (this.currentControls.ipd_controls.is_requested_service === true) {
          if (!this.allowServiceZeroPrice) {
            if (this.serviceData.patient_services[i].service_price === 0) {
              toastr.error(
                'This Service is not configured correctly. Please contact IT Administrator',
              );
              return;
            }
          }
        }
      }
    } else {
      for (let i = 0; i < this.serviceData.patient_services.length; i++) {
        this.serviceData.discount =
          this.serviceData.discount +
          this.serviceData.patient_services[i].discount;
        const serviceType = $('#serviceTypeMain' + i).val();
        const serviceName = $('#serviceNameMain' + i).val();
        if (serviceType === 'Select type' || serviceType === null) {
          $('#serviceTypeMain' + i).css('border', '1px solid #dc3545');
          counter++;
        }
        // let typeObj = this.uniq_services.find(m => m.name === this.serviceData.patient_services[i].service_type);
        // if (typeObj.ref_doctor === true && this.serviceData.patient_services[i].doctor_name === '') {
        //   counter++;
        //   $('#referringDoctorMian' + i).css('border', '1px solid #dc3545');
        // }
        if (
          serviceName === 'Select service' ||
          serviceName === '-1' ||
          serviceName === null
        ) {
          $('#serviceNameMain' + i).css('border', '1px solid #dc3545');
          counter++;
        }
        if (this.currentControls.ipd_controls.is_requested_service === true) {
          if (!this.allowServiceZeroPrice) {
            if (this.serviceData.patient_services[i].service_price === 0) {
              toastr.error(
                'This Service is not configured correctly. Please contact IT Administrator',
              );
              return;
            }
          }
        }
      }
    }
    if (counter > 0) {
      return;
    }
    if (this.checkDuplicateServices === true && type === 'main') {
      for (let i = 0; i < this.serviceData.patient_services.length; i++) {
        for (let z = 0; z < this.patientServiceHistory.length; z++) {
          if (
            this.serviceData.patient_services[i].service_id ===
              this.patientServiceHistory[z].service?.id &&
            new Date(this.patientServiceHistory[z].created_at).getDate() ===
              new Date().getDate()
          ) {
            $('#serviceType' + i).css('border', '1px solid #dc3545');
            counter++;
          }
        }
      }
      if (counter > 0) {
        this.isChecked = false;
        $('#duplicateServicesAllertModal').modal('show');
        return;
      }
    }
    if (this.hasDuplicateIds(this.serviceData.patient_services)) {
      toastr.error('Duplicate services are selected');
      return;
    }
    this.checkDuplicateServices = true;
    this.serviceData.cash = this.servicesTotalBill;
    this.ngxLoader.start();
    this.patientService
      .addPatientServiceIPDV2(
        this.patient,
        this.serviceData,
        'unpaid',
        this.isopd,
        this.isReq,
      )
      .subscribe(
        (data) => {
          this.services = [];
          setTimeout(() => {
            $('.serviceSelectpicker').selectpicker();
          }, 10);
          this.isMainServicesUpdate = Math.random();
          this.isMainServicesRecieved.emit(
            this.isMainServicesUpdate.toString(),
          );
          this.fetchServicesHistory('');
          this.servicesTotalBill = 0;
          this.serviceData = {
            patient_id: '',
            mode_of_payment: 'Credit',
            discount: 0,
            receivable: 0,
            cash: 0,
            ledger: '',
            eprescription: true,
            patient_services: [
              {
                service_id: '',
                pricing_id: '',
                doctor_id: '',
                service_type: '',
                comments: '',
                department_id: '',
                billing_code: '',
                performing_doctor_id: '',
                referring_doctor_id: '',
                price: 0,
                service_dept: '',
                discount: 0,
                service_price: 0,
                quantity: 1,
                urgent: false,
              },
            ],
          };
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  addServicesDuplicateConfirm(): any {
    if (this.isChecked === false) {
      toastr.error('Check confirmation first');
      return;
    }
    this.checkDuplicateServices = false;
    this.addServices('main');
  }

  printRadiologyReport(id, patientMrn): any {
    window.open(
      '/radiology/pdf-report-v2?id=' +
        id +
        '&mrn=' +
        patientMrn.mrn +
        '&visit_id=' +
        patientMrn.visit_id +
        '&show_header=true' +
        '&show_footer=true',
    );
    // window.open(this.current_environment + '/v4/reports/print_report.pdf?auth_token=' + localStorage.getItem('auth_token') + '&medical_unit_id=' + localStorage.getItem('current_medical_unit_id') + '&id=' + id);
  }

  viewExternalReport(lab_order_detail_id): any {
    window.open(
      environment.rorUrl +
        '/v4/lab_order_details/report?auth_token=' +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id') +
        '&lab_order_detail_id=' +
        lab_order_detail_id +
        '&patient_id=' +
        this.patient.mrn,
    );
  }

  printService(id, LabOrderId, testCode): any {
    if (this.settingData?.enable_external_lis) {
      this.getLisIntegration(LabOrderId, testCode);
    } else {
      window.open(
        'lab/lab-report-pdf?id=' +
          this.patient.id +
          '&visit_id=' +
          this.patient.visit_id +
          '&mrn=' +
          this.patient.mrn +
          '&invoice_id=' +
          '&lab_id=' +
          id,
      );
    }
    // window.open(this.current_environment + '/v4/orders/lab_order_report.pdf?auth_token=' + localStorage.getItem('auth_token') + '&medical_unit_id=' + localStorage.getItem('current_medical_unit_id') + '&id=' + id);
  }
  getLisIntegration(LabOrderId, testCode): any {
    this.ngxLoader.start();
    this.patientService
      .getLisIntegration(this.settingData?.external_lis_base_url, LabOrderId)
      .subscribe(
        (data) => {
          if (data.length > 0) {
            const serviceObj = data.find((m) => m.testcode === testCode);
            if (serviceObj) {
              window.open(serviceObj?.url);
            } else {
              toastr.error('Report not finalized');
            }
          } else {
            toastr.error('Report not finalized');
          }
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error('Something Went Wrong');
        },
      );
  }

  openPrescriptionSlip(id): any {
    if (this.currentControls !== '') {
      if (this.currentControls.patient_services.is_prescription === false) {
        window.open(
          'pdf-reports/prescription-v2?id=' +
            this.patient.id +
            '&visit_id=' +
            this.patient.visit_id +
            '&mrn=' +
            this.patient.mrn,
        );
      } else {
        window.open(
          environment.rorUrl +
            '/v4/patients/' +
            this.patient.id +
            '/prescription_slip.pdf?visit_id=' +
            id +
            '&auth_token=' +
            localStorage.getItem('auth_token') +
            '&medical_unit_id=' +
            localStorage.getItem('current_medical_unit_id'),
        );
      }
    } else {
      window.open(
        environment.rorUrl +
          '/v4/patients/' +
          this.patient.id +
          '/prescription_slip.pdf?visit_id=' +
          id +
          '&auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
      );
    }
  }

  resetMainTabData(): any {
    this.progressNotes = '';
    this.progressNotesShift = 'Morning';
    this.progressNotesDoctor = '';
    this.diagnosisListData = [];
    this.mainDiagnosisListAdd = [];
    const diagnosisObj = {
      id: '',
      name: '',
      type: '',
    };
    this.mainDiagnosisListAdd.push(diagnosisObj);
    setTimeout(() => {
      $('.doctorNotes').selectpicker('refresh');
      $('.doctorNotes').selectpicker();
      $('.nursingPicker').selectpicker('refresh');
      $('.nursingPicker').selectpicker();

      $('#ipdPatientAllergiesMain').select2();
      // $('#ipdPatientAllergiesMain').val('');
      $('#ipdPatientAllergiesMain').select2().trigger('change');
    }, 500);
    this.resetServices();
    this.getPaitentDiagnosis('');
  }

  resetMainTabAfterDischarge(): any {
    this.paitestDiagnosisHistory = [];
    this.vitalsListData = [];
    this.patientServiceHistory = [];
    this.allAllergiesListData = [];
  }

  getPaitentDiagnosis(val): any {
    this.paitestDiagnosisHistoryData = [];
    this.paitestDiagnosisHistory = [];
    this.diagnosisSearch = '';
    this.differentialSearch = '';
    this.finalSearch = '';
    this.opdService
      .getPaitentDiagnosisFunv2(this.patient, this.patientAllHistory, '')
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          this.paitestDiagnosisHistoryData = data;
          this.paitestDiagnosisHistory = data.patient_diagnoses;
          $('#diagnosisFilter').val('patient_diagnosis');
        },
        (err) => {
          this.ngxLoader.stop();
        },
      );
  }

  resetServices(): any {
    setTimeout(() => {
      $('.serviceSelectpicker').selectpicker();
    }, 10);
    // this.services = [];
    this.services[0] = [];
    this.servicesTotalBill = 0;
    this.serviceData = {
      patient_id: '',
      mode_of_payment: 'Credit',
      discount: 0,
      receivable: 0,
      cash: 0,
      ledger: '',
      eprescription: true,
      patient_services: [
        {
          service_id: '',
          pricing_id: '',
          doctor_id: '',
          service_type: '',
          comments: '',
          department_id: '',
          billing_code: '',
          performing_doctor_id: '',
          referring_doctor_id: '',
          price: 0,
          service_dept: '',
          discount: 0,
          service_price: 0,
          quantity: 1,
          urgent: false,
        },
      ],
    };
    this.checkDuplicateServices = true;
  }

  getDiagnosisList(): any {
    this.diagnosisListData = [];
    this.diagnosisSearchResult = false;
    if (this.diagnosisSearch.length < 2) {
      return;
    }
    this.opdService.getDiagnosisListFunHistory(this.diagnosisSearch).subscribe(
      (data) => {
        this.diagnosisListData = data.data;
        this.diagnosisSearchResult = true;
      },
      (err) => {
        this.diagnosisListData = [];
        this.diagnosisSearchResult = true;
      },
    );
  }

  getAllDiagnosisListMain(i): any {
    this.allDiagnosisDataMain = [];
    this.diagnosisSearchResultMain = false;
    if (this.mainDiagnosisListAdd[i].name < 2) {
      return;
    }
    this.opdService
      .getDiagnosisListFunHistory(this.mainDiagnosisListAdd[i].name)
      .subscribe(
        (data) => {
          this.allDiagnosisDataMain = data.data;
          this.diagnosisSearchResultMain = true;
        },
        (err) => {
          this.allDiagnosisDataMain = [];
          this.diagnosisSearchResultMain = true;
        },
      );
  }

  setAllDiagnosisMain(value, i): any {
    this.mainDiagnosisListAdd[i].id = value.id;
    this.mainDiagnosisListAdd[i].name = value.name;
    $('.diagnosis-search-bar').hide();
  }

  addMoreDianosisMain(): any {
    const diagnosisObj = {
      id: '',
      name: '',
      type: '',
    };
    this.mainDiagnosisListAdd.push(diagnosisObj);
  }

  removeDianosisMain(i: number): any {
    this.mainDiagnosisListAdd.splice(i, 1);
    if (this.mainDiagnosisListAdd.length === 0) {
      this.addMoreDianosisMain();
    }
  }

  setAndAddDiagnosisMain(data): any {
    const diagnosisObj = {
      id: data.id,
      name: data.name,
      type: data.type,
    };
    this.mainDiagnosisListAdd.push(diagnosisObj);
    setTimeout(function () {
      $('.diagnosis-search-bar').hide();
    }, 10);
  }

  submitMainDiagnosis(): any {
    $('.diagnosisMain .form-control').css('border', '1px solid #e4e6ef');
    if (this.mainDiagnosisListAdd.length === 0) {
      toastr.error('Add Diagnosis');
      return;
    }
    for (let i = 0; i < this.mainDiagnosisListAdd.length; i++) {
      if (this.mainDiagnosisListAdd[i].name === '') {
        $('#diagnosisNameMain' + i).css('border', '1px solid #dc3545');
        return;
      }
      if (this.mainDiagnosisListAdd[i].type === '') {
        $('#diagnosisTypeMain' + i).css('border', '1px solid #dc3545');
        return;
      }
    }
    this.ngxLoader.start();
    this.opdService
      .addDiagnosisFunMainV2(this.mainDiagnosisListAdd, this.patient)
      .subscribe(
        (data) => {
          this.mainDiagnosisListAdd = [];
          const diagnosisObj = {
            id: '',
            name: '',
            type: '',
          };
          this.ngxLoader.stop();
          this.isMainDiagnosisUpdate = Math.random();
          this.isMainDiagnosisRecieved.emit(
            this.isMainDiagnosisUpdate.toString(),
          );
          this.mainDiagnosisListAdd.push(diagnosisObj);
          this.getPaitentDiagnosis('');
        },
        (err) => {
          this.ngxLoader.stop();
        },
      );
  }

  showNursingConfirmationModal(): any {
    if (
      this.progressNotesForm.value.vital_attributes.temperature === '' &&
      this.progressNotesForm.value.vital_attributes.bp_systolic === '' &&
      this.progressNotesForm.value.vital_attributes.bp_diastolic === '' &&
      this.progressNotesForm.value.vital_attributes.pulse === '' &&
      this.progressNotesForm.value.vital_attributes.resp_rate === '' &&
      this.progressNotesForm.value.vital_attributes.o2_saturation === '' &&
      this.progressNotesForm.value.vital_attributes.weight === ''
    ) {
      this.progressNotesForm.value.vital_attributes = '';
    }
    $('#nursingNotesModal').modal('show');
    this.empId = '';
    this.userPassword = '';
    this.isConfimedNursing = false;
  }

  confirmNursingNotes(): any {
    this.submitted = true;
    if (this.progressNotesForm.invalid) {
      return;
    }
    if (
      this.vitalsValidation.actionOnVitals(
        this.progressNotesForm.value.vital_attributes,
      ) === false
    ) {
      return;
    }

    if (!this.empId) {
      toastr.error('Add Employee Id or User Name');
      return;
    }
    if (!this.userPassword) {
      toastr.error('Add Password');
      return;
    }
    if (!this.isConfimedNursing) {
      toastr.error('Select confirmation');
      return;
    }
    this.ngxLoader.start();
    this.opdService
      .createNurseProgressNotesFunV2(
        this.progressNotesForm.value,
        this.patient,
        this.empId,
        this.userPassword,
        this.isConfimedNursing,
      )
      .subscribe(
        (data) => {
          if (data) {
            this.progressNotesForm.reset({
              shift: 'Morning',
              investigation_type: 'nursing',
            });
          }
          this.isNotesUpdate = Math.random();
          this.isNotesRecieved.emit(this.isNotesUpdate.toString());
          this.getProgressNotes('nursing', '');
          $('#nursingNotesModal').modal('hide');
          toastr.success('Notes add');
          this.progressNotesForm.value.vital_attributes = '';
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  getProgressNotes(status, val): any {
    $('.notesAll').prop('checked', false);
    this.notesPrintList = [];
    if (status === 'doctor') {
      this.progressNotesDoctor = '';
      setTimeout(() => {
        $('.doctorNotes').selectpicker('refresh');
        $('.doctorNotes').selectpicker();
      }, 500);
    }
    if (status === 'pharmacist') {
      this.pharmacistForm.get('doctor_id').setValue('');
      setTimeout(() => {
        $('.doctorNotes').selectpicker('refresh');
        $('.doctorNotes').selectpicker();
      }, 500);
    }
    if (status === 'nursing') {
      setTimeout(() => {
        $('.nursingPicker').selectpicker('refresh');
        $('.nursingPicker').selectpicker();
      }, 500);
    }
    this.progressNotes = '';
    // this.progressNotesDoctor = this.doctors[0].id;
    this.progressNotesShift = 'Morning';
    this.notesList = [];
    this.notesListNurse = [];
    this.notesListDoc = [];
    this.ngxLoader.start();
    this.opdService.getProgressNotesFunV2(this.patient, status, '').subscribe(
      (data) => {
        this.notesList = data.investigation_notes;
        if (status === 'doctor') {
          this.notesListDoc = data.investigation_notes;
          for (let i = 0; i < this.notesList.length; i++) {
            this.notesListDoc[i].update_doctor_note = this.notesListDoc[i].note
              ? this.notesListDoc[i].note.replace(/(\r\n|\n)/g, '<br/>')
              : '';
          }
        } else if (status === 'nursing') {
          this.notesListNurse = data.investigation_notes;
          for (let i = 0; i < this.notesList.length; i++) {
            this.notesListNurse[i].update_nursing_note = this.notesListNurse[i]
              .note
              ? this.notesListNurse[i].note.replace(/(\r\n|\n)/g, '<br/>')
              : '';
            this.notesListNurse[i].update_nursing_event = this.notesListNurse[i]
              .event
              ? this.notesListNurse[i].event.replace(/(\r\n|\n)/g, '<br/>')
              : '';
          }
        } else if (status === 'pharmacist') {
          this.notesListPharmacist = data.investigation_notes;
        }
        this.chRef.detectChanges();
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        // toastr.error(err.error.message);
      },
    );
  }
  showDoctorUpdateConfirmationModal(type): any {
    if (this.progressNotes === '') {
      toastr.error('Add Notes details');
      return;
    }
    if (this.vitalsValidation.actionOnVitals(this.progressNotes) === false) {
      return;
    }
    if (type === '') {
      if (this.progressNotesShift === '') {
        toastr.error('Select Doctor Shift');
        return;
      }
    }
    $('#doctorNotesModal').modal('show');
    this.doctorEmpId = '';
    this.doctorUserPassword = '';
    this.isConfirmedDoctor = false;
  }
  addDoctorProgressNotes(type): any {
    if (!this.doctorEmpId) {
      toastr.error('Add Employee Id or User Name');
      return;
    }
    if (!this.doctorUserPassword) {
      toastr.error('Add Password');
      return;
    }
    if (!this.isConfirmedDoctor) {
      toastr.error('Select confirmation');
      return;
    }
    let obj = {
      note: this.progressNotes,
      shift: this.progressNotesShift,
      doctor_id: this.progressNotesDoctor,
      investigation_type: 'doctor',
    };
    this.ngxLoader.start();
    this.opdService
      .addDoctorProgressNotesFunV2(
        obj,
        this.patient,
        this.doctorEmpId,
        this.doctorUserPassword,
      )
      .subscribe(
        (data) => {
          if (type !== 'main') {
            this.getProgressNotes('doctor', '');
          } else {
            this.progressNotes = '';
            this.progressNotesShift = 'Morning';
            this.progressNotesDoctor = '';
            // setTimeout(() => {
            //   $('.doctorNotes').selectpicker('refresh');
            //   $('.doctorNotes').selectpicker();
            // }, 500);
          }
          this.ngxLoader.stop();
          $('#doctorNotesModal').modal('hide');
          toastr.success('Notes added');
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  getAllAllergiesList(val): any {
    this.allAllergiesListData = [];
    this.opdService.getAllergiesListFunV2('').subscribe(
      (data) => {
        this.allAllergiesListData = data.allergy_types;
        console.log('this.allAllergiesListData', this.allAllergiesListData);
      },
      (err) => {},
    );
  }
}
