import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private router: Router,
  ) {}

  login(username, password): Observable<any> {
    return this.http.post(
      `${environment.rorUrl}/v4/sessions`,
      { username, password },
      {
        headers: new HttpHeaders({
          'content-type': 'application/json',
        }),
      },
    );
  }
  loginv2(username, password, medical_unit_id): Observable<any> {
    return this.http.post(
      environment.pythonUrl + `/api/new_session/`,
      { username, password, medical_unit_id },
      {
        headers: new HttpHeaders({
          'content-type': 'application/json',
        }),
      },
    );
  }

  userAuthenticate(id): Observable<any> {
    return this.http.get(
      `${environment.rorUrl}/v4/users/get_user_by_token?auth_token=` + id,
      {
        headers: new HttpHeaders({
          'content-type': 'application/json',
        }),
      },
    );
  }
  getMedicalUnits(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/add_medical_unit/all_medical_unit_listing/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  switchMedicalUnits(id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/api/add_medical_unit/update_medical_unit_of_user/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        { user_new_medical_unit_id: id },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  logOut(authtoken): any {
    return this.http.delete(
      `${environment.rorUrl}/v4/sessions?auth_token=` +
        authtoken +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id'),
      {
        headers: new HttpHeaders({
          'content-type': 'application/json',
        }),
      },
    );
  }
  logOutv2(authtoken): any {
    return this.http.post(
      `${environment.pythonUrl}/api/session_log_out/?auth_token=` +
        authtoken +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id'),
      {
        headers: new HttpHeaders({
          'content-type': 'application/json',
        }),
      },
    );
  }

  isAuthenticated(): boolean {
    const token = localStorage.getItem('auth_token');
    if (token) {
      return true;
    } else {
      return false;
    }
  }

  notAuthenticated(): any {
    localStorage.clear();
    this.router.navigate(['login']);
  }

  forgotPassword(email): Observable<any> {
    return this.http.post(
      `${environment.rorUrl}/v4/users/forgot_password`,
      { email },
      {
        headers: new HttpHeaders({
          'content-type': 'application/json',
        }),
      },
    );
  }
  forgotPasswordV2(email): Observable<any> {
    return this.http.post(
      `${environment.pythonUrl}/api/forgot_password/`,
      { email },
      {
        headers: new HttpHeaders({
          'content-type': 'application/json',
        }),
      },
    );
  }

  // loginPatient(mrn, password): Observable<any> {
  //   const results = this.http.get(`${environment.rorUrl}/v4/patients/verify_patient_by_contact_no?mrn=` + mrn + `&phone1=` + password
  //     , { headers: new HttpHeaders({
  //         AuthenticationToken: localStorage.getItem('auth_token'),
  //         Accept: 'application/json'
  //       })}).pipe(map(data => data));
  //   return results;
  // }

  loginPatient(mrn, password): Observable<any> {
    return this.http
      .get(
        `${environment.rorUrl}/v4/patients/verify_patient_by_contact_no?mrn=` +
          mrn +
          `&phone1=` +
          password,
        {
          headers: new HttpHeaders({
            // AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }

  loginPatientV2(mrn, password, passcode, subDomain): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/api/verify_patient_by_contact_no?mrn=` +
          mrn +
          `&phone1=` +
          password +
          `&passcode=` +
          passcode +
          `&sub_domain=` +
          subDomain,
        {
          headers: new HttpHeaders({
            // AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }

  sendEmailForForgotPassword(email): Observable<any> {
    let results = this.http
      .post(
        `${environment.rorUrl}/v4/users/forgot_password_email`,
        {
          email,
        },
        {
          headers: new HttpHeaders({
            Authorization: 'Bearer mytoken',
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  saveErrorLogs(data): Observable<any> {
    return this.http
      .post(
        `${environment.pythonUrl}/logs/logs/`,
        { data },
        { headers: new HttpHeaders({ Accept: 'application/json' }) },
      )
      .pipe(map((data) => data));
  }
  getErrorLogs(
    currentPage: any,
    perPage: any,
    invoiceItem: any,
  ): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/logs/logs?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          currentPage +
          '&per_page=' +
          perPage +
          '&from_date=' +
          invoiceItem.from_date +
          '&to_date=' +
          invoiceItem.to_date,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
