<!--begin::Content-->
<div
  class="content d-flex flex-column flex-column-fluid"
  id="kt_content"
  xmlns:width="http://www.w3.org/1999/xhtml"
>
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--begin::Page Heading-->
        <div class="d-flex align-items-baseline flex-wrap mr-5">
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">
            IPD History Report
          </h5>
          <!--          tab headings-->
          <!--end::Page Title-->
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->
      <!--begin::Toolbar-->
      <div class="d-flex align-items-center">
        <button class="btn btn-primary btn-sm" (click)="historyPrint()">
          Print
        </button>
        <!--        <button class="btn btn-primary btn-sm ml-2" >Back</button>-->
      </div>
      <!--end::Toolbar-->
    </div>
  </div>
  <!--end::Subheader-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <div class="container-fluid">
      <!--begin:: Content-->
      <div class="card pdf-card" id="reports">
        <div class="card-body">
          <!-- Patient information Head Card-->
          <div class="d-flex">
            <!--Patent information-->
            <div style="width: 85%">
              <!-- header table -->
              <table class="table">
                <tbody>
                  <tr class="borders">
                    <td style="width: 15%">
                      <img
                        [src]="settingData?.logo?.url"
                        style="width: auto; height: 106px"
                      />
                    </td>
                    <td style="width: 85%">
                      <div class="text-center">
                        <h2 style="font-size: 19px">
                          {{ settingData?.title }}
                        </h2>
                      </div>
                      <div class="font-size-h5 text-center">
                        <h5 style="font-size: 17px">
                          {{ settingData?.address }}
                        </h5>
                      </div>
                      <div class="text-center">
                        <h2 style="font-size: 17px">IPD History Report</h2>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- patient info table -->
              <table class="w-100 info_table mb-2">
                <tbody>
                  <tr>
                    <td style="width: 45%">
                      <h3
                        *ngIf="patient"
                        class="m-0 line-clamp font-print-bold mr-5"
                      >
                        {{ patient?.title | titlecase }}
                        {{
                          patient?.first_name + " " + patient?.last_name + ","
                            | titlecase
                        }}
                        {{ patient?.age + "," }}
                        {{ patient?.gender.charAt(0) | titlecase }}
                      </h3>
                    </td>
                    <td style="width: 55%">
                      <h3 class="m-0 font-print-bold">
                        MRN#{{ patient?.mrn }}
                      </h3>
                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- patient info detail table -->
              <table class="w-100 info_table">
                <tbody>
                  <tr>
                    <td style="width: 15%" class="paraFont">
                      <strong>Father/Husband:</strong>
                    </td>
                    <td style="width: 20%" class="paraFont">
                      {{ patient?.father_name || "--" | titlecase }}
                    </td>
                    <td style="width: 10%"></td>
                    <td style="width: 15%" class="paraFont">
                      <strong>Patient Type:</strong>
                    </td>
                    <td style="width: 20%" class="paraFont">
                      {{ patient?.patient_type?.name || "--" }}
                    </td>
                    <td style="width: 20%"></td>
                  </tr>
                  <tr>
                    <td style="width: 15%" class="paraFont">
                      <strong>Contact:</strong>
                    </td>
                    <td style="width: 20%" class="paraFont">
                      {{ patient?.phone1 || "--" }}
                    </td>
                    <td style="width: 10%"></td>
                    <td style="width: 15%" class="paraFont">
                      <strong>Requested On:</strong>
                    </td>
                    <td style="width: 20%" class="paraFont">
                      {{ patient?.created_at | date: "dd-MM-yyyy h:mm a" }}
                    </td>
                    <td style="width: 20%"></td>
                  </tr>
                  <tr>
                    <td style="width: 15%" class="paraFont">
                      <strong>CNIC:</strong>
                    </td>
                    <td style="width: 20%" class="paraFont">
                      {{ patient?.patient_nic || "N/A" }}
                    </td>
                    <td style="width: 10%"></td>
                    <td style="width: 15%" class="paraFont">
                      <strong>Consultant:</strong>
                    </td>
                    <td style="width: 20%" class="paraFont">
                      {{ patient?.consultant_name }}
                    </td>
                    <td style="width: 20%"></td>
                  </tr>
                  <tr>
                    <td style="width: 15%" class="paraFont">
                      <strong>Address:</strong>
                    </td>
                    <td style="width: 20%" class="paraFont">
                      {{ patient?.address1 || "N/A" }}
                    </td>
                    <td style="width: 10%"></td>
                    <!--                  <td *ngIf="patientInvoices?.admission?.department"  style="width: 15%" class="paraFont"><strong>Department:</strong></td>-->
                    <!--                  <td *ngIf="patientInvoices?.admission?.department"  style="width: 20%" class="paraFont">{{ patientInvoices?.admission?.department || 'N/A' }}</td>-->
                  </tr>

                  <tr *ngIf="patient?.admissions[0]?.id">
                    <td style="width: 15%" class="paraFont">
                      <strong>Admission #:</strong>
                    </td>
                    <td style="width: 20%" class="paraFont">
                      {{ patient?.admissions[0]?.id || "N/A" }}
                    </td>
                    <td style="width: 10%"></td>
                    <td style="width: 15%" class="paraFont">
                      <strong>Ward Name:</strong>
                    </td>
                    <td style="width: 30%" class="paraFont">
                      {{ patient?.admissions[0]?.ward?.title || "N/A" }}
                    </td>
                    <td style="width: 10%"></td>
                  </tr>
                  <tr>
                    <!--                <td style="width: 15%" class="paraFont"><strong>MLC:</strong></td>-->
                    <!--                <td style="width: 20%" class="paraFont">N/A</td>-->

                    <td style="width: 15%" class="paraFont">
                      <strong>Ref./Prv. MRN:</strong>
                    </td>
                    <td style="width: 20%" class="paraFont">
                      {{ patient?.old_mrn || "N/A" }}
                    </td>
                    <td style="width: 10%"></td>
                    <td style="width: 15%"></td>
                    <td style="width: 30%"></td>
                    <td style="width: 10%"></td>
                  </tr>
                  <!--                <tr>-->
                  <!--                  <td style="width: 15%" class="paraFont"><strong>Req. No.</strong></td>-->
                  <!--                  <td style="width: 20%" class="paraFont">{{patientInvoices?.request_no}}</td>-->
                  <!--                </tr>-->
                </tbody>
              </table>
            </div>
          </div>
          <hr class="my-6 line" />
          <!--Patient  information Head Card-->
          <!--          <p style="margin-bottom: 0" class="paraHeadingFont"><strong>Requisition Info</strong></p>-->
          <table class="w-100 info_table mb-3">
            <tbody>
              <tr>
                <td style="width: 15%" class="">Taken From:</td>
                <td style="width: 20%" class="">
                  {{ patientHistorySource?.taken_from }}
                </td>
                <td style="width: 10%"></td>
                <td style="width: 15%" class="">Name:</td>
                <td class="" style="width: 20%">
                  {{ patientHistorySource?.name || "--" }}
                </td>
                <td style="width: 20%"></td>
              </tr>
            </tbody>
            <p
              style="margin-bottom: 0; margin-top: 20px"
              class="paraHeadingFont"
              *ngIf="this.paitestDiagnosisHistory.length > 0"
            >
              <strong>Diagnosis</strong>
            </p>
          </table>
          <!--          Diagnosis-->
          <table class="w-100 info_table mb-3">
            <tbody>
              <tr>
                <td style="width: 15%" class="">
                  <button
                    type="button"
                    class="btn btn-light-primary btn-sm m-2"
                    *ngFor="let data of paitestDiagnosisHistory; let i = index"
                  >
                    {{ data?.name }}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <!--          General History start-->
          <p
            style="margin-bottom: 0; margin-top: 20px"
            class="paraHeadingFont"
            *ngIf="
              patientComplaint?.presenting_complaints ||
              patientComplaint?.allergies ||
              patientComplaint?.married_for ||
              patientComplaint?.edd ||
              patientComplaint?.lmp ||
              patientComplaint?.gestational_age ||
              patientComplaint?.other
            "
          >
            <strong>General History</strong>
          </p>
          <div class="row">
            <div
              class="col-xl-12 col-lg-12 col-md-12 col-sm-12"
              *ngIf="patientComplaint?.presenting_complaints"
            >
              <div>
                Presenting Complains:
                <span>{{ patientComplaint?.presenting_complaints }}</span>
              </div>
            </div>
            <div
              class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-2 mb-2"
              *ngIf="patientComplaint?.allergies"
            >
              <div>
                Allergies: <span>{{ patientComplaint?.allergies }}</span>
              </div>
            </div>
          </div>
          <table class="w-100 info_table mb-4">
            <tbody>
              <!--            <tr>-->
              <!--              <td style="width: 15%" class="">Presenting Complains:</td>-->
              <!--              <td style="width: 20%" class="">{{patientComplaint?.presenting_complaints}}</td>-->
              <!--&lt;!&ndash;              <td style="width: 10%"></td>&ndash;&gt;-->
              <!--              <td style="width: 15%" class="">Allergies:</td>-->
              <!--              <td class="" style="width: 20%">-->
              <!--                <p>{{patientComplaint?.allergies}}</p>-->
              <!--              </td>-->
              <!--              <td style="width: 15%"></td>-->
              <!--              <td style="width: 20%"></td>-->
              <!--            </tr>-->
              <tr>
                <td
                  style="width: 15%"
                  class=""
                  *ngIf="patientComplaint?.married_for"
                >
                  Married For:
                </td>
                <td
                  style="width: 20%"
                  class=""
                  *ngIf="patientComplaint?.married_for"
                >
                  {{ patientComplaint?.married_for }}
                </td>
                <td style="width: 15%" class="" *ngIf="patientComplaint?.edd">
                  EDD:
                </td>
                <td style="width: 20%" class="" *ngIf="patientComplaint?.edd">
                  {{ patientComplaint?.edd }}
                </td>
                <td style="width: 15%" class="" *ngIf="patientComplaint?.lmp">
                  LMP:
                </td>
                <td style="width: 20%" class="" *ngIf="patientComplaint?.lmp">
                  {{ patientComplaint?.lmp }}
                </td>
              </tr>
              <tr>
                <td
                  style="width: 15%"
                  class=""
                  *ngIf="patientComplaint?.gestational_age"
                >
                  Gestrational Age:
                </td>
                <td
                  style="width: 20%"
                  class=""
                  *ngIf="patientComplaint?.gestational_age"
                >
                  {{ patientComplaint?.gestational_age }}
                </td>
                <!--              <td style="width: 10%"></td>-->
                <td style="width: 15%" class=""></td>
                <td style="width: 30%" class=""></td>
                <td style="width: 15%"></td>
                <td style="width: 20%"></td>
              </tr>
            </tbody>
          </table>
          <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <div *ngIf="patientComplaint?.other">
                Other: <span>{{ patientComplaint?.other }}</span>
              </div>
            </div>
          </div>
          <!--          Gynacological History start-->
          <p
            style="margin-bottom: 0; margin-top: 20px"
            class="paraFont"
            *ngIf="
              patientGeneralHistory?.patient_gynaecological_histories_attributes
                ?.age_of_minarche ||
              patientGeneralHistory?.patient_gynaecological_histories_attributes
                ?.menstrual_cycle ||
              patientGeneralHistory?.patient_gynaecological_histories_attributes
                ?.length ||
              patientGeneralHistory?.patient_gynaecological_histories_attributes
                ?.imb ||
              patientGeneralHistory?.patient_gynaecological_histories_attributes
                ?.contraception ||
              patientGeneralHistory?.patient_gynaecological_histories_attributes
                ?.pop_smear ||
              patientGeneralHistory?.patient_gynaecological_histories_attributes
                ?.dysmenorrhea ||
              patientGeneralHistory?.patient_gynaecological_histories_attributes
                ?.dyspareunia ||
              patientGeneralHistory?.patient_gynaecological_histories_attributes
                ?.other
            "
          >
            <strong> Gynacological History</strong>
          </p>
          <table class="w-100 info_table mb-4">
            <tbody>
              <tr>
                <td
                  style="width: 15%"
                  class=""
                  *ngIf="
                    patientGeneralHistory
                      ?.patient_gynaecological_histories_attributes
                      ?.age_of_minarche
                  "
                >
                  Age of Minarche:
                </td>
                <td
                  style="width: 20%"
                  class=""
                  *ngIf="
                    patientGeneralHistory
                      ?.patient_gynaecological_histories_attributes
                      ?.age_of_minarche
                  "
                >
                  {{
                    patientGeneralHistory
                      ?.patient_gynaecological_histories_attributes
                      ?.age_of_minarche
                  }}
                </td>
                <td
                  style="width: 15%"
                  class=""
                  *ngIf="
                    patientGeneralHistory
                      ?.patient_gynaecological_histories_attributes
                      ?.menstrual_cycle
                  "
                >
                  Menstrual Cycle:
                </td>
                <td
                  style="width: 20%"
                  class=""
                  *ngIf="
                    patientGeneralHistory
                      ?.patient_gynaecological_histories_attributes
                      ?.menstrual_cycle
                  "
                >
                  {{
                    patientGeneralHistory
                      ?.patient_gynaecological_histories_attributes
                      ?.menstrual_cycle
                  }}
                </td>
                <td
                  style="width: 15%"
                  class=""
                  *ngIf="
                    patientGeneralHistory
                      ?.patient_gynaecological_histories_attributes?.length
                  "
                >
                  Lenght:
                </td>
                <td
                  style="width: 20%"
                  class=""
                  *ngIf="
                    patientGeneralHistory
                      ?.patient_gynaecological_histories_attributes?.length
                  "
                >
                  {{
                    patientGeneralHistory
                      ?.patient_gynaecological_histories_attributes?.length
                  }}
                </td>
              </tr>
              <tr>
                <td
                  style="width: 15%"
                  class=""
                  *ngIf="
                    patientGeneralHistory
                      ?.patient_gynaecological_histories_attributes?.imb
                  "
                >
                  IMB:
                </td>
                <td
                  style="width: 20%"
                  class=""
                  *ngIf="
                    patientGeneralHistory
                      ?.patient_gynaecological_histories_attributes?.imb
                  "
                >
                  {{
                    patientGeneralHistory
                      ?.patient_gynaecological_histories_attributes?.imb
                  }}
                </td>
                <td
                  style="width: 15%"
                  class=""
                  *ngIf="
                    patientGeneralHistory
                      ?.patient_gynaecological_histories_attributes
                      ?.contraception
                  "
                >
                  Contraception:
                </td>
                <td
                  style="width: 20%"
                  class=""
                  *ngIf="
                    patientGeneralHistory
                      ?.patient_gynaecological_histories_attributes
                      ?.contraception
                  "
                >
                  {{
                    patientGeneralHistory
                      ?.patient_gynaecological_histories_attributes
                      ?.contraception
                  }}
                </td>
                <td
                  style="width: 15%"
                  class=""
                  *ngIf="
                    patientGeneralHistory
                      ?.patient_gynaecological_histories_attributes?.pop_smear
                  "
                >
                  Pap Smear:
                </td>
                <td
                  style="width: 20%"
                  class=""
                  *ngIf="
                    patientGeneralHistory
                      ?.patient_gynaecological_histories_attributes?.pop_smear
                  "
                >
                  {{
                    patientGeneralHistory
                      ?.patient_gynaecological_histories_attributes?.pop_smear
                  }}
                </td>
              </tr>
              <tr>
                <td
                  style="width: 15%"
                  class=""
                  *ngIf="
                    patientGeneralHistory
                      ?.patient_gynaecological_histories_attributes
                      ?.dysmenorrhea
                  "
                >
                  Dysmenorrhoea:
                </td>
                <td
                  style="width: 20%"
                  class=""
                  *ngIf="
                    patientGeneralHistory
                      ?.patient_gynaecological_histories_attributes
                      ?.dysmenorrhea
                  "
                >
                  {{
                    patientGeneralHistory
                      ?.patient_gynaecological_histories_attributes
                      ?.dysmenorrhea
                  }}
                </td>
                <td
                  style="width: 15%"
                  class=""
                  *ngIf="
                    patientGeneralHistory
                      ?.patient_gynaecological_histories_attributes?.dyspareunia
                  "
                >
                  Dyspyerunea:
                </td>
                <td
                  style="width: 20%"
                  class=""
                  *ngIf="
                    patientGeneralHistory
                      ?.patient_gynaecological_histories_attributes?.dyspareunia
                  "
                >
                  {{
                    patientGeneralHistory
                      ?.patient_gynaecological_histories_attributes?.dyspareunia
                  }}
                </td>
                <td style="width: 15%" class=""></td>
                <td style="width: 20%" class=""></td>
              </tr>
            </tbody>
          </table>
          <div class="row">
            <div
              class="col-xl-12 col-lg-12 col md-12 col-sm-12"
              *ngIf="
                patientGeneralHistory
                  ?.patient_gynaecological_histories_attributes?.other
              "
            >
              <div>
                Other:
                <span>{{
                  patientGeneralHistory
                    ?.patient_gynaecological_histories_attributes?.other
                }}</span>
              </div>
            </div>
          </div>
          <!-- Gynacological History end-->
          <!--          Obstractical History start-->
          <p
            style="margin-bottom: 0; margin-top: 20px"
            class="paraFont"
            *ngIf="
              patientGeneralHistory?.patient_obstetrical_histories_attributes
                ?.g ||
              patientGeneralHistory?.patient_obstetrical_histories_attributes
                ?.p ||
              patientGeneralHistory?.patient_obstetrical_histories_attributes
                ?.a ||
              patientGeneralHistory?.patient_obstetrical_histories_attributes
                ?.last_child_born ||
              patientGeneralHistory?.patient_obstetrical_histories_attributes
                ?.mode_of_delivery ||
              patientGeneralHistory?.patient_obstetrical_histories_attributes
                ?.other
            "
          >
            <strong> Obstractical History</strong>
          </p>
          <table class="w-100 info_table mb-4">
            <tbody>
              <tr>
                <td
                  style="width: 5%"
                  class=""
                  *ngIf="
                    patientGeneralHistory
                      ?.patient_obstetrical_histories_attributes?.g
                  "
                >
                  G:
                </td>
                <td
                  style="width: 5%"
                  class=""
                  class=""
                  *ngIf="
                    patientGeneralHistory
                      ?.patient_obstetrical_histories_attributes?.g
                  "
                >
                  {{
                    patientGeneralHistory
                      ?.patient_obstetrical_histories_attributes?.g
                  }}
                </td>
                <td
                  style="width: 5%"
                  class=""
                  class=""
                  *ngIf="
                    patientGeneralHistory
                      ?.patient_obstetrical_histories_attributes?.p
                  "
                >
                  P:
                </td>
                <td
                  style="width: 5%"
                  class=""
                  class=""
                  *ngIf="
                    patientGeneralHistory
                      ?.patient_obstetrical_histories_attributes?.p
                  "
                >
                  {{
                    patientGeneralHistory
                      ?.patient_obstetrical_histories_attributes?.p
                  }}
                </td>
                <td style="width: 5%"></td>
                <td
                  style="width: 5%"
                  class=""
                  class=""
                  *ngIf="
                    patientGeneralHistory
                      ?.patient_obstetrical_histories_attributes?.a
                  "
                >
                  A:
                </td>
                <td
                  style="width: 5%"
                  class=""
                  class=""
                  *ngIf="
                    patientGeneralHistory
                      ?.patient_obstetrical_histories_attributes?.a
                  "
                >
                  {{
                    patientGeneralHistory
                      ?.patient_obstetrical_histories_attributes?.a
                  }}
                </td>
                <td
                  style="width: 15%"
                  class=""
                  class=""
                  *ngIf="
                    patientGeneralHistory
                      ?.patient_obstetrical_histories_attributes
                      ?.last_child_born
                  "
                >
                  Last Child Born:
                </td>
                <td
                  style="width: 20%"
                  class=""
                  class=""
                  *ngIf="
                    patientGeneralHistory
                      ?.patient_obstetrical_histories_attributes
                      ?.last_child_born
                  "
                >
                  {{
                    patientGeneralHistory
                      ?.patient_obstetrical_histories_attributes
                      ?.last_child_born
                  }}
                </td>
                <td style="width: 30%"></td>
              </tr>
            </tbody>
          </table>
          <table class="w-100 info_table mb-4">
            <tbody>
              <tr>
                <td
                  style="width: 15%"
                  class=""
                  *ngIf="
                    patientGeneralHistory
                      ?.patient_obstetrical_histories_attributes
                      ?.mode_of_delivery
                  "
                >
                  Mode of Delivery:
                </td>
                <td
                  style="width: 20%"
                  class=""
                  *ngIf="
                    patientGeneralHistory
                      ?.patient_obstetrical_histories_attributes
                      ?.mode_of_delivery
                  "
                >
                  {{
                    patientGeneralHistory
                      ?.patient_obstetrical_histories_attributes
                      ?.mode_of_delivery
                  }}
                </td>
                <!--                            <td style="width: 5%"></td>-->
                <td
                  style="width: 15%"
                  class=""
                  *ngIf="
                    patientGeneralHistory
                      ?.patient_obstetrical_histories_attributes?.other
                  "
                >
                  Other:
                </td>
                <td
                  style="width: 20%"
                  class=""
                  *ngIf="
                    patientGeneralHistory
                      ?.patient_obstetrical_histories_attributes?.other
                  "
                >
                  {{
                    patientGeneralHistory
                      ?.patient_obstetrical_histories_attributes?.other
                  }}
                </td>
                <td style="width: 30%"></td>
                <td style="width: 20%"></td>
              </tr>
            </tbody>
          </table>
          <!--          Obstractical History end-->
          <!--          Past History start-->
          <p
            style="margin-bottom: 0; margin-top: 20px"
            class="paraFont"
            *ngIf="
              patientGeneralHistory?.patient_past_history_attributes
                ?.diabetes ||
              patientGeneralHistory?.patient_past_history_attributes
                ?.diabetes_details ||
              patientGeneralHistory?.patient_past_history_attributes
                ?.diabetes_duration ||
              patientGeneralHistory?.patient_past_history_attributes?.IHD ||
              patientGeneralHistory?.patient_past_history_attributes
                ?.IHD_details ||
              patientGeneralHistory?.patient_past_history_attributes
                ?.IHD_duration ||
              patientGeneralHistory?.patient_past_history_attributes
                ?.hypertention ||
              patientGeneralHistory?.patient_past_history_attributes
                ?.hypertention_details ||
              patientGeneralHistory?.patient_past_history_attributes
                ?.hypertention_duration ||
              patientGeneralHistory?.patient_past_history_attributes
                ?.operation ||
              patientGeneralHistory?.patient_past_history_attributes
                ?.operation_details ||
              patientGeneralHistory?.patient_past_history_attributes
                ?.major_illness ||
              patientGeneralHistory?.patient_past_history_attributes
                ?.major_illness_details ||
              patientGeneralHistory?.patient_past_history_attributes?.other ||
              patientGeneralHistory?.patient_past_history_attributes
                ?.other_details
            "
          >
            <strong> Past History</strong>
          </p>
          <div class="row">
            <div
              class="col-xl-4 col-lg-4 col-md-4 col-sm-6"
              *ngIf="
                patientGeneralHistory?.patient_past_history_attributes?.diabetes
              "
            >
              <div>
                Diabeties:
                <span
                  *ngIf="
                    patientGeneralHistory?.patient_past_history_attributes
                      ?.diabetes === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    patientGeneralHistory?.patient_past_history_attributes
                      ?.diabetes === false
                  "
                  >No</span
                >
              </div>
            </div>
            <div
              class="col-xl-4 col-lg-4 col-md-4 col-sm-6"
              *ngIf="
                patientGeneralHistory?.patient_past_history_attributes
                  ?.diabetes_details
              "
            >
              <div>
                Detail:
                <span>{{
                  patientGeneralHistory?.patient_past_history_attributes
                    ?.diabetes_details
                }}</span>
              </div>
            </div>
            <div
              class="col-xl-4 col-lg-4 col-md-4 col-sm-6"
              *ngIf="
                patientGeneralHistory?.patient_past_history_attributes
                  ?.diabetes_duration
              "
            >
              <div>
                Duration:
                <span>{{
                  patientGeneralHistory?.patient_past_history_attributes
                    ?.diabetes_duration
                }}</span>
              </div>
            </div>
            <div
              class="col-xl-4 col-lg-4 col-md-4 col-sm-6"
              *ngIf="
                patientGeneralHistory?.patient_past_history_attributes?.IHD
              "
            >
              <div>
                IHD:
                <span
                  *ngIf="
                    patientGeneralHistory?.patient_past_history_attributes
                      ?.IHD === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    patientGeneralHistory?.patient_past_history_attributes
                      ?.IHD === false
                  "
                  >No</span
                >
              </div>
            </div>
            <div
              class="col-xl-4 col-lg-4 col-md-4 col-sm-6"
              *ngIf="
                patientGeneralHistory?.patient_past_history_attributes
                  ?.IHD_details
              "
            >
              <div>
                Detail:
                <span>{{
                  patientGeneralHistory?.patient_past_history_attributes
                    ?.IHD_details
                }}</span>
              </div>
            </div>
            <div
              class="col-xl-4 col-lg-4 col-md-4 col-sm-6"
              *ngIf="
                patientGeneralHistory?.patient_past_history_attributes
                  ?.IHD_duration
              "
            >
              <div>
                Duration:
                <span>{{
                  patientGeneralHistory?.patient_past_history_attributes
                    ?.IHD_duration
                }}</span>
              </div>
            </div>
            <div
              class="col-xl-4 col-lg-4 col-md-4 col-sm-6"
              *ngIf="
                patientGeneralHistory?.patient_past_history_attributes
                  ?.hypertention
              "
            >
              <div>
                Hypertention:
                <span
                  *ngIf="
                    patientGeneralHistory?.patient_past_history_attributes
                      ?.hypertention === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    patientGeneralHistory?.patient_past_history_attributes
                      ?.hypertention === false
                  "
                  >No</span
                >
              </div>
            </div>
            <div
              class="col-xl-4 col-lg-4 col-md-4 col-sm-6"
              *ngIf="
                patientGeneralHistory?.patient_past_history_attributes
                  ?.hypertention_details
              "
            >
              <div>
                Detail:
                <span>{{
                  patientGeneralHistory?.patient_past_history_attributes
                    ?.hypertention_details
                }}</span>
              </div>
            </div>
            <div
              class="col-xl-4 col-lg-4 col-md-4 col-sm-6"
              *ngIf="
                patientGeneralHistory?.patient_past_history_attributes
                  ?.hypertention_duration
              "
            >
              <div>
                Duration:
                <span>{{
                  patientGeneralHistory?.patient_past_history_attributes
                    ?.hypertention_duration
                }}</span>
              </div>
            </div>
            <div
              class="col-xl-4 col-lg-4 col-md-4 col-sm-6"
              *ngIf="
                patientGeneralHistory?.patient_past_history_attributes
                  ?.operation
              "
            >
              <div>
                Operation:
                <span
                  *ngIf="
                    patientGeneralHistory?.patient_past_history_attributes
                      ?.operation === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    patientGeneralHistory?.patient_past_history_attributes
                      ?.operation === false
                  "
                  >No</span
                >
              </div>
            </div>
            <div
              class="col-xl-8 col-lg-8 col-md-6 col-sm-6"
              *ngIf="
                patientGeneralHistory?.patient_past_history_attributes
                  ?.operation_details
              "
            >
              <div>
                Detail:
                <span>{{
                  patientGeneralHistory?.patient_past_history_attributes
                    ?.operation_details
                }}</span>
              </div>
            </div>
            <div
              class="col-xl-4 col-lg-4 col-md-4 col-sm-6"
              *ngIf="
                patientGeneralHistory?.patient_past_history_attributes
                  ?.major_illness
              "
            >
              <div>
                Major illness:
                <span
                  *ngIf="
                    patientGeneralHistory?.patient_past_history_attributes
                      ?.major_illness === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    patientGeneralHistory?.patient_past_history_attributes
                      ?.major_illness === false
                  "
                  >No</span
                >
              </div>
            </div>
            <div
              class="col-xl-8 col-lg-8 col-md-6 col-sm-6"
              *ngIf="
                patientGeneralHistory?.patient_past_history_attributes
                  ?.major_illness_details
              "
            >
              <div>
                Detail:
                <span>{{
                  patientGeneralHistory?.patient_past_history_attributes
                    ?.major_illness_details
                }}</span>
              </div>
            </div>
            <div
              class="col-xl-4 col-lg-4 col-md-4 col-sm-6"
              *ngIf="
                patientGeneralHistory?.patient_past_history_attributes?.other
              "
            >
              <div>
                Other:
                <span
                  *ngIf="
                    patientGeneralHistory?.patient_past_history_attributes
                      ?.other === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    patientGeneralHistory?.patient_past_history_attributes
                      ?.other === false
                  "
                  >No</span
                >
              </div>
            </div>
            <div
              class="col-xl-4 col-lg-4 col-md-4 col-sm-6"
              *ngIf="
                patientGeneralHistory?.patient_past_history_attributes
                  ?.other_details
              "
            >
              <div>
                Detail:
                <span>{{
                  patientGeneralHistory?.patient_past_history_attributes
                    ?.other_details
                }}</span>
              </div>
            </div>
          </div>
          <!--          Past History end-->
          <!--          Social History start-->
          <p
            style="margin-bottom: 0; margin-top: 20px"
            class="paraFont"
            *ngIf="
              patientGeneralHistory?.patient_social_history_attributes
                ?.alcohol ||
              patientGeneralHistory?.patient_social_history_attributes
                ?.smoker ||
              patientGeneralHistory?.patient_social_history_attributes?.other ||
              patientGeneralHistory?.patient_social_history_attributes
                ?.other_details
            "
          >
            <strong> Social History</strong>
          </p>
          <div class="row">
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-sm-6"
              *ngIf="
                patientGeneralHistory?.patient_social_history_attributes
                  ?.alcohol
              "
            >
              <div>
                Alcohol:
                <span
                  *ngIf="
                    patientGeneralHistory?.patient_social_history_attributes
                      ?.alcohol === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    patientGeneralHistory?.patient_social_history_attributes
                      ?.alcohol === false
                  "
                  >No</span
                >
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-sm-6"
              *ngIf="
                patientGeneralHistory?.patient_social_history_attributes?.smoker
              "
            >
              <div>
                Smoker :
                <span
                  *ngIf="
                    patientGeneralHistory?.patient_social_history_attributes
                      ?.smoker === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    patientGeneralHistory?.patient_social_history_attributes
                      ?.smoker === false
                  "
                  >No</span
                >
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-sm-6"
              *ngIf="
                patientGeneralHistory?.patient_social_history_attributes?.other
              "
            >
              <div>
                Other:
                <span
                  *ngIf="
                    patientGeneralHistory?.patient_social_history_attributes
                      ?.other === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    patientGeneralHistory?.patient_social_history_attributes
                      ?.other === false
                  "
                  >No</span
                >
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-sm-6"
              *ngIf="
                patientGeneralHistory?.patient_social_history_attributes
                  ?.other_details
              "
            >
              <div>
                Detail:
                <span>{{
                  patientGeneralHistory?.patient_social_history_attributes
                    ?.other_details
                }}</span>
              </div>
            </div>
          </div>
          <!--          Social History end-->
          <!--          Family History start-->
          <p
            style="margin-bottom: 0; margin-top: 20px"
            class="paraFont"
            *ngIf="
              patientGeneralHistory?.patient_family_history_attributes?.DM ||
              patientGeneralHistory?.patient_family_history_attributes
                ?.hypertention ||
              patientGeneralHistory?.patient_family_history_attributes
                ?.arthritis ||
              patientGeneralHistory?.patient_family_history_attributes?.IH ||
              patientGeneralHistory?.patient_family_history_attributes
                ?.malignancy ||
              patientGeneralHistory?.patient_family_history_attributes?.other
            "
          >
            <strong> Family History</strong>
          </p>
          <div class="row">
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-sm-6"
              *ngIf="
                patientGeneralHistory?.patient_family_history_attributes?.DM
              "
            >
              <div>
                DM:
                <span
                  *ngIf="
                    patientGeneralHistory?.patient_family_history_attributes
                      ?.DM === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    patientGeneralHistory?.patient_family_history_attributes
                      ?.DM === false
                  "
                  >No</span
                >
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-sm-6"
              *ngIf="
                patientGeneralHistory?.patient_family_history_attributes
                  ?.hypertention
              "
            >
              <div>
                Hypertention:
                <span
                  *ngIf="
                    patientGeneralHistory?.patient_family_history_attributes
                      ?.hypertention === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    patientGeneralHistory?.patient_family_history_attributes
                      ?.hypertention === false
                  "
                  >No</span
                >
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-sm-6"
              *ngIf="
                patientGeneralHistory?.patient_family_history_attributes
                  ?.arthritis
              "
            >
              <div>
                Arthritis:
                <span
                  *ngIf="
                    patientGeneralHistory?.patient_family_history_attributes
                      ?.arthritis === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    patientGeneralHistory?.patient_family_history_attributes
                      ?.arthritis === false
                  "
                  >No</span
                >
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-sm-6"
              *ngIf="
                patientGeneralHistory?.patient_family_history_attributes?.IH
              "
            >
              <div>
                IHD:
                <span
                  *ngIf="
                    patientGeneralHistory?.patient_family_history_attributes
                      ?.IHD === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    patientGeneralHistory?.patient_family_history_attributes
                      ?.IHD === false
                  "
                  >No</span
                >
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-sm-6"
              *ngIf="
                patientGeneralHistory?.patient_family_history_attributes
                  ?.malignancy
              "
            >
              <div>
                Malignancy:
                <span
                  *ngIf="
                    patientGeneralHistory?.patient_family_history_attributes
                      ?.malignancy === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    patientGeneralHistory?.patient_family_history_attributes
                      ?.malignancy === false
                  "
                  >No</span
                >
              </div>
            </div>
            <div
              class="col-xl-9 col-lg-9 col-md-6 col-sm-6"
              *ngIf="
                patientGeneralHistory?.patient_family_history_attributes?.other
              "
            >
              <div>
                Other:
                <span>{{
                  patientGeneralHistory?.patient_family_history_attributes
                    ?.other
                }}</span>
              </div>
            </div>
          </div>
          <!--Family History end-->
          <!--          Birth History start-->
          <p
            style="margin-bottom: 0; margin-top: 20px"
            class="paraFont"
            *ngIf="
              patientGeneralHistory?.patient_birth_histories_attributes?.details
            "
          >
            <strong> Birth History</strong>
          </p>
          <div class="row">
            <div
              class="col-xl-9 col-lg-9 col-md-6 col-sm-6"
              *ngIf="
                patientGeneralHistory?.patient_birth_histories_attributes
                  ?.details
              "
            >
              <div>
                Birth History:
                <span>{{
                  patientGeneralHistory?.patient_birth_histories_attributes
                    ?.details
                }}</span>
              </div>
            </div>
          </div>
          <!--          Birth History end-->
          <!-- General History end-->
          <!--          Review Of System start-->
          <p
            style="margin-bottom: 0; margin-top: 20px"
            class="paraHeadingFont"
            *ngIf="
              reviewSystemHistory?.patient_general_health_history_attributes
                ?.weight_loss ||
              reviewSystemHistory?.patient_general_health_history_attributes
                ?.fatigue_tiredness ||
              patientGeneralHistory?.patient_family_history_attributes
                ?.night_sweats ||
              reviewSystemHistory?.patient_general_health_history_attributes
                ?.fever_chills ||
              reviewSystemHistory?.patient_general_health_history_attributes
                ?.other ||
              reviewSystemHistory?.patient_general_health_history_attributes
                ?.other_details ||
              reviewSystemHistory?.patient_ent_history_attributes?.glaucoma ||
              reviewSystemHistory?.patient_ent_history_attributes
                ?.visual_difdiculty ||
              reviewSystemHistory?.patient_ent_history_attributes
                ?.hearing_problem ||
              reviewSystemHistory?.patient_ent_history_attributes
                ?.recurrent_sore_throat ||
              reviewSystemHistory?.patient_ent_history_attributes
                ?.swollen_glands ||
              reviewSystemHistory?.patient_cardiology_history_attributes
                ?.heart_problem ||
              reviewSystemHistory?.patient_cardiology_history_attributes
                ?.high_blood_pressure ||
              reviewSystemHistory?.patient_cardiology_history_attributes
                ?.stroke ||
              reviewSystemHistory?.patient_cardiology_history_attributes?.PCI ||
              reviewSystemHistory?.patient_cardiology_history_attributes
                ?.other ||
              reviewSystemHistory?.patient_cardiology_history_attributes
                ?.other ||
              reviewSystemHistory?.patient_respiratory_history_attributes
                ?.shirtness_of_breath ||
              reviewSystemHistory?.patient_respiratory_history_attributes
                ?.asthma ||
              reviewSystemHistory?.patient_respiratory_history_attributes
                ?.chronic_cought ||
              reviewSystemHistory?.patient_respiratory_history_attributes
                ?.lung_disease ||
              reviewSystemHistory?.patient_respiratory_history_attributes
                ?.heamoptysis ||
              reviewSystemHistory?.patient_neuro_history_attributes?.seizures ||
              reviewSystemHistory?.patient_neuro_history_attributes?.headache ||
              reviewSystemHistory?.patient_neuro_history_attributes?.vertigo ||
              reviewSystemHistory?.patient_skin_history_attributes?.rash ||
              reviewSystemHistory?.patient_skin_history_attributes?.pigmentation
            "
          >
            <strong>Review Of System</strong>
          </p>
          <!--          General Health start-->
          <p
            style="margin-bottom: 0; margin-top: 20px"
            class="paraFont"
            *ngIf="
              reviewSystemHistory?.patient_general_health_history_attributes
                ?.weight_loss ||
              reviewSystemHistory?.patient_general_health_history_attributes
                ?.fatigue_tiredness ||
              patientGeneralHistory?.patient_family_history_attributes
                ?.night_sweats ||
              reviewSystemHistory?.patient_general_health_history_attributes
                ?.fever_chills ||
              reviewSystemHistory?.patient_general_health_history_attributes
                ?.other ||
              reviewSystemHistory?.patient_general_health_history_attributes
                ?.other_details
            "
          >
            <strong>General Health</strong>
          </p>
          <div class="row">
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-sm-6"
              *ngIf="
                reviewSystemHistory?.patient_general_health_history_attributes
                  ?.weight_loss
              "
            >
              <div>
                Weight loss:
                <span
                  *ngIf="
                    reviewSystemHistory
                      ?.patient_general_health_history_attributes
                      ?.weight_loss === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    reviewSystemHistory
                      ?.patient_general_health_history_attributes
                      ?.weight_loss === false
                  "
                  >No</span
                >
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-sm-6"
              *ngIf="
                reviewSystemHistory?.patient_general_health_history_attributes
                  ?.fatigue_tiredness
              "
            >
              <div>
                Fatigue tiredness:
                <span
                  *ngIf="
                    reviewSystemHistory
                      ?.patient_general_health_history_attributes
                      ?.fatigue_tiredness === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    reviewSystemHistory
                      ?.patient_general_health_history_attributes
                      ?.fatigue_tiredness === false
                  "
                  >No</span
                >
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-sm-6"
              *ngIf="
                patientGeneralHistory?.patient_family_history_attributes
                  ?.night_sweats
              "
            >
              <div>
                Night Sweats:
                <span
                  *ngIf="
                    patientGeneralHistory?.patient_family_history_attributes
                      ?.night_sweats === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    patientGeneralHistory?.patient_family_history_attributes
                      ?.night_sweats === false
                  "
                  >No</span
                >
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-sm-6"
              *ngIf="
                reviewSystemHistory?.patient_general_health_history_attributes
                  ?.fever_chills
              "
            >
              <div>
                Fever Chills:
                <span
                  *ngIf="
                    reviewSystemHistory
                      ?.patient_general_health_history_attributes
                      ?.fever_chills === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    reviewSystemHistory
                      ?.patient_general_health_history_attributes
                      ?.fever_chills === false
                  "
                  >No</span
                >
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-sm-6"
              *ngIf="
                reviewSystemHistory?.patient_general_health_history_attributes
                  ?.other
              "
            >
              <div>
                Other:
                <span
                  *ngIf="
                    reviewSystemHistory
                      ?.patient_general_health_history_attributes?.other ===
                    true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    reviewSystemHistory
                      ?.patient_general_health_history_attributes?.other ===
                    false
                  "
                  >No</span
                >
              </div>
            </div>
            <div
              class="col-xl-9 col-lg-9 col-md-9 col-sm-6"
              *ngIf="
                reviewSystemHistory?.patient_general_health_history_attributes
                  ?.other_details
              "
            >
              <div>
                Detail:
                <span>{{
                  reviewSystemHistory?.patient_general_health_history_attributes
                    ?.other_details
                }}</span>
              </div>
            </div>
          </div>
          <!--          General Health end-->
          <!--          ENT start-->
          <p
            style="margin-bottom: 0; margin-top: 20px"
            class="paraFont"
            *ngIf="
              reviewSystemHistory?.patient_ent_history_attributes?.glaucoma ||
              reviewSystemHistory?.patient_ent_history_attributes
                ?.visual_difdiculty ||
              reviewSystemHistory?.patient_ent_history_attributes
                ?.hearing_problem ||
              reviewSystemHistory?.patient_ent_history_attributes
                ?.recurrent_sore_throat ||
              reviewSystemHistory?.patient_ent_history_attributes
                ?.swollen_glands
            "
          >
            <strong>ENT</strong>
          </p>
          <div class="row">
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-sm-6"
              *ngIf="
                reviewSystemHistory?.patient_ent_history_attributes?.glaucoma
              "
            >
              <div>
                Glaucoma:
                <span
                  *ngIf="
                    reviewSystemHistory?.patient_ent_history_attributes
                      ?.glaucoma === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    reviewSystemHistory?.patient_ent_history_attributes
                      ?.glaucoma === false
                  "
                  >No</span
                >
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-sm-6"
              *ngIf="
                reviewSystemHistory?.patient_ent_history_attributes
                  ?.visual_difdiculty
              "
            >
              <div>
                Visual difdiculty:
                <span
                  *ngIf="
                    reviewSystemHistory?.patient_ent_history_attributes
                      ?.visual_difdiculty === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    reviewSystemHistory?.patient_ent_history_attributes
                      ?.visual_difdiculty === false
                  "
                  >No</span
                >
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-sm-6"
              *ngIf="
                reviewSystemHistory?.patient_ent_history_attributes
                  ?.hearing_problem
              "
            >
              <div>
                Hearing problem :
                <span
                  *ngIf="
                    reviewSystemHistory?.patient_ent_history_attributes
                      ?.hearing_problem === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    reviewSystemHistory?.patient_ent_history_attributes
                      ?.hearing_problem === false
                  "
                  >No</span
                >
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-sm-6"
              *ngIf="
                reviewSystemHistory?.patient_ent_history_attributes
                  ?.recurrent_sore_throat
              "
            >
              <div>
                Recurrent Sore throat:
                <span
                  *ngIf="
                    reviewSystemHistory?.patient_ent_history_attributes
                      ?.recurrent_sore_throat === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    reviewSystemHistory?.patient_ent_history_attributes
                      ?.recurrent_sore_throat === false
                  "
                  >No</span
                >
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-sm-6"
              *ngIf="
                reviewSystemHistory?.patient_ent_history_attributes
                  ?.swollen_glands
              "
            >
              <div>
                Swollen Glands:
                <span
                  *ngIf="
                    reviewSystemHistory?.patient_ent_history_attributes
                      ?.swollen_glands === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    reviewSystemHistory?.patient_ent_history_attributes
                      ?.swollen_glands === false
                  "
                  >No</span
                >
              </div>
            </div>
          </div>
          <!--          ENT end-->
          <!--      Cardiology start-->
          <p
            style="margin-bottom: 0; margin-top: 20px"
            class="paraFont"
            *ngIf="
              reviewSystemHistory?.patient_cardiology_history_attributes
                ?.heart_problem ||
              reviewSystemHistory?.patient_cardiology_history_attributes
                ?.high_blood_pressure ||
              reviewSystemHistory?.patient_cardiology_history_attributes
                ?.stroke ||
              reviewSystemHistory?.patient_cardiology_history_attributes?.PCI ||
              reviewSystemHistory?.patient_cardiology_history_attributes
                ?.other ||
              reviewSystemHistory?.patient_cardiology_history_attributes?.other
            "
          >
            <strong>Cardiology</strong>
          </p>
          <div class="row">
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-sm-6"
              *ngIf="
                reviewSystemHistory?.patient_cardiology_history_attributes
                  ?.heart_problem
              "
            >
              <div>
                Heart problem:
                <span
                  *ngIf="
                    reviewSystemHistory?.patient_cardiology_history_attributes
                      ?.heart_problem === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    reviewSystemHistory?.patient_cardiology_history_attributes
                      ?.heart_problem === false
                  "
                  >No</span
                >
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-sm-6"
              *ngIf="
                reviewSystemHistory?.patient_cardiology_history_attributes
                  ?.high_blood_pressure
              "
            >
              <div>
                High Blood Pressure:
                <span
                  *ngIf="
                    reviewSystemHistory?.patient_cardiology_history_attributes
                      ?.high_blood_pressure === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    reviewSystemHistory?.patient_cardiology_history_attributes
                      ?.high_blood_pressure === false
                  "
                  >No</span
                >
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-sm-6"
              *ngIf="
                reviewSystemHistory?.patient_cardiology_history_attributes
                  ?.stroke
              "
            >
              <div>
                Stroke:
                <span
                  *ngIf="
                    reviewSystemHistory?.patient_cardiology_history_attributes
                      ?.stroke === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    reviewSystemHistory?.patient_cardiology_history_attributes
                      ?.stroke === false
                  "
                  >No</span
                >
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-sm-6"
              *ngIf="
                reviewSystemHistory?.patient_cardiology_history_attributes?.PCI
              "
            >
              <div>
                PCI/CABG:
                <span
                  *ngIf="
                    reviewSystemHistory?.patient_cardiology_history_attributes
                      ?.PCI === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    reviewSystemHistory?.patient_cardiology_history_attributes
                      ?.PCI === false
                  "
                  >No</span
                >
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-sm-6"
              *ngIf="
                reviewSystemHistory?.patient_cardiology_history_attributes
                  ?.other
              "
            >
              <div>
                Other:
                <span
                  *ngIf="
                    reviewSystemHistory?.patient_cardiology_history_attributes
                      ?.other === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    reviewSystemHistory?.patient_cardiology_history_attributes
                      ?.other === false
                  "
                  >No</span
                >
              </div>
            </div>
            <div
              class="col-xl-9 col-lg-9 col-md-9 col-sm-6"
              *ngIf="
                reviewSystemHistory?.patient_cardiology_history_attributes
                  ?.other
              "
            >
              <div>
                Detail:
                <span>{{
                  reviewSystemHistory?.patient_cardiology_history_attributes
                    ?.other
                }}</span>
              </div>
            </div>
          </div>
          <!--      Cardiology end-->
          <!--          Respiratory start-->
          <p
            style="margin-bottom: 0; margin-top: 20px"
            class="paraFont"
            *ngIf="
              reviewSystemHistory?.patient_respiratory_history_attributes
                ?.shirtness_of_breath ||
              reviewSystemHistory?.patient_respiratory_history_attributes
                ?.asthma ||
              reviewSystemHistory?.patient_respiratory_history_attributes
                ?.chronic_cought ||
              reviewSystemHistory?.patient_respiratory_history_attributes
                ?.lung_disease ||
              reviewSystemHistory?.patient_respiratory_history_attributes
                ?.heamoptysis
            "
          >
            <strong>Respiratory</strong>
          </p>
          <div class="row">
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-sm-6"
              *ngIf="
                reviewSystemHistory?.patient_respiratory_history_attributes
                  ?.shirtness_of_breath
              "
            >
              <div>
                Shortness of breath :
                <span
                  *ngIf="
                    reviewSystemHistory?.patient_respiratory_history_attributes
                      ?.shirtness_of_breath === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    reviewSystemHistory?.patient_respiratory_history_attributes
                      ?.shirtness_of_breath === false
                  "
                  >No</span
                >
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-sm-6"
              *ngIf="
                reviewSystemHistory?.patient_respiratory_history_attributes
                  ?.asthma
              "
            >
              <div>
                Asthma :
                <span
                  *ngIf="
                    reviewSystemHistory?.patient_respiratory_history_attributes
                      ?.asthma === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    reviewSystemHistory?.patient_respiratory_history_attributes
                      ?.asthma === false
                  "
                  >No</span
                >
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-sm-6"
              *ngIf="
                reviewSystemHistory?.patient_respiratory_history_attributes
                  ?.chronic_cought
              "
            >
              <div>
                Chronic Cough:
                <span
                  *ngIf="
                    reviewSystemHistory?.patient_respiratory_history_attributes
                      ?.chronic_cought === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    reviewSystemHistory?.patient_respiratory_history_attributes
                      ?.chronic_cought === false
                  "
                  >No</span
                >
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-sm-6"
              *ngIf="
                reviewSystemHistory?.patient_respiratory_history_attributes
                  ?.lung_disease
              "
            >
              <div>
                Lung Disease:
                <span
                  *ngIf="
                    reviewSystemHistory?.patient_respiratory_history_attributes
                      ?.lung_disease === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    reviewSystemHistory?.patient_respiratory_history_attributes
                      ?.lung_disease === false
                  "
                  >No</span
                >
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-sm-6"
              *ngIf="
                reviewSystemHistory?.patient_respiratory_history_attributes
                  ?.heamoptysis
              "
            >
              <div>
                Hemoptysis :
                <span
                  *ngIf="
                    reviewSystemHistory?.patient_respiratory_history_attributes
                      ?.heamoptysis === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    reviewSystemHistory?.patient_respiratory_history_attributes
                      ?.heamoptysis === false
                  "
                  >No</span
                >
              </div>
            </div>
          </div>
          <!--          Respiratory end-->
          <!--          Neuro start-->
          <p
            style="margin-bottom: 0; margin-top: 20px"
            class="paraFont"
            *ngIf="
              reviewSystemHistory?.patient_neuro_history_attributes?.seizures ||
              reviewSystemHistory?.patient_neuro_history_attributes?.headache ||
              reviewSystemHistory?.patient_neuro_history_attributes?.vertigo
            "
          >
            <strong>Neuro</strong>
          </p>
          <div class="row">
            <div
              class="col-xl-4 col-lg-4 col-md-4 col-sm-6"
              *ngIf="
                reviewSystemHistory?.patient_neuro_history_attributes?.seizures
              "
            >
              <div>
                Seizures:
                <span
                  *ngIf="
                    reviewSystemHistory?.patient_neuro_history_attributes
                      ?.seizures === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    reviewSystemHistory?.patient_neuro_history_attributes
                      ?.seizures === false
                  "
                  >No</span
                >
              </div>
            </div>
            <div
              class="col-xl-4 col-lg-4 col-md-4 col-sm-6"
              *ngIf="
                reviewSystemHistory?.patient_neuro_history_attributes?.headache
              "
            >
              <div>
                Headache:
                <span
                  *ngIf="
                    reviewSystemHistory?.patient_neuro_history_attributes
                      ?.headache === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    reviewSystemHistory?.patient_neuro_history_attributes
                      ?.headache === false
                  "
                  >No</span
                >
              </div>
            </div>
            <div
              class="col-xl-4 col-lg-4 col-md-4 col-sm-6"
              *ngIf="
                reviewSystemHistory?.patient_neuro_history_attributes?.vertigo
              "
            >
              <div>
                Vertigo/Black outs:
                <span
                  *ngIf="
                    reviewSystemHistory?.patient_neuro_history_attributes
                      ?.vertigo === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    reviewSystemHistory?.patient_neuro_history_attributes
                      ?.vertigo === false
                  "
                  >No</span
                >
              </div>
            </div>
          </div>
          <!--          Neuro end-->
          <!--          Skin start-->
          <p
            style="margin-bottom: 0; margin-top: 20px"
            class="paraFont"
            *ngIf="
              reviewSystemHistory?.patient_skin_history_attributes?.rash ||
              reviewSystemHistory?.patient_skin_history_attributes?.pigmentation
            "
          >
            <strong>Skin</strong>
          </p>
          <div class="row">
            <div
              class="col-xl-4 col-lg-4 col-md-4 col-sm-6"
              *ngIf="reviewSystemHistory?.patient_skin_history_attributes?.rash"
            >
              <div>
                Rash/ Striae:
                <span
                  *ngIf="
                    reviewSystemHistory?.patient_skin_history_attributes
                      ?.rash === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    reviewSystemHistory?.patient_skin_history_attributes
                      ?.rash === false
                  "
                  >No</span
                >
              </div>
            </div>
            <div
              class="col-xl-4 col-lg-4 col-md-4 col-sm-6"
              *ngIf="
                reviewSystemHistory?.patient_skin_history_attributes
                  ?.pigmentation
              "
            >
              <div>
                Pigmentation:
                <span
                  *ngIf="
                    reviewSystemHistory?.patient_skin_history_attributes
                      ?.pigmentation === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    reviewSystemHistory?.patient_skin_history_attributes
                      ?.pigmentation === false
                  "
                  >No</span
                >
              </div>
            </div>
          </div>
          <!--  Skin end-->

          <!--          Review Of System end-->
          <!--          Medications Start-->
          <p
            style="margin-bottom: 0; margin-top: 20px"
            class="paraHeadingFont"
            *ngIf="paitentMedicineHistory.length > 0"
          >
            <strong>Medications</strong>
          </p>
          <!--          Medicines start-->
          <p
            style="margin-bottom: 0; margin-top: 20px"
            class="paraFont"
            *ngIf="paitentMedicineHistory.length > 0"
          >
            <strong>Medicines</strong>
          </p>
          <div class="row mx-auto mt-4" style="height: 100%; overflow-y: auto">
            <div class="col-12">
              <table
                class="table table-head-custom table-vertical-center"
                *ngIf="paitentMedicineHistory.length > 0"
              >
                <thead>
                  <tr>
                    <th style="width: 20%">Date</th>
                    <th style="width: 25%">Name</th>
                    <th>Dose</th>
                    <th>Days</th>
                    <th>From Date</th>
                    <th>To Date</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container
                    *ngFor="
                      let history of paitentMedicineHistory;
                      let i = index
                    "
                  >
                    <tr>
                      <td>
                        {{ history.created_at | date: "dd-MM-yyyy h:mm a" }}
                      </td>
                      <td style="width: 25%">
                        {{ history.item.product_name }}
                      </td>
                      <td>{{ history.dose }}</td>
                      <td>{{ history.no_of_days }}</td>
                      <td>{{ history.from_date }}</td>
                      <td>{{ history.to_date }}</td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>

          <!--          Medicines end-->
          <!--          Medications End-->
          <!--          PHYSICAL & SYSTEMIC EXAMINATION start-->
          <p
            style="margin-bottom: 0; margin-top: 20px"
            class="paraHeadingFont"
            *ngIf="
              physicalSystemicExaminationHistory?.patient_gpe_history_attributes
                ?.pallor ||
              reviewSystemHistory?.patient_respiratory_history_attributes
                ?.cyanosis ||
              reviewSystemHistory?.patient_respiratory_history_attributes
                ?.rash ||
              reviewSystemHistory?.patient_respiratory_history_attributes
                ?.clubbing ||
              reviewSystemHistory?.patient_respiratory_history_attributes
                ?.pedal ||
              reviewSystemHistory?.patient_respiratory_history_attributes
                ?.lymphadenopathy ||
              reviewSystemHistory?.patient_respiratory_history_attributes
                ?.peropheral_vessel_palpable ||
              physicalSystemicExaminationHistory
                ?.patient_chest_history_attributes?.chest_defermity ||
              physicalSystemicExaminationHistory
                ?.patient_chest_history_attributes?.vesicular ||
              physicalSystemicExaminationHistory
                ?.patient_chest_history_attributes?.bronchial ||
              physicalSystemicExaminationHistory
                ?.patient_heent_history_attributes?.head ||
              physicalSystemicExaminationHistory
                ?.patient_heent_history_attributes?.eye ||
              physicalSystemicExaminationHistory
                ?.patient_heent_history_attributes?.ent ||
              physicalSystemicExaminationHistory
                ?.patient_heent_history_attributes?.neck ||
              physicalSystemicExaminationHistory
                ?.patient_heent_history_attributes?.neck_viens ||
              physicalSystemicExaminationHistory
                ?.patient_cardiovascular_history_attributes?.apex_beat ||
              physicalSystemicExaminationHistory
                ?.patient_breast_examination_attributes?.breast_examination ||
              physicalSystemicExaminationHistory
                ?.patient_abdomen_history_attributes?.soft ||
              physicalSystemicExaminationHistory
                ?.patient_abdomen_history_attributes?.distended ||
              physicalSystemicExaminationHistory
                ?.patient_abdomen_history_attributes?.ascities ||
              physicalSystemicExaminationHistory
                ?.patient_abdomen_history_attributes?.tenderness ||
              physicalSystemicExaminationHistory
                ?.patient_abdomen_history_attributes?.bowel_sounds ||
              physicalSystemicExaminationHistory
                ?.patient_abdomen_history_attributes?.hepatomegaly ||
              physicalSystemicExaminationHistory
                ?.patient_abdomen_history_attributes?.spleenomegaly ||
              physicalSystemicExaminationHistory
                ?.patient_abdomen_history_attributes?.spleenomegaly ||
              physicalSystemicExaminationHistory
                ?.patient_abdomen_history_attributes?.inguinal_hernia ||
              physicalSystemicExaminationHistory
                ?.patient_abdomen_history_attributes?.umbilical ||
              physicalSystemicExaminationHistory
                ?.patient_abdomen_history_attributes?.palpable_contractions ||
              physicalSystemicExaminationHistory
                ?.patient_abdomen_history_attributes?.fhs ||
              physicalSystemicExaminationHistory
                ?.patient_abdomen_history_attributes?.scar_marks ||
              physicalSystemicExaminationHistory
                ?.patient_abdomen_history_attributes?.ps_examination ||
              physicalSystemicExaminationHistory
                ?.patient_abdomen_history_attributes?.pv_bm_examination ||
              physicalSystemicExaminationHistory
                ?.patient_abdomen_history_attributes?.grade ||
              physicalSystemicExaminationHistory
                ?.patient_abdomen_history_attributes?.area ||
              physicalSystemicExaminationHistory
                ?.patient_abdomen_history_attributes?.genital_examination ||
              physicalSystemicExaminationHistory
                ?.patient_abdomen_history_attributes?.other ||
              physicalSystemicExaminationHistory
                ?.patient_abdomen_history_attributes?.pelvic_genitalia ||
              physicalSystemicExaminationHistory
                ?.patient_abdomen_history_attributes?.rectal ||
              physicalSystemicExaminationHistory
                ?.patient_abdomen_history_attributes?.extremities ||
              physicalSystemicExaminationHistory
                ?.patient_abdomen_history_attributes?.musculoskeletal
            "
          >
            <strong>PHYSICAL & SYSTEMIC EXAMINATION</strong>
          </p>
          <!--          GPE start-->
          <p
            style="margin-bottom: 0; margin-top: 20px"
            class="paraFont"
            *ngIf="
              physicalSystemicExaminationHistory?.patient_gpe_history_attributes
                ?.pallor ||
              reviewSystemHistory?.patient_respiratory_history_attributes
                ?.cyanosis ||
              reviewSystemHistory?.patient_respiratory_history_attributes
                ?.rash ||
              reviewSystemHistory?.patient_respiratory_history_attributes
                ?.clubbing ||
              reviewSystemHistory?.patient_respiratory_history_attributes
                ?.pedal ||
              reviewSystemHistory?.patient_respiratory_history_attributes
                ?.lymphadenopathy ||
              reviewSystemHistory?.patient_respiratory_history_attributes
                ?.peropheral_vessel_palpable
            "
          >
            <strong>GPE</strong>
          </p>
          <div class="row">
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-sm-6"
              *ngIf="
                physicalSystemicExaminationHistory
                  ?.patient_gpe_history_attributes?.pallor
              "
            >
              <div>
                Pallor:
                <span
                  *ngIf="
                    physicalSystemicExaminationHistory
                      ?.patient_gpe_history_attributes?.pallor === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    physicalSystemicExaminationHistory
                      ?.patient_gpe_history_attributes?.pallor === false
                  "
                  >No</span
                >
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-sm-6"
              *ngIf="
                reviewSystemHistory?.patient_respiratory_history_attributes
                  ?.cyanosis
              "
            >
              <div>
                Cyanosis :
                <span
                  *ngIf="
                    reviewSystemHistory?.patient_respiratory_history_attributes
                      ?.cyanosis === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    reviewSystemHistory?.patient_respiratory_history_attributes
                      ?.cyanosis === false
                  "
                  >No</span
                >
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-sm-6"
              *ngIf="
                reviewSystemHistory?.patient_respiratory_history_attributes
                  ?.rash
              "
            >
              <div>
                Rash :
                <span
                  *ngIf="
                    reviewSystemHistory?.patient_respiratory_history_attributes
                      ?.rash === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    reviewSystemHistory?.patient_respiratory_history_attributes
                      ?.rash === false
                  "
                  >No</span
                >
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-sm-6"
              *ngIf="
                reviewSystemHistory?.patient_respiratory_history_attributes
                  ?.clubbing
              "
            >
              <div>
                Clubbing :
                <span
                  *ngIf="
                    reviewSystemHistory?.patient_respiratory_history_attributes
                      ?.clubbing === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    reviewSystemHistory?.patient_respiratory_history_attributes
                      ?.clubbing === false
                  "
                  >No</span
                >
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-sm-6"
              *ngIf="
                reviewSystemHistory?.patient_respiratory_history_attributes
                  ?.pedal
              "
            >
              <div>
                Pedal/Ankle Edema :
                <span
                  *ngIf="
                    reviewSystemHistory?.patient_respiratory_history_attributes
                      ?.pedal === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    reviewSystemHistory?.patient_respiratory_history_attributes
                      ?.pedal === false
                  "
                  >No</span
                >
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-sm-6"
              *ngIf="
                reviewSystemHistory?.patient_respiratory_history_attributes
                  ?.lymphadenopathy
              "
            >
              <div>
                Lymphadenopathy :
                <span
                  *ngIf="
                    reviewSystemHistory?.patient_respiratory_history_attributes
                      ?.lymphadenopathy === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    reviewSystemHistory?.patient_respiratory_history_attributes
                      ?.lymphadenopathy === false
                  "
                  >No</span
                >
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-sm-6"
              *ngIf="
                reviewSystemHistory?.patient_respiratory_history_attributes
                  ?.peropheral_vessel_palpable
              "
            >
              <div>
                Peropheral vessel palpable :
                <span
                  *ngIf="
                    reviewSystemHistory?.patient_respiratory_history_attributes
                      ?.peropheral_vessel_palpable === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    reviewSystemHistory?.patient_respiratory_history_attributes
                      ?.peropheral_vessel_palpable === false
                  "
                  >No</span
                >
              </div>
            </div>
          </div>
          <!--          GPE end-->
          <!--          Chest start-->
          <p
            style="margin-bottom: 0; margin-top: 20px"
            class="paraFont"
            *ngIf="
              physicalSystemicExaminationHistory
                ?.patient_chest_history_attributes?.chest_defermity ||
              physicalSystemicExaminationHistory
                ?.patient_chest_history_attributes?.vesicular ||
              physicalSystemicExaminationHistory
                ?.patient_chest_history_attributes?.bronchial
            "
          >
            <strong>Chest</strong>
          </p>
          <div class="row">
            <div
              class="col-xl-2 col-lg-2 col-md-2 col-sm-6"
              *ngIf="
                physicalSystemicExaminationHistory
                  ?.patient_chest_history_attributes?.chest_defermity
              "
            >
              <div><b>Defermity:</b></div>
            </div>
            <div
              class="col-xl-1 col-lg-1 col-md-1 col-sm-2"
              *ngIf="
                physicalSystemicExaminationHistory
                  ?.patient_chest_history_attributes?.chest_defermity
              "
            >
              <div>
                Yes:
                <span
                  *ngIf="
                    physicalSystemicExaminationHistory
                      ?.patient_chest_history_attributes?.chest_defermity ===
                    true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    physicalSystemicExaminationHistory
                      ?.patient_chest_history_attributes?.chest_defermity ===
                    false
                  "
                  >No</span
                >
              </div>
            </div>
            <div
              class="col-xl-1 col-lg-1 col-md-1 col-sm-2"
              *ngIf="
                physicalSystemicExaminationHistory
                  ?.patient_chest_history_attributes?.chest_defermity
              "
            >
              <div>
                No:
                <span
                  *ngIf="
                    physicalSystemicExaminationHistory
                      ?.patient_chest_history_attributes?.chest_defermity ===
                    true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    physicalSystemicExaminationHistory
                      ?.patient_chest_history_attributes?.chest_defermity ===
                    false
                  "
                  >No</span
                >
              </div>
            </div>
            <div
              class="col-xl-2 col-lg-2 col-md-2 col-sm-6"
              *ngIf="
                physicalSystemicExaminationHistory
                  ?.patient_chest_history_attributes?.vesicular ||
                physicalSystemicExaminationHistory
                  ?.patient_chest_history_attributes?.bronchial
              "
            >
              <div><b>Breathing:</b></div>
            </div>
            <div
              class="col-xl-2 col-lg-2 col-md-2 col-sm-2"
              *ngIf="
                physicalSystemicExaminationHistory
                  ?.patient_chest_history_attributes?.vesicular
              "
            >
              <div>
                Vesicular:
                <span
                  *ngIf="
                    physicalSystemicExaminationHistory
                      ?.patient_chest_history_attributes?.vesicular === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    physicalSystemicExaminationHistory
                      ?.patient_chest_history_attributes?.vesicular === false
                  "
                  >No</span
                >
              </div>
            </div>
            <div
              class="col-xl-2 col-lg-2 col-md-2 col-sm-2"
              *ngIf="
                physicalSystemicExaminationHistory
                  ?.patient_chest_history_attributes?.bronchial
              "
            >
              <div>
                Bronchial:
                <span
                  *ngIf="
                    physicalSystemicExaminationHistory
                      ?.patient_chest_history_attributes?.bronchial === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    physicalSystemicExaminationHistory
                      ?.patient_chest_history_attributes?.bronchial === false
                  "
                  >No</span
                >
              </div>
            </div>

            <div
              class="col-xl-2 col-lg-2 col-md-2 col-sm-6"
              *ngIf="
                physicalSystemicExaminationHistory
                  ?.patient_chest_history_attributes?.rhonchi ||
                physicalSystemicExaminationHistory
                  ?.patient_chest_history_attributes?.wheeeze
              "
            >
              <div><b>Added Sounds:</b></div>
            </div>
            <div
              class="col-xl-2 col-lg-2 col-md-2 col-sm-2"
              *ngIf="
                physicalSystemicExaminationHistory
                  ?.patient_chest_history_attributes?.rhonchi
              "
            >
              <div>
                Rhonchi:
                <span
                  *ngIf="
                    physicalSystemicExaminationHistory
                      ?.patient_chest_history_attributes?.rhonchi === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    physicalSystemicExaminationHistory
                      ?.patient_chest_history_attributes?.rhonchi === false
                  "
                  >No</span
                >
              </div>
            </div>
            <div
              class="col-xl-2 col-lg-2 col-md-2 col-sm-2"
              *ngIf="
                physicalSystemicExaminationHistory
                  ?.patient_chest_history_attributes?.wheeeze
              "
            >
              <div>
                Wheeeze:
                <span
                  *ngIf="
                    physicalSystemicExaminationHistory
                      ?.patient_chest_history_attributes?.wheeeze === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    physicalSystemicExaminationHistory
                      ?.patient_chest_history_attributes?.wheeeze === false
                  "
                  >No</span
                >
              </div>
            </div>
          </div>
          <!--  Chest end-->
          <!--          Heent start-->
          <p
            style="margin-bottom: 0; margin-top: 20px"
            class="paraFont"
            *ngIf="
              physicalSystemicExaminationHistory
                ?.patient_heent_history_attributes?.head ||
              physicalSystemicExaminationHistory
                ?.patient_heent_history_attributes?.eye ||
              physicalSystemicExaminationHistory
                ?.patient_heent_history_attributes?.ent ||
              physicalSystemicExaminationHistory
                ?.patient_heent_history_attributes?.neck ||
              physicalSystemicExaminationHistory
                ?.patient_heent_history_attributes?.neck_viens
            "
          >
            <strong>Heent</strong>
          </p>
          <div class="row">
            <div
              class="col-xl-4 col-lg-4 col-md-4 col-sm-6"
              *ngIf="
                physicalSystemicExaminationHistory
                  ?.patient_heent_history_attributes?.head
              "
            >
              <div>
                Head:
                <span>{{
                  physicalSystemicExaminationHistory
                    ?.patient_heent_history_attributes?.head
                }}</span>
              </div>
            </div>
            <div
              class="col-xl-4 col-lg-4 col-md-4 col-sm-6"
              *ngIf="
                physicalSystemicExaminationHistory
                  ?.patient_heent_history_attributes?.eye
              "
            >
              <div>
                EYE:
                <span>{{
                  physicalSystemicExaminationHistory
                    ?.patient_heent_history_attributes?.eye
                }}</span>
              </div>
            </div>
            <div
              class="col-xl-4 col-lg-4 col-md-4 col-sm-6"
              *ngIf="
                physicalSystemicExaminationHistory
                  ?.patient_heent_history_attributes?.ent
              "
            >
              <div>
                ENT:
                <span>{{
                  physicalSystemicExaminationHistory
                    ?.patient_heent_history_attributes?.ent
                }}</span>
              </div>
            </div>
            <div
              class="col-xl-4 col-lg-4 col-md-4 col-sm-6"
              *ngIf="
                physicalSystemicExaminationHistory
                  ?.patient_heent_history_attributes?.neck
              "
            >
              <div>
                Neck:
                <span>{{
                  physicalSystemicExaminationHistory
                    ?.patient_heent_history_attributes?.neck
                }}</span>
              </div>
            </div>
            <div
              class="col-xl-4 col-lg-4 col-md-4 col-sm-6"
              *ngIf="
                physicalSystemicExaminationHistory
                  ?.patient_heent_history_attributes?.neck_viens
              "
            >
              <div>
                N-Viens:
                <span>{{
                  physicalSystemicExaminationHistory
                    ?.patient_heent_history_attributes?.neck_viens
                }}</span>
              </div>
            </div>
          </div>
          <!--          Heent end-->
          <!--          Cardiovascular start-->
          <p
            style="margin-bottom: 0; margin-top: 20px"
            class="paraFont"
            *ngIf="
              physicalSystemicExaminationHistory
                ?.patient_cardiovascular_history_attributes?.apex_beat
            "
          >
            <strong>Cardiovascular</strong>
          </p>
          <div class="row">
            <div
              class="col-xl-12 col-lg-12 col-md-12 col-sm-12"
              *ngIf="
                physicalSystemicExaminationHistory
                  ?.patient_cardiovascular_history_attributes?.apex_beat
              "
            >
              <div>
                Apex Beat:
                <span>{{
                  physicalSystemicExaminationHistory
                    ?.patient_cardiovascular_history_attributes?.apex_beat
                }}</span>
              </div>
            </div>
          </div>
          <!--          Cardiovascular end-->
          <!--          Breast Examination start-->
          <p
            style="margin-bottom: 0; margin-top: 20px"
            class="paraFont"
            *ngIf="
              physicalSystemicExaminationHistory
                ?.patient_breast_examination_attributes?.breast_examination
            "
          >
            <strong>Breast Examination</strong>
          </p>
          <div class="row">
            <div
              class="col-xl-12 col-lg-12 col-md-12 col-sm-12"
              *ngIf="
                physicalSystemicExaminationHistory
                  ?.patient_breast_examination_attributes?.breast_examination
              "
            >
              <div>
                Breast Examination:
                <span>{{
                  physicalSystemicExaminationHistory
                    ?.patient_breast_examination_attributes?.breast_examination
                }}</span>
              </div>
            </div>
          </div>
          <!--          Breast Examination end-->
          <!--          Abdomen start-->
          <p
            style="margin-bottom: 0; margin-top: 20px"
            class="paraFont"
            *ngIf="
              physicalSystemicExaminationHistory
                ?.patient_abdomen_history_attributes?.soft ||
              physicalSystemicExaminationHistory
                ?.patient_abdomen_history_attributes?.distended ||
              physicalSystemicExaminationHistory
                ?.patient_abdomen_history_attributes?.ascities ||
              physicalSystemicExaminationHistory
                ?.patient_abdomen_history_attributes?.tenderness ||
              physicalSystemicExaminationHistory
                ?.patient_abdomen_history_attributes?.bowel_sounds ||
              physicalSystemicExaminationHistory
                ?.patient_abdomen_history_attributes?.hepatomegaly ||
              physicalSystemicExaminationHistory
                ?.patient_abdomen_history_attributes?.spleenomegaly ||
              physicalSystemicExaminationHistory
                ?.patient_abdomen_history_attributes?.spleenomegaly ||
              physicalSystemicExaminationHistory
                ?.patient_abdomen_history_attributes?.inguinal_hernia ||
              physicalSystemicExaminationHistory
                ?.patient_abdomen_history_attributes?.umbilical ||
              physicalSystemicExaminationHistory
                ?.patient_abdomen_history_attributes?.palpable_contractions ||
              physicalSystemicExaminationHistory
                ?.patient_abdomen_history_attributes?.fhs ||
              physicalSystemicExaminationHistory
                ?.patient_abdomen_history_attributes?.scar_marks ||
              physicalSystemicExaminationHistory
                ?.patient_abdomen_history_attributes?.ps_examination ||
              physicalSystemicExaminationHistory
                ?.patient_abdomen_history_attributes?.pv_bm_examination ||
              physicalSystemicExaminationHistory
                ?.patient_abdomen_history_attributes?.grade ||
              physicalSystemicExaminationHistory
                ?.patient_abdomen_history_attributes?.area ||
              physicalSystemicExaminationHistory
                ?.patient_abdomen_history_attributes?.genital_examination ||
              physicalSystemicExaminationHistory
                ?.patient_abdomen_history_attributes?.other ||
              physicalSystemicExaminationHistory
                ?.patient_abdomen_history_attributes?.pelvic_genitalia ||
              physicalSystemicExaminationHistory
                ?.patient_abdomen_history_attributes?.rectal ||
              physicalSystemicExaminationHistory
                ?.patient_abdomen_history_attributes?.extremities ||
              physicalSystemicExaminationHistory
                ?.patient_abdomen_history_attributes?.musculoskeletal
            "
          >
            <strong>Abdomen</strong>
          </p>
          <div class="row">
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-sm-6"
              *ngIf="
                physicalSystemicExaminationHistory
                  ?.patient_abdomen_history_attributes?.soft
              "
            >
              <div>
                Soft:
                <span
                  *ngIf="
                    physicalSystemicExaminationHistory
                      ?.patient_abdomen_history_attributes?.soft === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    physicalSystemicExaminationHistory
                      ?.patient_abdomen_history_attributes?.soft === false
                  "
                  >No</span
                >
                <span></span>
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-sm-6"
              *ngIf="
                physicalSystemicExaminationHistory
                  ?.patient_abdomen_history_attributes?.distended
              "
            >
              <div>
                Distended:
                <span
                  *ngIf="
                    physicalSystemicExaminationHistory
                      ?.patient_abdomen_history_attributes?.distended === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    physicalSystemicExaminationHistory
                      ?.patient_abdomen_history_attributes?.distended === false
                  "
                  >No</span
                >
                <span></span>
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-sm-6"
              *ngIf="
                physicalSystemicExaminationHistory
                  ?.patient_abdomen_history_attributes?.ascities
              "
            >
              <div>
                Ascities:
                <span
                  *ngIf="
                    physicalSystemicExaminationHistory
                      ?.patient_abdomen_history_attributes?.ascities === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    physicalSystemicExaminationHistory
                      ?.patient_abdomen_history_attributes?.ascities === false
                  "
                  >No</span
                >
                <span></span>
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-sm-6"
              *ngIf="
                physicalSystemicExaminationHistory
                  ?.patient_abdomen_history_attributes?.tenderness
              "
            >
              <div>
                Tenderness :
                <span
                  *ngIf="
                    physicalSystemicExaminationHistory
                      ?.patient_abdomen_history_attributes?.tenderness === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    physicalSystemicExaminationHistory
                      ?.patient_abdomen_history_attributes?.tenderness === false
                  "
                  >No</span
                >
                <span></span>
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-sm-6"
              *ngIf="
                physicalSystemicExaminationHistory
                  ?.patient_abdomen_history_attributes?.bowel_sounds
              "
            >
              <div>
                Bowel Sounds:
                <span
                  *ngIf="
                    physicalSystemicExaminationHistory
                      ?.patient_abdomen_history_attributes?.bowel_sounds ===
                    true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    physicalSystemicExaminationHistory
                      ?.patient_abdomen_history_attributes?.bowel_sounds ===
                    false
                  "
                  >No</span
                >
                <span></span>
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-sm-6"
              *ngIf="
                physicalSystemicExaminationHistory
                  ?.patient_abdomen_history_attributes?.hepatomegaly
              "
            >
              <div>
                Hepatomegaly:
                <span
                  *ngIf="
                    physicalSystemicExaminationHistory
                      ?.patient_abdomen_history_attributes?.hepatomegaly ===
                    true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    physicalSystemicExaminationHistory
                      ?.patient_abdomen_history_attributes?.hepatomegaly ===
                    false
                  "
                  >No</span
                >
                <span></span>
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-sm-6"
              *ngIf="
                physicalSystemicExaminationHistory
                  ?.patient_abdomen_history_attributes?.spleenomegaly
              "
            >
              <div>
                Spleenomegaly :
                <span
                  *ngIf="
                    physicalSystemicExaminationHistory
                      ?.patient_abdomen_history_attributes?.spleenomegaly ===
                    true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    physicalSystemicExaminationHistory
                      ?.patient_abdomen_history_attributes?.spleenomegaly ===
                    false
                  "
                  >No</span
                >
                <span></span>
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-sm-6"
              *ngIf="
                physicalSystemicExaminationHistory
                  ?.patient_abdomen_history_attributes?.inguinal_hernia
              "
            >
              <div>
                Inguinal hernia :
                <span
                  *ngIf="
                    physicalSystemicExaminationHistory
                      ?.patient_abdomen_history_attributes?.inguinal_hernia ===
                    true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    physicalSystemicExaminationHistory
                      ?.patient_abdomen_history_attributes?.inguinal_hernia ===
                    false
                  "
                  >No</span
                >
                <span></span>
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-sm-6"
              *ngIf="
                physicalSystemicExaminationHistory
                  ?.patient_abdomen_history_attributes?.umbilical
              "
            >
              <div>
                Umbilical/Paraumbilical:
                <span
                  *ngIf="
                    physicalSystemicExaminationHistory
                      ?.patient_abdomen_history_attributes?.umbilical === true
                  "
                  >Yes</span
                >
                <span
                  *ngIf="
                    physicalSystemicExaminationHistory
                      ?.patient_abdomen_history_attributes?.umbilical === false
                  "
                  >No</span
                >
                <span></span>
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-sm-6"
              *ngIf="
                physicalSystemicExaminationHistory
                  ?.patient_abdomen_history_attributes?.palpable_contractions
              "
            >
              <div>
                Palpable Contractions:
                <span>{{
                  physicalSystemicExaminationHistory
                    ?.patient_abdomen_history_attributes?.palpable_contractions
                }}</span>
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-sm-6"
              *ngIf="
                physicalSystemicExaminationHistory
                  ?.patient_abdomen_history_attributes?.fhs
              "
            >
              <div>
                FHS:
                <span>{{
                  physicalSystemicExaminationHistory
                    ?.patient_abdomen_history_attributes?.fhs
                }}</span>
              </div>
            </div>
            <div
              class="col-xl-3 col-lg-3 col-md-3 col-sm-6"
              *ngIf="
                physicalSystemicExaminationHistory
                  ?.patient_abdomen_history_attributes?.scar_marks
              "
            >
              <div>
                Scar Marks:
                <span>{{
                  physicalSystemicExaminationHistory
                    ?.patient_abdomen_history_attributes?.scar_marks
                }}</span>
              </div>
            </div>
            <div
              class="col-xl-12 col-lg-12 col-md-12 col-sm-12"
              *ngIf="
                physicalSystemicExaminationHistory
                  ?.patient_abdomen_history_attributes?.ps_examination
              "
            >
              <div>
                P/S Examination:
                <span>{{
                  physicalSystemicExaminationHistory
                    ?.patient_abdomen_history_attributes?.ps_examination
                }}</span>
              </div>
            </div>
            <div
              class="col-xl-12 col-lg-12 col-md-12 col-sm-12"
              *ngIf="
                physicalSystemicExaminationHistory
                  ?.patient_abdomen_history_attributes?.pv_bm_examination
              "
            >
              <div>
                PV/BM Examination:
                <span>{{
                  physicalSystemicExaminationHistory
                    ?.patient_abdomen_history_attributes?.pv_bm_examination
                }}</span>
              </div>
            </div>
            <div
              class="col-xl-6 col-lg-6 col-md-6 col-sm-12"
              *ngIf="
                physicalSystemicExaminationHistory
                  ?.patient_abdomen_history_attributes?.grade
              "
            >
              <div>
                Grade::
                <span>{{
                  physicalSystemicExaminationHistory
                    ?.patient_abdomen_history_attributes?.grade
                }}</span>
              </div>
            </div>
            <div
              class="col-xl-6 col-lg-6 col-md-6 col-sm-12"
              *ngIf="
                physicalSystemicExaminationHistory
                  ?.patient_abdomen_history_attributes?.area
              "
            >
              <div>
                Area:
                <span>{{
                  physicalSystemicExaminationHistory
                    ?.patient_abdomen_history_attributes?.area
                }}</span>
              </div>
            </div>

            <div
              class="col-xl-12 col-lg-12 col-md-12 col-sm-12"
              *ngIf="
                physicalSystemicExaminationHistory
                  ?.patient_abdomen_history_attributes?.genital_examination
              "
            >
              <div>
                Genital examination:
                <span>{{
                  physicalSystemicExaminationHistory
                    ?.patient_abdomen_history_attributes?.genital_examination
                }}</span>
              </div>
            </div>
            <div
              class="col-xl-12 col-lg-12 col-md-12 col-sm-12"
              *ngIf="
                physicalSystemicExaminationHistory
                  ?.patient_abdomen_history_attributes?.other
              "
            >
              <div>
                Other Details:
                <span>{{
                  physicalSystemicExaminationHistory
                    ?.patient_abdomen_history_attributes?.other
                }}</span>
              </div>
            </div>
            <div
              class="col-xl-12 col-lg-12 col-md-12 col-sm-12"
              *ngIf="
                physicalSystemicExaminationHistory
                  ?.patient_abdomen_history_attributes?.pelvic_genitalia
              "
            >
              <div>
                Pelvic Genitalia:
                <span>{{
                  physicalSystemicExaminationHistory
                    ?.patient_abdomen_history_attributes?.pelvic_genitalia
                }}</span>
              </div>
            </div>
            <div
              class="col-xl-12 col-lg-12 col-md-12 col-sm-12"
              *ngIf="
                physicalSystemicExaminationHistory
                  ?.patient_abdomen_history_attributes?.rectal
              "
            >
              <div>
                Rectal:
                <span>{{
                  physicalSystemicExaminationHistory
                    ?.patient_abdomen_history_attributes?.rectal
                }}</span>
              </div>
            </div>
            <div
              class="col-xl-12 col-lg-12 col-md-12 col-sm-12"
              *ngIf="
                physicalSystemicExaminationHistory
                  ?.patient_abdomen_history_attributes?.extremities
              "
            >
              <div>
                Extremities:
                <span>{{
                  physicalSystemicExaminationHistory
                    ?.patient_abdomen_history_attributes?.extremities
                }}</span>
              </div>
            </div>
            <div
              class="col-xl-12 col-lg-12 col-md-12 col-sm-12"
              *ngIf="
                physicalSystemicExaminationHistory
                  ?.patient_abdomen_history_attributes?.musculoskeletal
              "
            >
              <div>
                Musculoskeletal::
                <span>{{
                  physicalSystemicExaminationHistory
                    ?.patient_abdomen_history_attributes?.musculoskeletal
                }}</span>
              </div>
            </div>
          </div>
          <!--          Abdomen end-->
          <!--          PHYSICAL & SYSTEMIC EXAMINATION End-->
          <!--          NERVOUS SYSTEM start-->
          <p style="margin-bottom: 0; margin-top: 20px" class="paraHeadingFont">
            <strong>NERVOUS SYSTEM</strong>
          </p>
          <div class="row">
            <div
              class="col-xl-4 col-lg-4 col-md-4 col-sm-6"
              *ngIf="patientCentralNervousSystem?.gcs"
            >
              <div>
                GCS: <span>{{ patientCentralNervousSystem?.gcs }}</span>
              </div>
            </div>
            <div
              class="col-xl-4 col-lg-4 col-md-4 col-sm-6"
              *ngIf="patientCentralNervousSystem?.confused"
            >
              <div>
                Confused:
                <span *ngIf="patientCentralNervousSystem?.confused === true"
                  >Yes</span
                >
                <span *ngIf="patientCentralNervousSystem?.confused === false"
                  >No</span
                >
              </div>
            </div>
            <div
              class="col-xl-4 col-lg-4 col-md-4 col-sm-6"
              *ngIf="patientCentralNervousSystem?.oriented"
            >
              <div>
                Oriented :
                <span *ngIf="patientCentralNervousSystem?.oriented === true"
                  >Yes</span
                >
                <span *ngIf="patientCentralNervousSystem?.oriented === false"
                  >No</span
                >
              </div>
            </div>
            <div
              class="col-xl-4 col-lg-4 col-md-4 col-sm-6"
              *ngIf="patientCentralNervousSystem?.mood"
            >
              <div>
                Mood: <span>{{ patientCentralNervousSystem?.mood }}</span>
              </div>
            </div>
            <div
              class="col-xl-4 col-lg-4 col-md-4 col-sm-6"
              *ngIf="patientCentralNervousSystem?.speech"
            >
              <div>
                Speech: <span>{{ patientCentralNervousSystem?.speech }}</span>
              </div>
            </div>
            <div
              class="col-xl-4 col-lg-4 col-md-4 col-sm-6"
              *ngIf="patientCentralNervousSystem?.congnition"
            >
              <div>
                Congnition:
                <span>{{ patientCentralNervousSystem?.congnition }}</span>
              </div>
            </div>
            <div
              class="col-xl-4 col-lg-4 col-md-4 col-sm-6"
              *ngIf="patientCentralNervousSystem?.memory_recent"
            >
              <div>
                Mem. Recent:
                <span>{{ patientCentralNervousSystem?.memory_recent }}</span>
              </div>
            </div>
            <div
              class="col-xl-4 col-lg-4 col-md-4 col-sm-6"
              *ngIf="patientCentralNervousSystem?.memory_past"
            >
              <div>
                Mem. Past:
                <span>{{ patientCentralNervousSystem?.memory_past }}</span>
              </div>
            </div>
            <div
              class="col-xl-4 col-lg-4 col-md-4 col-sm-6"
              *ngIf="patientCentralNervousSystem?.pupil"
            >
              <div>
                Pupil: <span>{{ patientCentralNervousSystem?.pupil }}</span>
              </div>
            </div>
            <div
              class="col-xl-4 col-lg-4 col-md-4 col-sm-6"
              *ngIf="patientCentralNervousSystem?.sensory_system"
            >
              <div>Sensory system:</div>
            </div>
            <div
              class="col-xl-4 col-lg-4 col-md-4 col-sm-6"
              *ngIf="patientCentralNervousSystem?.sensory_system"
            >
              <div>
                Intact:
                <span
                  *ngIf="patientCentralNervousSystem?.sensory_system === true"
                  >Yes</span
                >
                <span
                  *ngIf="patientCentralNervousSystem?.sensory_system === false"
                  >No</span
                >
              </div>
            </div>
            <div
              class="col-xl-4 col-lg-4 col-md-4 col-sm-6"
              *ngIf="patientCentralNervousSystem?.sensory_system_detail"
            >
              <div>
                If deficit details:
                <span>{{
                  patientCentralNervousSystem?.sensory_system_detail
                }}</span>
              </div>
            </div>

            <div
              class="col-xl-4 col-lg-4 col-md-4 col-sm-6"
              *ngIf="patientCentralNervousSystem?.motor_system"
            >
              <div>Motor system:</div>
            </div>
            <div
              class="col-xl-4 col-lg-4 col-md-4 col-sm-6"
              *ngIf="patientCentralNervousSystem?.motor_system"
            >
              <div>
                Intact:
                <span *ngIf="patientCentralNervousSystem?.motor_system === true"
                  >Yes</span
                >
                <span
                  *ngIf="patientCentralNervousSystem?.motor_system === false"
                  >No</span
                >
              </div>
            </div>
            <div
              class="col-xl-4 col-lg-4 col-md-4 col-sm-6"
              *ngIf="patientCentralNervousSystem?.motor_system_detail"
            >
              <div>
                If deficit details:
                <span>{{
                  patientCentralNervousSystem?.motor_system_detail
                }}</span>
              </div>
            </div>
            <div
              class="col-xl-4 col-lg-4 col-md-4 col-sm-6"
              *ngIf="patientCentralNervousSystem?.kerning"
            >
              <div>
                Kerning:
                <span *ngIf="patientCentralNervousSystem?.kerning === true"
                  >Yes</span
                >
                <span *ngIf="patientCentralNervousSystem?.kerning === false"
                  >No</span
                >
              </div>
            </div>
            <div
              class="col-xl-4 col-lg-4 col-md-4 col-sm-6"
              *ngIf="patientCentralNervousSystem?.neck_stiffness"
            >
              <div>
                Neck Stiffness:
                <span
                  *ngIf="patientCentralNervousSystem?.neck_stiffness === true"
                  >Yes</span
                >
                <span
                  *ngIf="patientCentralNervousSystem?.neck_stiffness === false"
                  >No</span
                >
              </div>
            </div>
            <div
              class="col-xl-4 col-lg-4 col-md-4 col-sm-6"
              *ngIf="patientCentralNervousSystem?.other"
            >
              <div>
                Any Other:
                <span>{{ patientCentralNervousSystem?.other }}</span>
              </div>
            </div>
          </div>
          <!--          NERVOUS SYSTEM end-->
        </div>
      </div>
    </div>
  </div>
</div>
