<!--<app-mobile-header></app-mobile-header>-->

<div
  *ngIf="settingData"
  class="hospital_name_toggle d-print-none"
  [ngClass]="{ hospital_image: settingData?.homepage_image_top.url }"
>
  <div style="position: relative">
    <div class="hospital_name row">
      <!--      <img *ngIf="!hospitalName" class="pr-4" [src]="settingData?.homepage_image_top" style="width: 50px; height: 60px">-->
      <p
        *ngIf="!settingData?.homepage_image_top.url; else logoName"
        class="text-center m-0"
        style="font-weight: 500"
      >
        {{ settingData?.title }}
      </p>
      <ng-template #logoName>
        <img
          [src]="settingData?.homepage_image_top.url"
          style="
            width: auto;
            max-height: 80px;
            min-height: 80px;
            object-fit: cover;
          "
        />
      </ng-template>
    </div>
    <div class="d-flex justify-content-center">
      <div style="cursor: pointer; width: 100%" (click)="activeToggleBar()">
        <div class="bar-main text-center" aria-label="Resize">
          <i style="line-height: 12px" class="fas fa-grip-lines"></i>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="d-flex flex-column flex-root">
  <!--begin::Page-->
  <div class="d-flex flex-row flex-column-fluid page">
    <app-sidebar></app-sidebar>

    <div
      class="d-flex flex-column flex-row-fluid wrapper setBodyLayout"
      id="kt_wrapper"
    >
      <div class="newsAlert" *ngIf="expiryData.length > 0">
        <marquee class="text-white font-weight-bolder pt-1 d-print-none">
          <span class="mr-10">Alert =></span>
          <ng-container *ngFor="let data of expiryData">
            <span class="mr-5" *ngIf="this.today == data?.billing_date"
              >Billing Date: {{ data?.billing_date }} -
              {{ data?.billing_error }}</span
            >
            <span
              class="mr-5"
              *ngIf="
                this.today > data?.billing_date && this.today <= data?.due_date
              "
              >Due Date: {{ data?.due_date }} - {{ data?.due_error }}</span
            >
            <span
              class="mr-5"
              *ngIf="
                this.today > data?.billing_date &&
                this.today > data?.due_date &&
                this.today <= data?.closure_date
              "
              >Closure Date: {{ data?.closure_date }} -
              {{ data?.closure_error }}</span
            >
          </ng-container>
        </marquee>
      </div>
      <app-topbar></app-topbar>
      <router-outlet (activate)="changeOfRoutes()"></router-outlet>
      <app-footer></app-footer>
    </div>
  </div>
</div>

<!-- Modal-->
<div
  class="modal fade"
  id="promissionsAlertModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="text-center">
          <img src="../../../assets/media/error/allert.png" />
          <p class="font-weight-bold font-size-h5 text-dark-75 mt-3">
            you do not have permission to access this functionality.
          </p>
          <p>Please connect with IT Administration</p>
          <!--          <button type="button" class="btn btn-light-primary font-weight-bold" data-dismiss="modal">Close</button>-->
        </div>
      </div>
    </div>
  </div>
</div>
