import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoanService {
  constructor(private http: HttpClient) {}

  private currentStatusLoan = new BehaviorSubject('');
  currentLoanStatus = this.currentStatusLoan.asObservable();

  currentStateOfLoan(value): any {
    this.currentStatusLoan.next(value);
  }

  getbankList(page, per_page, search_string): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/bank_config?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          per_page +
          '&search_string=' +
          search_string,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getbankdata(id): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/bank_config_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addBankData(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/hr/bank_config?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  setDefaultBank(bankDefault, bankId): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/hr/set_default_bank?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          is_default: bankDefault,
          id: bankId,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateBankData(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/hr/bank_config_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getbankALlList(): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_bank_config?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateLoanType(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/hr/loan_type_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addLoanType(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/hr/loan_type?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getLoanTypeList(page, perPage, SearchValue): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/loan_type?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          perPage +
          '&search_string=' +
          SearchValue,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getLoanData(id): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/loan_type_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getLoanType(): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_loan_type?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getSecurityLoanType(): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_security_deduction_type?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  submitloanRequest(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/hr/loan_setting?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  submitSecurityDeductionRequest(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/hr/security_deduction?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getCurrentUserLoanRequestListing(
    page,
    perPage,
    status,
    show_all,
  ): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/loan_setting?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          perPage +
          '&status=' +
          status +
          '&show_all=' +
          show_all,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  SecurityDeductionList(page, perPage, status): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/security_deduction?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          perPage +
          '&status=' +
          status,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  changeLoanStatus(RequestId, status): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/hr/change_state_loan_request?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          RequestId,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          is_active: status,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  changeSecurityStatus(RequestId, status): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/hr/change_state_security_deduction?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          RequestId,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          is_active: status,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateLoanStatus(cancelRequestId, status): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/hr/change_loan_status?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          cancelRequestId,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          status,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getLoanRequestListing(
    page,
    per_page,
    search_string,
    status,
    val,
  ): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_employee_loan_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          per_page +
          '&search_string=' +
          search_string +
          '&status=' +
          status +
          '&hr=' +
          val,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateLeaveStatusValue(
    cancelRequestId,
    status,
    hr_comments,
  ): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/hr/change_loan_status?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          cancelRequestId,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          status,
          hr_comments,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getLoanDetail(leaveId): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/loan_setting_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          leaveId,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateManagerLoanStatusValue(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/hr/loan_setting_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getEmployeeLoanType(id): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_loan_settings?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getEmployeeLoanDetails(is_self, id): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_loan_entitlement_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id +
          '&is_self=' +
          is_self,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getEmployeeDetailForSecurityDeduction(is_self, id): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/employee_detail_for_security_deduction?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id +
          '&is_self=' +
          is_self,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getLoanRequestListForApproval(
    page,
    per_page,
    search_string,
  ): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_loan_request_for_approval?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          page +
          '&per_page=' +
          per_page +
          '&search_string=' +
          search_string,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getLoanRequestForApproval(id): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_loan_request_approval_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  approveLoanRequest(
    remarks,
    status,
    is_external,
    level_number,
    is_internal,
    id,
  ): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/hr/change_loan_approve_status?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          remarks,
          status,
          is_external,
          level_number,
          is_internal,
          id,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  submitFinancialLoanRequest(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/hr/update_approve_loan_screening?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  checkLoanStatus(id): any {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/check_loan_status?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getLoanReport(
    start_date,
    end_date,
    department,
    id,
    status,
    type,
    page,
    per_page,
  ): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_loan_report?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&start_date=' +
          start_date +
          '&end_date=' +
          end_date +
          '&department=' +
          department +
          '&id=' +
          id +
          '&status=' +
          status +
          '&type=' +
          type +
          '&page=' +
          page +
          '&per_page=' +
          per_page,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getLoanTypeActive(): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/get_loan_type?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
