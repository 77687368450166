import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
  OnChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { OpdService } from '../../../../services/opd.service';
import { VitalsValidationService } from '../../../../services/vitals-validation.service';
import { StaffService } from '../../../../services/staff.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../../environments/environment';
import { HospitaldoctorService } from '../../../../services/hospitaldoctor.service';
import { HospitalService } from '../../../../services/hospital.service';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.css'],
})
export class NotesComponent implements OnInit {
  @ViewChild('updateNursingModal') updateNursingModal: ElementRef;
  @ViewChild('updateDoctorNotesModal') updateDoctorNotesModal: ElementRef;

  @Input() patient: any;
  @Input() visitType: any;
  @Input() doctors: any;
  @Input() isAdd: boolean;
  @Input() editMoNursingNotesControl: boolean;
  @Input() isNotesRecieved: number;

  pharmacistForm: FormGroup;
  progressNotesForm: FormGroup;
  updateProgressNotesForm: FormGroup;

  notesPrintList: any = [];
  progressNotesDoctor = '';
  progressNotes = '';
  progressNotesShift = 'Morning';
  notesList: any = [];
  notesListNurse: any = [];
  notesListDoc: any = [];
  notesListPharmacist: any = [];
  submitted = false;
  staffNurses = [];
  updateNursingNotesId: any;
  updateDoctorNotesId: any;
  updatedProgressNotes = '';
  updatedProgressNotesShift = 'Morning';
  updatedProgressNotesDoctor = '';
  isTrue = false;
  settingData: any;
  userPassword = '';
  empId = '';
  doctorEmpId = '';
  doctorUserPassword = '';
  isConfirmedDoctor = false;
  isConfimedNursing = false;

  constructor(
    private formBuilder: FormBuilder,
    private ngxLoader: NgxUiLoaderService,
    private opdService: OpdService,
    private chRef: ChangeDetectorRef,
    private vitalsValidation: VitalsValidationService,
    private staffService: StaffService,
    private modalService: NgbModal,
    private hospitalDoctorsService: HospitaldoctorService,
    private hospitalService: HospitalService,
  ) {}

  ngOnInit(): void {
    this.pharmacistForm = this.formBuilder.group({
      doctor_id: [''],
      investigation_type: 'pharmacist',
      note: ['', Validators.required],
      appropriate_dose: [false],
      appropriate_route: [false],
      appropriate_time: [false],
      fda_approved_drug: [false],
      any_reaction: [false],
    });
    this.progressNotesForm = this.formBuilder.group({
      note: '',
      event: '',
      investigation_type: 'nursing',
      shift: 'Morning',
      handling_over_staff: '',
      receiving_staff: '',
      doctor_id: '',
      vital_attributes: this.formBuilder.group({
        weight: '',
        height: '',
        temperature: '',
        bp_systolic: '',
        bp_diastolic: '',
        pulse: '',
        resp_rate: '',
        o2_saturation: '',
        head_circ: '',
      }),
    });
    this.updateProgressNotesForm = this.formBuilder.group({
      note: '',
      event: '',
      investigation_type: 'nursing',
      shift: 'Morning',
      handling_over_staff: '',
      receiving_staff: '',
      doctor_id: '',
      vital_attributes: this.formBuilder.group({
        weight: '',
        height: '',
        temperature: '',
        bp_systolic: '',
        bp_diastolic: '',
        pulse: '',
        resp_rate: '',
        o2_saturation: '',
        head_circ: '',
      }),
    });

    this.fetchNursingStaff();
    this.getSettingsData();
    this.getProgressNotes('nursing', '');
  }

  ngOnChanges(changes: SimpleChanges): any {
    if (changes.isNotesRecieved?.currentValue) {
      this.getProgressNotes('nursing', '');
    }
    this.getProgressNotes('nursing', changes.patient?.currentValue);
  }

  getSettingsData(): any {
    this.hospitalService.getMedicalUnitDetailsv2().subscribe(
      (data) => {
        this.settingData = data.medical_unit;
      },
      (err) => {},
    );
  }

  showNursingConfirmationModal(): any {
    if (
      this.progressNotesForm.value.vital_attributes.temperature === '' &&
      this.progressNotesForm.value.vital_attributes.bp_systolic === '' &&
      this.progressNotesForm.value.vital_attributes.bp_diastolic === '' &&
      this.progressNotesForm.value.vital_attributes.pulse === '' &&
      this.progressNotesForm.value.vital_attributes.resp_rate === '' &&
      this.progressNotesForm.value.vital_attributes.o2_saturation === '' &&
      this.progressNotesForm.value.vital_attributes.weight === ''
    ) {
      this.progressNotesForm.value.vital_attributes = '';
    }
    if (
      this.vitalsValidation.actionOnVitals(
        this.progressNotesForm.value.vital_attributes,
      ) === false
    ) {
      return;
    }
    $('#nursingNotesModal').modal('show');
    this.empId = '';
    this.userPassword = '';
    this.isConfimedNursing = false;
  }
  showNursingUpdateConfirmationModal(): any {
    if (
      this.vitalsValidation.actionOnVitals(
        this.updateProgressNotesForm.value.vital_attributes,
      ) === false
    ) {
      return;
    }
    $('#nursingNotesModal').modal('show');
    this.empId = '';
    this.userPassword = '';
    this.isConfimedNursing = false;
  }
  showDoctorUpdateConfirmationModal(type, check): any {
    if (check) {
      if (this.progressNotes === '') {
        toastr.error('Add Notes details');
        return;
      }
      if (this.vitalsValidation.actionOnVitals(this.progressNotes) === false) {
        return;
      }
      if (type === '') {
        if (this.progressNotesShift === '') {
          toastr.error('Select Doctor Shift');
          return;
        }
        // if (this.progressNotesDoctor === '') {
        //   toastr.error('Select Doctor');
        //   return;
        // }
      }
    } else {
      if (this.updatedProgressNotes === '') {
        // if (this.updatedProgressNotes === '' || this.updatedProgressNotesDoctor === '') {
        return;
      }
    }
    $('#doctorNotesModal').modal('show');
    this.doctorEmpId = '';
    this.doctorUserPassword = '';
    this.isConfirmedDoctor = false;
  }

  getProgressNotes(status, val): any {
    $('.notesAll').prop('checked', false);
    this.notesPrintList = [];
    if (status === 'doctor') {
      this.progressNotesDoctor = '';
      setTimeout(() => {
        $('.doctorNotes').selectpicker('refresh');
        $('.doctorNotes').selectpicker();
      }, 500);
    }
    if (status === 'pharmacist') {
      this.pharmacistForm.get('doctor_id').setValue('');
      setTimeout(() => {
        $('.doctorNotes').selectpicker('refresh');
        $('.doctorNotes').selectpicker();
      }, 500);
    }
    if (status === 'nursing') {
      setTimeout(() => {
        $('.nursingPicker').selectpicker('refresh');
        $('.nursingPicker').selectpicker();
      }, 500);
    }
    this.progressNotes = '';
    // this.progressNotesDoctor = this.doctors[0].id;
    this.progressNotesShift = 'Morning';
    this.notesList = [];
    this.notesListNurse = [];
    this.notesListDoc = [];
    this.ngxLoader.start();
    if (this.visitType === 'both') {
      this.visitType = '';
    }
    this.opdService
      .getProgressNotesFunV2(this.patient, status, this.visitType)
      .subscribe(
        (data) => {
          this.notesList = data.investigation_notes;
          if (status === 'doctor') {
            this.notesListDoc = data.investigation_notes;
            for (let i = 0; i < this.notesList.length; i++) {
              this.notesListDoc[i].update_doctor_note = this.notesListDoc[i]
                .note
                ? this.notesListDoc[i].note.replace(/(\r\n|\n)/g, '<br/>')
                : '';
            }
          } else if (status === 'nursing') {
            this.notesListNurse = data.investigation_notes;
            for (let i = 0; i < this.notesList.length; i++) {
              this.notesListNurse[i].update_nursing_note = this.notesListNurse[
                i
              ].note
                ? this.notesListNurse[i].note.replace(/(\r\n|\n)/g, '<br/>')
                : '';
              this.notesListNurse[i].update_nursing_event = this.notesListNurse[
                i
              ].event
                ? this.notesListNurse[i].event.replace(/(\r\n|\n)/g, '<br/>')
                : '';
            }
          } else if (status === 'pharmacist') {
            this.notesListPharmacist = data.investigation_notes;
          }
          this.chRef.detectChanges();
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          // toastr.error(err.error.message);
        },
      );
  }

  confirmNursingNotes(): any {
    this.submitted = true;
    if (this.progressNotesForm.invalid) {
      return;
    }
    if (!this.empId) {
      toastr.error('Add Employee Id or User Name');
      return;
    }
    if (!this.userPassword) {
      toastr.error('Add Password');
      return;
    }
    if (!this.isConfimedNursing) {
      toastr.error('Select confirmation');
      return;
    }
    this.ngxLoader.start();
    this.opdService
      .createNurseProgressNotesFunV2(
        this.progressNotesForm.value,
        this.patient,
        this.empId,
        this.userPassword,
        this.isConfimedNursing,
      )
      .subscribe(
        (data) => {
          if (data) {
            this.progressNotesForm.reset({
              shift: 'Morning',
              investigation_type: 'nursing',
            });
          }
          this.getProgressNotes('nursing', '');
          $('#nursingNotesModal').modal('hide');
          toastr.success('Notes add');
          this.progressNotesForm.value.vital_attributes = '';
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  updateHandlingName(value: any): any {
    if (value !== '') {
      const id = Number(value);
      this.progressNotesForm.get('handling_over_staff').patchValue(id);

      setTimeout(() => {
        $('.nursingPicker').selectpicker('refresh');
      }, 100);
    }
  }

  fetchNursingStaff(): any {
    this.staffNurses = [];
    this.staffService.getNursingStaff().subscribe(
      (data) => {
        this.staffNurses = data;
      },
      (err) => {
        toastr.error(err.error.error);
      },
    );
  }

  selectAllNotes(value: boolean): any {
    if (value === true) {
      $('.notesCheckbox').prop('checked', true);
    } else if (value === false) {
      $('.notesCheckbox').prop('checked', false);
    }
  }

  selectNotes(value: any, id: any, type: any): any {
    if (value === true) {
      this.notesPrintList.push(id);
    } else if (value === false) {
      const data = this.notesPrintList.filter((t) => t === id);
      const index = this.notesPrintList.indexOf(data[0]);
      this.notesPrintList.splice(index, 1);
    }
  }

  patchNursingNotes(data: any): any {
    this.updateNursingNotesId = data.id;
    this.submitted = false;
    this.modalService.open(this.updateNursingModal, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });
    this.updateProgressNotesForm.patchValue({
      note: data.note || '',
      event: data.event || '',
      investigation_type: 'nursing',
      shift: data.shift || '',
      handling_over_staff: data.handling_over_staff || '',
      receiving_staff: data.receiving_staff || '',
      // doctor_id: '',
      vital_attributes: {
        weight: data.vital ? data.vital.weight : '',
        height: data.vital ? data.vital.height : '',
        temperature: data.vital ? data.vital.temperature : '',
        bp_systolic: data.vital ? data.vital.bp_systolic : '',
        bp_diastolic: data.vital ? data.vital.bp_diastolic : '',
        pulse: data.vital ? data.vital.pulse : '',
        resp_rate: data.vital ? data.vital.resp_rate : '',
        o2_saturation: data.vital ? data.vital.o2_saturation : '',
        head_circ: data.vital ? data.vital.head_circ : '',
      },
    });
    setTimeout(() => {
      $('.nursingPicker').selectpicker('refresh');
      $('.nursingPicker').selectpicker();
    }, 500);
    this.chRef.detectChanges();
  }

  printPatientNotes(investigationType): any {
    this.notesPrintList = [];
    if (investigationType === 'doctor') {
      if (this.notesListDoc.length > 0) {
        for (let i = 0; i < this.notesListDoc.length; i++) {
          if ($('#doctorNotesCheck' + i).prop('checked') === true) {
            this.notesPrintList.push(this.notesListDoc[i].id);
          }
        }
      }
    }
    if (investigationType === 'nursing') {
      if (this.notesListNurse.length > 0) {
        for (let i = 0; i < this.notesListNurse.length; i++) {
          if ($('#nurseingNotesCheck' + i).prop('checked') === true) {
            this.notesPrintList.push(this.notesListNurse[i].id);
          }
        }
      }
    }
    if (this.notesPrintList.length === 0) {
      toastr.error('Select Notes First');
      return;
    }
    window.open(
      'pdf-reports/patient-notes-report?id=' +
        this.patient.id +
        '&visit_id=' +
        this.patient.visit_id +
        '&mrn=' +
        this.patient.mrn +
        '&invoice_id=' +
        '&investigation_type=' +
        investigationType +
        '&notes_id=' +
        this.notesPrintList +
        '&admission_id=' +
        this.patient.admissions[0].id,
    );
    // window.open(environment.rorUrl + '/v3/investigation_notes/investigation_note_report.pdf?auth_token=' + localStorage.getItem('auth_token') + '&medical_unit_id=' + localStorage.getItem('current_medical_unit_id') + '&investigation_type=' + investigationType + '&patient_id=' + this.patient.mrn + '&notes_id=' + this.notesPrintList + '&admission_id=' + this.patient.admissions[0].id);
    $('.notesCheckbox').prop('checked', false);
    $('.notesAll').prop('checked', false);
    this.notesPrintList = [];
  }

  addDoctorProgressNotes(type): any {
    if (!this.doctorEmpId) {
      toastr.error('Add Employee Id or User Name');
      return;
    }
    if (!this.doctorUserPassword) {
      toastr.error('Add Password');
      return;
    }
    if (!this.isConfirmedDoctor) {
      toastr.error('Select confirmation');
      return;
    }
    let obj = {
      note: this.progressNotes,
      shift: this.progressNotesShift,
      doctor_id: this.progressNotesDoctor,
      investigation_type: 'doctor',
    };
    // doctor_id
    this.ngxLoader.start();
    this.opdService
      .addDoctorProgressNotesFunV2(
        obj,
        this.patient,
        this.doctorEmpId,
        this.doctorUserPassword,
      )
      .subscribe(
        (data) => {
          if (type !== 'main') {
            this.getProgressNotes('doctor', '');
          } else {
            this.progressNotes = '';
            this.progressNotesShift = 'Morning';
            this.progressNotesDoctor = '';
            setTimeout(() => {
              $('.doctorNotes').selectpicker('refresh');
              $('.doctorNotes').selectpicker();
            }, 500);
          }
          $('#doctorNotesModal').modal('hide');
          this.ngxLoader.stop();
          toastr.success('Notes added');
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  patchDoctorNotes(data: any): any {
    if (data) {
      this.updateDoctorNotesId = data.id;
      this.updatedProgressNotes = data.note;
      this.updatedProgressNotesShift = data.shift;
      this.updatedProgressNotesDoctor = '';
      // this.updatedProgressNotesDoctor = data.doctor ? data.doctor.id : '';
    }
    this.modalService.open(this.updateDoctorNotesModal, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });
  }

  submitPharmacist(): any {
    this.isTrue = true;
    if (this.pharmacistForm.invalid) {
      return;
    }
    this.ngxLoader.start();
    this.opdService
      .submitPharmacistV2(this.pharmacistForm.value, this.patient)
      .subscribe(
        (data) => {
          if (data) {
            this.pharmacistForm.reset({
              investigation_type: 'pharmacist',
              doctor_id: [''],
              note: '',
              appropriate_dose: false,
              appropriate_route: false,
              appropriate_time: false,
              fda_approved_drug: false,
              any_reaction: false,
            });
            this.isTrue = false;
          }
          this.getProgressNotes('pharmacist', '');
          toastr.success('pharmacist Notes add');
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  printCurrentScreen(): any {
    window.open(
      'pdf-reports/pharma-notes-report?id=' +
        this.patient.id +
        '&visit_id=' +
        this.patient.visit_id +
        '&mrn=' +
        this.patient.mrn,
    );

    // window.print();
  }

  updateWrittenName(value: any): any {
    if (value !== '') {
      const id = Number(value);
      this.updateProgressNotesForm.get('handling_over_staff').patchValue(id);
      setTimeout(() => {
        $('#updateNursePicker').selectpicker('refresh');
      }, 100);
    }
  }

  updateNursingNotes(): any {
    this.submitted = true;
    if (this.updateProgressNotesForm.invalid) {
      return;
    }
    if (!this.empId) {
      toastr.error('Add Employee Id or User Name');
      return;
    }
    if (!this.userPassword) {
      toastr.error('Add Password');
      return;
    }
    if (!this.isConfimedNursing) {
      toastr.error('Select confirmation');
      return;
    }
    this.ngxLoader.start();
    this.opdService
      .updateNurseProgressNotesFunV2(
        this.updateProgressNotesForm.value,
        this.patient,
        this.updateNursingNotesId,
        this.empId,
        this.userPassword,
        this.isConfimedNursing,
      )
      .subscribe(
        (data) => {
          this.modalService.dismissAll(this.updateNursingModal);
          toastr.success('Update Notes');
          this.getProgressNotes('nursing', '');
          this.updateNursingNotesId = '';
          $('#nursingNotesModal').modal('hide');
          this.updateProgressNotesForm.reset({
            note: '',
            event: '',
            investigation_type: 'nursing',
            shift: '',
            handling_over_staff: '',
            receiving_staff: '',
            doctor_id: '',
          });
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  updateDoctorNotes(): any {
    if (!this.doctorEmpId) {
      toastr.error('Add Employee Id or User Name');
      return;
    }
    if (!this.doctorUserPassword) {
      toastr.error('Add Password');
      return;
    }
    if (!this.isConfirmedDoctor) {
      toastr.error('Select confirmation');
      return;
    }
    let obj = {
      note: this.updatedProgressNotes,
      shift: this.updatedProgressNotesShift,
      doctor_id: '',
      investigation_type: 'doctor',
    };
    this.ngxLoader.start();
    this.opdService
      .updateDoctorProgressNotesFunV2(
        obj,
        this.patient,
        this.updateDoctorNotesId,
        this.doctorEmpId,
        this.doctorUserPassword,
      )
      .subscribe(
        (data) => {
          $('#doctorNotesModal').modal('hide');
          this.modalService.dismissAll(this.updateDoctorNotesModal);
          this.updateDoctorNotesId = '';
          toastr.success('Notes added');
          this.ngxLoader.stop();
          this.getProgressNotes('doctor', '');
          this.updatedProgressNotes = '';
          this.updatedProgressNotesDoctor = 'Morning';
          this.updatedProgressNotesShift = '';
          setTimeout(() => {
            $('.doctorNotes').selectpicker('refresh');
          }, 500);
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
}
