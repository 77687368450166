<div class="d-flex">
  <div class="col-10">
    <div
      class="col-12 d-flex pl-0"
      *ngIf="summaryControls?.show_summary === true"
    >
      <div class="col-6 pl-0">
        <h2
          *ngIf="patientServiceInvoicesDataSummary?.patient"
          class="m-0 line-clamp font-print-bolder mr-5"
        >
          {{ patientServiceInvoicesDataSummary?.patient?.title | titlecase }}
          {{
            patientServiceInvoicesDataSummary?.patient?.full_name | titlecase
          }}
          {{ patientServiceInvoicesDataSummary?.patient?.age + "," }}
          {{ patientServiceInvoicesDataSummary?.patient?.gender | titlecase }}
        </h2>
      </div>
      <div class="col-6 pl-0">
        <h2 class="m-0 font-print-bolder">
          MRN#{{ patientServiceInvoicesDataSummary?.patient?.mrn }}
        </h2>
      </div>
    </div>
    <div class="col-12 d-flex pl-0 patient-info-header-main">
      <div class="col-6 pl-0">
        <table style="width: 100%">
          <tbody>
            <ng-container *ngFor="let data of showContact; let i = index">
              <tr
                *ngIf="
                  data?.name === 'Patient Name' &&
                  data?.alignment === 'left' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.full_name
                "
              >
                <td class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td class="pdf-report-patient-heading-content">
                  <span>{{
                    patientServiceInvoicesData?.full_name | titlecase
                  }}</span>
                  <!--              <span *ngIf="!patientServiceInvoicesData?.full_name">N/A</span>-->
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Passcode' &&
                  data?.alignment === 'left' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.passcode
                "
              >
                <td style="width: 42%" class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td
                  style="width: 58%"
                  class="pdf-report-patient-heading-content"
                >
                  <span>{{ patientServiceInvoicesData?.passcode }}</span>
                  <!--              <span *ngIf="!patientServiceInvoicesData?.full_name">N/A</span>-->
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Referring Doctor' &&
                  data?.alignment === 'left' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.referring_doctor
                "
              >
                <td class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td class="pdf-report-patient-heading-content">
                  <span>{{
                    patientServiceInvoicesData?.referring_doctor
                  }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Bed No' &&
                  data?.alignment === 'left' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.bed_no
                "
              >
                <td class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td class="pdf-report-patient-heading-content">
                  <span>{{ patientServiceInvoicesData?.bed_no }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Age' &&
                  data?.alignment === 'left' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.age
                "
              >
                <td class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td class="pdf-report-patient-heading-content">
                  <span>{{ patientServiceInvoicesData?.age }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Gender' &&
                  data?.alignment === 'left' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.gender
                "
              >
                <td class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td class="pdf-report-patient-heading-content">
                  <span>{{
                    patientServiceInvoicesData?.gender || "N/A" | titlecase
                  }}</span>
                  <!--              <span *ngIf="!patientServiceInvoicesData?.gender">N/A</span>-->
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'MRN' &&
                  data?.alignment === 'left' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.mrn
                "
              >
                <td class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td class="pdf-report-patient-heading-content">
                  <span>{{ patientServiceInvoicesData?.mrn }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Discharge Date' &&
                  data?.alignment === 'left' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.discharge_date
                "
              >
                <td class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td class="pdf-report-patient-heading-content">
                  <span>{{
                    patientServiceInvoicesData?.discharge_date | dateTimeFormat
                  }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Father/Husband' &&
                  data?.alignment === 'left' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.father_name
                "
              >
                <td class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td class="pdf-report-patient-heading-content">
                  <span>{{
                    patientServiceInvoicesData?.father_name | titlecase
                  }}</span>
                  <!--              <span *ngIf="!patientServiceInvoicesData?.father_name">N/A</span>-->
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Patient Type' &&
                  data?.alignment === 'left' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.patient_type?.name
                "
              >
                <td class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td class="pdf-report-patient-heading-content">
                  <span>{{
                    patientServiceInvoicesData?.patient_type?.name
                  }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Contact' &&
                  data?.alignment === 'left' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.phone1
                "
              >
                <td class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td class="pdf-report-patient-heading-content">
                  <span>{{ patientServiceInvoicesData?.phone1 }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Reg Date' &&
                  data?.alignment === 'left' &&
                  data?.is_show === true
                "
              >
                <td class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td class="pdf-report-patient-heading-content">
                  <span>{{
                    patientServiceInvoicesData?.created_at | dateTimeFormat
                  }}</span>
                  <!--                  <span>{{-->
                  <!--                    patientServiceInvoicesData?.created_at-->
                  <!--                      | dateTimeFormat-->
                  <!--                  }}</span>-->
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'CNIC' &&
                  data?.alignment === 'left' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.patient_nic
                "
              >
                <td class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td class="pdf-report-patient-heading-content">
                  <span>{{ patientServiceInvoicesData?.patient_nic }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Visit Date' &&
                  data?.alignment === 'left' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.visit?.created_at
                "
              >
                <td class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td class="pdf-report-patient-heading-content">
                  <span>{{
                    patientServiceInvoicesData?.visit?.created_at
                      | dateTimeFormat
                  }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Patient Address' &&
                  data?.alignment === 'left' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.address1
                "
              >
                <td class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td class="pdf-report-patient-heading-content">
                  <span>{{ patientServiceInvoicesData?.address1 }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Invoice' &&
                  data?.alignment === 'left' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.id
                "
              >
                <td class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}#</span>
                </td>
                <td class="pdf-report-patient-heading-content">
                  <span>{{ patientServiceInvoicesData?.id }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Admission Id' &&
                  data?.alignment === 'left' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.admission_id
                "
              >
                <td class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td class="pdf-report-patient-heading-content">
                  <span>{{ patientServiceInvoicesData?.admission_id }}</span>
                </td>
              </tr>
              <!--          <tr *ngIf="data?.name === 'Ward' && data?.alignment === 'left' && data?.is_show === true">-->
              <!--            <td  class="pdf-report-Patient-heading">-->
              <!--              <span>{{data?.heading}}:</span>-->
              <tr
                *ngIf="
                  data?.name === 'Ward' &&
                  data?.alignment === 'left' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.ward_name
                "
              >
                <td class="pdf-report-Patient-heading">
                  <span
                    ><strong>{{ data?.heading }}:</strong></span
                  >
                </td>
                <td class="pdf-report-patient-heading-content">
                  <span>{{ patientServiceInvoicesData?.ward_name }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Category' &&
                  data?.alignment === 'left' &&
                  data?.is_show === true
                "
              >
                <td class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td class="pdf-report-patient-heading-content">
                  <span>{{
                    patientServiceInvoicesData?.patient_category
                  }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Clinic' &&
                  data?.alignment === 'left' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.clinic_name
                "
              >
                <td class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td class="pdf-report-patient-heading-content">
                  <span>{{ patientServiceInvoicesData?.clinic_name }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Time In' &&
                  data?.alignment === 'left' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.time_in
                "
              >
                <td class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td class="pdf-report-patient-heading-content">
                  <span>{{
                    patientServiceInvoicesData?.time_in | dateTimeFormat
                  }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Time Out' &&
                  data?.alignment === 'left' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.time_out
                "
              >
                <td class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td class="pdf-report-patient-heading-content">
                  <span>{{
                    patientServiceInvoicesData?.time_out | dateTimeFormat
                  }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Sub department' &&
                  data?.alignment === 'left' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.sub_department
                "
              >
                <td class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td class="pdf-report-patient-heading-content">
                  <span>{{ patientServiceInvoicesData?.sub_department }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Sample location' &&
                  data?.alignment === 'left' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.lab_order_detail
                    ?.sample_taken_from
                "
              >
                <td class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td class="pdf-report-patient-heading-content">
                  <span>{{
                    patientServiceInvoicesData?.lab_order_detail
                      ?.sample_taken_from
                  }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Sample Collection date & time' &&
                  data?.alignment === 'left' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.lab_order_detail?.collecting_time
                "
              >
                <td class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td class="pdf-report-patient-heading-content">
                  <span>{{
                    patientServiceInvoicesData?.lab_order_detail
                      ?.collecting_time | dateTimeFormat
                  }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Sample Received Date & Time' &&
                  data?.alignment === 'left' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.lab_order_detail?.receiving_time
                "
              >
                <td class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td class="pdf-report-patient-heading-content">
                  <span>{{
                    patientServiceInvoicesData?.lab_order_detail?.receiving_time
                      | dateTimeFormat
                  }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Reference # ' &&
                  data?.alignment === 'left' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.lab_order_detail?.reference_no
                "
              >
                <td class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td class="pdf-report-patient-heading-content">
                  <span>{{
                    patientServiceInvoicesData?.lab_order_detail?.reference_no
                  }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Created At Date & Time' &&
                  data?.alignment === 'left' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.patient_services?.created_at
                "
              >
                <td class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td class="pdf-report-patient-heading-content">
                  <span>{{
                    patientServiceInvoicesData?.patient_services?.created_at
                      | dateTimeFormat
                  }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Accession #' &&
                  data?.alignment === 'left' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.patient_services?.accession_number
                "
              >
                <td class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td class="pdf-report-patient-heading-content">
                  <span>{{
                    patientServiceInvoicesData?.patient_services
                      ?.accession_number
                  }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Reported At Date & time' &&
                  data?.alignment === 'left' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.patient_services?.finalsign_date
                "
              >
                <td class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td class="pdf-report-patient-heading-content">
                  <span>{{
                    patientServiceInvoicesData?.patient_services?.finalsign_date
                      | dateTimeFormat
                  }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Performed At date & time' &&
                  data?.alignment === 'left' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.patient_services
                    ?.acknowledged_date
                "
              >
                <td class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td class="pdf-report-patient-heading-content">
                  <span>{{
                    patientServiceInvoicesData?.patient_services
                      ?.acknowledged_date | dateTimeFormat
                  }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Requested on Date & time' &&
                  data?.alignment === 'left' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.operation_request?.created_at
                "
              >
                <td class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td class="pdf-report-patient-heading-content">
                  <span>{{
                    patientServiceInvoicesData?.operation_request?.created_at
                      | dateTimeFormat
                  }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Request #' &&
                  data?.alignment === 'left' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.operation_request?.id
                "
              >
                <td class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td class="pdf-report-patient-heading-content">
                  <span>{{
                    patientServiceInvoicesData?.operation_request?.id
                  }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Reporting Time' &&
                  data?.alignment === 'left' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.reporting_time
                "
              >
                <td class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td class="pdf-report-patient-heading-content">
                  <span>{{
                    patientServiceInvoicesData?.reporting_time | dateTimeFormat
                  }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Operation Req.Date' &&
                  data?.alignment === 'left' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.operation_request?.created_at
                "
              >
                <td class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td class="pdf-report-patient-heading-content">
                  <span>{{
                    patientServiceInvoicesData?.operation_request?.created_at
                      | dateTimeFormat
                  }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Operation ID' &&
                  data?.alignment === 'left' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.operation_request?.id
                "
              >
                <td class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td class="pdf-report-patient-heading-content">
                  <span>{{
                    patientServiceInvoicesData?.operation_request?.id
                  }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Company' &&
                  data?.alignment === 'left' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.company
                "
              >
                <td class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td class="pdf-report-patient-heading-content">
                  <span>{{ patientServiceInvoicesData?.company }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Group' &&
                  data?.alignment === 'left' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.group
                "
              >
                <td class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td class="pdf-report-patient-heading-content">
                  <span>{{ patientServiceInvoicesData?.group }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'City' &&
                  data?.alignment === 'left' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.city
                "
              >
                <td class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td class="pdf-report-patient-heading-content">
                  <span>{{ patientServiceInvoicesData?.city }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'MLC ' &&
                  data?.alignment === 'left' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.mlc
                "
              >
                <td class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td class="pdf-report-patient-heading-content">
                  <span>{{ patientServiceInvoicesData?.mlc }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Department ' &&
                  data?.alignment === 'left' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.department
                "
              >
                <td class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td class="pdf-report-patient-heading-content">
                  <span>{{ patientServiceInvoicesData?.department }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Document ID' &&
                  data?.alignment === 'left' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.document_id
                "
              >
                <td class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td class="pdf-report-patient-heading-content">
                  <span>{{ patientServiceInvoicesData?.document_id }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Consultant' &&
                  data?.alignment === 'left' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.consultant
                "
              >
                <td class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td class="pdf-report-patient-heading-content">
                  <span>{{ patientServiceInvoicesData?.consultant }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Token No' &&
                  data?.alignment === 'left' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.token_number
                "
              >
                <td class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td class="pdf-report-patient-heading-content">
                  <span>{{ patientServiceInvoicesData?.token_number }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Discharge Type' &&
                  data?.alignment === 'left' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.discharge_type
                "
              >
                <td class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td class="pdf-report-patient-heading-content">
                  <span>{{ patientServiceInvoicesData?.discharge_type }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Admission Date' &&
                  data?.alignment === 'left' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.admission_date
                "
              >
                <td class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td class="pdf-report-patient-heading-content">
                  <span>{{
                    patientServiceInvoicesData?.admission_date | dateTimeFormat
                  }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Date Of Birth' &&
                  data?.alignment === 'left' &&
                  data?.is_show === true &&
                  birthDate
                "
              >
                <td class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td class="pdf-report-patient-heading-content">
                  <span>{{ birthDate | dateFormat }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'LAB Report Verified At' &&
                  data?.alignment === 'left' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.lab_order_detail
                    ?.verification_time
                "
              >
                <td class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td class="pdf-report-patient-heading-content">
                  <span>{{
                    patientServiceInvoicesData?.lab_order_detail
                      ?.verification_time | dateTimeFormat
                  }}</span>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
      <div class="col-6 pl-0">
        <table style="width: 100%">
          <tbody>
            <ng-container *ngFor="let data of showContact; let i = index">
              <tr
                *ngIf="
                  data?.name === 'Patient Name' &&
                  data?.alignment === 'right' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.full_name
                "
              >
                <td style="width: 42%" class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td
                  style="width: 58%"
                  class="pdf-report-patient-heading-content"
                >
                  <span>{{
                    patientServiceInvoicesData?.full_name | titlecase
                  }}</span>
                  <!--              <span *ngIf="!patientServiceInvoicesData?.full_name">N/A</span>-->
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Passcode' &&
                  data?.alignment === 'right' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.passcode
                "
              >
                <td style="width: 42%" class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td
                  style="width: 58%"
                  class="pdf-report-patient-heading-content"
                >
                  <span>{{ patientServiceInvoicesData?.passcode }}</span>
                  <!--              <span *ngIf="!patientServiceInvoicesData?.full_name">N/A</span>-->
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Referring Doctor' &&
                  data?.alignment === 'right' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.referring_doctor
                "
              >
                <td style="width: 42%" class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}</span>
                </td>
                <td
                  style="width: 58%"
                  class="pdf-report-patient-heading-content"
                >
                  <span>{{
                    patientServiceInvoicesData?.referring_doctor
                  }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Bed No' &&
                  data?.alignment === 'right' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.bed_no
                "
              >
                <td style="width: 42%" class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td
                  style="width: 58%"
                  class="pdf-report-patient-heading-content"
                >
                  <span>{{ patientServiceInvoicesData?.bed_no }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Age' &&
                  data?.alignment === 'right' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.age
                "
              >
                <td style="width: 42%" class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td
                  style="width: 58%"
                  class="pdf-report-patient-heading-content"
                >
                  <span>{{ patientServiceInvoicesData?.age }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Discharge Date' &&
                  data?.alignment === 'right' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.discharge_date
                "
              >
                <td class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td class="pdf-report-patient-heading-content">
                  <span>{{
                    patientServiceInvoicesData?.discharge_date | dateTimeFormat
                  }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Gender' &&
                  data?.alignment === 'right' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.gender
                "
              >
                <td style="width: 42%" class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td
                  style="width: 58%"
                  class="pdf-report-patient-heading-content"
                >
                  <span>{{
                    patientServiceInvoicesData?.gender | titlecase
                  }}</span>
                  <!--              <span *ngIf="!patientServiceInvoicesData?.gender">N/A</span>-->
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'MRN' &&
                  data?.alignment === 'right' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.mrn
                "
              >
                <td style="width: 42%" class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td
                  style="width: 58%"
                  class="pdf-report-patient-heading-content"
                >
                  <span>{{ patientServiceInvoicesData?.mrn }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Father/Husband' &&
                  data?.alignment === 'right' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.father_name
                "
              >
                <td style="width: 42%" class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td
                  style="width: 58%"
                  class="pdf-report-patient-heading-content"
                >
                  <span>{{
                    patientServiceInvoicesData?.father_name | titlecase
                  }}</span>
                  <!--              <span *ngIf="!patientServiceInvoicesData?.father_name">N/A</span>-->
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Patient Type' &&
                  data?.alignment === 'right' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.patient_type?.name
                "
              >
                <td style="width: 42%" class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td
                  style="width: 58%"
                  class="pdf-report-patient-heading-content"
                >
                  <span>{{
                    patientServiceInvoicesData?.patient_type?.name
                  }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Contact' &&
                  data?.alignment === 'right' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.phone1
                "
              >
                <td style="width: 42%" class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td
                  style="width: 58%"
                  class="pdf-report-patient-heading-content"
                >
                  <span>{{ patientServiceInvoicesData?.phone1 }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Reg Date' &&
                  data?.alignment === 'right' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.created_at
                "
              >
                <td style="width: 42%" class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td
                  style="width: 58%"
                  class="pdf-report-patient-heading-content"
                >
                  <span>{{
                    patientServiceInvoicesData?.created_at | dateTimeFormat
                  }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'CNIC' &&
                  data?.alignment === 'right' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.patient_nic
                "
              >
                <td style="width: 42%" class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td
                  style="width: 58%"
                  class="pdf-report-patient-heading-content"
                >
                  <span>{{ patientServiceInvoicesData?.patient_nic }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Visit Date' &&
                  data?.alignment === 'right' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.visit?.created_at
                "
              >
                <td style="width: 42%" class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td
                  style="width: 58%"
                  class="pdf-report-patient-heading-content"
                >
                  <span>{{
                    patientServiceInvoicesData?.visit?.created_at
                      | dateTimeFormat
                  }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Patient Address' &&
                  data?.alignment === 'right' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.address1
                "
              >
                <td style="width: 42%" class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td
                  style="width: 58%"
                  class="pdf-report-patient-heading-content"
                >
                  <span>{{ patientServiceInvoicesData?.address1 }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Invoice' &&
                  data?.alignment === 'right' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.invoice_id
                "
              >
                <td style="width: 42%" class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}#</span>
                </td>
                <td
                  style="width: 58%"
                  class="pdf-report-patient-heading-content"
                >
                  <span>{{ patientServiceInvoicesData?.invoice_id }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Admission Id' &&
                  data?.alignment === 'right' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.admission_id
                "
              >
                <td style="width: 42%" class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td
                  style="width: 58%"
                  class="pdf-report-patient-heading-content"
                >
                  <span>{{ patientServiceInvoicesData?.admission_id }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Ward' &&
                  data?.alignment === 'right' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.ward_name
                "
              >
                <td style="width: 42%" class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td
                  style="width: 58%"
                  class="pdf-report-patient-heading-content"
                >
                  <span>{{ patientServiceInvoicesData?.ward_name }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Category' &&
                  data?.alignment === 'right' &&
                  data?.is_show === true
                "
              >
                <td style="width: 42%" class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td
                  style="width: 58%"
                  class="pdf-report-patient-heading-content"
                >
                  <span>{{
                    patientServiceInvoicesData?.patient_category
                  }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Clinic' &&
                  data?.alignment === 'right' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.clinic_name
                "
              >
                <td style="width: 42%" class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td
                  style="width: 58%"
                  class="pdf-report-patient-heading-content"
                >
                  <span>{{ patientServiceInvoicesData?.clinic_name }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Time In' &&
                  data?.alignment === 'right' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.time_in
                "
              >
                <td style="width: 42%" class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td
                  style="width: 58%"
                  class="pdf-report-patient-heading-content"
                >
                  <span>{{
                    patientServiceInvoicesData?.time_in | dateTimeFormat
                  }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Time Out' &&
                  data?.alignment === 'right' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.time_out
                "
              >
                <td style="width: 42%" class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td
                  style="width: 58%"
                  class="pdf-report-patient-heading-content"
                >
                  <span>{{
                    patientServiceInvoicesData?.time_out | dateTimeFormat
                  }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Sub department' &&
                  data?.alignment === 'right' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.sub_department
                "
              >
                <td style="width: 42%" class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td
                  style="width: 58%"
                  class="pdf-report-patient-heading-content"
                >
                  <span>{{ patientServiceInvoicesData?.sub_department }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Sample location' &&
                  data?.alignment === 'right' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.lab_order_detail
                    ?.sample_taken_from
                "
              >
                <td style="width: 42%" class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td
                  style="width: 58%"
                  class="pdf-report-patient-heading-content"
                >
                  <span>{{
                    patientServiceInvoicesData?.lab_order_detail
                      ?.sample_taken_from
                  }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Sample Collection date & time' &&
                  data?.alignment === 'right' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.lab_order_detail?.collecting_time
                "
              >
                <td style="width: 42%" class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td
                  style="width: 58%"
                  class="pdf-report-patient-heading-content"
                >
                  <span>{{
                    patientServiceInvoicesData?.lab_order_detail
                      ?.collecting_time | dateTimeFormat
                  }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Sample Received Date & Time' &&
                  data?.alignment === 'right' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.lab_order_detail?.receiving_time
                "
              >
                <td style="width: 42%" class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td
                  style="width: 58%"
                  class="pdf-report-patient-heading-content"
                >
                  <span>{{
                    patientServiceInvoicesData?.lab_order_detail?.receiving_time
                      | dateTimeFormat
                  }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Reference # ' &&
                  data?.alignment === 'right' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.lab_order_detail?.reference_no
                "
              >
                <td style="width: 42%" class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td
                  style="width: 58%"
                  class="pdf-report-patient-heading-content"
                >
                  <span>{{
                    patientServiceInvoicesData?.lab_order_detail?.reference_no
                  }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Created At Date & Time' &&
                  data?.alignment === 'right' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.patient_services?.created_at
                "
              >
                <td style="width: 42%" class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td
                  style="width: 58%"
                  class="pdf-report-patient-heading-content"
                >
                  <span>{{
                    patientServiceInvoicesData?.patient_services?.created_at
                      | dateTimeFormat
                  }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Accession #' &&
                  data?.alignment === 'right' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.patient_services?.accession_number
                "
              >
                <td style="width: 42%" class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td
                  style="width: 58%"
                  class="pdf-report-patient-heading-content"
                >
                  <span>{{
                    patientServiceInvoicesData?.patient_services
                      ?.accession_number
                  }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Reported At Date & time' &&
                  data?.alignment === 'right' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.patient_services?.finalsign_date
                "
              >
                <td style="width: 42%" class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td
                  style="width: 58%"
                  class="pdf-report-patient-heading-content"
                >
                  <span>{{
                    patientServiceInvoicesData?.patient_services?.finalsign_date
                      | dateTimeFormat
                  }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Performed At date & time' &&
                  data?.alignment === 'right' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.patient_services
                    ?.acknowledged_date
                "
              >
                <td style="width: 42%" class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td
                  style="width: 58%"
                  class="pdf-report-patient-heading-content"
                >
                  <span>{{
                    patientServiceInvoicesData?.patient_services
                      ?.acknowledged_date | dateTimeFormat
                  }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Requested on Date & time' &&
                  data?.alignment === 'right' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.operation_request?.created_at
                "
              >
                <td style="width: 42%" class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td
                  style="width: 58%"
                  class="pdf-report-patient-heading-content"
                >
                  <span>{{
                    patientServiceInvoicesData?.operation_request?.created_at
                      | dateTimeFormat
                  }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Request #' &&
                  data?.alignment === 'right' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.operation_request?.id
                "
              >
                <td style="width: 42%" class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td
                  style="width: 58%"
                  class="pdf-report-patient-heading-content"
                >
                  <span>{{
                    patientServiceInvoicesData?.operation_request?.id
                  }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Reporting Time' &&
                  data?.alignment === 'right' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.reporting_time
                "
              >
                <td style="width: 42%" class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td
                  style="width: 58%"
                  class="pdf-report-patient-heading-content"
                >
                  <span>{{
                    patientServiceInvoicesData?.reporting_time | dateTimeFormat
                  }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Operation Req.Date' &&
                  data?.alignment === 'right' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.operation_request?.created_at
                "
              >
                <td style="width: 42%" class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td
                  style="width: 58%"
                  class="pdf-report-patient-heading-content"
                >
                  <span>{{
                    patientServiceInvoicesData?.operation_request?.created_at
                      | dateTimeFormat
                  }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Operation ID' &&
                  data?.alignment === 'right' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.operation_request?.id
                "
              >
                <td style="width: 42%" class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td
                  style="width: 58%"
                  class="pdf-report-patient-heading-content"
                >
                  <span>{{
                    patientServiceInvoicesData?.operation_request?.id
                  }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Company' &&
                  data?.alignment === 'right' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.company
                "
              >
                <td style="width: 42%" class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td
                  style="width: 58%"
                  class="pdf-report-patient-heading-content"
                >
                  <span>{{ patientServiceInvoicesData?.company }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Group' &&
                  data?.alignment === 'right' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.group
                "
              >
                <td style="width: 42%" class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td
                  style="width: 58%"
                  class="pdf-report-patient-heading-content"
                >
                  <span>{{ patientServiceInvoicesData?.group }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'City' &&
                  data?.alignment === 'right' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.city
                "
              >
                <td style="width: 42%" class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td
                  style="width: 58%"
                  class="pdf-report-patient-heading-content"
                >
                  <span>{{ patientServiceInvoicesData?.city }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'MLC ' &&
                  data?.alignment === 'right' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.mlc
                "
              >
                <td style="width: 42%" class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td
                  style="width: 58%"
                  class="pdf-report-patient-heading-content"
                >
                  <span>{{ patientServiceInvoicesData?.mlc }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Department ' &&
                  data?.alignment === 'right' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.department
                "
              >
                <td style="width: 42%" class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td
                  style="width: 58%"
                  class="pdf-report-patient-heading-content"
                >
                  <span>{{ patientServiceInvoicesData?.department }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Document ID' &&
                  data?.alignment === 'right' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.document_id
                "
              >
                <td style="width: 42%" class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td
                  style="width: 58%"
                  class="pdf-report-patient-heading-content"
                >
                  <span>{{ patientServiceInvoicesData?.document_id }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Consultant' &&
                  data?.alignment === 'right' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.consultant
                "
              >
                <td style="width: 42%" class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td
                  style="width: 58%"
                  class="pdf-report-patient-heading-content"
                >
                  <span>{{ patientServiceInvoicesData?.consultant }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Token No' &&
                  data?.alignment === 'right' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.token_number
                "
              >
                <td style="width: 42%" class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td
                  style="width: 58%"
                  class="pdf-report-patient-heading-content"
                >
                  <span>{{ patientServiceInvoicesData?.token_number }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Discharge Type' &&
                  data?.alignment === 'right' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.discharge_type
                "
              >
                <td style="width: 42%" class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td
                  style="width: 58%"
                  class="pdf-report-patient-heading-content"
                >
                  <span>{{ patientServiceInvoicesData?.discharge_type }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Admission Date' &&
                  data?.alignment === 'right' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.admission_date
                "
              >
                <td class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td class="pdf-report-patient-heading-content">
                  <span>{{
                    patientServiceInvoicesData?.admission_date | dateTimeFormat
                  }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'Date Of Birth' &&
                  data?.alignment === 'right' &&
                  data?.is_show === true &&
                  birthDate
                "
              >
                <td class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td class="pdf-report-patient-heading-content">
                  <span>{{ birthDate | dateFormat }}</span>
                </td>
              </tr>
              <tr
                *ngIf="
                  data?.name === 'LAB Report Verified At' &&
                  data?.alignment === 'right' &&
                  data?.is_show === true &&
                  patientServiceInvoicesData?.lab_order_detail
                    ?.verification_time
                "
              >
                <td class="pdf-report-Patient-heading">
                  <span>{{ data?.heading }}:</span>
                </td>
                <td class="pdf-report-patient-heading-content">
                  <span>{{
                    patientServiceInvoicesData?.lab_order_detail
                      ?.verification_time | dateTimeFormat
                  }}</span>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="col-2">
    <div>
      <td style="width: 15%; vertical-align: top">
        <div
          class="text-right mb-2"
          *ngIf="summaryControls?.show_patient_picture === true"
        >
          <img
            class="patientPic"
            *ngIf="patientServiceInvoicesDataSummary?.patient?.profile_image"
            [src]="patientServiceInvoicesDataSummary?.patient?.profile_image"
            alt=""
          />
        </div>
        <div
          class="text-right mb-2"
          *ngIf="summaryControls?.show_patient_thumb_scan === true"
        >
          <img
            class="patientThumbPic"
            *ngIf="patientServiceInvoicesDataSummary?.patient?.thumb_expression"
            [src]="patientServiceInvoicesDataSummary?.patient?.thumb_expression"
            alt=""
          />
        </div>
        <div class="text-right" *ngIf="summaryControls?.show_qrcode === true">
          <img
            class="qrCode"
            *ngIf="patientServiceInvoicesDataSummary?.patient?.qrcode_url"
            [src]="patientServiceInvoicesDataSummary?.patient?.qrcode_url"
            alt=""
          />
        </div>
        <div class="text-right" *ngIf="summaryControls?.show_barcode === true">
          <img
            class="brCode"
            *ngIf="patientServiceInvoicesDataSummary?.patient?.barcode_url"
            [src]="patientServiceInvoicesDataSummary?.patient?.barcode_url"
            alt=""
          />
        </div>
        <div class="text-right" *ngIf="summaryControls?.show_mrn === true">
          <h2 class="mt-1 mr-1 font-print-bolder">
            {{ patientServiceInvoicesDataSummary?.patient?.mrn }}
          </h2>
        </div>
      </td>
    </div>
  </div>
</div>
