import { Component, OnInit } from '@angular/core';
import { LabTestService } from '../../../../services/lab-test.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { range } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { PdfServicesService } from '../../../../services/pdf-services.service';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-lab-report-pdf',
  templateUrl: './lab-report-pdf.component.html',
  styleUrls: ['./lab-report-pdf.component.css'],
})
export class LabReportPdfComponent implements OnInit {
  pageDataList = [];
  selectedId: number;
  footerPageData: any;
  headerPageData: any;
  showHeader = '';
  showFooter = '';
  settingData: any;
  labControlsData: any;
  headerType = -0;
  footerType = -0;
  checkApiCall = {
    LabReport: false,
    settingsData: false,
    webControl: false,
    headerControl: false,
    footerControl: false,
    patientData: false,
    SummaryControl: false,
  };
  extractedPath: any;
  headerImageType = '1';
  footerImageType = '1';

  constructor(
    private labTestService: LabTestService,
    private ngxLoader: NgxUiLoaderService,
    private route: ActivatedRoute,
    private pdfService: PdfServicesService,
    private ngxService: NgxUiLoaderService,
    private router: Router,
  ) {
    this.route.queryParams.subscribe((params) => {
      this.selectedId = params['lab_id'];
      this.showHeader = params['show_header'] || '';
      this.headerImageType = params['header_type'] || '1';
      this.showFooter = params['show_footer'] || '';
      this.footerImageType = params['footer_type'] || '1';
    });
    const currentUrl = this.router.url;
    this.extractedPath = this.extractPath(currentUrl);
  }
  extractPath(url: string): any {
    const urlWithoutQueryParams = url.split('?')[0];
    const cleanedUrl = urlWithoutQueryParams.startsWith('/')
      ? urlWithoutQueryParams.substring(1)
      : urlWithoutQueryParams;
    return cleanedUrl;
  }
  ngOnInit(): void {
    this.getLabReport();
    this.getSettingsData();
    this.getWebControls();
  }
  getSettingsData(): any {
    this.pdfService.getMedicalUnitDetailsV2().subscribe(
      (data) => {
        this.settingData = data.medical_unit;
        if (this.settingData) {
          this.checkApiCall.settingsData = true;
          this.allApiSuccess(this.checkApiCall);
        }
      },
      (err) => {
        toastr.error(err.error.error);
      },
    );
  }

  getLabReport(): any {
    this.pageDataList = [];
    this.ngxLoader.start();
    this.labTestService
      .labOrderReport(this.selectedId, this.showHeader, this.showFooter)
      .subscribe(
        (data) => {
          // this.pageDataList = data.report_data;
          this.footerPageData = data.footer_data;
          this.headerPageData = data.header_data;
          if (data.report_data && data.footer_data && data.header_data) {
            this.checkApiCall.LabReport = true;
            this.allApiSuccess(this.checkApiCall);
          }
          this.ngxLoader.stop();
          const groupedData = this.groupBy(data.report_data, 'report_name');
          const transformedResult = Object.keys(groupedData).map(
            (groupKey) => ({
              name: groupKey,
              value: groupedData[groupKey],
            }),
          );
          this.pageDataList = transformedResult;
          console.log('pageDataList', this.pageDataList);
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  groupBy(array, key): any {
    return array.reduce((acc, obj) => {
      const keyValue = obj[key];
      if (!acc[keyValue]) {
        acc[keyValue] = [];
      }
      acc[keyValue].push(obj);
      return acc;
    }, {});
  }
  getWebControls(): any {
    this.ngxService.start();
    this.labTestService.fetchLabsControlsForLabV2().subscribe(
      (data) => {
        this.labControlsData = data;
        if (this.showHeader === '') {
          this.showHeader = this.labControlsData.is_header.toString();
        }
        if (this.showFooter === '') {
          this.showFooter = this.labControlsData.is_footer.toString();
        }
        if (this.labControlsData) {
          this.checkApiCall.webControl = true;
          this.allApiSuccess(this.checkApiCall);
        }
        this.headerControlsArea();
        this.footerControlsArea();
      },
      (err) => {
        this.ngxService.stop();
        toastr.error(err.error.error);
      },
    );
  }

  headerControlsArea(): any {
    if (this.showHeader === 'false') {
      this.headerType = 1;
    }
    if (
      this.showHeader === 'true' &&
      this.labControlsData.is_header_img === true
    ) {
      this.headerType = 0;
    }
    if (
      this.showHeader === 'true' &&
      this.labControlsData.is_header_img === false
    ) {
      this.headerType = 2;
    }
    this.checkApiCall.headerControl = true;
    this.allApiSuccess(this.checkApiCall);
  }
  footerControlsArea(): any {
    if (this.showFooter === 'false') {
      this.footerType = 1;
    }
    if (
      this.showFooter === 'true' &&
      this.labControlsData.is_footer_img === true
    ) {
      this.footerType = 0;
    }
    if (
      this.showFooter === 'true' &&
      this.labControlsData.is_footer_img === false
    ) {
      this.footerType = 2;
    }
    this.checkApiCall.footerControl = true;
    this.allApiSuccess(this.checkApiCall);
  }
  getPatient(event): any {
    this.checkApiCall.patientData = true;
    this.allApiSuccess(this.checkApiCall);
  }
  getSummaryControl(event): any {
    this.checkApiCall.SummaryControl = true;
    this.allApiSuccess(this.checkApiCall);
  }
  allApiSuccess(obj): any {
    if (
      obj.LabReport &&
      obj.settingsData &&
      obj.webControl &&
      obj.headerControl &&
      obj.footerControl &&
      obj.patientData &&
      obj.SummaryControl
    ) {
      setTimeout(() => {
        if (window.innerWidth < 768) {
          window.print();
        }
      }, 500);
    }
  }
  printReport(): any {
    window.print();
  }
}
