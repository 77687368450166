import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';
import { ShareDataService } from '../../../services/share-data.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from '../../../../environments/environment';
import { ConfigurationService } from '../../../services/configuration.service';

declare var $: any;
declare var toastr: any;
@Component({
  selector: 'app-menu-overview',
  templateUrl: './menu-overview.component.html',
  styleUrls: ['./menu-overview.component.css'],
})
export class MenuOverviewComponent implements OnInit {
  currentMenus: any;
  colors = [
    '#BFDDE3',
    '#F3D6EF',
    '#F6E5CA',
    '#f3dfd4',
    '#d4c9a6',
    '#ebcfaa',
    '#eee2c9',
    '#efdba7',
    '#ead8bb',
    '#ffe2c7',
    '#a6fbb2',
    '#bee7a5',
    '#e9edbd',
  ];
  localStorageScreenData: any;
  userData: any;

  constructor(
    private router: Router,
    private shareDataService: ShareDataService,
    private ngxLoader: NgxUiLoaderService,
    private configService: ConfigurationService,
  ) {}

  ngOnInit(): void {
    this.shareDataService.currentMessage.subscribe((message) => {
      this.currentMenus = message;
      // console.log('currentMenus', this.currentMenus);
      this.shuffleArray();
    });
  }
  shuffleArray(): any {
    for (let i = 0; i < this.colors.length; i++) {
      const randomNum = Math.floor(Math.random() * this.colors.length);
      let tempName = '';
      const currentName = this.colors[i];
      const randomName = this.colors[randomNum];
      // swap
      tempName = currentName;
      this.colors[i] = randomName;
      this.colors[randomNum] = tempName;
    }
  }
  addToFav(screen_id, bookmarked, i, j): any {
    bookmarked = !bookmarked;
    this.ngxLoader.start();
    this.configService.addToFav(screen_id, bookmarked).subscribe(
      (data) => {
        this.ngxLoader.stop();
        this.currentMenus.role_menu_sub_menus[i].role_menu_sub_menu_screens[
          j
        ].screen.is_bookmarked = bookmarked;
        const dataScreen = localStorage.getItem('currentScreen');
        let localStorageScreenData = JSON.parse(dataScreen);
        localStorageScreenData.role_menu_sub_menus[
          i
        ].role_menu_sub_menu_screens[j].screen.is_bookmarked = bookmarked;
        localStorage.setItem(
          'currentScreen',
          JSON.stringify(localStorageScreenData),
        );

        const userData = localStorage.getItem('currentUser');
        this.userData = JSON.parse(userData);
        const currentUserArray = this.userData.role.role_menus.findIndex(
          (t) => t.id === localStorageScreenData.id,
        );
        console.log('currentUserArray', currentUserArray);
        this.userData.role.role_menus[currentUserArray].role_menu_sub_menus[
          i
        ].role_menu_sub_menu_screens[j].screen.is_bookmarked = bookmarked;
        localStorage.setItem('currentUser', JSON.stringify(this.userData));
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.err.message);
      },
    );
  }
  currentNavBar(screen, menu): any {
    console.log('menu', menu.closure);
    if (menu.closure === true) {
      $('#promissionsAlertModal').modal('show');
      return;
    }
    if (screen.url_type === 'new_url' || screen.url_type === 'both') {
      // console.log('screen', screen);
      this.router.navigate([screen.url]);
    } else {
      var url =
        environment.oldAdminUrl +
        '/user-verification?user_id=' +
        window.localStorage.getItem('auth_token') +
        '&redirecturl=' +
        screen.url;
      console.log('url', url);
      window.open(url, '_blank');
    }
  }
}
