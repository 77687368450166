<div
  class="modal"
  id="imageModal"
  tabindex="-1"
  role="dialog"
  data-backdrop="static"
  data-keyboard="false"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content w-100">
      <div class="modal-header">
        <h5 class="modal-title">Images</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="example-preview">
          <ul class="nav nav-tabs" id="myTab1" role="tablist">
            <ng-container *ngIf="isDisplayImage">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="display-file-tab-1"
                  data-toggle="tab"
                  href="#home-1"
                >
                  <span class="nav-icon">
                    <i class="flaticon2-chat-1"></i>
                  </span>
                  <span class="nav-text">Display Tab</span>
                </a>
              </li>
            </ng-container>
            <ng-container *ngIf="isUploadImage">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="file-upload-tab-1"
                  data-toggle="tab"
                  href="#profile-1"
                  aria-controls="profile"
                >
                  <span class="nav-icon">
                    <i class="flaticon2-layers-1"></i>
                  </span>
                  <span class="nav-text">File Upload</span>
                </a>
              </li>
            </ng-container>
          </ul>
          <div class="tab-content" id="myTabContent1">
            <ng-container *ngIf="isDisplayImage">
              <div
                class="tab-pane fade show active"
                id="home-1"
                role="tabpanel"
                aria-labelledby="file-upload-tab-1"
              >
                <div class="silkSliderMain">
                  <ng-container *ngFor="let img of receivedImages">
                    <ng-container *ngIf="fileURL; else notFileUrl">
                      <div>
                        <img
                          class="w-100"
                          [src]="img.file"
                          width="100%"
                          height="450"
                          style="min-height: 400px"
                        />
                        <div
                          *ngIf="documentType === 'document'"
                          class="row ml-0 mt-5"
                        >
                          <a
                            class="btn btn-primary"
                            (click)="openDocuments(img?.file)"
                            >Download</a
                          >
                        </div>
                      </div>
                    </ng-container>
                    <ng-template #notFileUrl>
                      <div>
                        <img class="w-100" [src]="baseUrl + img.file" />
                      </div>
                    </ng-template>
                  </ng-container>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="isUploadImage">
              <div
                class="tab-pane fade show active"
                id="profile-1"
                role="tabpanel"
                aria-labelledby="display-file-tab-1"
              >
                <div class="d-flex flex-column w-100">
                  <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                    <label>Input Files</label>
                    <input
                      type="file"
                      multiple=""
                      class="marginInput"
                      class="form-control"
                      id="document"
                      [accept]="
                        documentType === 'document' ? '' : '.png, .jpg, .jpeg'
                      "
                      (change)="fileChangeEvent($event)"
                    />
                  </div>
                  <div>
                    <ng-container *ngFor="let doc of docs; let i = index">
                      <div class="d-flex justify-content-between">
                        <div class="ml-5">{{ doc.name }}</div>
                        <div>
                          <button
                            class="btn btn-sm mb-2 p-0 mr-5"
                            type="button"
                            (click)="removeFileObject(i)"
                          >
                            <i class="icon text-hover-danger fas fa-trash"></i>
                          </button>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <!--        <a class="col-lg-6 col-md-6 col-sm-6 btn tab-button" (click)="fileTab()"><h4>Select File</h4></a>-->
        <!--        <a class="col-lg-6 col-md-6 col-sm-6 btn tab-button" (click)="showSlider()"><h4>View Files</h4></a>-->
      </div>
      <div class="modal-footer">
        <button
          type="button"
          *ngIf="isUploadImage"
          class="btn btn-sm btn-primary"
          (click)="FileUploadToComponent()"
        >
          {{
            documentType === "document" ? "Upload Documents" : "Upload Images"
          }}
        </button>
        <button type="button" class="btn btn-secondary" (click)="closeModal()">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
<button
  type="button"
  id="isUploadImage"
  *ngIf="isUploadImage"
  class="btn btn-sm btn-primary mr-3"
  (click)="openImageModalUpload()"
>
  {{ documentType === "document" ? "Upload Documents" : "Upload Images" }}
</button>
<button
  type="button"
  id="isDisplayImage"
  *ngIf="isDisplayImage"
  class="btn btn-sm btn-primary"
  (click)="openImageModalDisplay()"
>
  Display Images
</button>
