import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { DataSharingServiceService } from '../../../services/data-sharing-service.service';
import { environment } from '../../../../environments/environment';

declare var $: any;

@Component({
  selector: 'app-upload-images',
  templateUrl: './upload-images.component.html',
  styleUrls: ['./upload-images.component.css'],
})
export class UploadImagesComponent implements OnInit, AfterViewInit {
  linkOne: any;
  linktwo: any;
  docs = [];
  tabStatus = true;
  @Input() clearModal: boolean;
  @Input() isDisplayImage: boolean;
  @Input() isUploadImage: boolean;
  @Input() receivedImageFiles: any = [];
  @Input() isArrayList: boolean;
  @Input() documentType: any;
  @Output() imageData: EventEmitter<string[]> = new EventEmitter();
  receivedImages = [];
  isUpload = false;
  isData = false;
  fileURL = false;
  baseUrl: any;

  constructor(private data: DataSharingServiceService) {}

  ngOnInit(): void {
    this.baseUrl = environment.pythonUrl;
    // this.baseUrl = environment.pythonUrl;
    console.log(this.baseUrl);
    $('#imageModal').on('hide.bs.modal', () => {
      if (!this.receivedImageFiles) {
        this.isDisplayImage = false;
      }
      if (this.isArrayList) {
        this.isDisplayImage = true;
        this.isUploadImage = true;
      }
    });
  }

  ngAfterViewInit() {}

  openImageModalDisplay(): any {
    this.isDisplayImage = true;
    this.isUploadImage = false;
    this.receivedImages = [];
    this.receivedImages = this.receivedImageFiles;
    if (this.receivedImages[0].file.includes('http')) {
      this.fileURL = true;
    }
    $('#imageModal').modal('show');
    setTimeout(() => {
      $('.silkSliderMain').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        dots: true,
        infinite: false,
        nextArrow:
          "<button type='button' class='slick-next mr-15 btn-arrow'><i class='fa fa-angle-right btn-icon ' ></i></button>",
        autoplaySpeed: 2000,
        prevArrow:
          "<button type='button' class='slick-prev ml-15 btn-arrow'><i class='fa fa-angle-left btn-icon ' ></i></button>",
      });
    }, 500);
  }

  openImageModalUpload(): any {
    this.isDisplayImage = false;
    this.isUploadImage = true;
    $('#imageModal').modal('show');
  }

  fileChangeEvent(event: any) {
    for (let i = 0; i < event.target.files.length; i++) {
      this.docs.push(event.target.files[i]);
      if (event.target.files.length === i + 1) {
        $('#document').val('');
      }
    }
  }

  removeFileObject(index: any) {
    this.docs.splice(index, 1);
  }

  FileUploadToComponent(): any {
    this.isDisplayImage = false;
    this.isUploadImage = true;
    this.data.imageFiles = this.docs;
    this.imageData.emit(this.docs);
    if (this.clearModal) {
      this.docs = [];
    }
    $('#imageModal').modal('hide');
    if (!this.receivedImageFiles) {
      this.isDisplayImage = false;
    }

    if (this.isArrayList) {
      this.isDisplayImage = true;
      this.isUploadImage = true;
    }
  }
  closeModal(): any {
    $('#imageModal').modal('hide');
  }

  openDocuments(url): any {
    window.open(url);
  }
}
