import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from '../../../../environments/environment';
import { DialysisService } from '../../../services/dialysis.service';
import * as moment from 'moment';
import { RouteShareService } from '../../../services/route-share.service';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-reports-reprint-v2',
  templateUrl: './reports-reprint-v2.component.html',
  styleUrls: ['./reports-reprint-v2.component.css'],
})
export class ReportsReprintV2Component implements OnInit {
  current_environment: string;
  searchFilterForm: FormGroup;
  page = 1;
  totalPages: any;
  perPage = 25;
  pageDataList = [];
  filterType = 'All';
  screenName = '';
  reportID = '';
  isConfimed = false;
  isConfirmationDisabled = false;

  constructor(
    private formBuilder: FormBuilder,
    private ngxLoader: NgxUiLoaderService,
    private dialysisService: DialysisService,
    private ref: ChangeDetectorRef,
    private routeSevice: RouteShareService,
  ) {
    this.screenName = this.routeSevice.getValue();
  }

  ngOnInit(): void {
    this.current_environment = environment.rorUrl;
    this.searchFilterForm = this.formBuilder.group({
      from_date: ['', Validators.required],
      to_date: ['', Validators.required],
      patient_id: [''],
    });
    setTimeout(() => {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      $('#fromDate').datetimepicker({
        defaultDate: yesterday,
        format: 'DD-MM-YYYY h:mm a',
        autoClose: true,
      });
      const previousDateTime = moment(yesterday).format('DD-MM-YYYY h:mm a');
      $('#fromDate').val(previousDateTime);
      $('#toDate').datetimepicker({
        defaultDate: new Date(),
        format: 'DD-MM-YYYY h:mm a',
      });
      const current_time = moment().format('DD-MM-YYYY h:mm a');
      $('#toDate').val(current_time);
      this.searchInvoices();
    }, 1000);
  }

  searchInvoices(): any {
    this.searchFilterForm.patchValue({
      from_date: $('#fromDate').val(),
      to_date: $('#toDate').val(),
      type: '',
    });
    this.page = 1;
    this.perPage = 25;
    this.searchFilter();
  }
  searchFilter(): any {
    this.pageDataList = [];
    this.ngxLoader.start();
    this.dialysisService
      .getDeliveredReportsV2(
        this.page,
        this.perPage,
        this.searchFilterForm.value,
      )
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          if (data.meta) {
            this.totalPages = data.meta.total_count;
          }
          if (data.dialysis_patients) {
            this.pageDataList = data.dialysis_patients;
          } else {
            this.pageDataList = [];
          }
          this.ref.markForCheck();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  selectPaginationLimit(value): any {
    this.perPage = value;
    if (value === '') {
      this.perPage = this.totalPages;
      this.page = 1;
    }
    this.searchFilter();
  }
  loadPage(event): any {
    this.searchFilter();
  }
  printReport(element): any {
    // window.open(
    //   this.current_environment +
    //   '/v4/dialysis_patients/report.pdf?auth_token=' +
    //   localStorage.getItem('auth_token') +
    //   '&medical_unit_id=' +
    //   localStorage.getItem('current_medical_unit_id') +
    //   '&id=' +
    //   id,
    // );
    window.open(
      'dialysis/dialysis-report?patient_id=' +
        element.patient.id +
        '&mrn=' +
        element.patient.mrn +
        '&dialysis_id=' +
        element.id,
    );
  }
  confirmreport(id): any {
    this.isConfimed = false;
    this.reportID = id;
    console.log('reportID', this.reportID);
    $('#confirmationModal').modal('show');
  }
  confirmDialysis(): any {
    if (this.isConfimed) {
      this.isConfirmationDisabled = true;
      this.ngxLoader.start();
      this.dialysisService.createDialysisReport(this.reportID).subscribe(
        (data) => {
          this.ngxLoader.stop();
          toastr.success(' Successfully Returned');
          $('#confirmationModal').modal('hide');
          this.isConfirmationDisabled = false;
          this.searchInvoices();
        },
        (error) => {
          this.ngxLoader.stop();
          this.isConfirmationDisabled = false;
          toastr.error(error.error.message);
        },
      );
    } else {
      toastr.error('Select Confirmation');
    }
  }
}
