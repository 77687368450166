<form [formGroup]="DialysisServicesForm" (ngSubmit)="createDialysisService()">
  <div class="row">
    <!--      <div class="col-lg-3 col-md-3 col-sm-6 col-12">-->
    <!--        <div class="form-group">-->
    <!--          <label>Department <span class="text-danger">*</span></label>-->
    <!--          <div  [ngClass]="{ 'is-invalid': submitted && f.parent_department_id.errors }">-->
    <!--            <select class="form-control form-control-sm departmentSelectpicker" data-live-search="true"-->
    <!--                    formControlName="parent_department_id"-->
    <!--                    (change)=parent_department()>-->
    <!--              <option value="" [selected]=true [disabled]="true">Select department</option>-->
    <!--              <ng-container *ngFor="let department of departments">-->
    <!--                <option *ngIf="department.department_code === 'Dialysis'"-->
    <!--                        [value]="department.id">{{department.title}}</option>-->
    <!--              </ng-container>-->
    <!--            </select>-->
    <!--          </div>-->

    <!--          &lt;!&ndash; <div *ngIf="submitted && f.parent_department_id.errors" class="invalid-feedback">-->
    <!--            <div *ngIf="f.parent_department_id.errors.required">Department is required</div>-->
    <!--          </div> &ndash;&gt;-->
    <!--        </div>-->
    <!--      </div>-->

    <div class="col-lg-4 col-md-4 col-sm-6 col-12">
      <div class="form-group">
        <label>Sub Department <span class="text-danger">*</span></label>
        <div [ngClass]="{ 'is-invalid': submitted && f.department_id.errors }">
          <select
            class="form-control form-control-sm subDepartmentSelectpicker"
            data-live-search="true"
            formControlName="department_id"
            data-none-selected-text="Select Department"
            (change)="departmentsServices()"
          >
            <option value="" [selected]="true" [disabled]="true">
              Select Department
            </option>
            <option
              [value]="sub_department.id"
              *ngFor="let sub_department of sub_departments"
            >
              {{ sub_department.title }}
            </option>
          </select>
        </div>
        <!-- <div *ngIf="submitted && f.department_id.errors" class="invalid-feedback">
            <div *ngIf="f.department_id.errors.required">Sub Department is required</div>
          </div> -->
      </div>
    </div>

    <!-- <div class="col-lg-3 col-md-3 col-sm-6 col-12">
        <div class="form-group">
          <label>Service</label>
          <select  class="form-control form-control-sm" formControlName="department_service_id">
            <option value="" [selected]=true [disabled]="true">Select service</option>
            <option [value]="department_service.id" *ngFor="let department_service of departmentServices">{{department_service.name}}</option>
          </select>
        </div>
      </div> -->

    <div class="col-lg-4 col-md-4 col-sm-6 col-12">
      <div class="form-group">
        <label>Name <span class="text-danger">*</span></label>
        <input
          type="text"
          formControlName="service_name"
          class="form-control form-control-sm"
          placeholder="Enter Service Name"
          [ngClass]="{ 'is-invalid': submitted && f.service_name.errors }"
        />
      </div>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-6 col-12" *ngIf="!currentId">
      <div class="form-group">
        <label> Price <span class="text-danger">*</span></label>
        <input
          type="text"
          class="form-control form-control-sm"
          [ngClass]="{ 'is-invalid': submitted && f.service_price.errors }"
          placeholder="Price"
          formControlName="service_price"
          (keypress)="_onlyNumeric($event); noDecimalPoint($event)"
        />
      </div>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-6 col-12" *ngIf="!currentId">
      <label
        >Replicate Service price On<span class="text-danger">*</span></label
      >
      <div class="form-group">
        <select
          class="form-control form-control-sm bootstrap-select-container"
          multiple
          data-live-search="true"
          data-container="body"
          id="patient_types"
          formControlName="replicate_service_pat_types"
          data-none-selected-text="Select Patient Type"
        >
          <!--                    <select  autofocus class="form-control form-control-sm" formControlName="copy_patient_type_id">-->
          <option value="" [disabled]="true">Select Patient Type</option>
          <option
            [value]="patient_type.id"
            *ngFor="let patient_type of patientTypes"
          >
            {{ patient_type.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-6 col-12 mt-6 align-self-center">
      <div class="form-group">
        <label class="checkbox checkbox-sm">
          <input type="checkbox" formControlName="is_active" />
          <span class="mr-2"></span>Status
        </label>
      </div>
    </div>
    <div style="padding: 0px 12.5px" *ngIf="!updateDialysis">
      <label class="checkbox checkbox-sm mt-6 mb-6">
        <input
          type="checkbox"
          [(ngModel)]="isChecked"
          [ngModelOptions]="{ standalone: true }"
        />
        <span class="mr-2"></span>Do you want to apply the same service price on
        selected Patient type?
      </label>
    </div>
  </div>

  <div class="modal-footer p-0">
    <div class="row text-right">
      <div class="col-12" style="margin-top: 7px">
        <button
          type="button"
          class="btn btn-secondary btn-sm mr-2"
          data-dismiss="modal"
        >
          Close
        </button>
        <button
          type="button"
          *ngIf="!updateDialysis"
          (click)="clear()"
          class="btn btn-primary btn-sm"
        >
          Clear
        </button>
        <button
          type="submit"
          *ngIf="!updateDialysis"
          [disabled]="disableSubmit"
          class="btn btn-primary btn-sm ml-2"
          accesskey="s"
        >
          Submit
        </button>
        <button
          type="button"
          *ngIf="updateDialysis"
          [disabled]="disableSubmit"
          class="btn btn-primary btn-sm ml-2"
          (click)="updateDialysisService()"
        >
          Update
        </button>
      </div>
    </div>
  </div>
</form>
