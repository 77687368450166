<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--begin::Page Heading-->
        <div class="d-flex align-items-baseline flex-wrap mr-5">
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">Menu Overview</h5>
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->
    </div>
  </div>
  <!--end::Subheader-->

  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!--    container-xl container-xxl-->
    <div class="container-fluid">
      <!--begin::Dashboard-->

      <div class="row">
        <div class="col-md-8 col-sm-12 col-12">
          <!--          <div class="card card-custom gutter-b">-->
          <!--            <div class="card-body pt-2 pb-0 mt-3">-->
          <div class="row">
            <div class="card-columns">
              <ng-container
                *ngFor="
                  let menu of currentMenus?.role_menu_sub_menus;
                  let i = index
                "
              >
                <div
                  class="mr-4 pb-2 card card-custom gutter-b"
                  style="height: fit-content"
                  [ngStyle]="{ 'background-color': colors[i] }"
                >
                  <div class="card-body pt-2 pb-0 px-2 mt-3">
                    <div class="col-sm-12">
                      <h5>{{ menu?.name }}</h5>

                      <ng-container
                        *ngFor="
                          let menuItem of menu?.role_menu_sub_menu_screens;
                          let j = index
                        "
                      >
                        <ng-container
                          *ngIf="menuItem.screen?.is_display === true"
                        >
                          <!--                          menu-link-->

                          <a class="d-flex justify-content-between borderList">
                            <ul class="styles">
                              <li class="listStyle cursor-pointer">
                                <span
                                  (click)="
                                    currentNavBar(menuItem.screen, menuItem)
                                  "
                                  class="pointerColor"
                                  >{{ menuItem.name }}</span
                                >
                              </li>
                            </ul>
                            <span
                              class="ml-4 cursor-pointer"
                              (click)="
                                addToFav(
                                  menuItem?.screen_id,
                                  menuItem?.screen.is_bookmarked,
                                  i,
                                  j
                                )
                              "
                              ><i
                                style="font-size: small"
                                class="fas fa-star"
                                [ngClass]="{
                                  filled: menuItem?.screen.is_bookmarked
                                }"
                              ></i
                            ></span>
                          </a>
                        </ng-container>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <!--              </div>-->
          <!--          </div>-->
        </div>

        <div class="col-md-4 col-sm-12 col-12">
          <div class="card card-custom gutter-b">
            <!--begin::Header-->
            <div class="card-header border-0 pt-5">
              <h3 class="card-title align-items-start flex-column">
                <span class="card-label font-weight-bolder text-dark"
                  >Department Notifications</span
                >
              </h3>
            </div>
            <!--end::Header-->
            <!--begin::Body-->
            <div class="card-body pt-2 pb-0 mt-3">
              <div claas="">
                <div class="table-responsive"><br /><br /></div>
              </div>
            </div>
            <!--end::Body-->
          </div>

          <div class="card card-custom gutter-b">
            <!--begin::Header-->
            <div class="card-header border-0">
              <h3 class="card-title font-weight-bolder text-dark">
                Live Tech Support
              </h3>
            </div>
            <!--end::Header-->
            <!--begin::Body-->
            <div class="card-body pt-0">
              <!--begin::Item-->
              <br />
            </div>
            <!--end: Card Body-->
          </div>
        </div>
      </div>

      <!--end::Dashboard-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Entry-->
</div>
<!--end::Content-->

<div
  class="modal fade"
  id="promissionsAlertModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="text-center">
          <img src="../../../assets/media/error/allert.png" />
          <p class="font-weight-bold font-size-h5 text-dark-75 mt-3">
            you do not have permission to access this functionality.
          </p>
          <p>Please connect with IT Administration</p>
          <!--          <button type="button" class="btn btn-light-primary font-weight-bold" data-dismiss="modal">Close</button>-->
        </div>
      </div>
    </div>
  </div>
</div>
