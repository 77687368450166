import { Component, Input, OnInit } from '@angular/core';

declare var $: any;
@Component({
  selector: 'app-summernotes',
  templateUrl: './summernotes.component.html',
  styleUrls: ['./summernotes.component.css'],
})
export class SummernotesComponent implements OnInit {
  @Input() id: string;
  @Input() htmlData: string;
  constructor() {}

  ngOnInit(): void {
    setTimeout(() => {
      $('#' + this.id).html(this.htmlData);
    }, 100);
  }
}
