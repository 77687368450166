<!--begin::Content-->
<div
  class="contentin d-flex flex-column flex-column-fluid"
  id="kt_content"
  style="padding-top: 20px !important"
>
  <!--begin::Subheader-->
  <div
    class="subheader py-2 py-lg-6 subheader-solid"
    id="kt_subheader"
    style="z-index: 105"
  >
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--begin::Mobile Toggle-->
        <button
          class="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
          id="kt_subheader_mobile_toggle"
          [ngClass]="{
            'pointer-events-no': patient === null || patient === undefined
          }"
        >
          <span></span>
        </button>
        <!--end::Mobile Toggle-->
        <!--begin::Page Heading-->
        <div class="d-flex align-items-baseline flex-wrap mr-5">
          <!--begin::Page Title-->
          <!--          <h5 class="text-dark font-weight-bold my-1 mr-5">IPD</h5>-->
          <!--end::Page Title-->
          <app-patient-details
            (changeBed)="changeBed()"
            [patient]="patient"
            [vitalWeight]="vitalWeight"
          ></app-patient-details>
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->
      <!--begin::Toolbar-->
      <div class="d-flex align-items-center mt-2 mt-sm-0 mt-md-0">
        <!--begin::Actions-->
        <!--        <a  class="btn btn-light-primary font-weight-bolder btn-sm">Actions</a>-->
        <!--end::Actions-->

        <!--begin::Dropdown-->

        <!--end::Dropdown-->
        <!--begin::Patient-->
        <a
          class="btn btn-light-primary font-weight-bolder btn-sm"
          *ngIf="!otRequestedId"
          (click)="openPatientList()"
          >Patients</a
        >
        <a
          *ngIf="otRequestedId"
          class="btn btn-light-primary font-weight-bolder btn-sm"
          style="width: 145px"
          (click)="goToOtSurgery()"
          >Switch to OT</a
        >
        <!--end::Patient-->
      </div>
      <!--end::Toolbar-->
    </div>
  </div>
  <div
    class="container-fluid d-flex flex-row"
    [ngClass]="{
      'pointer-events-no': patient === null || patient === undefined
    }"
  >
    <div
      class="flex-row-auto offcanvas-mobile w-180px w-xxl-220px"
      id="kt_profile_aside"
    >
      <!--begin::Profile Card-->
      <div class="card card-custom card-stretch">
        <div class="card-body user-card-body pt-4">
          <!--begin::Toolbar-->
          <div class="d-flex justify-content-end"></div>
          <!--end::Toolbar-->

          <!--begin::Nav-->
          <div
            class="navi navi-bold navi-hover navi-active navi-link-rounded"
            role="tablist"
          >
            <div class="navi-item mb-2" *ngIf="mainControl === true">
              <a
                class="navi-link py-4 active"
                data-toggle="pill"
                id="MainTabs"
                href="#Main"
                (click)="toggleActive('Main')"
              >
                <span class="navi-text font-size-lg">Main</span>
              </a>
            </div>

            <div class="navi-item mb-2" *ngIf="vitalsControl === true">
              <a
                class="navi-link py-4"
                id="VitalsTabs"
                data-toggle="pill"
                href="#Vitals"
                (click)="toggleActive('Vitals')"
              >
                <span class="navi-text font-size-lg">Vitals</span>
              </a>
            </div>

            <div class="navi-item mb-2" *ngIf="consultationControl === true">
              <a
                class="navi-link py-4"
                data-toggle="pill"
                id="ConsultationsTabs"
                href="#Consultation"
                (click)="toggleActive('Consultation')"
              >
                <span class="navi-text font-size-lg">Consultation</span>
              </a>
            </div>

            <div class="navi-item mb-2 d-none">
              <a
                class="navi-link py-4"
                data-toggle="pill"
                id="HistoryTabs"
                href="#HistoryTaking"
                (click)="toggleActive('HistoryTaking')"
              >
                <span class="navi-text font-size-lg">History1</span>
              </a>
            </div>

            <!--                [routerLink]="['/ipd/history/' + patient?.mrn]"-->
            <div
              class="navi-item mb-2"
              *ngIf="patient && historyControl === true"
            >
              <a
                class="navi-link py-4"
                data-toggle="pill"
                (click)="toHistory()"
              >
                <span class="navi-text font-size-lg">History</span>
              </a>
            </div>

            <div class="navi-item mb-2" *ngIf="diagnosisControl === true">
              <a
                class="navi-link py-4"
                data-toggle="pill"
                id="DiagnosisTabs"
                href="#Diagnosis"
                (click)="toggleActive('Diagnosis')"
              >
                <span class="navi-text font-size-lg">Diagnosis</span>
              </a>
            </div>

            <div class="navi-item mb-2" *ngIf="medicinesControl === true">
              <a
                class="navi-link py-4"
                data-toggle="pill"
                id="MedicinesTabs"
                href="#Medicines"
                (click)="toggleActive('Medicines')"
              >
                <span class="navi-text font-size-lg">Medicines</span>
              </a>
            </div>
            <div
              class="navi-item mb-2"
              *ngIf="nursingProcedureControl === true"
            >
              <a
                class="navi-link py-4"
                data-toggle="pill"
                id="ProcedureTabs"
                href="#Nursing"
                (click)="toggleActive('Nursing')"
              >
                <span class="navi-text font-size-lg">Nursing Procedure</span>
              </a>
            </div>

            <div class="navi-item mb-2" *ngIf="servicesControl === true">
              <a
                class="navi-link py-4"
                data-toggle="pill"
                id="ServicesTabs"
                href="#Services"
                (click)="toggleActive('Services')"
              >
                <span class="navi-text font-size-lg">Services</span>
              </a>
            </div>

            <div class="navi-item mb-2" *ngIf="referralControl === true">
              <a
                class="navi-link py-4"
                data-toggle="pill"
                id="ReferralTabs"
                href="#Referral"
                (click)="toggleActive('Referral')"
              >
                <span class="navi-text font-size-lg">Referral</span>
              </a>
            </div>

            <div class="navi-item mb-2" *ngIf="vaccinationControl === true">
              <a
                class="navi-link py-4"
                data-toggle="pill"
                id="VaccinationTabs"
                href="#Vaccination"
                (click)="toggleActive('Vaccination')"
              >
                <span class="navi-text font-size-lg">Vaccination</span>
              </a>
            </div>

            <div
              class="navi-item mb-2"
              *ngIf="
                patient?.gender === 'female' && birthCertificateControl === true
              "
            >
              <a
                class="navi-link py-4"
                data-toggle="pill"
                id="BirthCertificateTabs"
                href="#BirthCertificate"
                (click)="toggleActive('BirthCertificate')"
              >
                <span class="navi-text font-size-lg">Birth Certificate</span>
              </a>
            </div>

            <div class="navi-item mb-2" *ngIf="dischargeControl === true">
              <a
                class="navi-link py-4"
                data-toggle="pill"
                id="DischargeTabs"
                href="#Discharge"
                (click)="toggleActive('Discharge')"
              >
                <span class="navi-text font-size-lg">Discharge</span>
              </a>
            </div>

            <div class="navi-item mb-2" *ngIf="notesControl === true">
              <a
                class="navi-link py-4"
                data-toggle="pill"
                id="NotesTabs"
                href="#Notes"
                (click)="toggleActive('Notes')"
              >
                <span class="navi-text font-size-lg">Notes</span>
              </a>
            </div>

            <div class="navi-item mb-2" *ngIf="consentFormControl === true">
              <a
                class="navi-link py-4"
                data-toggle="pill"
                id="AnesthesiaTabs"
                href="#Anesthesia"
                (click)="toggleActive('Anesthesia')"
              >
                <span class="navi-text font-size-lg">Consent Forms</span>
              </a>
            </div>

            <!--                <div class="navi-item mb-2">-->
            <!--                  <a class="navi-link py-4" data-toggle="pill" id="OperationTabs" href="#Operation"-->
            <!--                     (click)="toggleActive('Operation');fetchAnesthesiaTemplates('operation_template');fetchPatientOperationConsent();getWidth()">-->
            <!--                    <span class="navi-text font-size-lg">Operation Form</span>-->
            <!--                  </a>-->
            <!--                </div>-->

            <div
              class="navi-item mb-2"
              *ngIf="operationRequestControl === true"
            >
              <a
                class="navi-link py-4"
                data-toggle="pill"
                id="OperationRequestTabs"
                href="#OperationRequest"
                (click)="toggleActive('OperationRequest')"
              >
                <span class="navi-text font-size-lg">Operation Request</span>
              </a>
            </div>

            <div class="navi-item mb-2" *ngIf="inTakeOutPutControl === true">
              <a
                class="navi-link py-4"
                data-toggle="pill"
                id="InOutTakeTabs"
                href="#InOutTake"
                (click)="toggleActive('InOutTake')"
              >
                <span class="navi-text font-size-lg"
                  >In-Take/Out-Put Chart</span
                >
              </a>
            </div>
            <div class="navi-item mb-2" *ngIf="glasgowComaControl === true">
              <a
                class="navi-link py-4"
                data-toggle="pill"
                id="glascoComaTab"
                href="#Glasco"
                (click)="toggleActive('Glasco')"
              >
                <span class="navi-text font-size-lg">Glasgow Coma</span>
              </a>
            </div>
            <div class="navi-item mb-2" *ngIf="documentsControl === true">
              <a
                class="navi-link py-4"
                data-toggle="pill"
                href="#myDocs"
                (click)="toggleActive('myDocs')"
              >
                <span class="navi-text font-size-lg">Documents</span>
              </a>
            </div>
            <div class="navi-item mb-2" *ngIf="bloodSugarControl === true">
              <a
                class="navi-link py-4"
                data-toggle="pill"
                href="#bloodSugar"
                (click)="toggleActive('bloodSugar')"
              >
                <span class="navi-text font-size-lg">Blood Sugar</span>
              </a>
            </div>
          </div>
          <!--end::Nav-->

          <!--              close navbar on mobile-->
          <button
            id="kt_user_profile_aside_close"
            type="button"
            style="visibility: hidden"
          ></button>
        </div>
      </div>
    </div>
    <div class="flex-row-fluid ml-lg-5">
      <div class="tab-content h-100">
        <div id="Vitals" class="tab-pane fade">
          <div *ngIf="patient && isVitalsShow">
            <app-vitals
              [patient]="patient"
              [isAdd]="true"
              [isVitalsUpdate]="isVitalsUpdate"
              (isVitalsRecieved)="PatientsDataChenges($event)"
            ></app-vitals>
          </div>
        </div>
        <div id="Consultation" class="tab-pane fade">
          <div *ngIf="patient && isConsultationShow">
            <app-consultations
              [patient]="patient"
              [doctor]="doctor"
              [doctors]="doctors"
              [isAdd]="true"
              [isConsultationReceived]="isConsultationUpdate"
              [isipd]="true"
              [status]="'IPD'"
            ></app-consultations>
          </div>
        </div>
        <div id="Medicines" class="tab-pane fade">
          <div *ngIf="patient && isMedicinesShow">
            <app-medicines
              [patient]="patient"
              [isAdd]="true"
              [isipd]="true"
              [type]="'IPD'"
            ></app-medicines>
          </div>
        </div>
        <div id="Services" class="tab-pane fade">
          <div *ngIf="patient && isServiceShow">
            <app-services
              [patient]="patient"
              [doctor]="doctor"
              [isAdd]="true"
              [showMsg]="showMsg"
              [isopd]="false"
              [isReq]="true"
              [isServicesUpdate]="isServicesUpdate"
              (isServicesRecieved)="PatientsServicesChenges($event)"
            ></app-services>
          </div>
        </div>
        <div id="Diagnosis" class="tab-pane fade">
          <div *ngIf="patient && isDiagnosisShow">
            <app-diagnosis
              [patient]="patient"
              [isAdd]="true"
              [isDiagnosisUpdate]="isDiagnosisUpdate"
              (isDiagnosisRecieved)="PatientsDiagnosisChenges($event)"
            ></app-diagnosis>
          </div>
        </div>
        <div id="Vaccination" class="tab-pane fade">
          <div *ngIf="patient && isVaccinationShow">
            <app-vaccination
              [patient]="patient"
              [isAdd]="true"
            ></app-vaccination>
          </div>
        </div>
        <div id="BirthCertificate" class="tab-pane fade">
          <div *ngIf="patient?.gender === 'female' && isBirthCertificateShow">
            <app-birth-certificate
              [patient]="patient"
              [doctors]="doctor"
            ></app-birth-certificate>
          </div>
        </div>
        <div id="Notes" class="tab-pane fade">
          <div *ngIf="patient && isNotesShow">
            <app-notes
              [patient]="patient"
              [doctors]="doctor"
              [isAdd]="true"
              [isNotesRecieved]="isNotesUpdate"
              [editMoNursingNotesControl]="editMoNursingNotesControl"
              [visitType]="'IPD'"
            ></app-notes>
          </div>
        </div>
        <div id="Nursing" class="tab-pane fade">
          <div *ngIf="patient && isNursingShow">
            <app-nursing-procedure
              [patient]="patient"
              [isAdd]="true"
            ></app-nursing-procedure>
          </div>
        </div>
        <div id="OperationRequest" class="tab-pane fade">
          <div *ngIf="patient && isOperationRequestShow">
            <app-operation-request
              [patient]="patient"
              [oRDoctors]="doctor"
              [isAdd]="true"
            ></app-operation-request>
          </div>
        </div>
        <div id="Glasco" class="tab-pane fade">
          <div *ngIf="patient && isGlascoShow">
            <app-glasgow-coma [patient]="patient"></app-glasgow-coma>
          </div>
        </div>
        <div id="InOutTake" class="tab-pane fade">
          <div *ngIf="patient && isInOutTakeShow">
            <app-intake-output-chart
              [patient]="patient"
              [isAdd]="true"
            ></app-intake-output-chart>
          </div>
        </div>
        <div id="myDocs" class="tab-pane fade">
          <div *ngIf="patient && ismyDocsShow">
            <app-documents [patient]="patient" [isAdd]="true"></app-documents>
          </div>
        </div>
        <div id="bloodSugar" class="tab-pane fade">
          <div *ngIf="patient && isbloodSugarShow">
            <app-blood-sugar
              [patient]="patient"
              [isAdd]="true"
            ></app-blood-sugar>
          </div>
        </div>
        <div id="Discharge" class="tab-pane fade">
          <div *ngIf="patient && isDischargeShow">
            <app-discharge
              [patient]="patient"
              [doctors]="doctor"
              (isPatientRefresh)="PatientRefresh($event)"
              (isPatientDischargId)="resetPatientDisId($event)"
            ></app-discharge>
          </div>
        </div>
        <div id="Referral" class="tab-pane fade">
          <div *ngIf="patient && isReferralShow">
            <app-referral></app-referral>
          </div>
        </div>
        <div id="Anesthesia" class="tab-pane fade">
          <div *ngIf="patient && isAnesthesiaShow">
            <app-consent-form
              [patient]="patient"
              [doctor]="doctor"
              [isAdd]="true"
            ></app-consent-form>
          </div>
        </div>
        <div id="Main" class="tab-pane active">
          <div *ngIf="isMainShow && mainControl">
            <app-main
              [patient]="patient"
              [doctor]="doctor"
              [resetMainData]="resetMainData"
              [doctors]="doctor"
              [refdoctor]="doctor"
              [isopd]="false"
              [isReq]="true"
              [isMainVitalsUpdate]="isMainVitalsUpdate"
              [isMainServicesUpdate]="isMainServicesUpdate"
              [isNotesUpdate]="isNotesUpdate"
              [isConsultationUpdate]="isConsultationUpdate"
              (isMainVitalsRecieved)="PatientsVitalsChenges($event)"
              (isMainServicesRecieved)="PatientsMainServicesChenges($event)"
              (isNotesRecieved)="PatientsNotesChenges($event)"
              (isConsultationRecieved)="PatientsConsultationChenges($event)"
              [isMainDiagnosisUpdate]="isMainDiagnosisUpdate"
              (isMainDiagnosisRecieved)="PatientsMainDiagnosisChenges($event)"
            ></app-main>
          </div>
        </div>
        <div id="HistoryTaking" class="tab-pane fade">
          <div *ngIf="isHistoryTakingShow">
            <app-hist-taking [patient]="patient"></app-hist-taking>
          </div>
        </div>
        <div class="card d-print-none">
          <div class="card-body user-card-body">
            <div class="row">
              <div class="col-sm-12 col-12">
                <div class="">
                  <button
                    class="btn btn-light-primary btn-sm mr-2 mt-2 button-width"
                    (click)="changeBed()"
                  >
                    Transfer
                  </button>
                  <button
                    class="btn btn-light-primary btn-sm mr-2 mt-2 button-width"
                  >
                    Assessment
                  </button>
                  <button
                    class="btn btn-light-primary btn-sm mr-2 mt-2 button-width"
                  >
                    Prescription
                  </button>
                  <button
                    class="btn btn-light-primary btn-sm mr-2 mt-2 button-width"
                  >
                    Reminder
                  </button>
                  <button
                    class="btn btn-light-primary btn-sm mr-2 mt-2 button-width"
                  >
                    Op Theater
                  </button>
                  <button
                    class="btn btn-light-primary btn-sm mr-2 mt-2 button-width"
                    (click)="toPatientHistory()"
                  >
                    Patient Hist.
                  </button>
                  <button
                    *ngIf="patient"
                    class="btn btn-light-primary btn-sm mr-2 mt-2 button-width"
                    (click)="openHistTab()"
                  >
                    Hist Taking
                  </button>
                  <button
                    class="btn btn-light-primary btn-sm mr-2 mt-2 button-width"
                    routerLink="/patient/admission-history"
                  >
                    Adm Hist
                  </button>
                  <button
                    class="btn btn-light-primary btn-sm mr-2 mt-2 button-width"
                    *ngIf="patient"
                    [routerLink]="['/dialysis/dialysis-request/' + patient.mrn]"
                  >
                    Dialysis Req.
                  </button>
                  <button
                    class="btn btn-light-primary btn-sm mr-2 mt-2 button-width"
                    style="width: 145px"
                  >
                    Word Instructions
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--end::Content-->

<!--begin::Patient List-->
<div id="patient-list" class="offcanvas offcanvas-right p-5">
  <!--begin::Header-->
  <div
    class="offcanvas-header d-flex align-items-center justify-content-between pb-7"
  >
    <h4 class="font-weight-bold m-0">Patient List</h4>
    <button
      class="btn btn-xs btn-icon btn-light btn-hover-primary"
      (click)="closePatientList()"
    >
      <i class="ki ki-close icon-xs text-muted"></i>
    </button>
  </div>
  <!--end::Header-->
  <!--begin::Content-->
  <div class="offcanvas-content">
    <!--begin::Wrapper-->
    <div class="offcanvas-wrapper mb-5 scroll-pull">
      <form [formGroup]="PatientSearchForm" class="mb-6">
        <select
          class="form-control form-control-sm mb-3"
          (change)="updatePatientWard($event.target.value)"
        >
          <option value="">All</option>
          <option *ngFor="let ward of wardsList" [value]="ward.id">
            {{ ward.title }}
          </option>
        </select>
        <select
          class="form-control form-control-sm mb-3"
          formControlName="type"
          (change)="selectPatientType($event)"
        >
          <option value="default">All</option>
          <option value="mrn">MRN</option>
          <option value="name">Name</option>
          <option value="phone1">phone #</option>
          <option value="patient_nic">CNIC</option>
          <option value="sap_id">SAP ID</option>
          <option value="doctor_type">Doctor Name</option>
        </select>
        <select
          class="form-control form-control-sm mb-3"
          (change)="fetchDoctorPatient($event.target.value)"
          *ngIf="showDoctorList"
        >
          <option selected value="">Select Name</option>
          <option [value]="doc.id" *ngFor="let doc of doctors">
            {{ doc.first_name }} {{ doc.last_name }}
          </option>
        </select>
        <input
          type="text"
          formControlName="search_query"
          type="text"
          placeholder="MRN or CNIC or Phone #"
          class="form-control form-control-sm"
        />
        <button
          class="btn btn-primary btn-sm"
          value="Search"
          (click)="searchPatient()"
          style="
            float: right;
            margin-top: -32px;
            padding: 7px 12px;
            position: relative;
          "
        >
          <span>Search</span>
        </button>
      </form>

      <div class="patient-listing">
        <ng-container *ngFor="let patient of patients; let i = index">
          <div class="patient-panel">
            <!--begin::Item-->
            <div
              class="d-flex align-self-start px-4 pt-4 pb-0"
              (click)="patientCompleteConform(patient, i)"
            >
              <!--begin::Symbol-->
              <div class="symbol symbol-40 symbol-light-success mr-5">
                <span class="symbol-label">
                  <img
                    *ngIf="patient?.gender === 'transgender'"
                    src="assets/media/users/blank.png"
                    class="h-100 align-self-end"
                    alt=""
                  />
                  <img
                    *ngIf="patient?.gender === 'unidentified'"
                    src="assets/media/users/blank.png"
                    class="h-100 align-self-end"
                    alt=""
                  />
                  <img
                    *ngIf="patient?.gender === 'male'"
                    src="assets/media/svg/avatars/009-boy-4.svg"
                    class="h-75 align-self-end"
                    alt=""
                  />
                  <img
                    *ngIf="patient?.gender === 'female'"
                    src="assets/media/svg/avatars/002-girl.svg"
                    class="h-75 align-self-end"
                    alt=""
                  />
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <!--            flex-grow-1-->
              <div class="d-flex flex-column flex-grow-1 font-weight-bold">
                <!--              text-hover-primary-->
                <a class="text-dark mb-1 font-size-lg">{{
                  patient?.first_name + " " + patient?.last_name
                }}</a>
                <div class="d-inline">
                  <span class="text-muted mr-4">{{ patient?.mrn }}</span>
                  <span class="text-muted mr-4">{{
                    patient?.patient_type?.name | titlecase
                  }}</span>
                </div>
                <div class="d-inline">
                  <span class="text-muted mr-4"
                    >{{ patient?.age }} /
                    {{ (patient?.gender).charAt(0) | titlecase }}</span
                  >
                </div>
              </div>

              <!--end::Text-->
            </div>

            <div class="d-flex align-items-start px-4 pt-0 pb-4">
              <div class="symbol symbol-40 mr-5">
                <span
                  class="symbol-label"
                  style="background-color: transparent !important"
                >
                </span>
              </div>

              <div class="flex-column flex-grow-1">
                <span class="text-muted mt-1">{{
                  patient?.admissions[0]?.ward + ", " + patient?.bed_name
                }}</span>
              </div>
            </div>
            <!--end::Item-->
          </div>
        </ng-container>
      </div>
    </div>
    <!--end::Wrapper-->
  </div>
  <!--end::Content-->
</div>
<!--end::Patient List-->

<!--patient change Confirm Modal-->
<div class="example-preview">
  <div>
    <ng-template #patientChangeConform let-c="close" let-d="dismiss">
      <div class="">
        <form>
          <div class="modal-header p-3">
            <h4 class="modal-title">Confirmation</h4>
            <a
              type="button"
              class="close"
              aria-label="Close"
              (click)="d('Cross click')"
            >
              <span aria-hidden="true">&times;</span>
            </a>
          </div>
          <div class="modal-body">
            <div>Are you sure you want to change patient?<br /></div>
            <div class="row" *ngIf="isBedAllocate === false">
              <div class="col-sm-6 col-12 mt-5">
                <label>Assign Bed</label>
                <div class="form-group">
                  <!--                  [(ngModel)]="freeBedId" [ngModelOptions]="{standalone: true}"-->
                  <select
                    class="form-control form-control-sm"
                    [(ngModel)]="bedAllocateId"
                    name="bedAllocateId"
                  >
                    <option value="">Select</option>
                    <option
                      *ngFor="let bed of freeBedsList"
                      [value]="bed.id"
                      [disabled]="bed?.status === 'Occupied'"
                    >
                      {{ bed?.bed_number + " " + bed?.status }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer p-3">
            <a
              type="button"
              class="btn btn-sm btn-primary"
              (click)="c('Close click')"
              >Close</a
            >
            <button
              type="submit"
              class="btn btn-sm btn-primary"
              (submit)="fetchPatientInfo(patientTemp)"
              (click)="fetchPatientInfo(patientTemp)"
              *ngIf="isBedAllocate === true"
            >
              Confirm
            </button>
            <button
              type="submit"
              class="btn btn-sm btn-primary"
              (submit)="patientBedUpdate()"
              (click)="patientBedUpdate()"
              [disabled]="isBedUpdateDisabled"
              *ngIf="isBedAllocate === false"
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </ng-template>
  </div>
</div>

<!--Change Patient's Bed-->
<div class="example-preview">
  <div>
    <ng-template #changeBedModal let-c="close" let-d="dismiss">
      <div class="">
        <div class="modal-header p-3">
          <h4 class="modal-title">Transfer to</h4>
          <a
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </a>
        </div>
        <div class="modal-body">
          <form [formGroup]="changeWardForm">
            <div class="row">
              <div class="col-md-4 col-sm-6 col-12">
                <label>Ward</label>
                <div class="form-group">
                  <!--                  [(ngModel)]="freeWardId" [ngModelOptions]="{standalone: true}"-->
                  <select
                    class="form-control form-control-sm bootstrap-select-container"
                    data-size="7"
                    id="patientWard"
                    (change)="getFreeBedList($event.target.value)"
                    formControlName="wardId"
                    data-live-search="true"
                  >
                    <option value="">Select</option>
                    <ng-container *ngFor="let ward of wards">
                      <option [value]="ward.id">{{ ward?.title }}</option>
                    </ng-container>
                    <!--                    <option *ngFor="let ward of freeWardList;let i = index" [value]="ward.id">{{ward?.title}}</option>-->
                  </select>
                </div>
              </div>

              <div class="col-md-4 col-sm-6 col-12">
                <label>Bed</label>
                <div class="form-group">
                  <!--                  [(ngModel)]="freeBedId" [ngModelOptions]="{standalone: true}"-->
                  <select
                    class="form-control form-control-sm"
                    id="patientBed"
                    formControlName="bedId"
                  >
                    <option value="" disabled>Select</option>
                    <!--                      <span *ngIf="patient?.bed_id === bed?.id">(Allocated)</span>-->
                    <option
                      *ngFor="let bed of freeBedsList"
                      [value]="bed.id"
                      [disabled]="bed?.status === 'Occupied'"
                    >
                      {{ bed?.bed_number + " " + bed?.status }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md-4 col-sm-6 col-12">
                <label>Condition</label>
                <div class="form-group">
                  <select
                    class="form-control form-control-sm"
                    formControlName="condition"
                  >
                    <option value="normal">Normal</option>
                    <option value="abnormal">Abnormal</option>
                  </select>
                </div>
              </div>

              <div class="col-md-4 col-sm-6 col-12">
                <label>Inform to Dr.</label>
                <div class="form-group">
                  <select
                    class="form-control form-control-sm"
                    formControlName="informing_dr"
                  >
                    <option value="">Select</option>
                    <option *ngFor="let doc of doctorsAll" [value]="doc.id">
                      {{ doc?.first_name }} {{ doc?.last_name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md-4 col-sm-6 col-12">
                <label>Via</label>
                <div class="form-group">
                  <select
                    class="form-control form-control-sm"
                    formControlName="via"
                  >
                    <option value="">Select</option>
                    <option value="Call">Call</option>
                    <option value="Message">Message</option>
                  </select>
                </div>
              </div>

              <div class="col-md-4 col-sm-6 col-12">
                <div class="form-group">
                  <label>Response</label>
                  <input
                    class="form-control form-control-sm"
                    placeholder="Response"
                    formControlName="response"
                  />
                </div>
              </div>

              <div class="col-12">
                <div class="form-group">
                  <label>Comments</label>
                  <input
                    class="form-control form-control-sm"
                    placeholder="Comments"
                    formControlName="comments"
                  />
                </div>
              </div>
            </div>

            <div class="row mt-3 text-right">
              <div class="col-12">
                <button
                  type="submit"
                  class="btn btn-sm btn-primary ml-2"
                  (click)="printPatientBedHistory()"
                >
                  Print
                </button>
                <a
                  type="button"
                  class="btn btn-sm btn-light-primary ml-2"
                  (click)="c('Close click')"
                  >Close</a
                >
                <button
                  type="submit"
                  class="btn btn-sm btn-primary ml-2"
                  (submit)="updatePatientBed()"
                  (click)="updatePatientBed()"
                >
                  Save
                </button>
              </div>
            </div>
          </form>

          <!--          <div>-->
          <app-transfer-history [patient]="patient"></app-transfer-history>
          <!--          </div>-->
        </div>
      </div>
    </ng-template>
  </div>
</div>
