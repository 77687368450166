import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StringReplacementPipe } from '../custom-pipes/stringReplacement.pipe';
import { CustomDateConversionPipe } from '../custom-pipes/custom-date-conversion.pipe';
import { CustomDateFormatPipe } from '../custom-pipes/custom-date-format-pipe';
import { CustomTimeFormatPipe } from '../custom-pipes/custom-time-format-pipe';
import { CustomDateTimeFormatPipe } from '../custom-pipes/custom-date-time-format-pipe';
@NgModule({
  declarations: [
    StringReplacementPipe,
    CustomDateConversionPipe,
    CustomDateFormatPipe,
    CustomTimeFormatPipe,
    CustomDateTimeFormatPipe,
  ],
  imports: [CommonModule],
  exports: [
    StringReplacementPipe,
    CustomDateConversionPipe,
    CustomDateFormatPipe,
    CustomTimeFormatPipe,
    CustomDateTimeFormatPipe,
  ],
})
export class CustomPipeModuleModule {}
