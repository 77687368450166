import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MenuOverviewComponent } from './general/menu-overview/menu-overview.component';
import { LabParameterService } from '../services/lab-parameter.service';

// @ts-ignore
const routes: Routes = [
  { path: 'menu-details', component: MenuOverviewComponent },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'pharmacy',
    loadChildren: () =>
      import('./pharmacy/pharmacy.module').then((m) => m.PharmacyModule),
  },
  {
    path: 'ot',
    loadChildren: () => import('./ot/ot.module').then((m) => m.OtModule),
  },
  {
    path: 'employee-profile',
    loadChildren: () =>
      import(
        './human-resource-management/employee-profile/employee-profile.module'
      ).then((m) => m.HrModule),
  },
  {
    path: 'pharmacy-config',
    loadChildren: () =>
      import('./pharmacy-configurator/pharmacy-configurator.module').then(
        (m) => m.PharmacyConfiguratorModule,
      ),
  },
  {
    path: 'radiology',
    loadChildren: () =>
      import('./radiology/radiology.module').then((m) => m.RadiologyModule),
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('./system-reports/system-reports.module').then(
        (m) => m.SystemReportsModule,
      ),
  },
  {
    path: 'patient',
    loadChildren: () =>
      import('./patient/patient.module').then((m) => m.PatientModule),
  },
  {
    path: 'payroll-settings',
    loadChildren: () =>
      import(
        './human-resource-management/payroll-settings/payroll-settings.module'
      ).then((m) => m.PayrollSettingsModule),
  },
  {
    path: 'lab',
    loadChildren: () => import('./lab/lab.module').then((m) => m.LabModule),
  },
  {
    path: 'opd',
    loadChildren: () => import('./opd/opd.module').then((m) => m.OpdModule),
  },
  {
    path: 'ipd',
    loadChildren: () => import('./ipd/ipd.module').then((m) => m.IpdModule),
  },
  {
    path: 'configuration',
    loadChildren: () =>
      import('./configuration/configuration.module').then(
        (m) => m.ConfigurationModule,
      ),
  },
  {
    path: 'ward',
    loadChildren: () => import('./ward/ward.module').then((m) => m.WardModule),
  },
  {
    path: 'billing',
    loadChildren: () =>
      import('./billing/billing.module').then((m) => m.BillingModule),
  },
  {
    path: 'stock',
    loadChildren: () =>
      import('./stock/stock.module').then((m) => m.StockModule),
  },
  {
    path: 'sale',
    loadChildren: () => import('./sale/sale.module').then((m) => m.SaleModule),
  },
  {
    path: 'pricing',
    loadChildren: () =>
      import('./pricing/pricing.module').then((m) => m.PricingModule),
  },
  {
    path: 'doctor',
    loadChildren: () =>
      import('./doctor/doctor.module').then((m) => m.DoctorModule),
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./account/account.module').then((m) => m.AccountModule),
  },
  {
    path: 'department',
    loadChildren: () =>
      import('./department/department.module').then((m) => m.DepartmentModule),
  },
  {
    path: 'job-application',
    loadChildren: () =>
      import(
        './human-resource-management/job-application/job-application.module'
      ).then((m) => m.JobApplicationModule),
  },
  {
    path: 'roster',
    loadChildren: () =>
      import('./human-resource-management/roster/roster.module').then(
        (m) => m.RosterModule,
      ),
  },

  {
    path: 'system-config',
    loadChildren: () =>
      import('./system-configurations/system-configurations.module').then(
        (m) => m.SystemConfigurationsModule,
      ),
  },
  {
    path: 'general-settings',
    loadChildren: () =>
      import(
        './human-resource-management/general-settings/general-settings.module'
      ).then((m) => m.GeneralSettingsModule),
  },
  // { path: 'manage-employees', loadChildren: () => import('./human-resource-management/manage-employees/manage-employees.module').then(m => m.ManageEmployeesModule)},
  {
    path: 'inventory/settings',
    loadChildren: () =>
      import(
        './inventory-system/inventory-general-settings/inventory-general-settings.module'
      ).then((m) => m.InventoryGeneralSettingsModule),
  },
  {
    path: 'inventory/status',
    loadChildren: () =>
      import('./inventory-system/status/status.module').then(
        (m) => m.StatusModule,
      ),
  },
  // { path: 'inventory/status', loadChildren: () => import('./inventory-system/s') },

  {
    path: 'inventory/store',
    loadChildren: () =>
      import(
        './inventory-system/inventory-store-management/inventory-store-management.module'
      ).then((m) => m.InventoryStoreManagementModule),
  },
  {
    path: 'inventory/product',
    loadChildren: () =>
      import('./inventory-system/product-catalog/product-catalog.module').then(
        (m) => m.ProductCatalogModule,
      ),
  },
  {
    path: 'inventory/material',
    loadChildren: () =>
      import('./inventory-system/material/material.module').then(
        (m) => m.MaterialModule,
      ),
  },
  {
    path: 'inventory/purchase-request',
    loadChildren: () =>
      import(
        './inventory-system/purchase-request/purchase-request.module'
      ).then((m) => m.PurchaseRequestModule),
  },
  {
    path: 'inventory/purchase-order',
    loadChildren: () =>
      import('./inventory-system/purchase-order/purchase-order.module').then(
        (m) => m.PurchaseOrderModule,
      ),
  },

  {
    path: 'inventory/dashboard',
    loadChildren: () =>
      import(
        './inventory-system/inventory-dashboard/inventory-dashboard.module'
      ).then((m) => m.InventoryDashboardModule),
  },

  {
    path: 'inventory/vendor',
    loadChildren: () =>
      import('./inventory-system/vendor/vendor.module').then(
        (m) => m.VendorModule,
      ),
  },
  {
    path: 'inventory/reports',
    loadChildren: () =>
      import('./inventory-system/reports/reports.module').then(
        (m) => m.ReportsModule,
      ),
  },
  {
    path: 'inventory/warehouse-transfer',
    loadChildren: () =>
      import(
        './inventory-system/warehouse-transfer/warehouse-transfer.module'
      ).then((m) => m.WarehouseTransferModule),
  },

  {
    path: 'system-settings',
    loadChildren: () =>
      import('./system-configurations/system-configurations.module').then(
        (m) => m.SystemConfigurationsModule,
      ),
  },
  {
    path: 'pricing',
    loadChildren: () =>
      import('./pricing/pricing.module').then((m) => m.PricingModule),
  },
  {
    path: 'general-settings',
    loadChildren: () =>
      import(
        './human-resource-management/general-settings/general-settings.module'
      ).then((m) => m.GeneralSettingsModule),
  },
  {
    path: 'payroll',
    loadChildren: () =>
      import('./human-resource-management/payroll/payroll.module').then(
        (m) => m.PayrollModule,
      ),
  },
  {
    path: 'misc-services',
    loadChildren: () =>
      import('./misc-services/misc-services.module').then(
        (m) => m.MiscServicesModule,
      ),
  },
  {
    path: 'blood-bank',
    loadChildren: () =>
      import('./blood-bank/blood-bank.module').then((m) => m.BloodBankModule),
  },
  {
    path: 'campaign',
    loadChildren: () =>
      import('./campaign/campaign.module').then((m) => m.CampaignModule),
  },
  {
    path: 'questionnaire',
    loadChildren: () =>
      import('./questionnaire/questionnaire.module').then(
        (m) => m.QuestionnaireModule,
      ),
  },
  {
    path: 'leave',
    loadChildren: () =>
      import('./human-resource-management/leave/leave.module').then(
        (m) => m.LeaveModule,
      ),
  },
  {
    path: 'loan',
    loadChildren: () =>
      import('./human-resource-management/loan/loan.module').then(
        (m) => m.LoanModule,
      ),
  },
  {
    path: 'slot-management',
    loadChildren: () =>
      import('./slot-management/slot-management.module').then(
        (m) => m.SlotManagementModule,
      ),
  },
  {
    path: 'dialysis',
    loadChildren: () =>
      import('./dialysis/dialysis.module').then((m) => m.DialysisModule),
  },
  {
    path: 'emergency',
    loadChildren: () =>
      import('./emergency/emergency.module').then((m) => m.EmergencyModule),
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'patient-history',
    loadChildren: () =>
      import('./patient-history/patient-history.module').then(
        (m) => m.PatientHistoryModule,
      ),
  },
  {
    path: 'pdf-reports',
    loadChildren: () =>
      import('./pdf-reports/pdf-reports.module').then(
        (m) => m.PdfReportsModule,
      ),
  },
  {
    path: 'finance/configuration',
    loadChildren: () =>
      import('./finance/configuration/configuration.module').then(
        (m) => m.ConfigurationModule,
      ),
  },
  {
    path: 'complaint-management',
    loadChildren: () =>
      import('./complaint-management/complaint-management.module').then(
        (m) => m.ComplaintManagementModule,
      ),
  },
  {
    path: 'finance/voucher-managing',
    loadChildren: () =>
      import('./finance/voucher-managing/voucher-managing.module').then(
        (m) => m.VoucherManagingModule,
      ),
  },
  {
    path: 'finance/reports',
    loadChildren: () =>
      import('./finance/reports/reports.module').then((m) => m.ReportsModule),
  },
  {
    path: 'finance/budgets',
    loadChildren: () =>
      import('./finance/budget/budget.module').then((m) => m.BudgetModule),
  },
  {
    path: 'finance/manage-payable',
    loadChildren: () =>
      import(
        './finance/manage-vendor-payables/manage-vendor-payables.module'
      ).then((m) => m.ManageVendorPayablesModule),
  },
  {
    path: 'finance/bank-reconciliation',
    loadChildren: () =>
      import('./finance/bank-reconciliation/bank-reconciliation.module').then(
        (m) => m.BankReconciliationModule,
      ),
  },
  {
    path: 'patient-deceased',
    loadChildren: () =>
      import('./patient-deceased/patient-deceased.module').then(
        (m) => m.PatientDeceasedModule,
      ),
  },
  {
    path: 'patient-baby-born',
    loadChildren: () =>
      import('./patient-baby-born/patient-baby-born.module').then(
        (m) => m.PatientBabyBornModule,
      ),
  },
  {
    path: 'employee-visiting',
    loadChildren: () =>
      import(
        './human-resource-management/visiting-employee-configuration/visiting-employee-configuration.module'
      ).then((m) => m.VisitingEmployeeConfigurationModule),
  },
  {
    path: 'create-reservation',
    loadChildren: () =>
      import('./create-reservation/create-reservation.module').then(
        (m) => m.CreateReservationModule,
      ),
  },
  {
    path: 'donation-registration',
    loadChildren: () =>
      import('./donation-registration/donation-registration.module').then(
        (m) => m.DonationRegistrationModule,
      ),
  },
  {
    path: 'indicator',
    loadChildren: () =>
      import('./indicator/indicator.module').then((m) => m.IndicatorModule),
  },
  {
    path: 'task-management',
    loadChildren: () =>
      import('./task-management/task-management.module').then(
        (m) => m.TaskManagementModule,
      ),
  },
  {
    path: 'worker',
    loadChildren: () =>
      import('./worker/worker.module').then((m) => m.WorkerModule),
  },
  {
    path: 'slot',
    loadChildren: () =>
      import('./appointment-and-booking/appointment-and-booking.module').then(
        (m) => m.AppointmentAndBookingModule,
      ),
  },
  {
    path: 'cardiac',
    loadChildren: () =>
      import('./cardiac/cardiac.module').then((m) => m.CardiacModule),
  },
  {
    path: 'system-admin',
    loadChildren: () =>
      import('./system-administration/system-administration.module').then(
        (m) => m.SystemAdministrationModule,
      ),
  },
  {
    path: 'que-management',
    loadChildren: () =>
      import('./que-management/que-management.module').then(
        (m) => m.QueManagementModule,
      ),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [LabParameterService],
})
export class PagesRoutingModule {}
