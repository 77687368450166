import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  data = {
    title: 'Title',
    first_name: 'First Name',
    // registration_no: 'Registration No.',
    last_name: 'Last Name',
    guardian: 'Guardian',
    husband: 'Husband',
    blood_group: 'Blood group',
    gender: ' Gender',
    age: 'Age',
    date_of_birth: 'Date of Birth',
    cnic: 'CNIC',
    contact: 'Contact',
    number: 'Number',
    old_mrn: 'Ref./Old MRN',
    ref_no: 'MC Ref.No',
    email: 'Email',
    country: 'Country',
    passport: 'Passport',
    emp_sap_id: 'Std/Emp.SAP ID',
    emp_id: 'Employee ID',
    worker_sap_id: 'Registration No.',
    state: 'State',
    city: 'City',
    location: 'Location',
    clinic: 'Clinic',
    address: 'Address',
    patient_type: 'Patient Type',
    worker_type: 'Types Of Worker',
    medical_approval_date: 'Medical Approval Date',
    valid_until: 'Valid Until',
    company: 'Company',
    group: 'Group',
    primary_mrn: 'Guardian MRN',
    primary_name: 'Guardian Name',
    rel_with_patient: 'Guardian Relation',
    patient_heading: 'Patient Information',
    worker_heading: 'Worker Information',
    mlc: 'MLC',
    picture: 'Get Picture',
    impression: 'Scan Thumb',
    select: 'Select',
    guardian_name: 'Guardian Name',
    relation: 'Relation',
    guardian_cnic: 'Guardian CNIC',
    police: 'Police Officer',
    belt_number: 'Belt Number',
    police_station: 'Police Station',
    all: 'All',
    name: 'Name',
    phone: 'Phone',
    name_of_recruiting_agency: 'Name Of Recruiting Agency',
    address_of_recruiting_agency: 'Address Of Recruiting Agency',
    secondary_contact: 'Secondary Contact',
    primary_number: 'Primary Number',
    zip_code: 'ZIP',
    race: 'Race',
    ethnicity: 'Ethnicity',
    employment_status: 'Employment Status',
    account_status: 'Account Status',
    contact_new: 'Contact',
    ssn: 'SSN',
    written_contact_preference: 'Written Contact Preference',
    alternate_contact: 'Alternate Contact',
  };
  dataSpanish = {
    title: 'Título',
    first_name: 'Primer nombre',
    last_name: 'Apellido',
    guardian: 'guardián',
    husband: 'Esposo',
    blood_group: 'Grupo sanguíneo',
    gender: 'Género',
    age: 'Años',
    date_of_birth: 'Fecha de nacimiento',
    cnic: 'CNIC',
    contact: 'Contacto',
    number: 'Número',
    old_mrn: 'Ref./Antiguo MRN',
    email: 'Correo electrónico',
    country: 'País',
    passport: 'Pasaporte',
    emp_sap_id: 'ID de SAP estándar/emp.',
    emp_id: 'ID de empleado',
    state: 'Estado',
    city: 'Ciudad',
    location: 'Ubicación',
    clinic: 'Clínica',
    address: 'Dirección',
    patient_type: 'Tipo de paciente',
    company: 'Compañía',
    group: 'Grupo',
    primary_mrn: 'MRN primario',
    primary_name: 'Nombre principal',
    rel_with_patient: 'Rel.con Paciente',
    patient_heading: 'Información del paciente',
    worker_heading: 'Información del obrero',
    mlc: 'Mlc',
    picture: 'Imagen',
    impression: 'Impresión',
    select: 'Seleccione',
    guardian_name: 'Nombre del guardián',
    relation: 'relación',
    guardian_cnic: 'Guardián CNIC',
    police: 'Policía',
    belt_number: 'número de cinturón',
    police_station: 'número de la estación de policía',
    all: 'todos',
    name: 'nombre',
    phone: 'teléfono',
    secondary_contact: 'Contacto secundario',
    primary_number: 'Numero primario',
    zip_code: 'cremallera',
    race: 'raza',
    ethnicity: 'etnicidad',
    employment_status: 'Situación laboral',
    account_status: 'Estado de la cuenta',
    written_contact_preference: 'Preferencia de contacto escrito',
    contact_new: 'Contacto',
    ssn: 'SSN',
    alternate_contact: 'Contacto alternativo',
  };

  getData(): any {
    const setLanguage = localStorage.getItem('language');
    console.log('setLanguagesetLanguage', setLanguage);
    if (!setLanguage) {
      return this.data;
    } else if (setLanguage === 'spanish') {
      return this.dataSpanish;
    } else {
      return this.data;
    }
  }

  constructor() {}
}
