import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RadiologyRoutingModule } from './radiology-routing.module';
import { RadiologyService } from '../../services/radiology.service';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DepartmentsbyhospitalService } from '../../services/departmentsbyhospital.service';
import { TechAcknowledgementService } from '../../services/tech-acknowledgement.service';
import { ReportWritingComponent } from './report-writing/report-writing.component';
import { PdfServicesService } from '../../services/pdf-services.service';
import { RadiologyPdfReportComponent } from './reports/radiology-pdf-report/radiology-pdf-report.component';
import { UserService } from '../../services/user.service';
import { ConfigurationService } from '../../services/configuration.service';
import { TemplatePrintComponent } from './template-print/template-print.component';
import { ReportWritingNewComponent } from './report-writing-new/report-writing-new.component';
import { PdfReportNewComponent } from './reports/pdf-report-new/pdf-report-new.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HrService } from '../../services/hr.service';
import { AcknowledgementV2Component } from './acknowledgement-v2/acknowledgement-v2.component';
import { ReportWritingNewV2Component } from './report-writing-new-v2/report-writing-new-v2.component';
import { ReportAddendumV2Component } from './report-addendum-v2/report-addendum-v2.component';
import { PdfReportNewV2Component } from './reports/pdf-report-new-v2/pdf-report-new-v2.component';
import { DeliveryReportsV2Component } from './reports/delivery-reports-v2/delivery-reports-v2.component';
import { RadiologyServiceListingV2Component } from './radiology-service-listing-v2/radiology-service-listing-v2.component';
import { RadiologyServiceDetailV2Component } from './radiology-service-detail-v2/radiology-service-detail-v2.component';
import { DefineTemplatesV2Component } from './define-templates-v2/define-templates-v2.component';
import { ReportsReprintV2Component } from './reports/reports-reprint-v2/reports-reprint-v2.component';
import { RadiologyPacsUrlV2Component } from './radiology-pacs-url-v2/radiology-pacs-url-v2.component';
import { LogReportV2Component } from './log-report-v2/log-report-v2.component';
import { PagesModule } from '../pages.module';
import { PatientModule } from '../patient/patient.module';
import { DialysisModule } from '../dialysis/dialysis.module';

@NgModule({
  declarations: [
    ReportWritingComponent,
    RadiologyPdfReportComponent,
    TemplatePrintComponent,
    ReportWritingNewComponent,
    PdfReportNewComponent,
    DashboardComponent,
    AcknowledgementV2Component,
    ReportWritingNewV2Component,
    ReportAddendumV2Component,
    PdfReportNewV2Component,
    DeliveryReportsV2Component,
    RadiologyServiceListingV2Component,
    RadiologyServiceDetailV2Component,
    DefineTemplatesV2Component,
    RadiologyPacsUrlV2Component,
    ReportsReprintV2Component,
    LogReportV2Component,
  ],
  imports: [
    CommonModule,
    RadiologyRoutingModule,
    NgbPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    PagesModule,
    PatientModule,
    DialysisModule,
  ],
  exports: [
    RadiologyPacsUrlV2Component,
    PdfReportNewV2Component,
    RadiologyServiceDetailV2Component,
  ],
  providers: [
    RadiologyService,
    DepartmentsbyhospitalService,
    TechAcknowledgementService,
    PdfServicesService,
    UserService,
    ConfigurationService,
    HrService,
  ],
})
export class RadiologyModule {}
