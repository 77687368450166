import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class CultureConfigService {
  constructor(private http: HttpClient) {}
  addPcrConfig(pcrForm): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/pcr_configurator/?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&auth_token=' +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          type: pcrForm.type,
          name: pcrForm.name,
          short_name: pcrForm.short_name,
          code: pcrForm.code,
          range_from: pcrForm.range_from,
          range_to: pcrForm.range_to,
          low_from: pcrForm.low_from,
          low_to: pcrForm.low_to,
          medium_from: pcrForm.medium_from,
          medium_to: pcrForm.medium_to,
          high_from: pcrForm.high_from,
          high_to: pcrForm.high_to,
          comments: pcrForm.comments,
          wound_range_from: pcrForm.wound_range_from,
          wound_range_to: pcrForm.wound_range_to,
          wound_comments: pcrForm.wound_comments,
          status: pcrForm.status,
          anti_biotic: pcrForm.anti_biotic,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updatePcrConfig(pcrForm, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/api/pcr_configurator/` +
          id +
          `/?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&auth_token=' +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          type: pcrForm.type,
          name: pcrForm.name,
          short_name: pcrForm.short_name,
          code: pcrForm.code,
          range_from: pcrForm.range_from,
          range_to: pcrForm.range_to,
          low_from: pcrForm.low_from,
          low_to: pcrForm.low_to,
          medium_from: pcrForm.medium_from,
          medium_to: pcrForm.medium_to,
          high_from: pcrForm.high_from,
          high_to: pcrForm.high_to,
          comments: pcrForm.comments,
          wound_range_from: pcrForm.wound_range_from,
          wound_range_to: pcrForm.wound_range_to,
          wound_comments: pcrForm.wound_comments,
          anti_biotic: pcrForm.anti_biotic,
          status: pcrForm.status,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getPcrConfigList(page, perPage): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/pcr_configurator/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&page=' +
          page +
          '&per_page=' +
          perPage +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getSinglePcrList(id): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/pcr_configurator/` +
          id +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  index(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/culture_configurations?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  indexV2(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/culture_configurations?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getOrganism(type): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/pcr_configurator/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          type +
        '&status=true',
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getDetector(type): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/pcr_configurator/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&type=' +
          type +
        '&status=true',
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  indexByType(value): Observable<any> {
    const results = this.http
      .get(
        environment.rorUrl +
          '/v4/culture_configurations/filter_culture_type?culture_type=' +
          value +
          '&auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  create(cultureConfiguration): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/culture_configurations`,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
          culture_configuration: cultureConfiguration,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  createV2(cultureConfiguration): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/culture_configurations/?` +
          '&auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          culture_configuration: cultureConfiguration,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateEhm(cultureConfigurations): Observable<any> {
    const results = this.http
      .post(
        `${environment.rorUrl}/v4/culture_configurations/update_culture_configurations`,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
          culture_configurations: cultureConfigurations,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateEhmV2(cultureConfigurations): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/update_culture_configurations/?` +
          '&auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          culture_configurations: cultureConfigurations,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
