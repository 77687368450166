import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class DepartmentsbyhospitalService {
  constructor(private http: HttpClient) {}

  index(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/departments?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  indexDepartmentsv2(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/departments?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getServicesSlots(value, reservation_date): Observable<any> {
    const results = this.http
      .get(
        environment.pythonUrl +
          '/booking/slots/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&service_id=' +
          value +
          '&reservation_date=' +
          reservation_date,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  addServicesEvents(
    services,
    patient_id,
    employee_id,
    reservation_date,
  ): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http
      .post(
        `${environment.pythonUrl}/booking/add_booking/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          auth_token: localStorage.getItem('auth_token'),
          patient_id,
          reservation_date,
          employee_id,
          services,
        },
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }

  getChairOfDepartment(value, reservation_date): Observable<any> {
    const results = this.http
      .get(
        environment.pythonUrl +
          '/booking/list_department_services_booking/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&department_id=' +
          value +
          '&reservation_date=' +
          reservation_date,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  indexV2(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/fetch_opd_departments/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchUserDepartment(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/departments/user_departments?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchSubDeptbyService(departmentCode: string): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/departments/fetch_sub_dept_service_wise?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&department_code=' +
          departmentCode,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchSubDeptbyServicev2(departmentCode: string): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/fetch_sub_dept_service_wise/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&department_code=' +
          departmentCode,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  parent_dept(departmentId: number): Observable<any> {
    const results = this.http
      .get(
        environment.rorUrl +
          '/v4/departments/fetch_sub_department?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&department_id=' +
          departmentId,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  parent_deptV2(departmentId: number): Observable<any> {
    const results = this.http
      .get(
        environment.pythonUrl +
          '/api/fetch_sub_department/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&department_id=' +
          departmentId,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  parent_deptV2Dialysis(departmentId, departmentCode): Observable<any> {
    const results = this.http
      .get(
        environment.pythonUrl +
          '/api/fetch_sub_department/?auth_token=' +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&department_id=' +
          departmentId +
          '&department_code=' +
          departmentCode,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchServices(departmentId: number): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/departments/fetch_department_services?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&department_id=' +
          departmentId,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchServicesV2(departmentId: number): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/fetch_department_services/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&department_id=' +
          departmentId,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchAllServices(departmentId: number): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/departments/fetch_parent_wise_department_services?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&department_id=' +
          departmentId,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchAllServicesV2(departmentId: number): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/fetch_department_services/fetch_parent_wise_department_services/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&department_id=' +
          departmentId,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getOpdDepartments(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/departments/get_opd_departments?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getOpdDepartmentsList(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/departments/fetch_opd_departments?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // Python Start
  getOpdDepartmentsListV2(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/fetch_opd_departments?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getServicesOfDepartAndSubDepart(copyPrice): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/v2/services_dept_sub_dept_wise/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&department=' +
          copyPrice.department +
          '&sub_department=' +
          copyPrice.sub_department,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  // Python End
  getIPDDepartmentsList(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/departments/get_ipd_departments?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getIPDDepartmentsListV2(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/get_ipd_departments/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchDepartmentByHospital(medial_id): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/departments?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          medial_id,
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchSubDept(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/departments/fetch_sub_dept?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  fetchMainDepartments(): Observable<any> {
    const results = this.http
      .get(
        `${environment.rorUrl}/v4/departments?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getServicesAgainstSubDepartments(subDepartment: any): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/finance/dept_wise_doctor_services/dept_wise_service/?department=${subDepartment}&auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
