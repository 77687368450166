<!--begin::Content-->
<!--<div class="content d-flex flex-column flex-column-fluid" id="kt_content">-->

<!--begin::Subheader-->
<!--  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">-->
<!--    <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">-->
<!--      &lt;!&ndash;begin::Info&ndash;&gt;-->
<!--      <div class="d-flex align-items-center flex-wrap mr-1">-->
<!--        &lt;!&ndash;begin::Page Heading&ndash;&gt;-->
<!--        <div class="d-flex align-items-baseline flex-wrap mr-5">-->
<!--          &lt;!&ndash;begin::Page Title&ndash;&gt;-->
<!--          <h3 class="card-label" *ngIf="this.currentId">Update Radiology</h3>-->
<!--          <h3 class="card-label" *ngIf="!this.currentId">New Radiology</h3>-->
<!--          &lt;!&ndash;          <h5 class="text-dark font-weight-bold my-1 mr-5">Minimized Aside</h5>&ndash;&gt;-->
<!--          &lt;!&ndash;end::Page Title&ndash;&gt;-->
<!--          &lt;!&ndash;begin::Breadcrumb&ndash;&gt;-->
<!--          &lt;!&ndash;          <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm">&ndash;&gt;-->
<!--          &lt;!&ndash;            <li class="breadcrumb-item">&ndash;&gt;-->
<!--          &lt;!&ndash;              <a  class="text-muted">General</a>&ndash;&gt;-->
<!--          &lt;!&ndash;            </li>&ndash;&gt;-->
<!--          &lt;!&ndash;            <li class="breadcrumb-item">&ndash;&gt;-->
<!--          &lt;!&ndash;              <a  class="text-muted">Minimized Aside</a>&ndash;&gt;-->
<!--          &lt;!&ndash;            </li>&ndash;&gt;-->
<!--          &lt;!&ndash;          </ul>&ndash;&gt;-->
<!--          &lt;!&ndash;end::Breadcrumb&ndash;&gt;-->
<!--        </div>-->
<!--        &lt;!&ndash;end::Page Heading&ndash;&gt;-->
<!--      </div>-->
<!--      &lt;!&ndash;end::Info&ndash;&gt;-->
<!--    </div>-->
<!--  </div>-->
<!--end::Subheader-->

<!--begin::Entry-->
<!--  <div class="d-flex flex-column-fluid main-content">-->
<!--begin::Container-->
<!-- container-xl container-xxl -->
<!--    <div class="container-fluid">-->
<!--      <div class="card card-custom">-->
<!--        <div class="card-header">-->
<!--          <div class="card-title">-->
<!--            <h3 class="card-label" *ngIf="this.currentId">Update Pharmacy</h3>-->
<!--            <h3 class="card-label" *ngIf="!this.currentId">Add Pharmacy</h3>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="card-body">-->
<form [formGroup]="RadiologyForm" (ngSubmit)="createRadiology()">
  <div class="row">
    <!--Sub Department-->
    <div class="col-4 required">
      <div class="form-group">
        <label>Sub Department <span class="text-danger">*</span></label>
        <select
          autofocus
          class="form-control form-control-sm bootstrap-select-container"
          formControlName="department_id"
          id="department_id"
          data-container="body"
          data-live-search="true"
          data-none-selected-text="Select Sub Department"
          [ngClass]="{ 'is-invalid': submitted && f.department_id.errors }"
        >
          -->
          <option value="" [selected]="true" [disabled]="true">
            Select Sub Department
          </option>
          <option
            [value]="department.id"
            *ngFor="let department of departments"
          >
            {{ department.title }}
          </option>
        </select>
        <div
          *ngIf="submitted && f.department_id.errors"
          class="invalid-feedback"
        >
          <div *ngIf="f.department_id.errors.required">
            Department is required
          </div>
        </div>
      </div>
    </div>
    <!--Name-->
    <div class="col-4 required">
      <div class="form-group">
        <label>Name <span class="text-danger">*</span></label>
        <input
          type="text"
          formControlName="service_name"
          class="form-control form-control-sm"
          placeholder="Enter Service Name"
          [ngClass]="{ 'is-invalid': submitted && f.service_name.errors }"
        />
        <div
          *ngIf="submitted && f.service_name.errors"
          class="invalid-feedback"
        >
          <div *ngIf="f.service_name.errors.required">
            Service name is required
          </div>
        </div>
      </div>
    </div>
    <!--Contrast-->
    <div class="col-4 required">
      <div class="form-group">
        <label>Contrast <span class="text-danger">*</span></label>
        <select
          class="form-control form-control-sm"
          formControlName="contrast"
          data-none-selected-text="Select Contrast"
          [ngClass]="{ 'is-invalid': submitted && f.contrast.errors }"
        >
          <option value="" [selected]="true" [disabled]="true">
            Select Contrast
          </option>
          <option [value]="co" *ngFor="let co of contrast_option">
            {{ co }}
          </option>
        </select>
        <div *ngIf="submitted && f.contrast.errors" class="invalid-feedback">
          <div *ngIf="f.contrast.errors.required">Contrast is required</div>
        </div>
      </div>
    </div>
    <!--CPT Code-->
    <div class="col-4 required">
      <div class="form-group">
        <label>CPT Code <span class="text-danger">*</span></label>
        <input
          type="text"
          formControlName="cpt_code"
          class="form-control form-control-sm"
          placeholder="Enter CPT Code"
          [ngClass]="{ 'is-invalid': submitted && f.cpt_code.errors }"
        />
        <div *ngIf="submitted && f.cpt_code.errors" class="invalid-feedback">
          <div *ngIf="f.cpt_code.errors.required">CPT code is required</div>
        </div>
      </div>
    </div>
    <!--Region-->
    <div class="col-4 required">
      <div class="form-group">
        <label>Region <span class="text-danger">*</span></label>
        <input
          type="text"
          formControlName="body_region"
          class="form-control form-control-sm"
          placeholder="Enter Body Region"
          [ngClass]="{ 'is-invalid': submitted && f.body_region.errors }"
        />
        <div *ngIf="submitted && f.body_region.errors" class="invalid-feedback">
          <div *ngIf="f.body_region.errors.required">
            Body region is required
          </div>
        </div>
      </div>
    </div>
    <!--              Service Price-->
    <div class="col-lg-4 col-md-4 col-sm-6 col-12" *ngIf="!currentId">
      <div class="form-group">
        <label> Price <span class="text-danger">*</span></label>
        <input
          type="text"
          class="form-control form-control-sm"
          placeholder="Price"
          formControlName="service_price"
          [ngClass]="{ 'is-invalid': submitted && f.service_price.errors }"
          (keypress)="_onlyNumeric($event); noDecimalPoint($event)"
        />
        <div
          *ngIf="submitted && f.service_price.errors"
          class="invalid-feedback"
        >
          <div *ngIf="f.service_price.errors.required">Price is required</div>
        </div>
      </div>
    </div>
    <!--              patient Types-->
    <div class="col-lg-4 col-md-4 col-sm-6 col-12" *ngIf="!currentId">
      <label
        >Replicate Service price On<span class="text-danger">*</span></label
      >
      <div
        class="form-group"
        [ngClass]="{
          'is-invalid': submitted && f.replicate_service_pat_types.errors
        }"
      >
        <select
          class="form-control form-control-sm bootstrap-select-container"
          multiple
          data-live-search="true"
          data-container="body"
          id="patient_type_service"
          formControlName="replicate_service_pat_types"
          data-none-selected-text="Select Patient Type"
        >
          <!--                    <select  autofocus class="form-control form-control-sm" formControlName="copy_patient_type_id">-->
          <option value="" [disabled]="true">Select Patient Type</option>
          <option
            [value]="patient_type.id"
            *ngFor="let patient_type of patientTypes"
          >
            {{ patient_type.name }}
          </option>
        </select>
        <div
          *ngIf="submitted && f.replicate_service_pat_types.errors"
          class="invalid-feedback"
        >
          <div *ngIf="f.replicate_service_pat_types.errors.required">
            Replicate service price on is required
          </div>
        </div>
      </div>
    </div>
    <!--Status-->
    <div class="col-4 required">
      <div class="form-group">
        <div class="d-flex align-items-center justify-content-center">
          <label>Status </label>
          <span class="switch switch-icon ml-5">
            <label>
              <input
                type="checkbox"
                name="select"
                formControlName="is_active"
              />
              <span></span>
            </label>
          </span>
        </div>
      </div>
    </div>
    <div style="padding: 0px 12.5px" *ngIf="!currentId">
      <label class="checkbox checkbox-sm mt-6">
        <input
          type="checkbox"
          [(ngModel)]="isChecked"
          [ngModelOptions]="{ standalone: true }"
        />
        <span class="mr-2"></span>Do you want to apply the same service price on
        selected Patient type?
      </label>
    </div>
    <!--add button-->
    <div class="col-12 text-right">
      <br />
      <!--                <button [routerLink]="['/radiology/services-v2']" class="btn btn-primary btn-sm mr-3 cus_btm" ngbTooltip="Back to Pharmacy Index" placement="bottom">Back</button>-->
      <button
        type="button"
        class="btn btn btn-secondary btn-sm mr-2"
        data-dismiss="modal"
      >
        Close
      </button>
      <button
        type="submit"
        *ngIf="!currentId"
        [disabled]="submitRad"
        class="btn btn-primary btn-sm cus_btm"
        ngbTooltip="Add Pharmacy"
        placement="bottom"
      >
        Add<i class="fas fa-plus plus_icon"></i>
      </button>
      <button
        type="submit"
        *ngIf="currentId"
        [disabled]="submitRad"
        class="btn btn-primary btn-sm cus_btm"
        ngbTooltip="Update Pharmacy"
        placement="bottom"
      >
        Update
      </button>
    </div>
  </div>
</form>
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

<!--</div>-->
