import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PagesRoutingModule } from './pages-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VendorListingComponent } from './inventory-system/vendor/vendor-listing/vendor-listing.component';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { MenuOverviewComponent } from './general/menu-overview/menu-overview.component';
import { VisitingEmployeeConfigurationModule } from './human-resource-management/visiting-employee-configuration/visiting-employee-configuration.module';
import { UploadImagesComponent } from './shared-modules/upload-images/upload-images.component';
import { LabParameterComponent } from './shared-modules/lab-parameter/lab-parameter.component';
import { PatientDetailsComponent } from './shared-modules/patient-details/patient-details.component';
import { PatientInfoComponent } from './shared-modules/patient-info/patient-info.component';
import { PdfReportsModule } from './pdf-reports/pdf-reports.module';
import { ServicesHistoryComponent } from './shared-modules/services-history/services-history.component';
import { AdmissionHistoryComponent } from './shared-modules/admission-history/admission-history.component';
import { FamilyInfoComponent } from './shared-modules/family-info/family-info.component';
import { ServiceCostingDetailComponent } from './shared-modules/service-costing-detail/service-costing-detail.component';
import { CustomPipeModuleModule } from '../custom-pipe-module/custom-pipe-module.module';
import { SummernotesComponent } from './shared-modules/summernotes/summernotes.component';
import { ProductSearchComponent } from './shared-modules/product-search/product-search.component';

@NgModule({
  declarations: [
    VendorListingComponent,
    MenuOverviewComponent,
    UploadImagesComponent,
    LabParameterComponent,
    PatientDetailsComponent,
    PatientInfoComponent,
    ServicesHistoryComponent,
    AdmissionHistoryComponent,
    FamilyInfoComponent,
    ServiceCostingDetailComponent,
    SummernotesComponent,
    ProductSearchComponent,
  ],
    exports: [
        UploadImagesComponent,
        LabParameterComponent,
        PatientDetailsComponent,
        PatientInfoComponent,
        ServicesHistoryComponent,
        AdmissionHistoryComponent,
        FamilyInfoComponent,
        ServiceCostingDetailComponent,
        SummernotesComponent,
        ProductSearchComponent,
    ],
  imports: [
    CommonModule,
    PagesRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbPaginationModule,
    VisitingEmployeeConfigurationModule,
    CustomPipeModuleModule,
  ],
})
export class PagesModule {}
