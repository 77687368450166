<!--<div class="mb-5">-->
<!--  <h4 class="secndData">-->
<!--    <strong>{{ pageDataList?.test_name }}</strong>-->
<!--  </h4>-->
<!--</div>-->
<div class="mt-5">
  <h5><strong>Introduction</strong></h5>
</div>
<ng-container *ngFor="let data of pageDataList.lab_test_details">
  <p style="font-size: 14px; white-space: pre-line">
    {{ data?.pcr_report_details?.introduction }}
  </p>
  <div class="mt-5">
    <h5><strong>Molecular Diagnostic Test Results</strong></h5>
  </div>
  <div>
    <table class="w-75 tHeadFont mt-5 mx-auto">
      <thead>
        <tr>
          <th colspan="3" class="tHeaderSide tHeadFont text-center">
            <span *ngIf="labReportName.includes('wound'); else uti">Wound</span>
            <ng-template #uti>UTI</ng-template> Molecular Test
          </th>
        </tr>
      </thead>
      <tbody style="border: 1px solid black">
        <tr
          *ngFor="
            let organism of data?.pcr_report_details.organism;
            let i = index
          "
        >
          <td
            *ngIf="labReportName.includes('uti')"
            style="padding-left: 5px; width: 30%; text-align: center"
            [ngClass]="{
              'font-weight-bold': organism?.organism_result === 'Positive'
            }"
          >
            <span *ngIf="organism?.organism_type === 'l'">L</span>
            <span *ngIf="organism?.organism_type === 'm'">M</span>
            <span *ngIf="organism?.organism_type === 'h'">H</span>
            <span *ngIf="organism?.organism_type === ''">--</span>
          </td>
          <td style="padding-left: 5px; width: 40%">
            <span style="position: relative">
              <span
                [ngClass]="{
                  'text-left': labReportName.includes('uti'),
                  'font-weight-bold': organism?.organism_result === 'Positive'
                }"
                >{{ organism?.organism_name || "--" }}</span
              >
              <span
                *ngIf="
                  organism?.organism_comments &&
                  organism?.organism_result === 'Positive'
                "
                style="position: absolute; right: -0.8em; font-size: 11px"
                >{{ organism?.organism_comments_index }}</span
              >
            </span>
          </td>
          <td
            style="width: 20%; padding-right: 5px; text-align: right"
            [ngClass]="{
              'font-weight-bold': organism?.organism_result === 'Positive'
            }"
          >
            {{ organism?.organism_result || "--" }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="mt-5 mb-5" *ngIf="labReportName.includes('uti')">
    <p class="m-0 p-0" style="font-size: 14px">
      <strong>H:</strong> High Concentration (100,000 colony forming units per
      mL in a standard Urine Culture).
    </p>
    <p class="m-0 p-0" style="font-size: 14px">
      <strong>M:</strong> Medium Concentration (10,000 to 50,000 colony forming
      units mL in a standard Urine Culture).
    </p>
    <p class="m-0 p-0" style="font-size: 14px">
      <strong>L:</strong> Low Concentration (10,000 colony forming units per mL
      in a standard Urine Culture).
    </p>
  </div>
  <div class="mt-5">
    <p
      class="m-0 p-0"
      style="font-size: 14px"
      *ngFor="let organism of data?.pcr_report_details.organism; let i = index"
    >
      <strong
        *ngIf="
          organism?.organism_comments &&
          organism?.organism_result === 'Positive'
        "
        >{{ organism?.organism_comments_index }}.
      </strong>
      <span
        style="font-style: italic"
        *ngIf="
          organism?.organism_comments &&
          organism?.organism_result === 'Positive'
        "
        >{{ organism?.organism_comments }}</span
      >
    </p>
  </div>
  <div class="mt-5" *ngIf="data?.pcr_report_details?.detector.length">
    <h5><strong>Drug Resistance Results</strong></h5>
  </div>
  <table
    class="w-75 tHeadFont mt-5 mx-auto"
    *ngIf="data?.pcr_report_details?.detector.length"
  >
    <thead>
      <tr>
        <th colspan="3" class="tHeaderSide tHeadFont text-center">
          Drug Resistance
        </th>
      </tr>
    </thead>
    <tbody style="border: 1px solid black">
      <tr *ngFor="let detector of data?.pcr_report_details?.detector">
        <td style="width: 40%; padding-left: 5px">
          <ng-container *ngFor="let antibiotic of detector?.anti_biotic_data; let antiInd = index">
            <span
            [ngClass]="{
              'font-weight-bold': detector?.detector_result === 'R'
            }"><span *ngIf="antiInd > 0">, </span>{{ antibiotic?.name}}</span>
          </ng-container>
        </td>
        <td style="width: 30%; text-align: left">
          <ng-container *ngFor="let name of detector.detector_type_data; let deInd = index">
            <span
            [ngClass]="{
              'font-weight-bold': detector?.detector_result === 'R'
            }"><span *ngIf="deInd > 0">, </span>{{ name?.name}}</span>
          </ng-container>
        </td>
        <td style="width: 30%; text-align: right; padding-right: 5px">
          <span class="font-weight-bold">{{ detector?.detector_result }}</span>
        </td>
      </tr>
    </tbody>
  </table>
  <div
    class="mt-5"
    style="font-size: 14px"
    *ngIf="data?.pcr_report_details?.detector.length"
  >
    <div>S: Sensitive</div>
    <div>R: Resistant</div>
  </div>

  <div class="mt-5" *ngIf="data?.pcr_report_details?.result_interpretation">
    <h5><strong>Result Interpretation</strong></h5>
  </div>
  <p style="font-size: 14px; white-space: pre-line">
    {{ data?.pcr_report_details?.result_interpretation }}
    <!--  <p>-->
    <!--    The patient's wound swab specimen tested <strong>POSITIVE</strong> for the-->
    <!--    <strong>S. Epidermidis and Anaerococcus species</strong> (Anaerococcus-->
    <!--    prevotii, Anaerococcus vaginalis).-->
    <!--  </p>-->
    <!--  <p>-->
    <!--    Drug resistance panel showed that the patient is-->
    <!--    <strong>RESISTANT</strong> to-->
    <!--    <strong>Macrolide Resistant genes (mefA, mrsA).</strong>-->
    <!--  </p>-->
  </p>

  <div class="mt-5" *ngIf="data?.pcr_report_details?.limitations">
    <h5><strong>Limitations</strong></h5>
  </div>
  <p style="font-size: 14px; white-space: pre-line">
    {{ data?.pcr_report_details?.limitations }}
    <!--  <p>-->
    <!--    Performance of the Wound Panel Assay for the 19 common pathogens was-->
    <!--    established for wound specimens only. Other specimen types have not been-->
    <!--    evaluated.-->
    <!--  </p>-->
    <!--  <p>-->
    <!--    Negative results do not preclude infection with viral or other bacterial or-->
    <!--    fungal microorganisms that are not listed in the Wound Panel Assay.-->
    <!--  </p>-->
    <!--  <p>-->
    <!--    Negative results of the Drug Resistance Panel do not preclude resistance to-->
    <!--    antibiotics as there may be other mechanisms of treatment failure.-->
    <!--    Therefore, negative results should not be used as the sole basis for patient-->
    <!--    management decisions. Negative results must be combined with clinical-->
    <!--    observations, patient history, and epidemiological information.-->
    <!--  </p>-->
  </p>
</ng-container>
<div *ngIf="pageDataList?.pathologist_remarks">
  <h4 class="labTestData">Remarks:</h4>
  <div
    style="font-size: 14px"
    [innerHTML]="pageDataList?.pathologist_remarks"
  ></div>
</div>
