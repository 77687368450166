import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

export class ReservationManagmentServiceService {
  constructor(private http: HttpClient) {}

  getEmployeesListing(): Observable<any> {
    return this.http.get(`${environment.pythonUrl}/reservation/employee/`, {
      reportProgress: false,
      withCredentials: false,
      params: undefined,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `${localStorage.getItem('auth_token')}`,
      }),
    });
  }

  getReservationSlots(month: any, employees: any): Observable<any> {
    return this.http.get(
      `${environment.pythonUrl}/reservation/appointment?month=${month}&employees=${employees}` +
        '&auth_token=' +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id'),
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `${localStorage.getItem('auth_token')}`,
        }),
      },
    );
  }

  // submitReservationSlots(reservations: any, dates: any, employee_id: any, month: any): Observable<any> {
  //   // return this.http.post(`${environment.pythonUrl}/reservation/appointment/` + '&auth_token=' + localStorage.getItem('auth_token') , {
  //   //   medical_unit_id: localStorage.getItem('current_medical_unit_id'),
  //     const results = this.http.post(`${environment.pythonUrl}/hr/reporting_hierarchy?auth_token=` + localStorage.getItem('auth_token') + '&id=' + employeeId, {
  //       medical_unit_id: localStorage.getItem('current_medical_unit_id'),
  //     reservations, dates, employee_id, month
  //   }, {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Authorization': `${localStorage.getItem('auth_token')}`
  //     })
  //   });
  // }
  submitReservationSlots(
    reservations: any,
    dates: any,
    employee_id: any,
    month: any,
  ): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/reservation/appointment/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          reservations,
          dates,
          employee_id,
          month,
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
        },
        {
          headers: new HttpHeaders({
            // //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  deleteReservationSlot(id: any): Observable<any> {
    return this.http.delete(
      `${environment.pythonUrl}/reservation/appointment/${id}/?auth_token=` +
        localStorage.getItem('auth_token'),
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `${localStorage.getItem('auth_token')}`,
        }),
      },
    );
  }

  submitDoctorSlots(
    selectedSlot,
    doctorId,
    currentMonth,
    startTime,
    endTime,
    slotMins,
  ): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/doctor_slots/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          doctor_id: doctorId,
          start_time: startTime,
          end_time: endTime,
          slot_min: slotMins,
          month: currentMonth,
          slots: selectedSlot,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  getSlotsDataList(doctorId, fromDate, toType): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/api/doctor_slots/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&doctor_id=' +
          doctorId +
          '&from_date=' +
          fromDate +
          '&to_type=' +
          toType +
          '&to_count=7',
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  deleteDoctorSlots(id: any): Observable<any> {
    return this.http.delete(
      `${environment.pythonUrl}/api/doctor_slots/` +
        id +
        `/?auth_token=` +
        localStorage.getItem('auth_token') +
        '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id'),
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `${localStorage.getItem('auth_token')}`,
        }),
      },
    );
  }

  updateReserveSlot(slotId, patientId, status): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/api/reserve_slot/` +
          slotId +
          `/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          patient_id: patientId,
          status: status,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  cancelReserveSlot(slotId, patientId): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/reserve_slot/cancel_reservation/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          patient_id: patientId,
          slot_id: slotId,
        },
        {
          headers: new HttpHeaders({
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  submitSlotbooking(
    slotId,
    patientId,
    visitType,
    visitFee,
    serviceId,
    pricingId,
    doctorId,
    departmentId,
    paymentAdvance,
  ): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/api/booked_slot/?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          slot_id: slotId,
          patient: patientId,
          visit_type: visitType,
          mode_of_payment: paymentAdvance.mode_of_payment,
          visit_fee: visitFee,
          service: serviceId,
          pricing: pricingId,
          doctor: doctorId,
          department: departmentId,
          receipt_number: paymentAdvance.receipt_number,
          bank_id: paymentAdvance.bank_id,
          payment_details: paymentAdvance.payment_details,
        },
        {
          headers: new HttpHeaders({
            // //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
